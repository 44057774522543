import { ItemCategoryTypes, QuoteItemRevTypes, QuotingMethodTypes, PaintGroupTypes, LabourTimeTypes } from '@/enums'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteItemsMixin from './QuoteItemsMixin'
import { mapGetters } from 'vuex'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  mixins: [QuoteTotalsMethodMixin, QuoteItemsMixin],
  computed: {
    labourTotal() {
      return this.rrTotal + this.repairTotal + this.rwaTotal + this.paintTotal
    },
    rrTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.labours, ItemCategoryTypes.RR)
      }
    },
    repairTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.labours, ItemCategoryTypes.REP)
      }
    },
    rwaTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.labours, ItemCategoryTypes.RWA)
      }
    },
    paintTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.labours, ItemCategoryTypes.PAINT)
      }
    },
    weldHourTotal() {
      if (this.mixinEntity) {
        return this.mixinEntity.labours
          .filter((i) => !i.deleted && !i.reportOnly && i.itemDesc.toUpperCase().includes('(W)') && i.itemType === ItemCategoryTypes.RR)
          .reduce(function (total, item) {
            return total + item.hourValue
          }, 0)
      }
    },
    ...mapGetters('quotes', {
      quoteInsurer: 'quoteInsurer',
      ntarLoadingItems: 'ntarLoadingItems',
      ntarOtherLoadingItems: 'ntarOtherLoadingItems',
      ntarLoadingValues: 'ntarLoadingValues'
    })
  },
  methods: {
    updateValue(item) {
      // const itemToUpdate = this.innerValue.labours.find(i => i.quoteItemId === item.quoteItemId)
      // if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
      //   itemToUpdate.dollarValue = item.hourValue
      // } else {
      //   itemToUpdate.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      // }
      // if (item && item.deleteOtherItem && item.hourValue > 0) {
      //   item.hourValue = item.hourValue * -1
      // }
      if (this.innerValue.quotingMethod === QuotingMethodTypes.Dollar) {

        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        if (this.getLabourRateEx(item).rate === item.rate) {
          // The following if statement is for compatibility to V1
          // if (item.rev !== null) {
          item.rev = QuoteItemRevTypes.Normal
          // }
        } else {
          item.rev = QuoteItemRevTypes.SpecRate
        }
      }
      // if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
      //   this.items[index].dollarValue = this.items[index].hourValue
      // } else {
      //   this.items[index].dollarValue = roundAwayFromZero(this.items[index].hourValue * this.items[index].rate)
      // }
      this.addUpdateRepairPaintLoading(item)
      // this.updateTotal()
    },

    getLabourRateEx(item) {
      if (!this.innerValue.rates && this.vehicle) {
        return {}
      }
      const code = item.itemType !== ItemCategoryTypes.PAINT ? item.itemType : this.vehicle ? this.vehicle.paintGroup : 'S1'
      const codeType = item.itemType !== ItemCategoryTypes.PAINT ? item.itemType : ItemCategoryTypes.PAINT
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === codeType)
        const newRate = {
          quoteId: this.innerValue.quoteId,
          labourCodeId: code,
          labourTypeId: labourType.labourTypeId,
          rate: this.isNtar ? this.innerValue.shopRate : 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          quoteVersion: 0,
          deleted: false
        }
        this.innerValue.rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    async addUpdateRepairPaintLoading(item, isManualLoading = false) {
      let repairItem =
        item.itemType === ItemCategoryTypes.REP
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find((i) => i.itemType === ItemCategoryTypes.REP && i.itemNo === item.itemNo && i.itemDesc === item.itemDesc && !i.deleted)
      // console.log(repairItem)
      if (!this.isNtar || !repairItem || !this.ntarLoadingItems || !this.ntarOtherLoadingItems || !this.ntarLoadingValues || !this.quoteInsurer) {
        return
      }

      const linkedItem = this.innerValue.labours.find((i) => i.linkedItemId === repairItem.quoteItemId && i.isLoadItem && !i.deleted)
      let ntarLoadingItem = null
      if (this.innerValue.labourType === LabourTimeTypes.eMTA) {
        ntarLoadingItem = this.ntarOtherLoadingItems.find((n) => n.mtaItemDescription === repairItem.itemDesc && n.category === 'RA')
      } else {
        ntarLoadingItem = this.ntarLoadingItems.find((n) => n.itemNo === repairItem.itemNo && n.category === 'RA')
      }
      const hasPaintLoading =
        (ntarLoadingItem || isManualLoading) && (repairItem.itemType === ItemCategoryTypes.REP || repairItem.itemType === ItemCategoryTypes.PAINT)
      if (!hasPaintLoading && !linkedItem) {
        // console.log('!hasPaintLoading', repairItem.itemNo, ntarLoadingItem)
        return
      } else if (linkedItem && (repairItem.hourValue === 0 || repairItem.reportOnly)) {
        // Delete paint loading
        // console.log('Delete paint loading')
        const index = this.innerValue.labours.findIndex((i) => i.quoteItemId === linkedItem.quoteItemId)
        this.deleteItem(linkedItem, index)
        repairItem.loadingPercent = 0
        return
      }
      let paintItem =
        item.itemType === ItemCategoryTypes.PAINT
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find(
              (i) => i.itemType === ItemCategoryTypes.PAINT && i.itemNo === repairItem.itemNo && i.itemDesc === repairItem.itemDesc && !i.deleted
            )
      console.log('find paintItem', paintItem)
      let paintHour = 0
      if (!paintItem) {
        const paintItems = await this.getDefaultPaintItem(repairItem.itemNo)
        paintItem = paintItems[0]
        console.log('!paintItem', paintItem)
        paintHour = paintItem.paintHour
      } else {
        paintHour = paintItem.hourValue
        console.log('found paintItem', paintItem)
      }
      const paintLoadingItemNo = (parseInt(repairItem.itemNo, 10) + 5000).toString()

      let paintLoadingItem =
      linkedItem
      || this.innerValue.labours.find((p) => p.itemType === ItemCategoryTypes.PAINT && p.itemNo === paintLoadingItemNo && p.isLoadItem && !p.deleted)

      let isNew = false

      if(this.quoteInsurer.insurerLoadingValues.length === 0 && !isManualLoading) {
        // this.$toast.open({
        //   message: 'No loading values found for insurer, Please manually add loading values',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   duration: 3000
        // })
        this.$notification.openNotificationWithType('danger','','No loading values found for insurer, Please manually add loading values')

      }

      const loadingValue = this.quoteInsurer.insurerLoadingValues.find(
        (v) => v.category === 'RA' && repairItem.hourValue >= v.lowerThreshold && repairItem.hourValue <= v.upperThreshold
      )

      const loadingPercent = (!loadingValue ? 0 : loadingValue.value) * 100

      if (!isManualLoading && !!this.quoteInsurer.insurerLoadingValues.length)
      {
        repairItem.loadingPercent = loadingPercent

        // this.$toast.open({
        //   message: 'Loading automatically applied based on insurer\'s loading values',
        //   type: 'is-success',
        //   position: 'is-top',
        //   duration: 3000
        // })
        this.$notification.openNotificationWithType('success','','Loading automatically applied based on insurer\'s loading values')

      }

      // TODO: what is this rule? this makes the dropdown not work as it jumps back
      // TODO: if you select 0% when its set to 25%, it jumps back right to 25 again :(
      // else if (isManualLoading && loadingPercent > repairItem.loadingPercent) {
      //   repairItem.loadingPercent = loadingPercent
      // }

      // Cannot find existing loading item then create new
      if (!paintLoadingItem) {
        paintLoadingItem = new QuoteItemModel(this.innerValue.quoteId, paintLoadingItemNo, `Paint Loading, ${repairItem.itemDesc}`, ItemCategoryTypes.PAINT)
        paintLoadingItem.sortNo = this.innerValue.labours.length ? Math.max(...this.innerValue.labours.map((i) => i.sortNo)) + 1 : 1
        paintLoadingItem.opgCode = repairItem.opgCode || ''

        isNew = true
      }

      paintLoadingItem.isLoadItem = true
      paintLoadingItem.linkedItemId = repairItem.quoteItemId
      paintLoadingItem.hourValue = roundAwayFromZero((paintHour * repairItem.loadingPercent) / 100)
      paintLoadingItem.rate = this.innerValue.shopRate
      paintLoadingItem.dollarValue = roundAwayFromZero(paintLoadingItem.hourValue * this.innerValue.shopRate)
      repairItem.linkedItemId = paintLoadingItem.quoteItemId
      // console.log(paintLoadingItemNo, repairItem.loadingPercent, paintLoadingItem.isNew)
      if (isNew) {
        this.innerValue.labours.splice(this.innerValue.labours.length, 1, paintLoadingItem)
        paintLoadingItem.lineNumber = this.nextLineNumber
        this.innerValue.lines = paintLoadingItem.lineNumber + 1
        // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      }
    },
    getLabourRate(code, rates, shopRate, quoteId) {
      if (!rates) {
        return {}
      }
      const isPaintType = Object.values(PaintGroupTypes).some((p) => p === code)
      const codeType = isPaintType ? ItemCategoryTypes.PAINT : code
      let rate = this.isNtar ? rates[0] : rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === codeType)
        const newRate = {
          quoteId: quoteId,
          labourCodeId: code,
          labourTypeId: labourType.labourTypeId,
          rate: this.isNtar ? shopRate : 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          quoteVersion: 0,
          deleted: false
        }
        rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    getOpgRate(item) {
      if (this.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
        let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
        if (!rate) {
          const labourType = this.$labourTypes.find((t) => t.labourTypeCode === PaintGroupTypes.PAINT)
          const newRate = {
            quoteId: this.innerValue.quoteId,
            labourCodeId: item.opgCode,
            labourTypeId: labourType.labourTypeId,
            rate: this.isNtar ? this.innerValue.shopRate : 0,
            modifiedBy: '',
            modifiedDate: null,
            createdBy: '',
            createdDate: null,
            isNew: true,
            quoteVersion: 0,
            deleted: false
          }
          this.innerValue.rates.push(newRate)
          rate = newRate
        }
        // this.items[index].rate = rate.rate
        // this.items[index].dollarValue = this.items[index].hourValue * this.items[index].rate
        item.rate = rate.rate
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      }
      this.$emit('opg-code-changed')
      // this.updateTotal()
    }

  }
}
