<template>
  <div class="tile is-parent">
    <article class="tile is-child box">
      <repx-list :hide-filter="hideFilter"
        :quote-id="value.quoteId" />
    </article>
  </div>
</template>

<script>
import RepxList from '../repx/RepxList.vue'
export default {
  name: 'BookingRepx',
  components: { RepxList},
  props: {
    value: null
  },
  data: () => {
    return {
      hideFilter: true
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {},
  methods: {
  }
}
</script>
