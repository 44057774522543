<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body ">
          <slot name="text-content" />
          <div class="field">
            <label class="label is-required-label">Repair Late Reason</label>
            <div class="control is-extended">
              <div class="select is-fullwidth mb-3"
              >
                <select class="select"
                  placeholder="-Select reason-"
                  v-model="value.repairLateReason">
                  <option value="null">- Select Late Reason -</option>
                  <option v-for="(s, index) in storeRepairDelayReasons"
                    :key="index"
                    :value="s.id">
                    {{ s.name }}
                  </option>
                </select>
                <span class="help is-danger"
                  v-if="!$v.entity.repairLateReason.required">Repair Late Reason is required</span>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label ">Details</label>
            <div class="control is-extended">
              <textarea class="textarea"
                v-model="value.repairLateReasonDetails"
                placeholder="Textarea" />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button v-if="($user.hasEdit($route.meta.id) || $user.hasAdd($route.meta.id))"
            class="button is-success tooltip"
            @click="saveContinue()"
            data-tooltip="Save &amp; continue">Save &amp; Continue</button>
          <!-- <button class="button is-warning tooltip"
            @click="skipSave()"
            data-tooltip="Continue without save"
            :disabled="skipDisabled">Skip</button> -->
          <button class="button tooltip is-tooltip-topright"
            @click="close()"
            data-tooltip="Don't continue">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from '../../../components/BulmaModal/BaseModalEx.vue'
import storeMixin from '../storeMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'LateReasonModal',
  inject: ['$vv'],
  components: {
    BaseModalEx
  },
  mixins: [storeMixin],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    skipDisabled: {
      type: Boolean,
      default: false
    },
    onSaveContinue: {
      type: Function,
      default: () => {}
    },
    onSkipSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isActive: this.active || false,
      selectedLateReason: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    ...mapGetters('quotes', {
      storeRepairDelayReasons: 'repairDelayReasons',
      jobStages: 'jobStages'
    })
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('quotes/load')
    this.value.repairLateReason = null
    // this.value.repairLateReason = this.selectedLateReason
  },
  create() {
  },
  methods: {
    saveContinue() {
      if (this.$v.repairLateGroup.$invalid) {
        this.$notification.error('Validation Errors', 'Please fix highlighted fields')
        return
      }
      this.$emit('saveContinue')
    },
    close() {
      if (this.$v.repairLateGroup.$invalid) {
        this.$notification.error('Validation Errors', 'Please fix highlighted fields')
      }
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.modal-card-body {
  min-height: 350px; /* Set your desired minimum height */
}
</style>
