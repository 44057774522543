<template>
  <div v-if="permissions.hasRead">
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-delete="false"
      :show-save="false"
      :show-print="false"
      :show-cancel="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <quote-template-side-menu />
        <quote-template-setting-menu
          @getQuoteTemplate="getQuoteTemplate"
        />
      </div>
      <div class="column">
        <router-view />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="is-flex is-justify-content-center pt-5">
      <div class="notification is-danger is-flex is-size-4">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import QuoteTemplateSideMenu from './QuoteTemplateSideMenu'
import QuoteTemplateSettingMenu from './QuoteTemplateSettingMenu'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import StoreMixin from './storeMixin'
import QuoteTemplateRoutes from './route-types'

export default {
  name: 'QuoteTemplateView',
  components: {
    AppDetailHeader,
    QuoteTemplateSideMenu,
    QuoteTemplateSettingMenu
  },
  mixins: [ StoreMixin ],
  data() {
    return {
      template: null
    }
  },
  computed: {
    permissions() {
      return this.$user.permissions?.find(p => p.codeName == this.$route.meta.id) || null
    }
  },
  async created() {
    await this.getQuoteTemplate()
    this.checkSettingMenuConfig()
  },
  methods: {
    cancel() {
      this.flagNtarLtarItems(false)
      this.saveQuoteTemplate({ entity: null })
      this.$router.push(QuoteTemplateRoutes.QuoteTemplateListView.path)
    },
    checkSettingMenuConfig() {
      if (this.$route.meta.route == 'labour' && (!this.templateBodyId || !this.templateTimesType)) {
        // this.$toast.open({
        //   message: 'Please select labour times and body style first.',
        //   type: 'is-warning',
        //   duration: 3000
        // })
      this.$notification.openNotificationWithType('warning','','Please select labour times and body style first.',3000)

      }
    },
    async getQuoteTemplate() {
      this.template =
        await QuoteTemplateService.getQuoteTemplateByTemplateId(this.$route.params.entityId)
      this.saveQuoteTemplate({ entity: _cloneDeep(this.template) })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    }
  }
}
</script>
