<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
        <button v-if="false"
          class="button is-pulled-right is-small is-turquoise"
          @click="gotoV1">
          <span class="icon">
            <i class="mdi mdi-arrow-right" />
          </span>
          <span>V1</span>
        </button>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-uppercase"
                type="text"
                placeholder="Search..."
                v-model="innerValue.search"
                @keyup="onFilterClick()">
            </div>
            <div class="help">
              Search by
              <ul>
                <li>Quote No.</li>
                <li>Rego No.</li>
                <li>Customer Name/Phone</li>
                <li>Claim No.</li>
                <li>Car Make/Model.</li>
                <li>Key No.</li>
              </ul>

            </div>
          </div>

          <div class="field"
            @focusout="onFilterClick()">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              popover.visibility="focus"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"

                      class="input"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field"
            @focusout="onFilterClick()">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              popover-visibility="focus"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>

          <div v-if="insurers"
            class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedInsurer"
                track-by="assetId"
                label="assetName"
                placeholder="Select an insurer"
                :options="insurers"
                :loading="isInsurerLoading"
                :internal-search="false"
                :options-limit="insurerFilter.pageSize"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                @select="selectInsurer"
                @remove="removeInsurer"
                @search-change="getListComboInsurersFiltered">
                <span class="has-text-danger"
                  slot="noResult">Insurer not found.</span>
              </multiselect>
              <!-- </div> -->
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.authorisedStatus"
                  @change="onFilterClick()">
                  <option value="B">-- Auth Status --</option>
                  <option value="A">Authorised</option>
                  <option value="U">Not Authorised</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.invoiceStatus"
                  @change="onFilterClick()">
                  <option value="A">-- Inv. Status --</option>
                  <option value="I">Invoiced</option>
                  <option value="U">Not Invoiced</option>
                  <option value="P">Partially Invoiced</option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div v-if="jobStages"
            class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.jobStage">
                  <option v-for="(jobStage) in jobStages"
                    :value="jobStage.stage"
                    :key="jobStage.stage">
                    {{ jobStage.enumDescription | formatJobStage }}
                  </option>
                </select>
              </div>
            </div>
          </div> -->

          <div v-if="jobStagesObj"
            class="field">
            <div class="field multi-job-stage-select">
              <multiselect v-model="innerValue.jobStage"
                @select="onFilterClick()"
                @remove="onFilterClick()"
                :options="jobStagesObj"
                :hide-selected="true"
                :multiple="true"
                :close-on-select="false"
                :show-labels="false"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Select Job Stages"
                label="description"
                track-by="stage"
                :taggable="true" />
            </div>
          </div>

          <div class="field"
            v-if="!!includeClaimsType">
            <div class="control is-expanded">
              <multiselect v-model="selectedClaimsType"
                :options="claimsTypes"
                :preserve-search="true"
                track-by="key"
                @select="onClaimsTypeChange"
                :internal-search="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Select Claims type"
                label="description"
                :allow-empty="true">
                <span class="has-text-danger"
                  slot="noResult">Claims type not found.</span>
              </multiselect>
              <!-- </div> -->
            </div>
          </div>

          <div class="buttons has-addons">
            <span class="button"
              :class="{ 'is-primary is-selected' : innerValue.active === 0}"
              @click="changeActive(0)">
              <span class="icon is-small">
                <i class="mdi mdi-adjust" />
              </span>
              <span>Both</span>
            </span>
            <span class="button"
              :class="{ 'is-success is-selected' : innerValue.active === 1}"
              @click="changeActive(1)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span class="button"
              :class="{ 'is-danger is-selected' : innerValue.active === 2}"
              @click="changeActive(2)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>

          <!-- <div class="field">
            <div class="buttons has-addons">
              <span class="button ">Show Rows</span>
              <span class="button"
                :class="{ 'is-primary is-selected' : innerPageSize === 12}"
                @click="handlePageSizeChange(12)">
                <span>Default</span>
              </span>
              <span class="button"
                :class="{ 'is-success is-selected' : innerPageSize === 1000}"
                @click="handlePageSizeChange(1000)">
                <span>1,000</span>
              </span>
            </div>
          </div> -->

        </li>
      </ul>
      <hr>
      <div class="buttons is-right">
        <button class="button is-primary"
          @click="onFilterClick">
          <span class="icon">
            <i class="mdi mdi-magnify mdi-18px" />
          </span>
          <span>Filter</span>
        </button>
        <button class="button"
          @click="onResetClick">
          <span class="icon">
            <i class="mdi mdi-refresh mdi-18px" />
          </span>
          <span>Reset</span>
        </button>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { JobStageTypes } from '@/enums'
// import MaskedInput from 'vue-masked-input'
import Multiselect from 'vue-multiselect'
import Guid from '@/components/Guid'
import QuoteService from './QuoteService'
import _debounce from 'lodash.debounce'
import QuoteFiltersMixins from './QuoteFiltersMixins'
import { Columns } from './columns'
import cloneDeep from 'lodash.clonedeep'
import { CompanyService } from '@/services'
export default {
  name: 'QuoteListSideMenu',
  components: {
    AppSideMenu,
    // MaskedInput,
    Multiselect
  },
  mixins: [QuoteFiltersMixins],
  props: {
    value: null,
    jobStages: null,
    resetValues: null,
    includeClaimsType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.getDataObj(this.value, this.jobStages),
      insurers: [],
      selectedInsurer: null,
      isInsurerLoading: false,
      insurerFilter: {
        assetId: this.value && this.value !== Guid.empty() ? this.value.insurerId : Guid.empty(),
        assetName: '',
        showCustomer: false,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      claimsTypes: [],
      timeout: null,
      innerPageSize: 12
    }
  },
  computed: {
    jobStageTypes() {
      return JobStageTypes
    },
    selectedClaimsType() {
      return this.claimsTypes.find((i) => i.key === this.innerValue.claimsTypeKey)
    },
    jobStagesObj() {
      return this.jobStages?.map((i) => ({
        stage: i.stage,
        description: i.description || i.enumDescription
      }))
    },
    columns() {
      return Columns
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },

    selectedFromDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateFrom = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.dateFrom = null
      }
      // this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
    },
    selectedToDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.dateTo = null
      }
      // this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
    }
  },
  beforeDestroy() {},
  created() {
    this.getListComboInsurers('')
    if (this.value.dateFrom) {
      this.selectedFromDate = new Date(this.value.dateFrom)
    }
    if (this.value.dateTo) {
      this.selectedToDate = new Date(this.value.dateTo)
    }
    if (this.value.pageSize) {
      this.innerPageSize = this.value.pageSize
    }
    if (!!this.includeClaimsType) {
      this.getClaimTypes()
    }
  },
  mounted() {},
  methods: {
    handleBlur() {
      console.log('Date picker blurred!')
    },
    getDataObj(value, jobStages) {
      // jobstages need to be converted from flat array of stageIds
      // to array of objects with stageId and description, etc

      if (!value.jobStage?.length || !jobStages?.length) {
        return { ...value, jobStage: [] }
      }
      return {
        ...value,
        jobStage: jobStages.filter((i) => value?.jobStage.includes(i.stage))
      }
    },
    onFilterClick() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('filter')
      }, 500)
      // this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      // this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''

    },

    onResetClick() {
      this.selectedInsurer = null
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
      this.innerValue = cloneDeep(this.resetValues)
    },
    changeActive(value) {
      this.innerValue.active = value
      this.onFilterClick()
    },
    selectInsurer(selected) {
      this.innerValue.insurerId = selected.assetId
      this.insurerFilter.assetId = selected.assetId
      this.innerValue.insurerName = selected.assetName
      this.onFilterClick()
    },
    removeInsurer() {
      this.innerValue.insurerId = Guid.empty()
      this.insurerFilter.assetId = Guid.empty()
      this.innerValue.insurerName = ''
      this.onFilterClick()
    },
    async getListComboInsurers(query) {
      this.isInsurerLoading = true
      this.insurerFilter.assetName = query
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      this.selectedInsurer = this.insurers.find((i) => i.assetId === this.insurerFilter.assetId)
      this.isInsurerLoading = false
    },
    getListComboInsurersFiltered: _debounce(async function (query) {
      this.getListComboInsurers(query)
    }, 500),
    gotoV1() {
      window.open(`${process.env.VUE_APP_ROOT_URI}/modules/quotesview/quotes.aspx`, '_blank')
    },
    async getClaimTypes() {
      this.claimsTypes = await CompanyService.getClaimsTypes()
    },
    onClaimsTypeChange(val) {
      this.innerValue.claimsTypeKey = val.key
      this.onFilterClick()
    }
    // handlePageSizeChange(pageSize) {
    //   this.innerPageSize = pageSize
    //   this.value.pageSize = this.innerPageSize
    //   this.onFilterClick()
    // }
  }
}
</script>

<style lang="scss">
</style>

<style lang="scss" >
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}

select.select-width {
  min-width: 15em;
}

.multiselect__tag {
  margin: 2px;
}

.multi-job-stage-select {
  max-width: clamp(15vw, 18vw, 18vw);
}
</style>
