<template>
  <div>
    <app-detail-header
      v-if="entity"
      :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-save="!!entity"
      :show-lock="false"
      :show-delete="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked"
    />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <sub-job-stage-side-menu v-if="entity"
          ref="sideMenu"
          :entity-name="`${entity.subJobStageName ??''}`"
          v-model="entity" />
      </div>
      <div class="column">
        <div class="tile is-ancestor is-parent">
          <div class="tile">
            <div class="tile is-parent is-half">
              <article class="tile is-child box detail-page-tile">
                <router-view v-if="entity"
                  v-model="entity"
                  ref="currentChild"
                  :is-tiled="false"
                  :key="$route.fullPath"
                  :route-name="$route.name" />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal
      v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'"
    >
      <p slot="text-title">Delete Item</p>
      <p slot="text-content">
        Item
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.itemNo}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import SubJobStageService from './SubJobStageService'
import SubJobStageValidation from '../subjobstage/SubJobStageValidation'
import SubJobStageRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import SubJobStageSideMenu from '../subjobstage/SubJobStageSideMenu.vue'

export default {
  name: 'SubJobStageView',
  components: {
    AppDetailHeader,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal,
    SubJobStageSideMenu
  },
  mixins: [SubJobStageValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isDelete: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    }
  },
  watch: {
    entity: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    this.clearSessionStorage()
    this.clearSnapshots(this.entity.subJobStageId)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  async created() {
    this.getEntity()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntityReload, async () => {
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReloaded)
    })
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.subJobStageId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.subJobStageId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(deleted) {
      const title = 'Sub Job Stage'
      if (this.validateError && !deleted) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await SubJobStageService.postSubJobStages(this.entity)
        } else if (this.snapshotDiff) {
          await SubJobStageService.putSubJobStages(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        this.$notification.success(title, deleted ? 'Sub Job Stage deleted' : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$notification.openMessageXhrError(title, e)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.isDelete = true
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            this.clearSnapshots(this.entity.id)
          }
          this.$router.push({
            name: SubJobStageRoutes.SubJobStageListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|item|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|item|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|item|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|item|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id, source) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: SubJobStageRoutes.SubJobStageDetail.name,
        params: { id: id, source: source },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.isDelete) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      this.clearSnapshots(this.entity.subJobStageId)
    }
    this.clearSessionStorage()
    this.clearRouteMeta()
    next()
  }
}
</script>
