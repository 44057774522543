<template>
  <div class="tile">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns mb-0 pb-0">
          <div class="column pb-0">
            <span class="title mb-2">
              Assessments
            </span>
            <button class="button is-small is-info ml-2 title-align"
              @click="openSummaryModal">
              <span class="icon">
                <i class="mdi mdi-eye" />
              </span>
              <span>
                View Summary
              </span>
            </button>
          </div>
        </div>
        <div v-if="assessments.length > 0 || numItemsChanged > 0">
          <div class="tabs">
            <ul>
              <li v-for="assessment in assessments"
                :key="assessment.assessmentID"
                :class="{'is-active' : selectedAssessment?.assessmentID === assessment.assessmentID, 'tab-disabled': selectedAssessment?.assessmentID !== assessment.assessmentID && (editAuthorisedMode || editQuotedMode)}">
                <a @click="selectAssessment(assessment)">
                  <span>
                    <span class="has-badge-child-offset2">{{ currentAuthorisedQuoteLabel(assessment, true) }}</span>
                    <span v-if="assessment?.authorisedAssessment && assessment.authorisedAssessment.isDraft !== true"
                      class="tag is-success icon"><i class="mdi mdi-check" /></span>
                    <span v-else-if="assessment?.submittedAssessment"
                      class="tag is-warning">{{ assessment.submittedAssessment ? assessment.submittedAssessment.assessmentQuoteItems.length : 0 }}</span>
                  </span>
                </a>
              </li>
              <li v-if="numItemsChanged > 0"
                :class="{'is-active' : selectedAssessment?.assessmentID === draftAssessment.assessmentID, 'tab-disabled': selectedAssessment?.assessmentID !== draftAssessment.assessmentID && (editAuthorisedMode || editQuotedMode)}">
                <a @click="selectDraftAssessment()">
                  <span>
                    <span class="has-badge-child-offset2">{{ assessments.length === 0 ? "MAIN" : `SUPP-${assessments.length}` }}</span>
                    <span class="tag is-light">{{ numItemsChanged }}</span>
                  </span>
                </a>
              </li>
            </ul>
            <button v-if="!assessments[0]?.authorisedAssessment && (editAuthorisedMode || editQuotedMode)"
              @click="openEditRatesModal"
              class="button is-primary tooltip is-tooltip-topright"
              data-tooltip="Edit rates">
              <span class="icon">
                <i class="mdi mdi-pencil" />
              </span>
              <span>Edit Rates</span>
            </button>
          </div>
          <div class="mt-2">
            <div class="columns is-gapless mb-0">
              <div class="column">
                <p>
                  <span>
                    <span class="has-text-weight-semibold">
                      {{ currentAuthorisedQuoteLabel(selectedAssessment) }} Requested Value:
                    </span>
                    <span class="has-text-weight-semibold">
                      {{ selectedQuotedValue | formatCurrency($userInfo.locale) }}
                    </span>
                  </span>
                  <span v-if="selectedAssessment.authorisedAssessment"
                    class="ml-6">
                    <span class="has-text-weight-semibold">
                      {{ currentAuthorisedQuoteLabel(selectedAssessment) }} Authorised Value:
                    </span>
                    <span class="has-text-weight-semibold"
                      :class="totalAuthorisedColourClass">
                      {{ selectedAuthorisedValue | formatCurrency($userInfo.locale) }}
                    </span>
                  </span>
                </p>
              </div>
              <div v-if="!isReadonly"
                class="control is-pulled-right">
                <div class="field-body">
                  <div class="field mr-2">
                    <div class="buttons">
                      <a v-if="!(editAuthorisedMode || editQuotedMode) && isAssessmentSubmitted && (!isEstimageEnabled || $userInfo.isSupportUser) && !isAssessmentAuthorised"
                        @click="openCancelRequestConfirmModal()"
                        class="button is-secondary tooltip is-tooltip-topright is-transparent"
                        data-tooltip="Cancel the last request to authority">
                        <span class="icon">
                          <i class="mdi mdi-close" />
                        </span>
                        <span>Cancel Request</span>
                      </a>
                      <a v-if="!(editAuthorisedMode || editQuotedMode) && isAssessmentAuthorised && selectedAssessment.subQuoteNo === assessments.length - 1 && selectedAssessment.isInvoiced !== true && (!isEstimageEnabled || $userInfo.isSupportUser)"
                        @click="openCancelAuthorityConfirmModal()"
                        class="button is-secondary tooltip is-tooltip-topright is-transparent"
                        data-tooltip="Cancel the last authority">
                        <span class="icon">
                          <i class="mdi mdi-close" />
                        </span>
                        <span>Cancel {{ currentAuthorisedQuoteLabel(selectedAssessment) }} Authority</span>
                      </a>
                      <a v-if="!(editAuthorisedMode || editQuotedMode) && !isAssessmentSubmitted && !isAssessmentAuthorised && isQuoteAuthorised"
                        @click="openRevertConfirmModal()"
                        class="button is-secondary tooltip is-tooltip-topright is-transparent"
                        data-tooltip="Revert to last authority">
                        <span class="icon">
                          <i class="mdi mdi-undo" />
                        </span>
                        <span>Revert to {{ lastAuthorisedQuoteLabel(selectedAssessment) }}</span>
                      </a>
                      <a v-if="editAuthorisedMode || editQuotedMode"
                        @click="cancel()"
                        class="button is-secondary tooltip is-tooltip-topright is-transparent"
                        data-tooltip="Cancel">
                        <span class="icon">
                          <i class="mdi mdi-close" />
                        </span>
                        <span>Cancel</span>
                      </a>

                      <!-- <a @click="editMode(false)" -->
                      <!-- <a v-if="!(editAuthorisedMode || editQuotedMode) && !isAssessmentSubmitted && !isAssessmentAuthorised && isPreviousQuoteAuthorised" -->
                      <a v-if="!(editAuthorisedMode || editQuotedMode) && (((!isAssessmentSubmitted || isEstimageEnabled) && !isAssessmentAuthorised && isPreviousQuoteAuthorised))"
                        @click="editMode(false)"
                        class="button is-info is-outlined tooltip is-tooltip-topright"
                        data-tooltip="Submit request">
                        <div v-if="!isAssessmentSubmitted">
                          <span class="icon">
                            <i class="mdi mdi-file-send" />
                          </span>
                          <span v-if="insurer.arnie">Send Request to Arnie</span>
                          <span v-else-if="isEstimageEnabled && !isAssessmentSubmitted">Send Request to EstImage</span>
                          <span v-else>Request Authority</span>
                        </div>
                        <div v-if="isEstimageEnabled && isAssessmentSubmitted && !isAssessmentAuthorised">
                          <span class="icon">
                            <i class="mdi mdi-refresh" />
                          </span>
                          <span>Resend Request</span>
                        </div>
                      </a>
                      <a v-if="!(editAuthorisedMode || editQuotedMode) && (selectedAssessmentStatus === 'submitted' || (selectedAssessmentStatus === 'draft' && !insurer.arnie && !isEstimageEnabled && isPreviousQuoteAuthorised) || (selectedAssessmentStatus === 'authorised' && nextSelectedAssessmentStatus == 'draft' && isEstimageEnabled))"
                        @click="editMode(true)"
                        class="button is-info is-outlined tooltip is-tooltip-topright"
                        data-tooltip="Submit authorise">
                        <span class="icon">
                          <i class="mdi mdi-playlist-check" />
                        </span>
                        <span v-if="isAssessmentAuthorised && isEstimageEnabled">Update Authority</span>
                        <span v-else>Authorise</span>
                      </a>
                      <a v-if="editAuthorisedMode"
                        @click="authorise()"
                        :disabled="!hasAllItemChecked"
                        class="button is-success tooltip is-tooltip-topright"
                        data-tooltip="Autorise selected items">
                        <span class="icon">
                          <i class="mdi mdi-playlist-check" />
                        </span>
                        <span>Confirm Authorise</span>
                      </a>
                      <a v-if="editQuotedMode && !editAuthorisedMode"
                        @click="confirmSendRequest()"
                        :disabled="!hasAllItemChecked"
                        class="button is-success tooltip is-tooltip-topright"
                        data-tooltip="Request selected items">
                        <span class="icon">
                          <i class="mdi mdi-file-send" />
                        </span>
                        <span>Confirm Send Request</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns mt-2">
              <div class="column">
                <div class="field">
                  <label class="label">Quote Status:</label>
                  <div>
                    <div v-if="selectedAssessment.isInvoiced"
                      class="tag is-success">
                      <i class="icon mdi mdi-check-circle-outline" />
                      Invoiced
                    </div>
                    <div v-else-if="isAssessmentAuthorised"
                      class="tag is-success">
                      Authorised
                    </div>
                    <div v-else-if="isAssessmentSubmitted"
                      class="tag is-warning">
                      Request Sent
                    </div>
                    <div v-else
                      class="tag is-light">
                      Draft
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Claim Number:</label>
                  <div class="control">
                    <input v-if="editAuthorisedMode"
                      class="input"
                      type="text"
                      v-model="innerValue.claimNo"
                      placeholder="Claim No">
                    <div v-else>{{ innerValue.claimNo }}</div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Date Requested:</label>
                  <div class="control">
                    <div>{{ selectedAssessment?.submittedAssessment ? $filters.formatDateTimezone(selectedAssessment?.submittedAssessment?.createdDate, $userInfo.locale) : '-' }}</div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Date Authorised:</label>
                  <div class="control">
                    <div>{{ selectedAssessment?.authorisedAssessment ? $filters.formatDateTimezone(selectedAssessment?.authorisedAssessment?.createdDate, $userInfo.locale) : '-' }}</div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Assessor Name:</label>
                  <div class="control">
                    <input v-if="editAuthorisedMode"
                      class="input"
                      type="text"
                      v-model="selectedAssessment.assessorName"
                      placeholder="Assessor Name">
                    <div v-else>{{ selectedAssessment?.assessorName ? selectedAssessment.assessorName : '-' }}</div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Excess Amount:</label>
                  <div class="control">
                    <div>{{ $filters.formatCurrency(innerValue.excess, $userInfo.locale) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="(editAuthorisedMode || editQuotedMode) && !isAllSelected"
              class="is-fullwidth mb-3 select-all-box">
              <div class="mr-3 ml-3 has-vertical-middle">
                <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input :value="isAllSelected"
                    @change="onSelectAllChanged"
                    type="checkbox">
                  <div class="state p-info">
                    <i class="icon mdi mdi-check" />
                    <label class="has-text-info">Select All Items</label>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(itemType) in labourCategories"
              :key="itemType">
              <authorise-labour :key="selectedAssessment.assessmentID + itemType"
                v-if="itemType !== 'PAINT'"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />

              <authorise-opg :key="selectedAssessment.assessmentID + itemType"
                v-if="itemType == 'PAINT'"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />

            </div>
            <div v-for="(itemType) in partCategories"
              :key="itemType">
              <authorise-part :key="selectedAssessment.assessmentID + itemType"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />
            </div>
            <!-- <div v-for="(itemType) in opgCategories"
              :key="itemType">
              <authorise-opg :key="selectedAssessment.assessmentID + itemType"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />
            </div> -->
            <div v-for="(itemType) in miscCategories"
              :key="itemType">
              <authorise-misc :key="selectedAssessment.assessmentID + itemType"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />
            </div>
            <div v-for="(itemType) in subletCategories"
              :key="itemType">
              <authorise-sublet :key="selectedAssessment.assessmentID + itemType"
                class="mb-5"
                :value="innerValue"
                :vehicle="innerVehicle"
                :assessment-items="selectedAssessmentItems"
                :item-type="itemType"
                :edit-authorised-mode="editAuthorisedMode"
                :edit-quoted-mode="editQuotedMode"
                :is-draft="selectedAssessment.isDraft"
                :select-all="isCategorySelected.find((i) => i.itemType === itemType).selected"
                @select-all-changed="onCategorySelectChange"
                @update-assessment-item="updateDraftAssessment"
                @add-assessment-item="addItemToDraftAssessment" />
            </div>
            <div v-if="selectedAssessmentItems?.length === 0">
              <section class="mt-5 mb-3">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>There is no Item in this assessment.</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </article>
    </div>
    <edit-rates-modal v-if="isEditRateModalVisible"
      :show-icon="true"
      :active.sync="isEditRateModalVisible"
      :quote-values="innerValue"
      :vehicle-values="innerVehicle"
      @apply-rates="applyRates"
      @close="isEditRateModalVisible = false" />
    <confirm-modal v-if="isRequestConfirmModalVisible"
      :active.sync="isRequestConfirmModalVisible"
      @ok="authorise()"
      @cancel="isRequestConfirmModalVisible = false"
      :ok-text="'Authorise'"
      :ok-class="'is-success'"
      :cancel-text="'Cancel'">
      <p slot="text-title">Confirm to request</p>
      <p slot="text-content">
        Are you sure you want to request the selected items?
      </p>
    </confirm-modal>
    <confirm-modal v-if="isRevertConfirmModalActive"
      :active.sync="isRevertConfirmModalActive"
      @ok="revertToAuthority(true)"
      @cancel="isRevertConfirmModalActive = false"
      :ok-text="`Revert to ${lastAuthorisedQuoteLabel(selectedAssessment)}`"
      :ok-class="'is-danger'"
      :cancel-text="'No'">
      <p slot="text-title">Revert to Authority</p>
      <div slot="text-content">
        <p>
          Are you sure you want to revert to the last authority? <strong class="has-text-danger">All changes {{ currentAuthorisedQuoteLabel(selectedAssessment) }} will be lost.</strong>
        </p>
        <br>
        <ul>
          <li>
            <span class="icon">
              <i class="mdi mdi-delete" />
            </span>
            New items will be <strong> removed</strong>.
          </li>
          <li>
            <span class="icon">
              <i class="mdi mdi-restore" />
            </span>
            Deleted items will be <strong> restored</strong>.
          </li>
          <li>
            <span class="icon">
              <i class="mdi mdi-undo" />
            </span>
            Modified items will be <strong>revert</strong> to original values.
          </li>
        </ul>
      </div>
    </confirm-modal>
    <confirm-modal v-if="isCancelAuthorityConfirmModalActive"
      :active.sync="isCancelAuthorityConfirmModalActive"
      :show-close="true"
      @ok="cancelAuthority(true)"
      @cancel="cancelAuthority(false)"
      @close="isCancelAuthorityConfirmModalActive = false"
      :show-cancel="false"
      :cancel-text="`Revert to ${lastAuthorisedQuoteLabel(selectedAssessment)}`"
      :cancel-class="'is-danger'"
      :ok-class="'is-danger'"
      :ok-text="`Cancel ${currentAuthorisedQuoteLabel(selectedAssessment)} Authority`"
      :close-text="'Close'">
      <p slot="text-title">Cancel last Authority</p>
      <div slot="text-content">
        <!-- <p>
          Do you want to keep or discard the changes made in {{ currentAuthorisedQuoteLabel(selectedAssessment) }}?
        </p>
        <br>
        <p>
          <span class="has-text-warning has-text-weight-semibold	">Keep {{ currentAuthorisedQuoteLabel(selectedAssessment) }} Changes: </span> will keep all items' changes on {{ currentAuthorisedQuoteLabel(selectedAssessment) }} as draft.
        </p>
        <p v-if="assessments.length > 1">
          <span class="has-text-danger has-text-weight-semibold	">Revert to {{ lastAuthorisedQuoteLabel(selectedAssessment) }}:</span> will discard all items' changes on {{ currentAuthorisedQuoteLabel(selectedAssessment) }}.
        </p> -->
        <p>
          Are you sure you want to cancel <strong>{{ currentAuthorisedQuoteLabel(selectedAssessment) }}</strong> authority?
        </p>
      </div>
    </confirm-modal>
    <confirm-modal v-if="isCancelRequestConfirmModalActive"
      :active.sync="isCancelRequestConfirmModalActive"
      :show-close="true"
      @ok="cancelRequest(true)"
      @cancel="cancelRequest(false)"
      @close="isCancelRequestConfirmModalActive = false"
      :show-cancel="false"
      :cancel-text="`Revert to ${lastAuthorisedQuoteLabel(selectedAssessment)}`"
      :cancel-class="'is-danger'"
      :ok-class="'is-danger'"
      :ok-text="`Cancel ${currentAuthorisedQuoteLabel(selectedAssessment)} Request`"
      :close-text="'Close'">
      <p slot="text-title">Cancel last Request</p>
      <div slot="text-content">
        <p>
          Are you sure you want to cancel <strong>{{ currentAuthorisedQuoteLabel(selectedAssessment) }}</strong> request?
        </p>
      </div>
    </confirm-modal>
    <confirm-modal v-if="isConfrimManualAuthoriseModalActive"
      :active.sync="isConfrimManualAuthoriseModalActive"
      :show-close="true"
      @ok="manualSendQuoteEstImage()"
      @close="isConfrimManualAuthoriseModalActive = false"
      :show-cancel="false"
      :ok-class="'is-info'"
      :ok-text="'Continue'"
      :close-text="'Close'">
      <p slot="text-title">Important</p>
      <div slot="text-content">
        <notification-box class="mb-3"
          :type="'warning'">
          <p class="is-size-6"
            slot="text-content">Please check all items in <b>FlexiQuote</b> and <b>EstImage</b> match.</p>
        </notification-box>
        <p>
          Click continue to change the FlexiQuote quote status to <b>"Request Sent"</b>.
        </p>
        <p>
          <b>Please Note:</b> The EstImage quote status will not be updated as the quote has already been sent in the system.
        </p>
        <br>
      </div>
    </confirm-modal>
    <quote-detail-assessment-summary-modal v-if="isSummaryModalVisible"
      :show-icon="true"
      :active.sync="isSummaryModalVisible"
      :value="innerValue"
      @close="isSummaryModalVisible = false" />

    <est-image-send-quote-modal v-if="isEstimageSendModalActive"
      :active.sync="isEstimageSendModalActive"
      :value="innerValue"
      :modal-type="'SendQuoteWithConfirm'"
      :title="'Send Quote to EstImage'"
      :icon-name="'mdi-upload'"
      @close="closeSendEstimage()"
      @submit="sendEstImageQuote" />
    <quote-export-modal v-if="isQuoteExportModalActive"
      :active.sync="isQuoteExportModalActive"
      v-model="innerValue"
      :vehicle="vehicle"
      :default-email="insurerDefaultEmail"
      :send-email-mode="true"
      :assessment="selectedAssessment"
      @close="closeArnieSendQuoteModal"
      @sent="sentToArnie" />
  </div>
</template>

<script>
import { ItemCategoryTypes, LabourItemCodeTypes, ItemCategoryCodeTypes, QuotingMethodTypes } from '@/enums'
import { EditRatesModal, QuoteDetailAssessmentSummaryModal, EstImageSendQuoteModal } from './components'
import _cloneDeep from 'lodash.clonedeep'
import StoreMixin from './storeMixin'
import { ConfirmModal } from '@/components/BulmaModal'
import { QuoteAssessmentMixin } from './mixins'
import QuoteItemsMixin from './mixins/QuoteItemsMixin'
import { NumberFiltersMixin } from '../../components/mixins/filters'
import QuoteTotalsMethodMixin from './mixins/QuoteTotalsMethodMixin'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import QuoteService from './QuoteService'
import { AuthoriseLabour, AuthorisePart, AuthoriseOpg, AuthoriseMisc, AuthoriseSublet, QuoteExportModal } from './components'
import { EstImageService } from '@/services'
import NotificationBox from '@/components/Notification/NotificationBox.vue'

export default {
  name: 'AuthoriseView',
  filters: {},
  components: {
    AuthoriseLabour,
    AuthorisePart,
    AuthoriseOpg,
    AuthoriseSublet,
    AuthoriseMisc,
    ConfirmModal,
    EditRatesModal,
    QuoteDetailAssessmentSummaryModal,
    QuoteExportModal,
    EstImageSendQuoteModal,
    NotificationBox
  },
  mixins: [StoreMixin, QuoteAssessmentMixin, QuoteItemsMixin, NumberFiltersMixin, QuoteTotalsMethodMixin],
  props: {
    value: null,
    vehicle: null,
    insurer: null
  },
  data() {
    return {
      innerValue: null,
      innerVehicle: null,
      editQuotedMode: false,
      editAuthorisedMode: false,
      selectedAssessment: null,
      draftAssessment: null,
      selectedAssessmentItems: null,
      selectedQuotedValue: 0,
      selectedAuthorisedValue: 0,
      isEditRateModalVisible: false,
      isRequestConfirmModalVisible: false,
      isRevertConfirmModalActive: false,
      isCancelAuthorityConfirmModalActive: false,
      isCancelRequestConfirmModalActive: false,
      isCategorySelected: [],
      isAllSelected: false,
      loading: false,
      isSummaryModalVisible: false,
      isQuoteExportModalActive: false,
      isEstimageSendModalActive: false,
      isConfrimManualAuthoriseModalActive: false
    }
  },

  computed: {
    assessments() {
      if (this.innerValue) {
        return this.innerValue.quoteAssessments.filter((a) => a.deleted !== true && a.isDeleted !== true).sort((a, b) => a.subQuoteNo - b.subQuoteNo)
      }
      return []
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    numItemsChanged() {
      if (!this.draftAssessment?.submittedAssessment) {
        return 0
      }
      return this.draftAssessment.submittedAssessment?.assessmentQuoteItems.length
    },
    activeAssessment() {
      return this.assessments.find(
        (a) => a.deleted !== true && a.isDeleted !== true && (a.authorisedAssessment === null || a.authorisedAssessment.isDraft === true)
      )
    },
    isReadonly() {
      if (this.value.orm && !this.$userInfo.isSupportUser) {
        return true
      }
      return false
    },
    isAssessmentAuthorised() {
      return this.selectedAssessment?.authorisedAssessment && this.selectedAssessment.authorisedAssessment.isDraft !== true
    },
    isAssessmentSubmitted() {
      return this.selectedAssessment?.submittedAssessment && this.selectedAssessment.submittedAssessment.isDraft !== true
    },
    isQuoteAuthorised() {
      return this.assessments.some((a) => a.authorisedAssessment && a.deleted !== true && a.authorisedAssessment.isDraft !== true)
    },
    isPreviousQuoteAuthorised() {
      return this.assessments[this.selectedAssessment.subQuoteNo - 1]?.authorisedAssessment || !this.assessments[this.selectedAssessment.subQuoteNo - 1]
    },
    hasAllItemChecked() {
      return this.selectedAssessmentItems.filter((i) => !i.isAdded || (i.isAdded && !i.deleted)).every((i) => i.isSelected)
    },
    totalAuthorisedColourClass() {
      if (this.selectedQuotedValue < this.selectedAuthorisedValue) {
        return 'has-text-success'
      } else if (this.selectedQuotedValue > this.selectedAuthorisedValue) {
        return 'has-text-danger'
      } else {
        return ''
      }
    },
    labourCategories() {
      const labourTypes = ItemCategoryCodeTypes.itemTypes.filter((type) => type.category === 'Labour' || type.category === 'OtherLabour')
      let companysItemCategory = this.$company.itemCategories
        .filter((ic) => ic.enabled === true)
        .filter((c) => c.enabled === true && labourTypes.some((type) => type.code === c.itemCategoryType))
        .map((c) => c.itemCategoryType)
      if (this.selectedAssessmentItems) {
        const missingItemTypes = this.selectedAssessmentItems
          .filter((item) => !companysItemCategory.includes(item.itemType) && labourTypes.some((type) => type.code === item.itemType))
          .map((item) => item.itemType)
        companysItemCategory = [...companysItemCategory, ...new Set(missingItemTypes)]
      }
      return companysItemCategory
    },
    partCategories() {
      const partTypes = ItemCategoryCodeTypes.itemTypes.filter((type) => type.category === 'Part')
      let companysItemCategory = this.$company.itemCategories
        .filter((c) => c.enabled === true && partTypes.some((type) => type.code === c.itemCategoryType))
        .map((c) => c.itemCategoryType)
      if (this.selectedAssessmentItems) {
        const missingItemTypes = this.selectedAssessmentItems
          .filter((item) => !companysItemCategory.includes(item.itemType) && partTypes.some((type) => type.code === item.itemType))
          .map((item) => item.itemType)
        companysItemCategory = [...companysItemCategory, ...new Set(missingItemTypes)]
      }
      return companysItemCategory
    },
    // opgCategories() {
    //   const opgTypes = ItemCategoryCodeTypes.itemTypes.filter((type) => type.category === 'OPG')
    //   let companysItemCategory = this.$company.itemCategories
    //     .filter((c) => c.enabled === true && opgTypes.some((type) => type.code === c.itemCategoryType))
    //     .map((c) => c.itemCategoryType)
    //   if (this.selectedAssessmentItems) {
    //     const missingItemTypes = this.selectedAssessmentItems
    //       .filter((item) => !companysItemCategory.includes(item.itemType) && opgTypes.some((type) => type.code === item.itemType))
    //       .map((item) => item.itemType)
    //     companysItemCategory = [...companysItemCategory, ...new Set(missingItemTypes)]
    //   }
    //   return companysItemCategory
    // },
    miscCategories() {
      const miscTypes = ItemCategoryCodeTypes.itemTypes.filter((type) => type.category === 'Misc')
      let companysItemCategory = this.$company.itemCategories
        .filter((c) => c.enabled === true && miscTypes.some((type) => type.code === c.itemCategoryType))
        .map((c) => c.itemCategoryType)
      if (this.selectedAssessmentItems) {
        const missingItemTypes = this.selectedAssessmentItems
          .filter((item) => !companysItemCategory.includes(item.itemType) && miscTypes.some((type) => type.code === item.itemType))
          .map((item) => item.itemType)
        companysItemCategory = [...companysItemCategory, ...new Set(missingItemTypes)]
      }
      return companysItemCategory
    },
    subletCategories() {
      const subletTypes = ItemCategoryCodeTypes.itemTypes.filter((type) => type.category === 'Sublet')
      let companysItemCategory = this.$company.itemCategories
        .filter((c) => c.enabled === true && subletTypes.some((type) => type.code === c.itemCategoryType))
        .map((c) => c.itemCategoryType)
      if (this.selectedAssessmentItems) {
        const missingItemTypes = this.selectedAssessmentItems
          .filter((item) => !companysItemCategory.includes(item.itemType) && subletTypes.some((type) => type.code === item.itemType))
          .map((item) => item.itemType)
        companysItemCategory = [...companysItemCategory, ...new Set(missingItemTypes)]
      }
      return companysItemCategory
    },
    saved() {
      return this.value.isNew === false && this.value.isDirty === false
    },
    insurerDefaultEmail() {
      const defaultEmail = this.insurer.emails.find((e) => e.isDefault)
      if (defaultEmail) {
        return defaultEmail.address
      } else {
        return ''
      }
    },
    isEstimageEnabled() {
      return this.$company.setting.estimageOnlineEnabled && this.value.isEstimage
    },
    selectedAssessmentStatus() {
      if (this.selectedAssessment.isInvoiced) {
        return 'invoiced'
      } else if (this.selectedAssessment.isAuthorised) {
        return 'authorised'
      } else if (this.selectedAssessment.isDraft) {
        return 'draft'
      } else {
        return 'submitted'
      }
    },
    nextSelectedAssessmentStatus() {
      const nextAssessment = this.assessments[this.selectedAssessment.subQuoteNo + 1]
      if (!nextAssessment) {
        return 'draft'
      } else if (nextAssessment.isInvoiced) {
        return 'invoiced'
      } else if (nextAssessment.IsAuthorised) {
        return 'authorised'
      } else {
        return 'submitted'
      }
    }
  },

  watch: {
    saved() {
      this.loading = true
      this.innerValue = _cloneDeep(this.value)
      this.innerVehicle = _cloneDeep(this.vehicle)
      this.draftAssessment = this.createDraftAssessment()
      this.initialiseSelectedAssessment()
      this.isCategorySelected = ItemCategoryCodeTypes.itemTypes.map((c) => {
        return { itemType: c.code, selected: false }
      })
      this.loading = false
    },
    value(newVal) {
      this.loading = true
      this.innerValue = _cloneDeep(newVal)
      this.draftAssessment = this.createDraftAssessment()
      this.initialiseSelectedAssessment()
      this.loading = false
    },
    vehicle(newVal) {
      this.innerVehicle = _cloneDeep(newVal)
    },
    selectedAssessment: {
      handler(newVal) {
        this.selectedAssessmentItems = this.createAssessmentItemsList(newVal)
        this.selectedQuotedValue = this.calculateQuotedTotal(newVal?.submittedAssessment?.assessmentQuoteItems)
        this.selectedAuthorisedValue = this.calculateAuthorisedTotal(newVal?.authorisedAssessment?.assessmentQuoteItems)
      },
      deep: true
    },
    editAuthorisedMode(newVal) {
      if (newVal) {
        this.selectedAuthorisedValue = 0
      }
    },
    editQuotedMode(newVal) {
      if (newVal) {
        this.selectedQuotedValue = 0
      }
    },
    isCategorySelected: {
      handler(newVal) {
        this.isAllSelected = newVal.every((i) => i.selected === true)
      },
      deep: true
    },
    loading(newVal) {
      if (newVal === true) {
        this.$showSpinner()
      } else {
        this.$resetSpinner()
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('disable-header-buttons', false)
    next()
  },
  created() {
    this.$emit('save', false, false)
    this.loading = true
    this.innerValue = _cloneDeep(this.value)
    this.innerVehicle = _cloneDeep(this.vehicle)
    this.draftAssessment = this.createDraftAssessment()
    this.initialiseSelectedAssessment()
    this.isCategorySelected = ItemCategoryCodeTypes.itemTypes.map((c) => {
      return { itemType: c.code, selected: false }
    })
    this.loading = false
  },

  updated() {},
  mounted() {},

  methods: {
    initialiseSelectedAssessment() {
      if (this.activeAssessment) {
        this.selectedAssessment = this.activeAssessment
      } else if (this.draftAssessment) {
        this.selectedAssessment = this.draftAssessment
      } else if (this.assessments.length > 0) {
        this.selectedAssessment = this.assessments[this.assessments.length - 1]
      } else {
        this.selectedAssessment = null
      }
    },
    selectAssessment(assessment) {
      this.selectedAssessment = assessment
    },
    selectDraftAssessment() {
      this.selectedAssessment = this.draftAssessment
    },
    openSummaryModal() {
      this.isSummaryModalVisible = true
    },
    openEditRatesModal() {
      this.isEditRateModalVisible = true
    },
    openRevertConfirmModal() {
      this.isRevertConfirmModalActive = true
    },
    openCancelAuthorityConfirmModal() {
      this.isCancelAuthorityConfirmModalActive = true
    },
    openCancelRequestConfirmModal() {
      this.isCancelRequestConfirmModalActive = true
    },
    closeSendEstimage() {
      this.isEstimageSendModalActive = false
      this.cancel()
    },
    async openArnieSendQuoteModal() {
      this.$showSpinner
      try {
        await QuoteService.validateQuote(this.innerValue.quoteId)
      } catch {
        this.cancel()
      } finally {
        this.$hideSpinner()
      }
      this.isQuoteExportModalActive = true
    },
    closeArnieSendQuoteModal() {
      this.isQuoteExportModalActive = false
      this.cancel()
    },
    sentToArnie() {
      this.isQuoteExportModalActive = false
      this.$emit('getEntity', true)
    },
    cancel() {
      this.editQuotedMode = false
      this.editAuthorisedMode = false
      this.innerValue = _cloneDeep(this.value)
      this.draftAssessment = this.createDraftAssessment()
      this.initialiseSelectedAssessment()
      this.$emit('disable-header-buttons', false)
    },
    editMode(toAuthorise) {
      if (toAuthorise) {
        this.editAuthorisedMode = true
        if (this.selectedAssessment.submittedAssessment.isDraft) {
          this.editQuotedMode = true
        }
        if (!this.selectedAssessment.authorisedAssessment) {
          this.selectedAssessment.authorisedAssessment = this.createAssessmentDetails(
            this.selectedAssessment.submittedAssessment.assessmentQuoteItems,
            this.selectedAssessment.subQuoteNo,
            true,
            true
          )
        }
      } else {
        this.editQuotedMode = true
      }
      this.$emit('disable-header-buttons', true)
    },
    confirmSendRequest() {
      if (this.isEstimageEnabled) {
        this.isEstimageSendModalActive = true
      } else if (this.insurer.arnie) {
        this.openArnieSendQuoteModal()
      } else {
        this.request()
      }
    },
    async revertToAuthority() {
      this.loading = true
      await QuoteService.revertToAuthorised(this.innerValue.quoteId)
      this.$emit('getEntity', true)
      this.isRevertConfirmModalActive = false
    },
    async cancelAuthority(merge) {
      this.loading = true
      await QuoteService.cancelLastAuthority(this.innerValue.quoteId, merge)
      this.$emit('getEntity', true)
      this.isCancelAuthorityConfirmModalActive = false
    },
    async cancelRequest(merge) {
      this.loading = true
      await QuoteService.cancelLastRequest(this.innerValue.quoteId, merge)
      this.$emit('getEntity', true)
      this.isCancelRequestConfirmModalActive = false
    },
    async authorise() {
      this.loading = true
      this.selectedAssessment.claimNo = this.innerValue.claimNo
      await QuoteService.authoriseAssessment(this.innerValue.quoteId, this.selectedAssessment)
      this.$emit('getEntity', true)
      this.editAuthorisedMode = false
      this.editQuotedMode = false
      this.$emit('disable-header-buttons', false)
    },
    async request() {
      this.loading = true
      await QuoteService.requestAuthorityAssessment(this.innerValue.quoteId, this.selectedAssessment)
      this.$emit('getEntity', true)
      this.editAuthorisedMode = false
      this.editQuotedMode = false
      this.$emit('disable-header-buttons', false)
    },
    async sendEstImageQuote(estimageObj) {
      // this.loading = true
      this.$showSpinner('Sending...')
      estimageObj.assessment = this.selectedAssessment
      try {
        const response = await EstImageService.sendQuote(estimageObj)
        if (response?.data?.error) {
          if (response?.data?.error === 'REPAIRER_NOT_AUTHORIZED_TO_ADD_QUOTE' && this.$userInfo.isSupportUser) {
            this.isConfrimManualAuthoriseModalActive = true
          } else {
            this.$notification.openNotificationWithType('danger', 'Error on Sending Quote to EstImage', response?.data?.details)
          }
        } else {
          await this.request()
          this.cancel()
        }
      } finally {
        this.$hideSpinner()
        this.isEstimageSendModalActive = false
        this.cancel()
      }
    },
    async manualSendQuoteEstImage() {
      this.isConfrimManualAuthoriseModalActive = false
      await this.request()
      this.cancel()
    },
    applyRates(rates) {
      let items = []
      if (this.editQuotedMode) {
        items.push(...this.selectedAssessment.submittedAssessment.assessmentQuoteItems)
      }
      if (this.editAuthorisedMode) {
        items.push(...this.selectedAssessment.authorisedAssessment.assessmentQuoteItems)
      }
      items
        .filter((i) => i.itemType === ItemCategoryTypes.RR)
        .forEach((item) => {
          const newRate = rates.find((r) => r.labourCodeId === LabourItemCodeTypes.RR).rate
          const oldRate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.RR).rate
          this.updateRate(item, newRate, oldRate)
        })
      items
        .filter((i) => i.itemType === ItemCategoryTypes.MECH)
        .forEach((item) => {
          const newRate = rates.find((r) => r.labourCodeId === LabourItemCodeTypes.MECH).rate
          const oldRate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.MECH).rate
          this.updateRate(item, newRate, oldRate)
        })
      items
        .filter((i) => i.itemType === ItemCategoryTypes.REP)
        .forEach((item) => {
          const newRate = rates.find((r) => r.labourCodeId === LabourItemCodeTypes.REP).rate
          const oldRate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.REP).rate
          this.updateRate(item, newRate, oldRate)
        })
      items
        .filter((i) => i.itemType === ItemCategoryTypes.PAINT)
        .forEach((item) => {
          const newRate = rates.find((r) => r.labourCodeId === this.innerVehicle.paintGroup).rate
          const oldRate = this.innerValue.rates.find((r) => r.labourCodeId === this.innerVehicle.paintGroup).rate
          this.updateRate(item, newRate, oldRate)
        })
      this.innerValue.rates = rates
      this.isEditRateModalVisible = false
    },
    onSelectAllChanged(event) {
      this.isCategorySelected.forEach((i, index) => {
        this.isCategorySelected[index].selected = event.target.checked
      })
    },
    onCategorySelectChange(value, itemType) {
      this.isCategorySelected.find((i) => i.itemType === itemType).selected = value
    },
    updateRate(item, newRate, oldRate) {
      if (item.rate === oldRate) item.rate = newRate
    },
    createDraftAssessment() {
      const items = this.getItemsChanged()
      const assessment = this.createAssessment(items, this.innerValue.quoteAssessments.length, this.innerValue.assessorName)
      return assessment
    },
    getItemsChanged() {
      const items = [
        ...this.innerValue.labours,
        ...this.innerValue.others,
        ...this.innerValue.parts,
        ...this.innerValue.opgs,
        ...this.innerValue.miscs,
        ...this.innerValue.sublets
      ]
      const itemsChanged = this.itemsChanged(items, this.innerValue.quotingMethod)
      return itemsChanged
    },
    createAssessmentItemsList(assessment) {
      if (!assessment.authorisedAssessment) {
        let items = assessment.submittedAssessment.assessmentQuoteItems
          .filter((item) => !item.isDeleted)
          .map((item) => ({
            isSelected: item.isSelected,
            itemDesc: item.itemDesc,
            itemComment: item.itemComment,
            partNo: item.partNo,
            reportOnly: item.reportOnly,
            isAdded: item.isAdded,
            itemType: item.itemType,
            vendorName: item.vendorName,
            quoteItemId: item.quoteItemId,
            sortNo: this.getItemSortNo(item.quoteItemId, item.itemType) || item.sortNo,
            isQuoteItemDeleted: item.isQuoteItemDeleted,
            submittedItem: { ...item },
            authorisedItem: null
          }))
        return items.sort((a, b) => a.sortNo - b.sortNo)
      } else {
        const submittedItems = assessment.submittedAssessment.assessmentQuoteItems.filter((item) => !item.isDeleted)
        const authorisedItems = assessment.authorisedAssessment.assessmentQuoteItems.filter((item) => !item.isDeleted)

        let joinedItems = authorisedItems.reduce((acc, authorisedItem) => {
          const submittedItem = submittedItems.find((i) => i.quoteItemId === authorisedItem.quoteItemId)
          acc.push({
            isSelected: authorisedItem.isSelected,
            itemDesc: authorisedItem.itemDesc,
            itemComment: authorisedItem.itemComment,
            partNo: authorisedItem.partNo,
            reportOnly: authorisedItem.reportOnly,
            isAdded: authorisedItem.isAdded,
            itemType: authorisedItem.itemType,
            isQuoteItemDeleted: authorisedItem.isQuoteItemDeleted,
            vendorName: authorisedItem.vendorName,
            quoteItemId: authorisedItem.quoteItemId,
            sortNo: this.getItemSortNo(authorisedItem.quoteItemId, authorisedItem.itemType) || authorisedItem.sortNo,
            submittedItem,
            authorisedItem
          })
          return acc
        }, [])
        return joinedItems.sort((a, b) => a.sortNo - b.sortNo)
      }
    },
    addItemToDraftAssessment(item) {
      if (item.submittedItem) {
        item.submittedItem.lineNumber = this.innerValue.lines
        item.submittedItem.itemNo = this.$filters.pad(item.submittedItem.lineNumber, 4)
        this.selectedAssessment.submittedAssessment.assessmentQuoteItems.push(item.submittedItem)
      }
      if (item.authorisedItem) {
        item.authorisedItem.lineNumber = this.innerValue.lines
        item.authorisedItem.itemNo = this.$filters.pad(item.authorisedItem.lineNumber, 4)
        this.selectedAssessment.authorisedAssessment.assessmentQuoteItems.push(item.authorisedItem)
      }
      this.innerValue.lines++
      // this.$toast.open({
      //   message: 'Item added',
      //   type: 'is-success',
      //   position: 'is-top',
      //   queue: false
      // })
      this.$notification.success('','Item added')

    },
    updateDraftAssessment(item) {
      if (item.submittedItem && this.editQuotedMode) {
        if (item.isDeleted) {
          //remove deleted item
          this.selectedAssessment.submittedAssessment.assessmentQuoteItems = this.selectedAssessment.submittedAssessment.assessmentQuoteItems.filter(
            (i) => i.quoteItemId !== item.submittedItem.quoteItemId
          )
        }
        let submittedAssessmentQuoteItem = this.selectedAssessment.submittedAssessment.assessmentQuoteItems.find(
          (i) => i.quoteItemId === item.submittedItem.quoteItemId
        )
        if (submittedAssessmentQuoteItem) {
          this.updateDraftItemsValues(submittedAssessmentQuoteItem, item.submittedItem)
          this.updateDraftItemsCommonValues(submittedAssessmentQuoteItem, item)
        }
      }
      if (item.authorisedItem && this.editAuthorisedMode) {
        if (item.isDeleted) {
          this.selectedAssessment.authorisedAssessment.assessmentQuoteItems = this.selectedAssessment.authorisedAssessment.assessmentQuoteItems.filter(
            (i) => i.quoteItemId !== item.authorisedItem.quoteItemId
          )
        }
        let authorisedAssessmentQuoteItem = this.selectedAssessment.authorisedAssessment.assessmentQuoteItems.find(
          (i) => i.quoteItemId === item.authorisedItem.quoteItemId
        )
        if (authorisedAssessmentQuoteItem) {
          this.updateDraftItemsValues(authorisedAssessmentQuoteItem, item.authorisedItem)
          this.updateDraftItemsCommonValues(authorisedAssessmentQuoteItem, item)
        }
      }
    },
    updateDraftItemsValues(targetItem, sourceItem) {
      targetItem.buyPrice = sourceItem.buyPrice
      targetItem.dollarValue = sourceItem.dollarValue
      targetItem.hourValue = sourceItem.hourValue
      targetItem.isQuoteItemDeleted = sourceItem.isQuoteItemDeleted
      targetItem.isSelected = sourceItem.isSelected
      targetItem.itemDesc = sourceItem.itemDesc
      targetItem.itemComment = sourceItem.itemComment
      targetItem.itemNo = sourceItem.itemNo
      targetItem.itemQuantity = sourceItem.itemQuantity
      targetItem.itemType = sourceItem.itemType
      targetItem.lineNumber = sourceItem.lineNumber
      targetItem.mark = sourceItem.mark
      targetItem.markupValue = sourceItem.markupValue
      targetItem.opgCode = sourceItem.opgCode
      targetItem.partNo = sourceItem.partNo
      targetItem.partSource = sourceItem.partSource
      targetItem.rate = sourceItem.rate
      targetItem.reportOnly = sourceItem.reportOnly
      targetItem.value = sourceItem.value
      targetItem.vendorName = sourceItem.vendorName
    },
    updateDraftItemsCommonValues(targetItem, sourceItem) {
      targetItem.reportOnly = sourceItem.reportOnly
      targetItem.isAdded = sourceItem.isAdded
      targetItem.itemDesc = sourceItem.itemDesc
      targetItem.itemComment = sourceItem.itemComment
      targetItem.partNo = sourceItem.partNo
      targetItem.isSelected = sourceItem.isSelected
      targetItem.isQuoteItemDeleted = sourceItem.isQuoteItemDeleted
      targetItem.sortNo = sourceItem.sortNo
      targetItem.quoteItemId = sourceItem.quoteItemId
    },
    calculateQuotedTotal(items) {
      if (!items || items.length === 0) return 0
      let total = 0
      if (this.editQuotedMode) {
        total = this.calculateAssessmentItemsTotal(
          this.innerValue.quotingMethod,
          items.filter((i) => i.isSelected)
        )
      } else {
        total = this.calculateAssessmentItemsTotal(this.innerValue.quotingMethod, items)
      }
      return total
    },
    calculateAuthorisedTotal(items) {
      if (!items || items.length === 0) return 0
      let total = 0
      if (this.editAuthorisedMode) {
        total = this.calculateAssessmentItemsTotal(
          this.innerValue.quotingMethod,
          items.filter((i) => i.isSelected)
        )
      } else {
        total = this.calculateAssessmentItemsTotal(this.innerValue.quotingMethod, items)
      }
      return total
    },
    calculateAssessmentItemsTotal(quotingMethod, items) {
      let total = 0
      total += items
        .filter((i) => !i.isQuoteItemDeleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PART)
        .reduce(function (subTotal, item) {
          return roundAwayFromZero(subTotal + item.itemQuantity * item.markupValue)
        }, 0)
      total += items
        .filter((i) => !i.isQuoteItemDeleted && !i.reportOnly && (i.itemType === ItemCategoryTypes.MISC || i.itemType === ItemCategoryTypes.SUBL))
        .reduce(function (subTotal, item) {
          return subTotal + item.value
        }, 0)
      total += items
        .filter((i) => !i.isQuoteItemDeleted && !i.reportOnly && i.itemType === ItemCategoryTypes.OPG)
        .reduce(function (subTotal, item) {
          return subTotal + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
        }, 0)

      total += items
        .filter(
          (i) =>
            !i.isQuoteItemDeleted &&
            !i.reportOnly &&
            i.itemType !== ItemCategoryTypes.OPG &&
            i.itemType !== ItemCategoryTypes.PART &&
            i.itemType !== ItemCategoryTypes.MISC &&
            i.itemType !== ItemCategoryTypes.SUBL
        )
        .reduce(function (subTotal, item) {
          return roundAwayFromZero(subTotal + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : roundAwayFromZero(item.hourValue * item.rate)))
        }, 0)
      return total
    },
    getItemSortNo(quoteItemId, itemType) {
      let sortNo = null
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Labour').some((c) => c.code === itemType)) {
        const item = this.innerValue.labours.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'OtherLabour').some((c) => c.code === itemType)) {
        const item = this.innerValue.others.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Part').some((c) => c.code === itemType)) {
        const item = this.innerValue.parts.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'OPG').some((c) => c.code === itemType)) {
        const item = this.innerValue.opgs.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Misc').some((c) => c.code === itemType)) {
        const item = this.innerValue.miscs.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Sublet').some((c) => c.code === itemType)) {
        const item = this.innerValue.sublets.find((i) => i.quoteItemId === quoteItemId)
        if (item) sortNo = item.sortNo
      }
      return sortNo
    },
    lastAuthorisedQuoteLabel(selectedAssessment, cap = false) {
      if (selectedAssessment.subQuoteNo === 0) return null
      let label = selectedAssessment.subQuoteNo === 1 ? 'Main' : `Supp-${selectedAssessment.subQuoteNo - 1}`
      return cap === true ? label.toUpperCase() : label
    },
    currentAuthorisedQuoteLabel(selectedAssessment, cap = false) {
      let label = selectedAssessment.subQuoteNo === 0 ? 'Main' : `Supp-${selectedAssessment.subQuoteNo}`
      return cap === true ? label.toUpperCase() : label
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  margin-bottom: 0;
}
.tab-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.tag {
  border-radius: 10rem !important;
}
.tag .icon {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.select-all-box {
  display: flex;
  align-items: center;
  border: 1px solid hsl(204, 86%, 53%);
  border-radius: 5px;
  height: 3rem;
}
.is-gray {
  background-color: #9c9c9c;
  color: #fff;
}
a.is-transparent {
  background-color: transparent;
  border: 1px solid;
}
button.title-align {
  vertical-align: text-bottom;
}
</style>
