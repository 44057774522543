<template>
  <div class="template-component">

    <div class="vehicle-section is-flex is-align-items-start is-justify-content-space-between mb-2"
      v-if="avaliableItemsType == 'LABOUR'">
      <span>Vehcile Sections </span>
      <div>
        <div class="
      tabs
      is-toggle
      py-0
      my-0">
          <ul>
            <li :class="selectedVehicleSection === vehicleSectionOptions.repair.key ? 'is-active' : ''"
              @click="selectedVehicleSection = vehicleSectionOptions.repair.key">
              <a>
                <span>Repair</span>
              </a>
            </li>

            <li class="has-text-success is-success"
              :class="selectedVehicleSection === vehicleSectionOptions.replace.key ? 'is-active ' : ''"
              @click="selectedVehicleSection = vehicleSectionOptions.replace.key">
              <a>
                <span>Replace</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Labour component -->
    <quote-builder-labour-component v-if="avaliableItemsType == 'LABOUR'"
      v-model="innerValue.labours"
      :quote-id="value.quoteId"
      :next-line-number="nextLineNumber"
      :avaliable-items-type="avaliableItemsType"
      :rr-rate="50"
      :repair-rate="45"
      :paint-rate="50"
      :structured-data="structuredData"
      :shop-rate="value.shopRate"
      :quoting-method="value.quotingMethod"
      :vehicle-paint-group="vehicle.paintGroup"
      :selected-vehicle-section="selectedVehicleSection"
      :vehicle-section-options="vehicleSectionOptions"
      :quote-builder-items="quoteBuilderItems"
      :schedule-id="vehicle.scheduleId"
      :body-id="vehicle.bodyId"
      :make="vehicle.model.make"
      :model="vehicle.model.model"
      :year="vehicle.buildYear"
      :labour-type="value.labourType"
      :is-ntar="isNtar"
      :show-scroll-on-add="false"
      @item-added="onItemAddedLabour" />

    <!-- Other (mech) component -->
    <quote-builder-other-component v-else-if="avaliableItemsType == 'OTHER'"
      :avaliable-items-type="avaliableItemsType"
      :structured-data="structuredData"
      v-model="innerValue.others"
      :quote-id="value.quoteId"
      :next-line-number="nextLineNumber"
      :quoting-method="value.quotingMethod"
      :quote-builder-items="quoteBuilderItems"
      :mech-rate="getLabourRate(itemCategoryTypes.MECH, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :shop-rate="value.shopRate"
      @item-added="onItemAddedOther" />

    <!-- parts component -->
    <quote-builder-parts-component v-if="avaliableItemsType == 'PARTS'"
      v-model="innerValue.parts"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :next-line-number="nextLineNumber"
      :structured-data="structuredData"
      :is-audanet="isAudanet"
      :selected-vehicle-section="selectedVehicleSection"
      :vehicle-section-options="vehicleSectionOptions"
      :quote-builder-items="quoteBuilderItems"
      @item-added="onItemAddedPart" />

    <!-- Miscs component -->
    <quote-builder-add-miscs-component v-if="avaliableItemsType == 'MISC'"
      v-model="innerValue.miscs"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :page-size="1200"
      @item-added="onItemAddedMisc" />

    <!-- Sublets Compnent -->
    <quote-builder-add-sublets-component v-if="avaliableItemsType == 'SUBLETS'"
      v-model="innerValue.sublets"
      :quote-id="value.quoteId"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :is-audanet="value.audatex !== null"
      :page-size="1200"
      @item-added="onItemAddedSublet" />

  </div>
</template>

<script>
// import QuoteService from '../../QuoteService'
import Guid from '@/components/Guid'
import { quoteBuilderData } from '../dummyData/quoteBuilderData'
import QuoteBuilderLabourComponent from './quoteBuilderComponents/QuoteBuilderLabourComponent.vue'
import QuoteBuilderOtherComponent from './quoteBuilderComponents/QuoteBuilderOtherComponent.vue'
import QuoteBuilderPartsComponent from './quoteBuilderComponents/QuoteBuilderPartsComponent.vue'
import QuoteBuilderAddMiscsComponent from './quoteBuilderComponents/QuoteBuilderAddMiscsComponent.vue'
import QuoteBuilderAddSubletsComponent from './quoteBuilderComponents/QuoteBuilderAddSubletsComponent.vue'
import cloneDeep from 'lodash.clonedeep'
import deepDiff from 'deep-diff'
import { LabourTimeTypes, ItemCategoryTypes } from '@/enums'
import _isEmpty from 'lodash/isEmpty'

export default {
  components: {
    QuoteBuilderLabourComponent,
    QuoteBuilderOtherComponent,
    QuoteBuilderPartsComponent,
    QuoteBuilderAddMiscsComponent,
    QuoteBuilderAddSubletsComponent
  },
  props: {
    avaliableItemsType: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },
    nextLineNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      vehicleSectionOptions: {
        repair: {
          name: 'Repair',
          key: 'repair'
        },
        replace: {
          name: 'Replace',
          key: 'replace'
        }
      },
      selectedVehicleSection: 'repair',
      itemsMain: [
        { name: 'FRONT BUMPER', open: false },
        { name: 'FRONT PANEL', open: false },
        { name: 'FRONT SIDE PANEL RH', open: false },
        { name: 'FRONT SIDE PANEL LH', open: false },
        { name: 'BODYSIDE PANEL RH', open: false },
        { name: 'PASSENGER COMPARTMENT', open: false },
        { name: 'ROOF', open: false },
        { name: 'REAR SIDE PANEL RH', open: false },
        { name: 'REAR SIDE PANEL LH', open: false },
        { name: 'REAR PARCEL SHELF', open: false },
        { name: 'REAR BOOT', open: false },
        { name: 'REAR BUMPER', open: false }
      ],

      itemsMech: [
        { name: 'FRONT SIDE PANEL RH', open: false },
        { name: 'FRONT SIDE PANEL LH', open: false },
        { name: 'ENGINE BAY', open: false },
        { name: 'BODYSIDE PANEL LH', open: false },
        { name: 'BODYSIDE PANEL RH', open: false },
        { name: 'PASSENGER COMPARTMENT', open: false },
        { name: 'REAR SIDE PANEL RH', open: false },
        { name: 'REAR SIDE PANEL LH', open: false }
      ],

      filter: {
        countryId: Guid.empty(),
        companyId: Guid.empty(),
        query: '',
        butterflyCode: 'RFD',
        scheduleId: '',
        bodyId: '',
        bodySize: '',
        paintGroup: 's3',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      expandedItems: [],
      quoteBuilderItems: [],
      structuredData: [],
      innerValue: null
    }
  },

  computed: {
    isAudanet() {
      return this.value.isAudanet || false
    },
    items() {
      if (this.avaliableItemsType == 'LABOUR' || this.avaliableItemsType == 'PARTS') {
        return this.itemsMain
      } else if (this.avaliableItemsType == 'OTHER') {
        return this.itemsMech
      }
    },
    jsonData() {
      return quoteBuilderData
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    }
  },
  watch: {
    quoteBuilderItems: {
      handler() {
        this.getStructuredData()
      },
      deep: true
    },
    selectedVehicleSection: {
      handler() {
        this.getStructuredData()
      },
      deep: true
    },
    items: {
      handler() {
        this.getStructuredData()
      },
      deep: true
    },
    innerValue: {
      handler() {
        this.$emit('handleUpdate', cloneDeep(this.innerValue))
      },
      deep: true
    },
    value: {
      handler(newValue) {
        if (deepDiff(this.innerValue, newValue)) {
          this.innerValue = cloneDeep(newValue)
        }
      },
      deep: true
    }
  },
  created() {
    this.innerValue = cloneDeep(this.value)
  },
  mounted() {
    // this.getAvailableLabour()
    this.quoteBuilderItems = this.jsonData
  },
  methods: {
    getLabourRate(code) {
      if (!this.value.insurerLabourRates) {
        return {}
      }
      var rate = this.value.insurerLabourRates.find((i) => i.labourCode === code)
      if (!rate) {
        const newRate = {
          columnName: code,
          insurerId: this.value.insurerId,
          labourCode: code,
          labourDesc: code,
          labourType: code,
          rate: 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          isDeleted: false
        }
        this.value.insurerLabourRates.push(newRate)
        rate = newRate
      }
      return rate
    },
    getStructuredData() {
      if (this.avaliableItemsType == 'LABOUR') {
        this.getDataLabour()
      } else if (this.avaliableItemsType == 'PARTS') {
        this.getDataParts()
      } else if (this.avaliableItemsType == 'OTHER') {
        this.getDataOther()
      }
    },
    getDataOther() {
      let structuredData = []
      this.items.forEach((item) => {
        let childItems = this.quoteBuilderItems.filter(
          (x) => x.location.toLocaleLowerCase() == item.name.toLocaleLowerCase() && (x.type == 'Mechanical' || x.type == 'Electrical')
        )

        structuredData.push({
          name: item.name,
          open: item.open,
          items: childItems
        })
      })
      this.structuredData = structuredData
    },

    getDataLabour() {
      let structuredData = []
      this.items.forEach((item) => {
        let childItems = this.quoteBuilderItems.filter((x) => x.location.toLocaleLowerCase() == item.name.toLocaleLowerCase())
        // sort child items by isFavorite property

        let childItemsCp = childItems.map((childItem, index) => {
          return {
            ...childItem,
            selectedLabours: {
              rr: childItem.isFavoriteRepair || childItem.isFavoriteReplace ? true : false,
              repair: index == 0 && this.selectedVehicleSection == this.vehicleSectionOptions.repair.key ? true : false,
              paint: index == 0
            }
          }
        })

        childItemsCp.sort((a, b) => (a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1))
        structuredData.push({
          name: item.name,
          open: item.open,
          items: childItemsCp
        })
      })
      this.structuredData = structuredData
    },

    getDataParts() {
      let structuredData = []
      this.items.forEach((item) => {
        let childItems = this.quoteBuilderItems.filter((x) => x.location.toLocaleLowerCase() == item.name.toLocaleLowerCase())
        // sort child items by isFavorite property

        let childItemsCp = childItems.map((childItem, index) => {
          return {
            ...childItem,
            selectedLabours: {
              rr: childItem.isFavoriteRepair || childItem.isFavoriteReplace ? true : false,
              repair: index == 0 && this.selectedVehicleSection == this.vehicleSectionOptions.repair.key ? true : false,
              paint: index == 0
            }
          }
        })
        childItemsCp.sort((a, b) => (a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1))
        structuredData.push({
          name: item.name,
          open: item.open,
          items: childItemsCp
        })
      })
      this.structuredData = structuredData
    },

    // async getAvailableLabour() {
    //   const availableItems = await QuoteService.getAvailableLabourItems(this.filter, 'NRMA'.toLocaleLowerCase())
    //   console.log(availableItems)
    // }

    onItemAddedPart(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.parts.splice(this.innerValue.parts.length, 1, newItem)
      this.$notification.openNotificationWithType('success', '', 'Part added')

      this.$nextTick(() => {
        this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId, true)
      })
    },
    onItemAddedLabour(newItems) {
      if (!_isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.labours.splice(vm.innerValue.labours.length, 1, item)
          // if (vm.isNtar && item.hourValue > 0 && (item.itemType === ItemCategoryTypes.REP || item.itemType === ItemCategoryTypes.PAINT)) {
          //   vm.addUpdateRepairPaintLoading(item)
          // }
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1
        this.$notification.openNotificationWithType('success', '', `Labour Item${newItems.length > 1 ? 's' : ''} added`)
      }
      this.$nextTick(() => {
        this.$eventHub.$emit('itemAddedButterfly', newItems[0].quoteItemId, true)
      })
    },
    onItemAddedMisc(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)
      this.$notification.openNotificationWithType('success', '', 'Miscallaneaous Item added')

      this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId, true)
    },
    onItemAddedSublet(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
      this.$notification.openNotificationWithType('success', '', 'Item added')

      this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId, true)
    },
    onItemAddedOther(newItems) {
      if (!_isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.others.splice(vm.innerValue.others.length, 1, item)
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1
        this.$notification.openNotificationWithType('success', '', `Item${newItems.length > 1 ? 's' : ''} added`)

        this.$eventHub.$emit('itemAddedButterfly', newItems[0].quoteItemId, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/themes/default/variables';

.header-row {
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  margin-bottom: 0;
}

.item-row {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}

.item-name,
.button {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
}

.button {
  margin: 0;
  white-space: nowrap; /* Prevent button text from wrapping */
}
</style>

<style lang="scss">
@import '@/assets/style/themes/default/variables';

.vehicle-section {
  width: 100%;
  overflow-x: auto;
}

.butterfly-item-values {
  cursor: pointer;

  border: 1px solid $grey;
  border-radius: 5px;
  align-self: center;

  &.is-selected {
    color: white;
    background-color: $primary;
    border: $primary 1px solid;
  }

  &:hover {
    color: $primary;
    border: $primary 1px solid;
  }

  &.is-selected:hover {
    color: white;
    border: $danger 1px solid;
  }
}

.vehicle-section-item {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.quote-builder-section-item {
  // avoid highlighting the text when clicking
  user-select: none;
  background-color: lighten($grey, 40%);
  border-radius: 5px;

  // hover effect
  &:hover {
    background-color: lighten($grey, 35%);
  }
}

.vehicle-section-item {
  &.hidden {
    display: none;
  }
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}
</style>