<template>
  <div v-if="$userInfo && isRepx != null ">
    <div class="tile is-parent">
      <div class="tile is-child detail-page-tile">
        <div class="columns">
          <div class="column">
            <div class="header">
              <div>
                <span class="subtitle is-inline-block">Job Status</span>
              </div>
            </div>
            <ul class="steps is-vertical is-narrow is-centered has-content-centered">
              <li v-for="(step, index) in mappedSteps"
                :key="index"
                class="steps-segment"
                :class="{ 'is-active': index == currentStep }">
                <a href="#"
                  class="has-text-dark">
                  <span class="steps-marker"
                    @click="toggleRepXStepModal(step, index)">
                    {{ index }}
                  </span>
                  <div class="steps-content">
                    <p class="heading">
                      <b>{{ step.step }}</b>
                    </p>
                    <p v-if="step.stepDate != null"
                      class="tags has-addons"
                      style="width: max-content;">
                      <!-- <span
                        class="tag is-dark">Last saved</span> -->
                      <span class="tag is-dark"
                        v-if="step.updateBy!=null">{{ step.updateBy }}</span>
                      <span class="tag is-success">{{ $filters.formatDateTimeLocale(step.stepDate, $userInfo.locale) }}</span>
                    </p>
                  </div>
                </a>
              </li>
            </ul>
            <confirm-modal
              v-if="selectedRepXStepItem && isRepXStepModalActive"
              :active.sync="isRepXStepModalActive"
              @ok="save()"
              @cancel="toggleRepXStepModal(false)"
              :ok-text="'Yes'"
              :cancel-text="'No'"
              v-model="selectedRepXStepItem"
            >
              <p slot="text-title">Job Status - {{ selectedRepXStepItem.step }}</p>
              <p slot="text-content">
                <strong>{{ selectedRepXStepItem.step }}</strong> job will be update to completed. Continue?
              </p>
            </confirm-modal>
            <base-modal-ex
              v-if="false"
              :min-height="55"
              :min-width="30"
              :active.sync="isRepXStepModalActive"
              :can-cancel="false"
              has-modal-card
              name="RepXStepModal"
              @cancel="toggleRepXStepModal(null)"
            >
              <header class="modal-card-head">
                <p class="modal-card-title">
                  <span>Job Status</span>
                </p>
              </header>
              <section class="modal-card-body">
                <pre>{{ selectedRepXStepItem }}</pre>
                <label class="label">{{ selectedRepXStepItem.step }}</label>
                <v-date-picker
                  v-model="selectedRepXStepItem.stepDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="30"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="selectedRepXStepItem"
                  :timezone="$filters.getIanaTimezone()"
                  :min-date="minDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input
                          type="text"
                          class="input"
                          :placeholder="selectedRepXStepItem.step"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                          ref="stepDate"
                        >
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <div class="control">
                        <a class="button tooltip"
                          data-tooltip="Reset dates"
                          @click="clearStartDate('stepDate')">
                          <span class="icon">
                            <i class="mdi mdi-close mdi-24px" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </section>
              <footer class="modal-card-foot is-flex is-justify-content-flex-end">
                <div class="buttons">
                  <button class="button is-success tooltip"
                    data-tooltip="Save"
                    @click="save">
                    <span class="icon">
                      <i class="mdi mdi-content-save mdi-20px" />
                    </span>
                    <span>Save</span>
                  </button>
                  <button class="button tooltip"
                    @click="toggleRepXStepModal(null)"
                    data-tooltip="Close">
                    <span class="icon">
                      <i class="mdi mdi-20px mdi-close" />
                    </span>
                    <span>Close</span>
                  </button>
                </div>
              </footer>
            </base-modal-ex>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepxStages } from '@/enums'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { BaseModalEx, ConfirmModal } from '@/components/BulmaModal'
import QuoteService from '../quote/QuoteService'
import StoreMixin from '../quote/storeMixin'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'RepxRepairStages',
  components: { BaseModalEx, ConfirmModal },
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      selectedRepXStepItem: null,
      isRepXStepModalActive: false,
      entity: null
    }
  },
  computed: {
    steps() {
      return Object.values(RepxStages)
    },
    mappedSteps() {
      const dateKeys = {
        'VEHICLE IN': 'arriveForRepairDate',
        'PANEL REPAIR': 'panelRepairDate',
        'PAINT PREPARATION': 'paintPreparationDate',
        PAINT: 'paintDate',
        'PANEL FIT UP': 'panelFitUpDate',
        'POLISH AND DETAILING': 'polishAndDetailingDate',
        'FINAL INSPECTION': 'finalInsepctionDate',
        'READY FOR PICKUP': 'readyForPickupDate',
        'PICKUP SCHEDULED': 'pickupScheduledDate',
        'PICKUP COMPLETE': 'pickupCompleteDate'
      }

      const result = []
      this.steps.forEach((step) => {
        var stage = Object.keys(RepxStages).find(key => RepxStages[key] === step)
        const key = dateKeys[step]
        var updateByProp = key.replace('Date','UpdateByName')
        result.push({ step: step, stepDate: this.entity.repx[key] || null, stage: stage, updateBy: this.entity.repx[updateByProp] })
      })

      return result
    },
    isRepx() {
      return this.entity.repx != null
    },
    currentStep() {
      var currentStep = this.mappedSteps.length - 1
      const steps = [...this.mappedSteps]
      var reverseSteps = steps.reverse()

      var noDate = steps.filter((i) => i.stepDate != null).length
      if (noDate == 0) return 0
      for (let step of reverseSteps) {
        // console.log(step.step)
        // If some condition is met, break the loop
        if (step.stepDate != null) {
          break // Breaks the loop
        } else currentStep--
      }

      return currentStep
    },
    minDate() {
      const today = new Date()
      return today
    }
  },
  watch: {
    // visible: {
    //   handler: function (newVal, oldVal) {
    //     this.isModelActive = newVal
    //   },
    //   deep: true
    // },
  },
  created() {
    this.entity = _cloneDeep(this.currentSnapshot)
    //if (this.messageId == undefined) this.getRepxMessageDetail()
  },
  mounted() {},
  methods: {
    // async getRepxMessageDetail() {
    //   this.entities = await RepxService.getWebServiceDetail(this.id)
    // },
    // hanldeClose() {
    //   this.isModelActive = false
    //   this.$emit('close')
    // }
    toggleRepXStepModal(item, index) {
      // var validStep = this.currentStep + 1
      // if (!item || index > validStep)
      if (!item) this.isRepXStepModalActive = false
      else {
        const { stepDate } = item || {}
        if (!stepDate && index >= this.currentStep) {
          this.selectedRepXStepItem = item
          this.isRepXStepModalActive = true
        }
      }
    },
    clearStartDate(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.selectedRepXStepItem[input] = null
      }
    },
    async save() {
      this.$showSpinner('Saving...')
      const data = {
        QuoteId: this.$props.value.quoteId,
        Stage: this.selectedRepXStepItem.stage
      }
      this.$emit('save', data)
    },
    async actualSave(data) {
      const isSuccess = await QuoteService.updateRepairStage(data)
      this.resetSaveModal()
      return isSuccess
    },
    async resetSaveModal() {
      this.isRepXStepModalActive = false
      this.refresh()
      this.$hideSpinner()
    },
    async refresh() {
      await this.getStoreItem(this.value.quoteId)
      this.entity = _cloneDeep(this.currentSnapshot)
    }
  }
}
</script>
