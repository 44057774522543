import { ComponentRoute } from '@/classes'

const BookingRoutes = {
  DashboardView: new ComponentRoute('/booking', 'Booking'),
  // CalenderView: new ComponentRoute('/bookingCalendar', 'Booking Calendar'),
  CalendarView: new ComponentRoute('/bookingCalendar', 'Booking Calendar'),
  BookingHeader: new ComponentRoute('/booking/:bookingId', 'BookingHeader'),
  BookingView: new ComponentRoute('/booking/:bookingId', 'BookingView'),
  BookingRepx: new ComponentRoute('/booking/:bookingId/repx', 'BookingRepx')
}

export default BookingRoutes