<template>

  <!--------------------------------------Full width Mode ------------------>
  <div v-if="isFullWidth"
    class="fixed-grid has-12-cols is-align-self-center">
    <div class="grid is-align-items-center">
      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6">
        <!-- Description, belnd and report -->
        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">
            <div class="cell is-col-span-11 is-flex is-flex-direction-column">
              <slot name="description" />
            </div>

            <!-- Report -->
            <div class="cell is-col-span-1 is-clickable">
              <slot name="report_blend" />
            </div>

          </div>
        </div>
      </div>

      <!----------- sublet type   -------------->
      <div class="cell is-col-span-2">
        <slot name="sublet_type" />
      </div>

      <!---------- Sublet Vendor ------------->
      <div class="cell is-col-span-2">
        <slot name="sublet_vendor" />
      </div>

      <!---------- Total value ------------->
      <div class="cell">
        <slot name="total_value" />
      </div>

      <!---------- Actions ------------->
      <div class="cell end">
        <slot name="comment" />
        <slot name="assessment_status" />
        <slot name="delete_button" />
        <slot name="drag_handle" />
      </div>

    </div>
  </div>

  <!------------------------------- Half screen width/ multi col view ------------>

  <div v-else
    class="fixed-grid has-12-cols is-align-self-center">
    <div class="grid "
      style="row-gap: 5px;">
      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6 is-align-self-center">
        <slot name="description" />
      </div>

      <!----------- Report  -------------->
      <div class="cell  is-flex is-justify-content-end">
        <slot name="report_blend" />
      </div>

      <!-- empty -->
      <div class="cell is-col-span-1" />

      <!---------- Total value ------------->
      <div class="cell is-col-span-2 is-align-self-center">
        <slot name="total_value" />
      </div>

      <!---------- Actions ------------->
      <div class="cell is-col-span-2 end">
        <slot name="comment" />
        <slot name="assessment_status" />
        <slot name="delete_button" />
        <slot name="drag_handle" />
      </div>

      <!-------------Sublet type -------------->
      <div class="cell is-col-span-3"
        v-if="isFullyExpanded"
        v-tippy="{ content: 'Sublet Type' }">
        <slot name="sublet_type" />
      </div>

      <!------------ Vendor ------------->
      <div class="cell is-col-span-3"
        v-if="isFullyExpanded"
        v-tippy="{ content: 'Vendor' }">
        <slot name="sublet_vendor" />
      </div>

      <!-- empty -->
      <div class="cell is-col-span-6"
        v-if="isFullyExpanded" />

      <!-- Line number -->
      <div class="cell is-col-span-2"
        v-tippy="{ content: 'Line Number' }"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="lineNo" />
      </div>

      <!--  -->
      <!-- item Number -->
      <div class="cell is-col-span-2"
        v-tippy="{ content: 'Item Number' }"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemNo" />
      </div>

      <!-- Item Status -->
      <div class="cell is-col-span-2"
        v-tippy="{ content: 'Item Status' }"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemStatus" />
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'QuoteBuilderSubletItemTemplate',
  props: {
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isFullyExpanded: {
      type: Boolean,
      default: false
    },
    isHeading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSupportUser() {
      return this.$userInfo.isSupportUser
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 2px solid $primary;
}
</style>