<template>
  <div>
    <app-booking-side-menu :min-width="18"
      :fixed-menu="false"
      :booking-nos="value.bookingNos"
      :current-booking-no="value.bookingNo"
      :current-route-name="$route.name"
      :is-new="value.isNew"
      :is-synced="value.isSynced"
      @select-booking="selectBooking">
      <aside class="menu"
        slot="side-menu">
        <div class="menu-label">
          <div class="columns is-gapless mb-2">
            <div class="column">
              <span class="title is-6"
                style="white-space: nowrap">
                Claim No.: {{ entityName }}
              </span>
            </div>
          </div>
          <div v-if="quoteId"
            class="columns is-gapless mb-1">
            <div class="column">

              <span class="title is-6 is-link"
                style="white-space: nowrap">
                Quote No.:
                <a @click="handleQuoteClick()">
                  {{ quoteNo }}
                </a>
              </span>

            </div>
          </div>
        </div>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: routeTypes.BookingHeader.name, params: { quoteId: $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span><span class="has-badge-child-offset2">Details</span></span>
              <!-- <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded': $v.estimateJobStartEnd.$error || $v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error || $v.ormSummaryGroup.$error }"
                :data-badge="$v.estimateJobStartEnd.$error || $v.quoteHeaderGroup.$error || $v.ormHeaderGroup.$error || $v.audaNetHeaderGroup.$error || $v.ormSummaryGroup.$error ? '' : null">
                <span class="has-badge-child-offset2">Header</span>
              </span>-->
            </router-link>
          </li>
          <li v-if="value.isRepx">
            <router-link :to="{ name: routeTypes.BookingRepx.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                RepX Log
              </span>
            </router-link>
          </li>
        </ul>
      </aside>
    </app-booking-side-menu>
  </div>
</template>

<script>
import AppBookingSideMenu from './components/AppBookingSideMenu'
import BookingRoutes from './route-types'
import Guid from '@/components/Guid'
import QuoteRoutes from '@/views/quote/route-types'

export default {
  name: 'BookingSideMenu',
  inject: ['$vv'],
  components: {
    AppBookingSideMenu
  },
  props: {
    value: null,
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageCount: 0,
      documentCount: 0,
      unreadInterval: null,
      unreadCount: 0
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return BookingRoutes
    },
    enableItemRoutes() {
      return Guid.validGuid(this.value.insurerId) && Guid.validGuid(this.value.vehicleId) && !this.$v.quoteHeaderGroup.$error
    },
    quoteNo() {
      if (this.value && this.value.quoteNo) return this.value.quoteNo
      return null
    },
    quoteId() {
      if (this.value && this.value.quoteId) return this.value.quoteId
      return null
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    selectBooking(bookingNo) {
      this.$emit('select-booking', bookingNo)
    },
    goToRoute(route) {
      if (this.enableItemRoutes) {
        this.$router.push(route)
      }
    },
    handleQuoteClick() {
      if (this.quoteId) {
        var route = QuoteRoutes.QuoteView.path.replace(':quoteId', this.quoteId)
        this.$router.push(`${route}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

span.superscript {
  vertical-align: super;
  font-size: smaller;
}

span.has-badge-child {
  margin-right: 1em;
}

.has-badge-icon {
  &::after {
    font-family: 'Material Design Icons';
    content: '';
    //content: attr(data-badge);
    // content: attr(data-test);
  }
}

.has-badge-icon-check {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f012c';
    //content: attr(data-test);
  }
}

.has-badge-icon-exclamation {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f0205';
  }
}
</style>
