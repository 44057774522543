import { required, maxLength, email, requiredIf } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    loadOptions: {
      quoteId: {
        validGuid: guidValidator
      },
      invoiceDate: {
        required
      },
      customerId: {
        validGuid: guidValidator
      },
      insurerId: {
        required,
        validGuid: guidValidator
      }
    },
    detailGroup: [
      'entity.claimNo',
      'entity.vehicleRegistrationNo',
      'entity.customerName',
      'entity.customerEmail',
      'entity.vehicleMake',
      'entity.vehicleModel',
      'entity.firstname',
      'entity.lastname',
      'entity.insurerId'
    ],
    entity: {
      claimNo: {
        maxLength: maxLength(300)
      },
      vehicleRegistrationNo: {
        required,
        maxLength: maxLength(300)
      },
      customerName: {
        required: requiredIf((model) => {
          return model.isCompany == true || model.isCompany == 'true'
        }),
        maxLength: maxLength(300)
      },
      customerEmail: {
        email
      },
      vehicleMake: {
        maxLength: maxLength(100)
      },
      vehicleModel: {
        maxLength: maxLength(200)
      },
      isRepairBooked: {
        required: requiredIf((model) => {
          return model.isArriveForInspection == true || model.isArriveForInspection == 'true'
        })
      },
      refuseBookRepairReason: {
        required: requiredIf((model) => {
          return (model.isRepairBooked == true || model.isRepairBooked == 'true') && (model.isRepairBooked == false || model.isRepairBooked == 'false' || model.isRepairBooked == '')
        })
      },
      firstname: {
        required: requiredIf((model) => {
          return model.isCompany == false || model.isCompany == 'false'
        }),
        maxLength: maxLength(80)
      },
      lastname: {
        required: requiredIf((model) => {
          return model.isCompany == false || model.isCompany == 'false'
        }),
        maxLength: maxLength(80)
      },
      insurerId: {
        required
      }
    },
    filter: {}
  }
}
