import _debounce from 'lodash/debounce'

export default {
  methods: {
    showSuccessToast(message, duration = 3000) {
      // this.$toast.open({
      //   message: message,
      //   type: 'is-success',
      //   position: 'is-top',
      //   queue: false,
      //   duration: duration
      // })
      this.$notification.openNotificationWithType('success','',message,duration)

    },
    showQuoteChangedToast() {
      // this.$toast.open({
      //   message: 'Quote has changed. Please save before continuing',
      //   type: 'is-warning',
      //   position: 'is-top',
      //   queue: false,
      //   duration: 3000
      // })
      this.$notification.openNotificationWithType('warning','','Quote has changed. Please save before continuing',3000)

    },
    showToast: _debounce(function(message, type = 'success', location = 'top') {
      // this.$toast.open({
      //   message: message,
      //   type: `is-${type}`,
      //   position: `is-${location}`,
      //   queue: true,
      //   duration: 2000
      // })
      this.$notification.openNotificationWithType(type,'',message,2000)

    }, 500)
  }
}
