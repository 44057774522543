<template>
  <div>
    <div
      v-show="currentStep === steps.CreateAssign.id"
      class="box">
      <load-quote-create-step
        v-model="value"
        :ecom-type="ecomType" />
    </div>
  </div>
</template>

<script>
import LoadQuoteCreateStep from './LoadQuoteCreateStep'

export default {
  name: 'LoadQuoteSteps',
  components: {
    LoadQuoteCreateStep
  },
  props: {
    value: null,
    currentStep: {
      type: Number
    },
    steps: null,
    ecomType: {
      type: String
    },
    enableEcomTypeSelection:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    // steps() {
    //   return Steps
    // }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {}
}
</script>
