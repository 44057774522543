<!--
  Sample Code:
  <div>
    <butterfly-modal v-if="isButterflyModalActive"
      :active.sync="isButterflyModalActive"
      v-model="selectedParts"
      @filterButterfly="filterButterfly"
      @reset="reset"
      @done="done"
      @cancel="cancel">
        <div slot="text-content">
          <butterfly v-if="isButterflyModalActive" v-model="selectedParts"></butterfly>
        </div>
    </butterfly-modal>
    <button class="button"
      @click="filterButterfly">
      <span class="icon">
        <i class="mdi mdi-car mdi-18px"></i>
      </span>
      <span>Filter-Butterfly</span>
    </button>
  </div>

  import { Butterfly, ButterflyModal } from '@/components/Butterfly'

  data() {
    return {
      selectedParts: [],
      isButterflyModalActive: false
    }
  },
  methods: {
    reset() {
      this.selectedParts = []
    },
    done() {
      this.isButterflyModalActive = false
    },
    cancel(value) {
      this.selectedParts = value
      this.isButterflyModalActive = false
    },
    filterButterfly() {
      this.isButterflyModalActive = true
    }
  }
-->
<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="fqi fqi-butterfly fqi-32px" />
        </span>
        <span>Butterfly Search</span>
      </p>
    </header>
    <section class="modal-card-body">
      <slot name="text-content" />
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div align="right"
          style="width: 100%">
          <button class="button tooltip"
            @click="reset()"
            data-tooltip="Reset">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
          <button class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Cancel</span>
          </button>
          <button class="button is-primary tooltip"
            @click="done()"
            data-tooltip="Done">
            <span class="icon">
              <i class="mdi mdi-check mdi-18px" />
            </span>
            <span>Done</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import EventBus from '@/components/EventBus'

export default {
  name: 'ButterflyModal',
  components: {
    BaseModalEx
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-settings'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      defaultParts: [],
      selectedParts: []
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.selectedParts = this.value
  },
  mounted() {
    this.defaultParts = Object.assign([], this.selectedParts)
  },
  methods: {
    reset() {
      this.defaultParts = []
      EventBus.$emit('resetEmpty')
    },
    cancel() {
      this.$emit('cancel', this.defaultParts)
    },
    done() {
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/themes/default/variables';

.is-flex-items-center {
  display: flex;
  align-items: center;
}
#svgButterfly {
  fill: $primary;
}
</style>
