export default {
  Vehicle_IN: 'VEHICLE IN',
  PANEL_REPAIR: 'PANEL REPAIR',
  PAINT_PREPARATION: 'PAINT PREPARATION',
  PAINT: 'PAINT',
  PANEL_FIT_UP: 'PANEL FIT UP',
  POLISH_AND_DETAILING: 'POLISH AND DETAILING',
  FINAL_INSPECTION: 'FINAL INSPECTION',
  READY_FOR_PICKUP: 'READY FOR PICKUP',
  PICKUP_SCHEDULED: 'PICKUP SCHEDULED',
  PICKUP_COMPLETE: 'PICKUP COMPLETE'
}