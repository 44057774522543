import { TableColumn } from '@/classes'

const QuoteColumns = [
  new TableColumn('Quote No.', 'quoteNoRef', true, 'asc', 'left'),
  new TableColumn('Rego', 'regoNo', true, 'asc', 'left'),
  new TableColumn('Make', 'make', true, 'asc', 'left'),
  new TableColumn('Model', 'model', true, 'asc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'asc', 'left'),
  new TableColumn('Job Stage', 'jobStageText', true, 'asc', 'left')
]

export { QuoteColumns }
