import { ComponentRoute } from '@/classes'

const QuoteRoutes = {
  QuoteListView: new ComponentRoute('/quotes', 'QuoteListView'),
  QuoteView: new ComponentRoute('/quotes/:quoteId', 'QuoteView'),
  QuoteHeader: new ComponentRoute('/quotes/:quoteId', 'QuoteHeader'),
  QuoteSummary: new ComponentRoute('summary', 'QuoteSummary'),
  QuoteDetail: new ComponentRoute('detail', 'QuoteDetail'),
  QuoteVehicleCustomer: new ComponentRoute('vehiclecustomer', 'QuoteVehicleCustomer'),
  QuoteRatesMarkups: new ComponentRoute('rates', 'QuoteRatesMarkups'),
  QuoteImages: new ComponentRoute('images', 'QuoteImages'),
  QuoteImagesBreakout: new ComponentRoute('imagesbreakout', 'QuoteImagesBreakout'),
  QuoteDocuments: new ComponentRoute('documents', 'QuoteDocuments'),
  QuoteLabour: new ComponentRoute('labour', 'QuoteLabour'),
  QuoteOtherLabour: new ComponentRoute('otherlabour', 'QuoteOtherLabour'),
  QuotePart: new ComponentRoute('part', 'QuotePart'),
  QuoteOpg: new ComponentRoute('opg', 'QuoteOpg'),
  QuoteMisc: new ComponentRoute('misc', 'QuoteMisc'),
  QuoteSublet: new ComponentRoute('sublet', 'QuoteSublet'),
  QuoteRemarks: new ComponentRoute('remarks', 'QuoteRemarks'),
  QuotePnetAudanet: new ComponentRoute('pnetaudanet', 'QuotePnetAudanet'),
  QuoteOrm: new ComponentRoute('orm', 'QuoteOrm'),
  QuoteRepx: new ComponentRoute('repx', 'QuoteRepx'),
  QuoteVehicleDamage: new ComponentRoute('quotevehicledamage', 'QuoteVehicleDamage'),
  QuoteArnie: new ComponentRoute('arnie', 'QuoteArnie'),
  QuoteEstImage: new ComponentRoute('estimage', 'QuoteEstImage'),
  QuoteAnnotations: new ComponentRoute('annotations', 'QuoteAnnotations'),
  QuoteInvoice: new ComponentRoute('invoice', 'QuoteInvoice'),
  QuoteAssessmentInvoice: new ComponentRoute('assessmentinvoice', 'QuoteAssessmentInvoice'),
  QuoteExcessInvoice: new ComponentRoute('excessinvoice', 'QuoteExcessInvoice'),
  QuoteItemsOverview: new ComponentRoute('itemsoverview', 'QuoteItemsOverview'),
  QuoteItemsAuthorise: new ComponentRoute('itemsauthoriseold', 'QuoteItemsAuthorise'),
  AuthoriseView: new ComponentRoute('assessments', 'AuthoriseView'),
  QuoteCommunications: new ComponentRoute('communications', 'QuoteCommunications'),
  QuotePriceParts: new ComponentRoute('priceparts', 'QuotePriceParts'),
  QuotePartsControl: new ComponentRoute('partscontrol', 'QuotePartsControl'),
  QuoteBuilder: new ComponentRoute('builder', 'QuoteBuilder')
}

export default QuoteRoutes
