<template>
  <div v-if="$userInfo.isSupportUser">
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="showSave"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <company-onboarding-side-menu />
      </div>
      <div class="column">
        <router-view ref="currentChild" />
      </div>
    </div>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import CompanyOnboardingSideMenu from './CompanyOnboardingSideMenu'
// import EventBus from '@/components/EventBus'
import CompanyRoutes from '@/views/company/route-types'

export default {
  name: 'CompanyOnboardingView',
  components: {
    AppDetailHeader,
    CompanyOnboardingSideMenu
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      showSave: false
    }
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.setHeaderButtons()
    }
  },
  created() {
    // EventBus.$on('showSave', (isShowSave) => {
    //   debugger
    //   this.showSave = !!isShowSave
    // })
    this.setHeaderButtons()
  },
  mounted() {
  },
  destroyed() {
    // EventBus.$off('showSave')
  },
  beforeDestroy() {},
  methods: {
    setHeaderButtons() {
      if (this.$route) {
        if (this.$route.name == CompanyRoutes.CompanyRepxStagesMappingSetup.name) {
          this.showSave = true
        } else {
          this.showSave = false
        }
      }
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case 'save':
          if (this.$refs.currentChild) {
            if (this.$refs.currentChild.save) this.$refs.currentChild.save()
          }
          break
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>
