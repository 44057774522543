import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Created Date', 'createdDate', true, 'desc', 'left'),
  new TableColumn('Repair Quote ID', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Quote No.', 'RepairQuoteId', true, 'desc', 'left'),
  new TableColumn('Message Type', 'messageTypeDescription', true, 'desc', 'left'),
  new TableColumn('Remark', 'Remark', false, 'desc', 'left'),
  new TableColumn('Status', 'StatusId', true, 'desc', 'centered'),
  new TableColumn('Detail', 'action', false, 'desc', 'centered')
]

export { Columns }
