<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="control is-pulled-right">
            <div class="select"
              @change="getAvailableMiscs()">
              <select v-model="activeTab">
                <option :value="labourType.toUpperCase() === availableMiscTypes.emta.toUpperCase() ? availableMiscTypes.emta : availableMiscTypes.standard">Standard</option>
                <option :value="availableMiscTypes.custom">Custom</option>
                <option :value="availableMiscTypes.emtasundry">e-MTA Sundry</option>
                <option v-if="$userInfo && $userInfo.countryCode.localeCompare('NZ', $userInfo.locale, {sensitivity: 'accent'}) === 0"
                  :value="availableMiscTypes.veronz">Vero-NZ</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-for="(item, index) in availableItems"
      :key="index"
      class="fixed-grid has-12-cols p-1 vehicle-section-item mb-1 b ">
      <div class="grid ">
        <!-- item name -->
        <div class="cell is-col-span-1">
          <span>{{ item.itemNo }}</span>
        </div>

        <div class="cell is-col-span-9">{{ item.description }}</div>

        <div class="cell is-col-span-2">
          <div class="butterfly-item-values is-flex is-justify-content-center"
            @click="addNewItem(item, index)"
            :class="{ ' has-text-weight-bold is-selected has-background-primary': getExistingItem(item)}">
            {{ item.price | formatNumber($userInfo.locale) }}
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import QuoteService from '../../../QuoteService'
import { AvailableMiscTypes } from '../../../QuoteService'
import { ItemCategoryTypes, LabourTimeTypes } from '@/enums'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'QuoteBuilderAddMiscsComponent',
  components: {},
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    labourType: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        countryId: Guid.empty(),
        query: '',
        type: 'standard',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 999
      },
      availableItems: null,
      selectedItems: [],
      activeTab: '',
      preActiveTab: ''
    }
  },
  computed: {
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    availableMiscTypes() {
      return AvailableMiscTypes
    }
  },
  created() {
    this.activeTab = this.labourType === LabourTimeTypes.eMTA ? this.availableMiscTypes.emta : this.availableMiscTypes.standard
    this.innerValue = this.value
    this.getAvailableMiscs()
  },
  mounted() {},
  methods: {
    async getAvailableMiscs() {
      this.isTableLoading = true

      if (this.activeTab === AvailableMiscTypes.custom) {
        this.filter.type = this.activeTab
      }

      if (this.activeTab === AvailableMiscTypes.standard) {
        this.filter.type = `${AvailableMiscTypes.standard}, ${AvailableMiscTypes.custom}`
      }

      if (this.preActiveTab !== this.activeTab) {
        this.filter.pageIndex = 1
        this.preActiveTab = this.activeTab
      }

      this.availableItems = await QuoteService.getAvailableMiscItems(this.filter, this.activeTab)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableMiscs()
    },
    reset() {
      this.selectedItems = []
      this.filter.query = ''
      this.type = 'standard'
      this.filter.pageIndex = 1
      this.getAvailableMiscs()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableMiscs()
    }, 500),
    cancel(value) {
      this.selectedItems = value
    },

    getExistingItem(item) {
      return this.value.find((i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
    },

    addNewItem(item, index) {
      const existingItem = this.getExistingItem(item)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.MISC)
        newItem.lineNumber = this.nextLineNumber
        newItem.value = item.price
        newItem.side = item.side
        newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        this.$emit('item-added', newItem)
      } else {
        this.$eventHub.$emit('deleteMisc', existingItem)
        // this.$notification.openNotificationWithType('warning', 'Misc', 'Item is already in selected list. Item not added.')
      }
    }
    // tabClicked(miscType) {
    //   if (miscType === this.availableMiscTypes.standard && this.labourType.toUpperCase() === this.availableMiscTypes.emta.toUpperCase()) {
    //     this.activeTab = this.availableMiscTypes.emta
    //   } else {
    //     this.activeTab = miscType
    //   }
    //   this.getAvailableMiscs()
    // }
  }
}
</script>

<style lang="scss" scoped>
.svg path {
  fill: red !important;
  &:hover path {
    fill: white !important;
  }
}
</style>
