import FqApi from '@/services/fq-api'

export default{
  async getSubJobStages(filter) {
    const url = '/subJobStage/list/'
    const result = await FqApi.post(url,filter)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/subJobStage/new')
    return result.data
  },
  async getEntity(id) {
    const result = await FqApi.get(`/subJobStage/${id}`)
    return result.data
  },
  async postSubJobStages(entity) {
    return FqApi.post('/subJobStage', entity)
  },
  putSubJobStages(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/subJobStage/${entity.subJobStageId}`, entity)
  }
}