<template>
  <div class="template-component">
    <!-- Add your template content here -->

    <div v-if="templatesLoading"
      style="position: relative; top: 100px;">
      <spinner />
    </div>

    <div v-else>
      <div class="is-flex  p-1 m-1 is-justify-content-space-between template-list-item"
        v-for="i in templates"
        :key="i.templateId">

        <div class="is-flex">
          <span class="material-symbols-outlined">
            close
          </span>
          <div>{{ i.name }}</div>
        </div>

        <div>
          <span class="material-symbols-outlined has-text-grey is-clickable mx-2"
            @click="handleTemplateClick(i)">
            visibility
          </span>

          <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
            @click="$emit('addRepairQuoteTemplateItems', i.templateId)">
            add
          </span>

        </div>
      </div>
    </div>

    <template-items-modal v-if="isTemplateItemsModalActive"
      :active.sync="isTemplateItemsModalActive"
      :selected-template="selectedTemplate"
      :rates="rates"
      @addTemplateItemsCustom="obj => $emit('addTemplateItemsCustom', obj)" />

  </div>
</template>

<script>
import QuoteTemplateService from '@/services/QuoteTemplateService'
import { QuoteTemplateListColumns } from '@/views/quotetemplate/columns.js'
import { QuoteTemplateTypeKeys } from '@/enums/QuoteTemplateTypes'
import TemplateItemsModal from './TemplateItemsModal.vue'
import Spinner from '../../../../components/Spinners/Spinner.vue'

export default {
  name: 'TemplateComponent',
  components: {
    TemplateItemsModal,
    Spinner
  },

  props: {
    vehicleBodyId: {
      type: String,
      required: true
    },
    quoteTimesType: {
      type: String,
      required: true
    },
    rates: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      isTemplateItemsModalActive: false,
      selectedTemplateId: '',
      templatesLoading: false,
      templates: [],
      filter: {
        name: '',
        sortColumn: QuoteTemplateListColumns[0].name,
        sortOrder: QuoteTemplateListColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 200
      }
      // Add your component data here
    }
  },
  computed: {
    // Add your computed properties here
    quoteTemplateTypeKeys() {
      return QuoteTemplateTypeKeys
    }
  },
  mounted() {
    this.getQuoteTemplates()
    // Add your mounted logic here
  },
  methods: {
    handleTemplateClick(template) {
      this.isTemplateItemsModalActive = true
      this.selectedTemplate = template
    },
    // Add your component methods here

    async getQuoteTemplates() {
      this.templatesLoading = true
      const quoteTemplates = await QuoteTemplateService.getQuoteTemplates(this.filter)
      const quoteRepairerTemplates = quoteTemplates.filter((template) => template.quoteType == this.quoteTemplateTypeKeys.Repairer)

      const quoteRepairerTemplatesWithoutLabour = quoteRepairerTemplates.filter(
        (template) =>
          (template.timesType == null && template.bodyId == null) ||
          (template.timesType == null && template.bodyId === this.vehicleBodyId) ||
          (template.bodyId == null && this.matchingTimesType(this.quoteTimesType, template.timesType))
      )

      const quoteRepairerTemplatesWithLabour = quoteRepairerTemplates.filter(
        (template) => template.bodyId === this.vehicleBodyId && this.matchingTimesType(this.quoteTimesType, template.timesType)
      )

      this.templates = [...quoteRepairerTemplatesWithLabour, ...quoteRepairerTemplatesWithoutLabour]

      this.templatesLoading = false
      console.log(this.templates)
    },

    matchingTimesType(quoteTimesType, templateTimesType) {
      return (
        (quoteTimesType === 'LTAR' && templateTimesType === 'LTAR') ||
        (quoteTimesType === 'NTAR' && templateTimesType === 'NTAR') ||
        (quoteTimesType !== 'LTAR' && quoteTimesType !== 'NTAR' && templateTimesType && templateTimesType !== 'NTAR' && templateTimesType !== 'LTAR')
      )
    },

    handleClose() {
      console.log('closing modal')

      this.isTemplateItemsModalActive = false
      this.selectedTemplate = null
    }
  }
}
</script>

<style lang="scss" scoped >
@import '@/assets/style/themes/default/variables';

.template-list-item {
  border-radius: 5px;

  &:hover {
    background-color: lighten($grey, 30%);
  }
}
</style>