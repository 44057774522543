<template>

  <!-- TODO editing values, adding new parts, etc agfter qb refactor -->

  <quote-parts-control-section>
    <template slot="heading">
      <span class="has-text-weight-bold has-text-primary mt-1">
        Not Ordered - Total: {{ $filters.formatCurrency(addTotal(unorderedParts, 'buyPrice') , $userInfo.locale) }}
      </span>
    </template>

    <template slot="heading_buttons">
      <div class="button ml-2 is-primary"
        :class="{'disabled is-outlined': !unorderedParts?.length}"
        @click="handleNewPurchaseOrder(partsControlEnums.newPo)">
        Purchase Order
      </div>

      <div class="button ml-2 is-primary"
        :class="{'disabled is-outlined': !unorderedParts?.length}"
        @click="handleNewPurchaseOrder(partsControlEnums.manualQuote)">
        Parts Enquiry
      </div>

      <div v-if="userPartsCheckPermission()"
        class="button ml-2 is-success"
        :class="{'is-outlined disabled': pricePartsDisabled}"
        @click="$emit('goToPriceParts', unorderedParts)">
        Price Parts
      </div>

    </template>

    <template slot="body">

      <div class="unordered-parts header">
        <div>#</div>

        <div>Reqst.</div>

        <div class="left">Description</div>
        <div>Part No</div>

        <div>Item Type</div>
        <div>Qty</div>
        <div>Supplier</div>
        <div class="right">Unit Cost</div>
        <div class="right">Unit Sell</div>
        <div> Status</div>
      </div>

      <div>
        <div v-for="(item, index) in unorderedParts"
          :key="item.quoteItemId">

          <div class="is-size-7 px-2 py-1 has-text-weight-bold"
            v-if="index == 0 || item.itemType !== unorderedParts[index-1]?.itemType">
            {{ typeFullName?.[item.itemType] }}
          </div>

          <div class="unordered-parts part-row "
            :class="{'is-editable' : item.partStatus === PartStatusTypes.sentManualEnquiry || item.partStatus === PartStatusTypes.pricedPartsControlManual}"
            @click="handleSelectRow(item); $event.stopPropagation()">
            <div>
              <span>{{ index + 1 }}</span>
            </div>
            <div>
              <div v-if="item.partStatus === 'Exp' || item.partStatus === 'Imp' || item.partStatus === 'Pord'"
                @click="goToPartsCheck($event, item?.partsCheckReferenceNo, 'quote', item)"
                class="is-flex is-flex-direction-row  is-align-content-center is-clickable is-underlined has-text-primary">
                <span>#{{ item.partsCheckReferenceNo }}</span>
              </div>
            </div>

            <div class="left">
              <input v-if="item.quoteItemId === itemEditingId"
                class="input focus-target"
                data-target="itemDesc"
                :maxlength="150"
                :title="'Description: ' + item.itemDesc"
                placeholder="Item Description"
                v-model="item.itemDesc"
                @click.stop>
              <span v-else>
                {{ item.itemDesc }}
              </span>
            </div>

            <div>
              <span v-if="!item.isNew">
                {{ item.partNo }}
              </span>
              <input v-else
                class="input focus-target mx-1"
                data-target="partNo"
                :maxlength="150"
                :title="'Part No.' + item.partNo"
                placeholder="Part No."
                v-model="item.partNo"
                @click.stop>
            </div>
            <div>{{ PartTypes?.[item.mark] || '-' }}</div>

            <div>
              <vue-numeric v-if="item.quoteItemId === itemEditingId && item.itemType === 'PART'"
                class="input has-text-right"
                v-model.number="item.itemQuantity"
                :min="1"
                :precision="0" />
              <span v-else>
                {{ item.itemType === "PART" ? item.itemQuantity - item.quantityOrdered + item.quantityReturned : 'N/A' }}
              </span>
            </div>

            <div>{{ item.vendorName ? item.vendorName : '-' }}</div>

            <div class="right">
              <vue-numeric v-if="item.quoteItemId === itemEditingId"
                class="input has-text-right ml-3"
                @input.native="handleOnPriceChange(item)"
                v-model.number="item.buyPrice"
                data-target="buyPrice"
                :title="'Cost Price: ' + item.buyPrice"
                :min="0"
                :precision="2" />
              <span v-else>
                {{ $filters.formatCurrency(item.buyPrice , $userInfo.locale) }}
              </span>
            </div>

            <div v-if="item.itemType === ItemCategoryTypes.SUBL"
              class="right sell-price-field"
              :id="item.quoteItemId">
              <vue-numeric v-if="item.quoteItemId === itemEditingId"
                @input.native="handleOnPriceChange(item)"
                class="input has-text-right ml-3"
                v-model.number="item.value"
                data-target="value"
                :title="'Sell Price: ' + item.value"
                :min="0"
                :precision="2" />
              <span v-else>
                {{ $filters.formatCurrency(item.value , $userInfo.locale) }}
              </span>
            </div>
            <div v-else
              class="right sell-price-field"
              :id="item.quoteItemId">
              <vue-numeric v-if="item.quoteItemId === itemEditingId"
                @input.native="handleOnPriceChange(item)"
                class="input has-text-right ml-3"
                v-model.number="item.markupValue"
                data-target="markupValue"
                :title="'Sell Price: ' + item.markupValue"
                :min="0"
                :precision="2" />
              <span v-else>
                {{ $filters.formatCurrency(item.markupValue , $userInfo.locale) }}
              </span>
            </div>
            <div class="left"
              v-tippy="{ content: getStatus(item)?.tooltip, enabled: !!getStatus(item)?.tooltip }"
              @click="(e) => getStatus(item)?.isPartsCheckRouteEnabled ? goToPartsCheck(e, item?.partsCheckReferenceNo, 'quote', item) : e.stopPropagation()">
              <div class="columns has-text-weight-bold ml-1"
                :class="[getStatus(item)?.colour, { 'is-clickable': getStatus(item)?.isPartsCheckRouteEnabled }]"
                style="width: 100%;">
                <div class="column is-2"
                  @click="getStatus(item)?.func()">
                  <span :class="[getStatus(item)?.classStatus]" />
                </div>
                <div class="column is-9 is-justify-content-left">
                  <span>{{ getStatus(item)?.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>

    <!-- add functionality to add parts, sub,  misc later -->
    <template slot="footer"
      v-if="false">
      <div class="button mr-2 is-primary is-outlined"
        @click="handleAddItem(ItemCategoryTypes.PART)">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Part</span>
      </div>
      <div class="button mr-2 is-primary is-outlined"
        @click="handleAddItem(ItemCategoryTypes.SUBL)">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Sublet</span>
      </div>
      <div class="button mr-2 is-primary is-outlined"
        @click="handleAddItem(ItemCategoryTypes.MISC)">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Misc</span>
      </div>
    </template>

  </quote-parts-control-section>
</template>

<script>
import QuotePartsControlSection from '../QuotePartsControlSection.vue'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import _cloneDeep from 'lodash/cloneDeep'
import { PartTypes, PartStatusTypes } from '@/enums'
import { ItemCategoryTypes, PartsControlActionTypes, QuoteItemsOrderStatusTypes } from '@/enums'
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import VendorService from '@/views/vendor/VendorService'

const typeFullName = {
  PART: 'Parts',
  SUBL: 'Sublets',
  MISC: 'Miscellaneous'
}

export default {
  name: 'QuotePartsControlNotOrdered',
  components: { QuotePartsControlSection, vueNumeric },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  props: {
    value: null,
    unorderedParts: {
      type: Array,
      default: () => []
    },
    partsCheckSettings: null
  },
  data() {
    return {
      openSubRowId: null,
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],
      selectedRows: [],
      innerValue: this.value,
      itemEditingId: null
    }
  },
  computed: {
    partsControlEnums() {
      return PartsControlActionTypes
    },
    PartStatusTypes() {
      return PartStatusTypes
    },
    typeFullName() {
      return typeFullName
    },
    PartTypes() {
      let obj = {}
      Object.values(PartTypes).map((item, index) => {
        obj = { ...obj, [item.value]: item.description }
      })
      return obj
    },
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    quotes() {
      return [this.innerValue]
    },
    pricePartsDisabled() {
      // if there are no parts available, disable the button
      return !this.unorderedParts?.filter((item) => item.itemType === 'PART').length
    },
    manualOrderItems() {
      return this.unorderedParts
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.getExternalSetting('partscheck'))
  },
  methods: {
    handleEditItem(item) {
      this.itemEditingId = item.quoteItemId
      this.$nextTick(() => {
        let elementById = document.getElementById(item.quoteItemId)
        /// focus on the input field within the div
        let inputElement = elementById.getElementsByClassName('input')
        inputElement[0].focus()
      })
    },
    handleSelectRow(item) {
      if (item.partStatus === PartStatusTypes.sentManualEnquiry || item.partStatus === PartStatusTypes.pricedPartsControlManual) {
        this.itemEditingId = item.quoteItemId
      }
    },
    handleAddItem(type) {
      // TODO: change after rewrite
      this.addBlankItem(type)
    },
    handleDeleteItem(item) {
      // TODO: change after rewrite
      this.deleteItem(item)
    },
    async goToPartsCheck(e, partsCheckReferenceNo, searchType, part) {
      this.$emit('goToPartsCheck', e, partsCheckReferenceNo, searchType, part)
    },
    getStatus(part) {
      let isPartsCheckRouteEnabled = false
      let tooltip = ''
      let func = () => {}
      let statusObj = {
        colour: '',
        class: '',
        title: ''
      }

      if (!part.partStatus && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.NotRequested
      } else if (part.partStatus === PartStatusTypes.sentManualEnquiry && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Requested
      } else if (part.partStatus === PartStatusTypes.pricedPartsControlManual && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Priced
      } else if (part.partStatus === 'Exp' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Requested
        isPartsCheckRouteEnabled = true
        tooltip = 'Go to Parts Check'
      } else if (part.partStatus === 'Imp' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Priced
        isPartsCheckRouteEnabled = true
        tooltip = 'Go to Parts Check'
      } else if (part.partStatus === 'Pord' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.PendingOrder
        isPartsCheckRouteEnabled = true
        tooltip = 'Go to Parts Check'
      } else if (
        (part.quantityOrdered > 0 && part.quantityReceived == 0 && part.quantityReturned == 0) ||
        (part.quantityOrdered > part.quantityReturned && part.quantityReceived == 0)
      ) {
        statusObj = QuoteItemsOrderStatusTypes.ItemOrdered
      } else if (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered > part.quantityReturned) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReturned
      } else if (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered == part.quantityReturned) {
        statusObj = QuoteItemsOrderStatusTypes.AllItemsReturned
      } else if (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered == part.quantityReceived) {
        statusObj = QuoteItemsOrderStatusTypes.FullyReceived
      } else if (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered !== part.quantityReceived) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReceived
      }
      return { classStatus: statusObj.class, title: statusObj.title, isPartsCheckRouteEnabled, tooltip, colour: statusObj.colour, func }
    },
    addTotal(arr, key) {
      let total = 0
      arr.forEach((item) => {
        if (item.itemType === 'PART') {
          total += item[key] * (item.itemQuantity - item.quantityOrdered + item.quantityReturned)
        } else {
          total += item[key]
        }
      })
      return total
    },
    // Order type is either Parts enquiry or manual purchase order
    handleNewPurchaseOrder(orderType) {
      // if (!this.manualOrderItems.length) {
      //   this.$notification.openNotificationWithType('danger', 'No Items', 'Priced Items cannot be priced through Manual Enquiry or manually ordered.')
      //   return
      // }
      if (!!this.manualOrderItems.filter((p) => p.deleted === false && p.isNew).length) {
        this.$notification.openNotificationWithType(
          'danger',
          'Unsaved Items',
          'You have added new part item(s).' + '\n' + 'Please save them before creating new Manual Quote/Order.' // TODO: message is too long, make it multiline
        )
        return
      }
      this.$emit('newPurchaseOrder', _cloneDeep(this.manualOrderItems), orderType.key)
    },
    handleOnPriceChange(item) {
      // if item was requested through manual enquiry and then price is changed
      if (item.partStatus === PartStatusTypes.sentManualEnquiry) {
        item.partStatus = PartStatusTypes.pricedPartsControlManual
      }
    },
    async getVendorName(vendorId) {
      if (vendorId) {
        const vendor = await VendorService.getEntity(vendorId)
        return vendor?.name
      }
    },
    userPartsCheckPermission() {
      let isPartsCheck = false
      for (let permissions of this.$user.permissions) {
        if (permissions.codeName == 'A021') {
          console.log('permissions', permissions)

          isPartsCheck = permissions.hasAccess && !!this.partsCheckSettings && this.partsCheckSettings.property2 == '1'
        }
      }
      return isPartsCheck
    }
  }
}
</script>

<style lang="scss" scoped>
.unordered-parts {
  display: grid;
  // headings : #, checkbox, Req., Description, Part No, Part Type, Authorised, Supp#, Qty, Supplier, Cost Price, Sell Price, Status
  grid-template-columns: 2% 5% 30% 10% 5% 5% 15% 9% 9% 10%;

  &.is-editable {
    &:hover {
      border: gray 1px solid;
      cursor: text;
    }
  }
}
</style>