<template>
  <div class="tile is-ancestor is-parent pl-0">
    <div class="tile is-parent">
      <article class="tile is-child box">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">RepX</p>
            <!-- <div class="tabs is-toggle is-toggle-rounded">
              <ul>
                <li :class="{ 'is-active': isRepxLogsActive }">
                  <a @click="tabClicked(tabs[0])">
                    <span class="icon is-small">
                      <i class="mdi mdi-web mdi-18px" />
                    </span>
                    <span>
                      <span>{{ tabs[0] }}</span>
                      <span class="has-badge-rounded has-badge-medium"
                        :class="[ false ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': isVehicleDamageActive }">
                  <a @click="tabClicked(tabs[1])">
                    <span class="icon is-small">
                      <i class="mdi mdi-car-wrench mdi-18px" />
                    </span>
                    <span>
                      <span>{{ tabs[1] }}</span>
                      <span class="has-badge-rounded has-badge-medium"
                        :class="[ false ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
              </ul>
            </div> -->
            <!-- <div class="columns"
              v-if="isRepxLogsActive">
              <div class="column is-one-fifth">
                <repx-repair-stages v-model="value.repx"
                  :quote-id="value.quoteId" />
              </div>
              <div class="column">
                <repx-list :hide-filter="hideFilter"
                  :quote-id="value.quoteId" />
              </div>
            </div> -->
            <!-- <div class="columns"
              v-if="isVehicleDamageActive">
              <div class="column">
                <quote-vehicle-damage :quote-id="value.quoteId" />
              </div>
            </div> -->
          </div>
          <div class="column">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <a class="button is-primary ml-3"
                    @click="handleFetch()">
                    <span class="icon">
                      <i class="mdi mdi-cloud-download mdi-18px" />
                    </span>
                    <span>Fetch</span>
                  </a>
                  <button class="button is-success tooltip ml-3"
                    @click="handleSync()"
                    data-tooltip="Sync">
                    <span class="icon">
                      <i class="mdi mdi-sync mdi-20px" />
                    </span>
                    <span>Sync</span>
                  </button>
                  <a class="button is-primary ml-3"
                    @click="toggleRepXLogModal()">
                    <span class="icon">
                      <i class="mdi mdi-18px mdi-eye" />
                    </span>
                    <span>View Log</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns"
          v-if="isRepxLogsActive">
          <div class="column is-one-fifth">
            <repx-repair-stages v-model="value.repx"
              ref="repxRepairStages"
              :quote-id="value.quoteId"
              @save="handleSaveRepxRepairStages" />
          </div>
          <div class="column">
            <quote-vehicle-damage :quote-id="value.quoteId"
              ref="quoteVehicleDamageRef" />
          </div>
          <div class="column"
            v-if="isVehicleDamageActive">
            <repx-list :hide-filter="hideFilter"
              :quote-id="value.quoteId" />
          </div>
        </div>
      </article>
    </div>
    <base-modal-ex v-if="value && isRepXLogModalActive"
      :active.sync="isRepXLogModalActive"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      :min-width="80"
      :min-height="85"
      has-modal-card
      @cancel="toggleRepXLogModal()">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span>RepX Log</span>
        </p>
      </header>
      <section class="modal-card-body">
        <repx-list :hide-filter="hideFilter"
          :quote-id="value.quoteId" />
      </section>
      <footer class="modal-card-foot is-flex is-justify-content-flex-end">
        <div class="buttons">
          <button class="button tooltip"
            @click="toggleRepXLogModal()"
            data-tooltip="Close">
            <span class="icon">
              <i class="mdi mdi-20px mdi-close" />
            </span>
            <span>Close</span>
          </button>
        </div>
      </footer>
    </base-modal-ex>
  </div>
</template>

<script>
import RepxList from '../repx/RepxList.vue'
import { QuoteRepxTabs } from '@/enums'
import RepxRepairStages from '../repx/RepxRepairStages.vue'
import QuoteVehicleDamage from './QuoteVehicleDamage.vue'
import BaseModalEx from '../../components/BulmaModal/BaseModalEx.vue'

export default {
  name: 'QuoteRepx',
  components: { RepxList, RepxRepairStages, QuoteVehicleDamage, BaseModalEx },
  props: {
    value: null
  },
  data: () => {
    return {
      id: '',
      entities: {},
      tabs: [QuoteRepxTabs.RepxLogs, QuoteRepxTabs.VehicleDamage],
      selectedTab: QuoteRepxTabs.RepxLogs,
      isModelActive: false,
      hideFilter: true,
      isRepXLogModalActive: false
    }
  },
  computed: {
    isRepxLogsActive() {
      return this.selectedTab == QuoteRepxTabs.RepxLogs
    },
    isVehicleDamageActive() {
      return this.selectedTab == QuoteRepxTabs.VehicleDamage
    }
  },
  watch: {
    // visible: {
    //   handler: function (newVal, oldVal) {
    //     this.isModelActive = newVal
    //   },
    //   deep: true
    // },
  },
  created() {
    //if (this.messageId == undefined) this.getRepxMessageDetail()
  },
  mounted() {},
  methods: {
    handleSaveRepxRepairStages(data) {
      this.$emit('save-repx-repair-stages', data)
    },
    saveRepxRepairStages(data) {
      const isSaved = this.$refs.repxRepairStages.actualSave(data)
      return isSaved
    },
    tabClicked(selected) {
      this.selectedTab = selected
    },
    toggleRepXLogModal() {
      this.isRepXLogModalActive = !this.isRepXLogModalActive
    },
    handleFetch(){
      if (this.$refs.quoteVehicleDamageRef!== undefined) {
        this.$refs.quoteVehicleDamageRef.syncVehicleDamage()
      }
    },
    handleSync(){
      if (this.$refs.quoteVehicleDamageRef!== undefined) {
        this.$refs.quoteVehicleDamageRef.save()
      }
    }
    // async getRepxMessageDetail() {
    //   this.entities = await RepxService.getWebServiceDetail(this.id)
    // },
    // hanldeClose() {
    //   this.isModelActive = false
    //   this.$emit('close')
    // }
  }
}
</script>
