<template>

  <!--------------------------------------Full width Mode ------------------>
  <div v-if="isFullWidth">
    <div class="fixed-grid has-12-cols">
      <div class="grid">
        <!-- Description, belnd and report -->
        <div class="cell is-col-span-6">

          <div class="fixed-grid has-12-cols">
            <div class="grid">
              <div class="cell is-col-span-10 is-flex is-flex-direction-column">
                <slot name="description" />
              </div>

              <!----------- part type   -------------->
              <div class="cell">
                <slot name="part_type" />
              </div>

              <div class="cell is-clickable">
                <slot name="report" />
              </div>

            </div>
          </div>

        </div>

        <!---------- part qty, buy, markup, unit price  ------------->
        <div class="cell is-col-span-4 ">

          <div class="fixed-grid has-12-cols">
            <div class="grid">

              <div class="cell is-col-span-2">
                <slot name="part_qty" />
              </div>

              <div class="cell is-col-span-3">
                <slot name="part_sell" />
              </div>

              <div class="cell is-col-span-3">
                <slot name="part_markup" />
              </div>

              <div class="cell is-col-span-3">
                <slot name="part_buy" />
              </div>

            </div>
          </div>

          <slot name="part_qty_values" />
        </div>

        <div class="cell is-col-span-2">
          <div class="fixed-grid has-12-cols">
            <div class="grid">

              <!---------- Total value ------------->
              <div class="cell is-col-span-5">
                <slot name="total_value" />
              </div>

              <!---------- Actions ------------->
              <div class="cell is-col-span-7">
                <div class="cell end is-align-items-center">
                  <div class="mt-1 is-flex">
                    <slot name="comment" />
                    <slot name="assessment_status" />
                    <slot name="part_status" />
                    <slot name="delete_button" />
                    <slot name="drag_button" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!------------------------------- Half screen width/ multi col view ------------>

  <div v-else
    class="fixed-grid has-12-cols">
    <div class="grid "
      style="row-gap: 5px;">
      <!-- Description, belnd and report -->
      <div class="cell is-col-span-7">

        <div class="fixed-grid has-12-cols">
          <div class="grid">
            <div class="cell is-col-span-8 is-flex is-flex-direction-column">
              <slot name="description" />
            </div>

            <!----------- part type   -------------->
            <div class="cell is-col-span-3 is-flex is-justify-content-center">
              <slot name="part_type" />
            </div>

            <!----------- Report -------------->
            <div class="cell is-flex">
              <slot name="report" />
            </div>

          </div>
        </div>
      </div>
      <!-- <div class="cell is-flex " /> -->

      <!-- align items center in heading, but not for actual items -->
      <!---------- qty ------------->
      <div class="cell"
        :class="{'is-align-content-center' : isHeader}">
        <slot name="part_qty" />
      </div>

      <div class="cell is-col-span-4">
        <div class="fixed-grid has-12-cols">
          <div class="grid">

            <!---------- Total value ------------->
            <div class="cell is-col-span-5"
              :class="{'is-align-content-center' : isHeader}">
              <slot name="total_value" />
            </div>

            <!---------- Actions ------------->
            <div class="cell is-col-span-7 end">
              <div class="is-flex mt-2">
                <slot name="comment" />
                <slot name="assessment_status" />
                <slot name="part_status" />
                <slot name="delete_button" />
                <slot name="drag_button" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="cell is-col-span-1"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="lineNo" />
      </div>

      <!-- Item No -->
      <div class="cell is-col-span-1"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemNo" />
      </div>

      <!-- Item Status -->
      <div class="cell is-col-span-1"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemStatus" />
      </div>

      <!-- Present as button -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded" />

      <!-- empty slot  -->
      <div class="cell is-col-span-3"
        v-if="isFullyExpanded && !isSupportUser">
        <slot name="empty_slot" />
      </div>

      <!-- unit price -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded">
        <div style="margin-top: -22px;">
          <span class="has-text-weight-bold ml-2">Unit Price:</span>
          <slot name="part_sell" />
        </div>
      </div>

      <!-- mark up price -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded">
        <div style="margin-top: -22px;">
          <span class="has-text-weight-bold ml-2">Mark up:</span>
          <slot name="part_markup" />
        </div>
      </div>

      <!-- buy price -->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded">
        <div style="margin-top: -22px;">
          <span class="has-text-weight-bold ml-2">Buy Price:</span>
          <slot name="part_buy" />
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'QuoteBuilderPartItemTemplate',
  props: {
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isFullyExpanded: {
      type: Boolean,
      default: false
    },
    isHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isSupportUser() {
      return !!this.$userInfo.isSupportUser
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 2px solid $primary;
}
</style>