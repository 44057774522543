export const quoteBuilderData = [
    { id: 1, description: 'Front Bumper Cover', type: 'Trim', location: 'Front Bumper', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 2, description: 'Front Bumper Bracket', type: 'Trim', location: 'Front Bumper', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 3, description: 'Front Bumper Reinforcement', type: 'Body Panel', location: 'Front Bumper', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 4, description: 'Front Bumper Indicator RH', type: 'Electrical', location: 'Front Bumper', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 5, description: 'Front Bumper Indicator LH', type: 'Electrical', location: 'Front Bumper', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 6, description: 'Front Bumper Foglamp RH', type: 'Electrical', location: 'Front Bumper', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 7, description: 'Front Bumper Foglamp LH', type: 'Electrical', location: 'Front Bumper', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 8, description: 'Front Bumper Foglamp Cover RH', type: 'Trim', location: 'Front Bumper', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: true, isFavoriteReplace: true },
    { id: 9, description: 'Front Bumper Foglamp Cover LH', type: 'Trim', location: 'Front Bumper', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: true },
    { id: 10, description: 'Front Bumper Lower Trim RH', type: 'Trim', location: 'Front Bumper', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: true },
    { id: 11, description: 'Front Bumper Lower Trim LH', type: 'Trim', location: 'Front Bumper', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: true },
    { id: 12, description: 'Front Bumper Lower Lip', type: 'Trim', location: 'Front Bumper', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 13, description: 'Front Bumper Fixings', type: 'Trim', location: 'Front Bumper', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 14, description: 'Front Bumper Other Trims', type: 'Trim', location: 'Front Bumper', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 15, description: 'ABS Sensors', type: 'Electrical', location: 'Front Bumper', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 16, description: 'Radar Sensors', type: 'Electrical', location: 'Front Bumper', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 17, description: 'Other Sensors', type: 'Electrical', location: 'Front Bumper', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 18, description: 'Front Panel Upper', type: 'Body Panel', location: 'Front Panel', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 19, description: 'Front Panel Assembly', type: 'Body Panel', location: 'Front Panel', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 20, description: 'Front Cross Member', type: 'Body Panel', location: 'Front Panel', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 21, description: 'Front Panel Lower', type: 'Body Panel', location: 'Front Panel', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 22, description: 'Front Panel Reinforcement', type: 'Body Panel', location: 'Front Panel', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 23, description: 'Headlamp Panel RH', type: 'Body Panel', location: 'Front Panel', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 24, description: 'HeadLamp Assembly RH', type: 'Electrical', location: 'Front Panel', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 25, description: 'Headlamp Bracket RH', type: 'Trim', location: 'Front Panel', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 26, description: 'Headlamp Cover Glass RH', type: 'Trim', location: 'Front Panel', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 27, description: 'Headlamp Bulb Socket RH', type: 'Trim', location: 'Front Panel', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 28, description: 'Headlamp Cover Strip RH', type: 'Trim', location: 'Front Panel', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 29, description: 'Indicator RH', type: 'Trim', location: 'Front Panel', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 30, description: 'Headlamp Panel LH', type: 'Body Panel', location: 'Front Panel', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 31, description: 'HeadLamp Assembly LH', type: 'Electrical', location: 'Front Panel', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 32, description: 'Headlamp Bracket LH', type: 'Trim', location: 'Front Panel', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 33, description: 'Headlamp Cover Glass LH', type: 'Trim', location: 'Front Panel', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 34, description: 'Headlamp Bulb Socket LH', type: 'Electrical', location: 'Front Panel', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 35, description: 'Headlamp Cover Strip LH', type: 'Trim', location: 'Front Panel', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 36, description: 'Indicator LH', type: 'Electrical', location: 'Front Panel', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 37, description: 'Airbag Sensor', type: 'Electrical', location: 'Front Panel', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 38, description: 'Other Sensor', type: 'Electrical', location: 'Front Panel', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 39, description: 'Fender RH', type: 'Body Panel', location: 'Front Side Panel RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 40, description: 'Fender RH Trim', type: 'Trim', location: 'Front Side Panel RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 41, description: 'Fender Splashshield RH', type: 'Trim', location: 'Front Side Panel RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 42, description: 'Fender Fixings', type: 'Trim', location: 'Front Side Panel RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 43, description: 'Fender RH Other Trim', type: 'Trim', location: 'Front Side Panel RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 44, description: 'Fender RH Indicator Lamp', type: 'Electrical', location: 'Front Side Panel RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: true, isFavoriteReplace: true, isFavouritePart: true },
    { id: 45, description: 'Wheelset RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 46, description: 'Front Brake Disc RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 47, description: 'Front Axle RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 48, description: 'Front Drive Shaft RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 49, description: 'Front Wishbone RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 50, description: 'Front Absorber RH', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 51, description: 'Front Wheel Cover', type: 'Trim', location: 'Front Side Panel RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 52, description: 'Tyre', type: 'Mechanical', location: 'Front Side Panel RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 53, description: 'Fender LH', type: 'Body Panel', location: 'Front Side Panel LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 54, description: 'Fender LH Trim', type: 'Trim', location: 'Front Side Panel LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 55, description: 'Fender Splashshield LH', type: 'Trim', location: 'Front Side Panel LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 56, description: 'Fender Fixings', type: 'Trim', location: 'Front Side Panel LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 57, description: 'Fender LH Other Trim', type: 'Trim', location: 'Front Side Panel LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 58, description: 'Fender LH Indicator Lamp', type: 'Electrical', location: 'Front Side Panel LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 59, description: 'Wheelset LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 60, description: 'Front Brake Disc LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 61, description: 'Front Axle LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 62, description: 'Front Drive Shaft LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 63, description: 'Front Wishbone LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 64, description: 'Front Absorber LH', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 65, description: 'Front Wheel Cover', type: 'Trim', location: 'Front Side Panel LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 66, description: 'Tyre', type: 'Mechanical', location: 'Front Side Panel LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 67, description: 'Wheelhouse RH', type: 'Body Panel', location: 'Engine Bay', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 68, description: 'Wheelhouse LH', type: 'Body Panel', location: 'Engine Bay', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 69, description: 'Battery', type: 'Electrical', location: 'Engine Bay', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 70, description: 'Battery Tray', type: 'Trim', location: 'Engine Bay', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 71, description: 'Wiring Harness', type: 'Electrical', location: 'Engine Bay', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 72, description: 'Radiator', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 73, description: 'Radiator Fan Housing', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 74, description: 'Radiator Fan Shroud', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 75, description: 'Engine Cover', type: 'Trim', location: 'Engine Bay', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 76, description: 'Radiator Spare Tank', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 77, description: 'Aircond Compressor', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 78, description: 'Alternator', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 79, description: 'Engine Manifold Cover', type: 'Mechanical', location: 'Engine Bay', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 80, description: 'Engine Manifold', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 81, description: 'Aircond Reciever/Drier', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 82, description: 'Gearbox Oil Cooling Unit', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 83, description: 'Engine Oil Sump', type: 'Mechanical', location: 'Engine Bay', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 84, description: 'Engine Oil Sump Cover', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 85, description: 'Engine Starter', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 86, description: 'Engine Subframe', type: 'Body Panel', location: 'Engine Bay', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 87, description: 'Master Brake Pump', type: 'Mechanical', location: 'Engine Bay', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 88, description: 'Washer Tank', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 89, description: 'Fuse Box', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 90, description: 'AirIntake Manifold', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 91, description: 'Other Sensors', type: 'Mechanical', location: 'Engine Bay', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 92, description: 'Turbo Unit', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 93, description: 'Turbo Intercooler', type: 'Mechanical', location: 'Engine Bay', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 94, description: 'Radiator Hoses', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 95, description: 'AirIntake Hose', type: 'Mechanical', location: 'Engine Bay', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 96, description: 'AirFilter Element', type: 'Mechanical', location: 'Engine Bay', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 97, description: 'Engine Control Unit (ECU)', type: 'Electrical', location: 'Engine Bay', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 98, description: 'Other Control Unit', type: 'Electrical', location: 'Engine Bay', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 99, description: 'Bonnet', type: 'Body Panel', location: 'Engine Bay', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 100, description: 'Bonnet Insulation', type: 'Trim', location: 'Engine Bay', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 101, description: 'Bonnet Hinge', type: 'Body Panel', location: 'Engine Bay', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 102, description: 'Bonnet Cable', type: 'Mechanical', location: 'Engine Bay', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 103, description: 'Bonnet Moulding', type: 'Trim', location: 'Engine Bay', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 104, description: 'Safety Control Unit', type: 'Electrical', location: 'Engine Bay', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false },
    { id: 105, description: 'Front Windscreen', type: 'Trim', location: 'Firewall', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 106, description: 'Front Windscreen Moulding', type: 'Trim', location: 'Firewall', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 107, description: 'Front Windscreen Cover Bottom', type: 'Trim', location: 'Firewall', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 108, description: 'Firewall', type: 'Body Panel', location: 'Firewall', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 109, description: 'Firewall Apron', type: 'Body Panel', location: 'Firewall', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 110, description: 'Firewall Crossmember', type: 'Body Panel', location: 'Firewall', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 111, description: 'Body Side Frame RH (Complete)', type: 'Body Panel', location: 'BodySide RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 112, description: 'Front Pillar RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 113, description: 'Front Pillar Inner Trim RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 114, description: 'Front Door RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 115, description: 'Front Door Moulding RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 116, description: 'Front Door Frame Cover RH', type: 'Trim', location: 'BodySide RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 117, description: 'Front Door Doorseal RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 118, description: 'Front Door Side Mirror Assembly RH', type: 'Trim', location: 'BodySide RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 119, description: 'Front Door Side Mirror Moulding RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 120, description: 'Front Door Side Mirror RH', type: 'Trim', location: 'BodySide RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 121, description: 'Front Door Glass RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 122, description: 'Front Door Weatherstrip RH', type: 'Trim', location: 'BodySide RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 123, description: 'Front Door Handle RH', type: 'Mechanical', location: 'BodySide RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 124, description: 'Front Door Window Regulator RH', type: 'Electrical', location: 'BodySide RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 125, description: 'Front Door Trimboard RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 126, description: 'Front Sill Panel RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 127, description: 'Front Sill Panel Trim RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 128, description: 'Front Sill Panel Side Step RH', type: 'Trim', location: 'BodySide RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 129, description: 'Central Pillar RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 130, description: 'Central Pillar Inner Trim Upper RH', type: 'Trim', location: 'BodySide RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 131, description: 'Central Pillar Inner Trim Lower RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 132, description: 'Rear Door RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 133, description: 'Rear Door Moulding RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 134, description: 'Rear Door Frame Cover RH', type: 'Trim', location: 'BodySide RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 135, description: 'Rear Door Doorseal RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 136, description: 'Rear Door Glass RH', type: 'Trim', location: 'BodySide RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 137, description: 'Rear Door Weatherstrip RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 138, description: 'Rear Door Handle RH', type: 'Mechanical', location: 'BodySide RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 139, description: 'Rear Door Window Regulator RH', type: 'Electrical', location: 'BodySide RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 140, description: 'Rear Door Trimboard RH', type: 'Trim', location: 'BodySide RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 141, description: 'Rear Sill Panel RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 142, description: 'Rear Sill Panel Trim RH', type: 'Trim', location: 'BodySide RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 143, description: 'Rear Sill Panel Side Step RH', type: 'Trim', location: 'BodySide RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 144, description: 'Rear Pillar RH', type: 'Body Panel', location: 'BodySide RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 145, description: 'Rear Pillar Inner Trim RH', type: 'Trim', location: 'BodySide RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 146, description: 'Body Side Frame LH (Complete)', type: 'Body Panel', location: 'BodySide LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 147, description: 'Front Pillar LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 148, description: 'Front Pillar Inner Trim LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 149, description: 'Front Door LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 150, description: 'Front Door Moulding LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 151, description: 'Front Door Frame Cover LH', type: 'Trim', location: 'BodySide LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 152, description: 'Front Door Doorseal LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 153, description: 'Front Door Side Mirror Assembly LH', type: 'Trim', location: 'BodySide LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 154, description: 'Front Door Side Mirror Moulding LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 155, description: 'Front Door Side Mirror LH', type: 'Trim', location: 'BodySide LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 156, description: 'Front Door Glass LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 157, description: 'Front Door Weatherstrip LH', type: 'Trim', location: 'BodySide LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 158, description: 'Front Door Handle LH', type: 'Mechanical', location: 'BodySide LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 159, description: 'Front Door Window Regulator LH', type: 'Electrical', location: 'BodySide LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 160, description: 'Front Door Trimboard LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 161, description: 'Front Sill Panel LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 162, description: 'Front Sill Panel Trim LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 163, description: 'Front Sill Panel Side Step LH', type: 'Trim', location: 'BodySide LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 164, description: 'Central Pillar LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 165, description: 'Central Pillar Inner Trim Upper LH', type: 'Trim', location: 'BodySide LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 166, description: 'Central Pillar Inner Trim Lower LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 167, description: 'Rear Door LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 168, description: 'Rear Door Moulding LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 169, description: 'Rear Door Frame Cover LH', type: 'Trim', location: 'BodySide LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 170, description: 'Rear Door Doorseal LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 171, description: 'Rear Door Glass LH', type: 'Trim', location: 'BodySide LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 172, description: 'Rear Door Weatherstrip LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 173, description: 'Rear Door Handle LH', type: 'Mechanical', location: 'BodySide LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 174, description: 'Rear Door Window Regulator LH', type: 'Electrical', location: 'BodySide LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 175, description: 'Rear Door Trimboard LH', type: 'Trim', location: 'BodySide LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 176, description: 'Rear Sill Panel LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 177, description: 'Rear Sill Panel Trim LH', type: 'Trim', location: 'BodySide LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 178, description: 'Rear Sill Panel Side Step LH', type: 'Trim', location: 'BodySide LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 179, description: 'Rear Pillar LH', type: 'Body Panel', location: 'BodySide LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 180, description: 'Rear Pillar Inner Trim LH', type: 'Trim', location: 'BodySide LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 181, description: 'Roof Panel', type: 'Body Panel', location: 'Roof', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 182, description: 'Roof Headlining', type: 'Trim', location: 'Roof', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 183, description: 'Roof Moulding', type: 'Trim', location: 'Roof', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 184, description: 'Roof Run channel RH', type: 'Trim', location: 'Roof', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 185, description: 'Roof Run channel LH', type: 'Trim', location: 'Roof', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 186, description: 'Front Seat RH', type: 'Trim', location: 'Passenger Compartment', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 187, description: 'Front Seat Armrest RH', type: 'Trim', location: 'Passenger Compartment', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 188, description: 'Front Seat LH', type: 'Trim', location: 'Passenger Compartment', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 189, description: 'Front Seat Armrest LH', type: 'Trim', location: 'Passenger Compartment', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 190, description: 'Dashboard', type: 'Trim', location: 'Passenger Compartment', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 191, description: 'Instrument Panel', type: 'Electrical', location: 'Passenger Compartment', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 192, description: 'Steering Wheel', type: 'Mechanical', location: 'Passenger Compartment', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 193, description: 'Steering Column', type: 'Mechanical', location: 'Passenger Compartment', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 194, description: 'Airbag Front RH', type: 'Electrical', location: 'Passenger Compartment', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 195, description: 'Side Airbag Front RH', type: 'Electrical', location: 'Passenger Compartment', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 196, description: 'Side Airbag Rear RH', type: 'Electrical', location: 'Passenger Compartment', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 197, description: 'Glove Compartment', type: 'Trim', location: 'Passenger Compartment', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 198, description: 'Gear Shift', type: 'Mechanical', location: 'Passenger Compartment', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 199, description: 'Centre Console', type: 'Trim', location: 'Passenger Compartment', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 200, description: 'Centre Console Trim Panel RH', type: 'Trim', location: 'Passenger Compartment', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 201, description: 'Centre Console Trim Panel LH', type: 'Trim', location: 'Passenger Compartment', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 202, description: 'Centre Console Armrest', type: 'Trim', location: 'Passenger Compartment', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 203, description: 'Centre Console AirCond Vent', type: 'Trim', location: 'Passenger Compartment', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 204, description: 'Rear Seat Panel', type: 'Body Panel', location: 'Passenger Compartment', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 205, description: 'Rear Seat Panel Cover RH', type: 'Trim', location: 'Passenger Compartment', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 206, description: 'Rear Seat Panel Cover LH', type: 'Trim', location: 'Passenger Compartment', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 207, description: 'Floor Trim Cover Front', type: 'Trim', location: 'Passenger Compartment', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 208, description: 'Floor Trim Cover Rear', type: 'Trim', location: 'Passenger Compartment', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 209, description: 'Rear Quarterpanel RH', type: 'Body Panel', location: 'Rear Side Panel RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 210, description: 'Rear Quaterpanel Moulding RH', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 211, description: 'Rear Quaterglass RH', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 212, description: 'Rear Quaterglass Rubberseal RH', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 213, description: 'Rear Pillar Innter Trim RH', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 214, description: 'Rear Wheelset RH', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 215, description: 'Rear Brake Disc RH', type: 'Mechanical', location: 'Rear Side Panel RH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 216, description: 'Rear Axle RH', type: 'Mechanical', location: 'Rear Side Panel RH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 217, description: 'Rear Drive Shaft RH', type: 'Mechanical', location: 'Rear Side Panel RH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 218, description: 'Rear Wishbone RH', type: 'Mechanical', location: 'Rear Side Panel RH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 219, description: 'Rear Absorber RH', type: 'Mechanical', location: 'Rear Side Panel RH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 220, description: 'Front Wheel Cover', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 221, description: 'Tyre', type: 'Trim', location: 'Rear Side Panel RH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 222, description: 'Rear Quarterpanel LH', type: 'Body Panel', location: 'Rear Side Panel LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 223, description: 'Rear Quaterpanel Moulding LH', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 224, description: 'Rear Quaterglass LH', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 225, description: 'Rear Quaterglass Rubberseal LH', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 226, description: 'Rear Pillar Innter Trim LH', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 227, description: 'Rear Wheelset LH', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 228, description: 'Rear Brake Disc LH', type: 'Mechanical', location: 'Rear Side Panel LH', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 229, description: 'Rear Axle LH', type: 'Mechanical', location: 'Rear Side Panel LH', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 230, description: 'Rear Drive Shaft LH', type: 'Mechanical', location: 'Rear Side Panel LH', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 231, description: 'Rear Wishbone LH', type: 'Mechanical', location: 'Rear Side Panel LH', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 232, description: 'Rear Absorber LH', type: 'Mechanical', location: 'Rear Side Panel LH', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 233, description: 'Front Wheel Cover', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 234, description: 'Tyre', type: 'Trim', location: 'Rear Side Panel LH', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 235, description: 'Rear Parcel Shelf', type: 'Body Panel', location: 'Rear Parcel Shelf', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 236, description: 'Rear Parcel Shelf Trimboard', type: 'Body Panel', location: 'Rear Parcel Shelf', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 237, description: 'Rear Windscreen', type: 'Trim', location: 'Rear Parcel Shelf', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 238, description: 'Rear Windscreen Moulding', type: 'Trim', location: 'Rear Parcel Shelf', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 239, description: 'Boot Floor Panel', type: 'Body Panel', location: 'Rear Boot', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 240, description: 'Boot Floor Cross member', type: 'Body Panel', location: 'Rear Boot', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 241, description: 'Rear Panel', type: 'Body Panel', location: 'Rear Boot', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 242, description: 'Boot Floor Side Panel RH', type: 'Body Panel', location: 'Rear Boot', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 243, description: 'Boot Floor Side Panel LH', type: 'Body Panel', location: 'Rear Boot', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 244, description: 'Rear Wheelhosue RH', type: 'Body Panel', location: 'Rear Boot', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 245, description: 'Rear Wheelhouse LH', type: 'Body Panel', location: 'Rear Boot', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 246, description: 'Rear Lamp Assembly RH', type: 'Electrical', location: 'Rear Boot', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 247, description: 'Rear Lamp Assembly LH', type: 'Electrical', location: 'Rear Boot', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 248, description: 'Rear Lamp on Trunk RH', type: 'Electrical', location: 'Rear Boot', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 249, description: 'Rear Lamp on Trunk LH', type: 'Electrical', location: 'Rear Boot', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 250, description: 'Rear Lamp on Quaterpanel RH', type: 'Electrical', location: 'Rear Boot', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 251, description: 'Rear Lamp on Quaterpanel LH', type: 'Electrical', location: 'Rear Boot', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 252, description: 'BootLid', type: 'Body Panel', location: 'Rear Boot', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 253, description: 'Bootlid Moulding', type: 'Trim', location: 'Rear Boot', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 254, description: 'Bootlid Rubberseal', type: 'Trim', location: 'Rear Boot', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 255, description: 'Number Plate', type: 'Trim', location: 'Rear Boot', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 256, description: 'Number Plate Light', type: 'Trim', location: 'Rear Boot', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 257, description: 'Rear Bumper Cover', type: 'Trim', location: 'Rear Bumper', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 258, description: 'Rear Bumper Bracket', type: 'Trim', location: 'Rear Bumper', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 259, description: 'Rear Bumper Reinforcement', type: 'Body Panel', location: 'Rear Bumper', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 260, description: 'Rear Bumper Indicator RH', type: 'Electrical', location: 'Rear Bumper', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 261, description: 'Rear Bumper Indicator LH', type: 'Electrical', location: 'Rear Bumper', RRHour: 1.67, RepHour: 2.78, PaintHour: 3.89, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 262, description: 'Rear Bumper Lower Trim RH', type: 'Trim', location: 'Rear Bumper', RRHour: 0.12, RepHour: 1.23, PaintHour: 2.34, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 263, description: 'Rear Bumper Lower Trim LH', type: 'Trim', location: 'Rear Bumper', RRHour: 3.45, RepHour: 0.56, PaintHour: 1.67, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 264, description: 'Rear Bumper Lower Lip', type: 'Trim', location: 'Rear Bumper', RRHour: 2.78, RepHour: 3.89, PaintHour: 0.12, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 265, description: 'Rear Bumper Fixings', type: 'Trim', location: 'Rear Bumper', RRHour: 1.23, RepHour: 2.34, PaintHour: 3.45, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 266, description: 'Rear Bumper Other Trims', type: 'Trim', location: 'Rear Bumper', RRHour: 0.56, RepHour: 1.67, PaintHour: 2.78, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 267, description: 'Radar Sensors', type: 'Electrical', location: 'Rear Bumper', RRHour: 3.89, RepHour: 0.12, PaintHour: 1.23, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true },
    { id: 268, description: 'Other Sensors', type: 'Electrical', location: 'Rear Bumper', RRHour: 2.34, RepHour: 3.45, PaintHour: 0.56, isFavoriteRepair: false, isFavoriteReplace: false, isFavouritePart: true }
]