<template>
  <div>
    <div v-for="i in structuredData"
      :key="i.name">
      <div class="fixed-grid has-12-cols quote-builder-section-item p-2 template-list-item is-clickable mb-1 "
        @click="handleOpen(i.name)">
        <div class="grid">
          <!-- Description -->
          <div class="cell is-col-span-8">{{ i.name }}</div>
          <!-- R&R, Repair and Paint values -->
          <div class="cell is-col-span-3">
            <div v-if="isOpen(i.name)"
              class="is-flex is-justify-content-end">
              Hour(s)
            </div>
          </div>
          <!-- Dropdown Arrow -->
          <div class="cell is-col-span-1 is-flex is-justify-content-end">
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-if="!isOpen(i.name)">
              chevron_right
            </span>
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-else>
              keyboard_arrow_down
            </span>
          </div>
        </div>
      </div>

      <div v-if="isOpen(i.name)">
        <div v-for="(item, index) in i.items"
          :key="index"
          class="fixed-grid has-12-cols p-1 vehicle-section-item mb-1 b ">
          <div class="grid ">
            <!-- item name -->
            <div class="cell is-col-span-1">
              <span>{{ item.id }}</span>
            </div>

            <div class="cell is-col-span-7">{{ item.description }}</div>

            <!-- R&R, Repair and Paint values -->
            <div class="cell is-col-span-3">

              <div class="butterfly-item-values is-flex is-justify-content-center"
                @click="addSingleNewItem(item, itemCategoryTypes.MECH, true)"
                :class="{ ' has-background-primary is-selected': findItem(item, itemCategoryTypes.MECH)}">
                {{ item.RRHour }}
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import { QuoteItemModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'QuoteBuilderOtherComponent',
  props: {
    avaliableItemsType: {
      type: String,
      required: true
    },
    structuredData: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    quotingMethod: {
      type: String,
      default: ''
    },
    mechRate: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      expandedItems: [],
      addedItems: []
    }
  },
  computed: {
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    QuotingMethodTypes() {
      return QuotingMethodTypes
    }
  },
  methods: {
    isOpen(name) {
      return this.expandedItems.includes(name)
    },
    handleOpen(name) {
      if (this.expandedItems.includes(name)) {
        this.expandedItems = this.expandedItems.filter((item) => item !== name)
      } else {
        this.expandedItems.push(name)
      }
    },
    findItem(item, type) {
      // const existingItem = this.value.find(
      //   (i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && i.itemType === type && !i.deleteOtherItem
      // )

      const existingItem = this.value.find((i) => i.itemNo === item.id && !i.deleted && i.itemType === type)

      return existingItem
    },
    addNewItems(item, index) {
      this.addSingleNewItem(item, ItemCategoryTypes.MECH)
      if (!this.addedItems.length) {
        this.$emit('item-added', this.addedItems)
        this.addedItems = []
      }
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.quoteId, itemNo, description, type)
      newItem.lineNumber = Math.max(this.maxAddedItemsLineNumber + 1, this.nextLineNumber)
      newItem.hourValue = this.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehiclePaintGroup
      newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    addSingleNewItem(item, type, emit = false) {
      let hour = 0
      let rate = (hour = item.RRHour)
      // rate = this.isNtar ? this.shopRate : this.mechRate
      // todo change this back and get it working properly
      rate = this.mechRate || 40

      const existingItem = this.findItem(item, type)

      if (!existingItem) {
        const newItem = this.generateItem(item.id, item.description, hour, rate, type)
        this.addedItems.splice(this.addedItems.length, 1, newItem)
        if (emit) {
          this.$emit('item-added', this.addedItems)
          this.addedItems = []
        }
      } else {
        this.$eventHub.$emit('deleteOtherLabour', existingItem)
      }
    }
  }
}
</script>

<style scoped>
.quote-builder-labour-component {
  /* Add your component styles here */
}
</style>