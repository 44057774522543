<!-- eslint-disable vue/html-indent -->
<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content" /> -->
      <div v-if="!hasActiveSubscription && !isLoadingActiveSubscription"
        class="message is-danger">
        <div class="message-body">
          There is no active subscription or balance is too low. Please contact FlexiQuote support.
        </div>
      </div>
      <article v-else-if="activeSubscription"
        class="message is-info">
        <!-- <div class="message-header">
          <p>Subscription</p>
        </div> -->
        <div class="message-body">
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">Subscription Date</label>
                <span class="has-text-weight-bold has-text-primary">{{ $filters.formatDateLocale(activeSubscription.purchaseDate, $userInfo.locale) }}</span>
              </div>
              <div class="field">
                <label class="label">Balance</label>
                <span class="has-text-weight-bold has-text-success">{{ activeSubscription.balance | formatCurrency($userInfo.locale) }}</span>
              </div>
              <div class="field">
                <label class="label">Usage</label>
                <span class="has-text-weight-bold has-text-success">{{ activeSubscription.usage | formatCurrency($userInfo.locale) }}</span>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column is-three-fifths">
            <div class="field">
              <label class="label">Select search tier</label>
              <div class="control">
                <input class="is-checkradio"
                  id="tier1"
                  type="radio"
                  name="tier1"
                  :value="1"
                  v-model="selectedTier">
                <label for="tier1">Tier 1</label>
                <input class="is-checkradio"
                  id="tier2"
                  type="radio"
                  name="tier2"
                  :value="2"
                  v-model="selectedTier">
                <label for="tier2">Tier 2</label>
                <input class="is-checkradio"
                  id="tier3"
                  type="radio"
                  name="tier3"
                  :value="3"
                  v-model="selectedTier">
                <label for="tier3">Tier 3</label>
              </div>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <div class="label">Rego</div>
                  <div class="control">
                    <input ref="rego"
                      class="input is-uppercase"
                      :class="{ 'is-danger' : $v.rego.$error }"
                      v-model="rego"
                      @input="$v.rego.$touch()"
                      v-focus-inserted>
                    <span class="help is-danger"
                      v-if="$v.rego.$error">Rego is required.
                    </span>
                  </div>
                </div>
                <div class="field">
                  <div class="label">State</div>
                  <div class="control">
                    <div class="select is-fullwidth"
                      :class="{ 'is-danger' : $v.selectedState.$error, 'is-loading': !companyDefaultAddress }">
                      <select v-model="selectedState">
                        <option v-for="s in states"
                          :key="s.code"
                          :value="s.code">{{ s.code }}</option>
                      </select>
                    </div>
                    <span class="help is-danger"
                      v-if="$v.selectedState.$error">State is required.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control is-pulled-right">
                <button class="button is-primary"
                  @click="search()"
                  :disabled="!hasActiveSubscription || notEnoughBalanceToSearchTier">
                  <span class="icon">
                    <i class="mdi mdi-magnify mdi-24px" />
                  </span>
                  <span>Search</span>
                </button>
              </div>
            </div>
          </div>
          <div class="is-divider-vertical"
            data-content="" />
          <div class="column">
            <p v-if="selectedPricing"
              class="has-text-primary has-text-weight-semibold is-size-5">Tier {{ selectedTier }} - {{ selectedPricing.price | formatCurrency($userInfo.locale) }} per request</p>
            <p v-if="noResponsePricing"
              class="help has-text-warning">Failed request will be charged {{ noResponsePricing.price | formatCurrency($userInfo.locale) }} per request</p>
            <p v-if="selectedTier === 1"
              class="content">
            <ul>
              <li>VIN: XXXXXXXXXXXXXXXXX</li>
              <li>Make: Holden</li>
              <li>Model: Commodore</li>
              <li>Colour: WHITE</li>
              <li>Vehicle Type: CAR / SMALL PASSENGER VEHICLE</li>
              <li>Body Type: CAR/STATION WAGON</li>
            </ul>
            </p>
            <p v-else-if="selectedTier === 2"
              class="content">
            <ul>
              <li>Vin: XXXXXXXXXXXXXXXXX</li>
              <li>Make: Holden</li>
              <li>Model: Commodore</li>
              <li>Series: VZ</li>
              <li>Colour: WHITE</li>
              <li>Build Year: 2006</li>
              <li>Body: Wagon</li>
            </ul>
            </p>
            <div v-else-if="selectedTier === 3"
              class="content">
              <ul>
                <li>Vin: XXXXXXXXXXXXXXXXX</li>
                <li>Make: Holden</li>
                <li>Model: Commodore</li>
                <li>Series: VZ</li>
                <li>Variant: SV6</li>
                <li>Colour: WHITE</li>
                <li>Build Year: 2006</li>
                <li>Body: Wagon</li>
              </ul>
            </div>
          </div>
        </div>
      </article>
      <div v-show="vehicles"
        class="is-divider"
        data-content="RESULTS" />
      <vehicle-lookup-result :value="vehicles"
        @selected="selected" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="select()"
        data-tooltip="Save &amp; continue"
        :disabled="disableSelect">Select</button>
      <button class="button tooltip is-tooltip-topright"
        @click="close()"
        data-tooltip="Don't continue">Close</button>
    </footer>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from '../BulmaModal/BaseModalEx'
import VehicleLookupService from './VehicleLookupService'
import _orderBy from 'lodash/orderBy'
import { FocusInserted } from '@/components/directives'
import { required } from 'vuelidate/lib/validators'
import { CallLogModel } from '@/classes/viewmodels'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import VehicleLookupResult from './VehicleLookupResult'

export default {
  name: 'VehicleLookupModal',
  components: {
    BaseModalEx,
    VehicleLookupResult
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: {
      type: String,
      default: ''
    },
    stateRego: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-car-info'
    },
    skipDisabled: {
      type: Boolean,
      default: false
    },
    onSaveContinue: {
      type: Function,
      default: () => {}
    },
    onSkipSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isActive: false,
      selectedTier: 2,
      rego: '',
      selectedState: '',
      states: null,
      companyDefaultAddress: null,
      activeSubscription: null,
      isLoadingActiveSubscription: false,
      pricing: null,
      vehicles: null,
      selectedVehicle: null,
      // if the search has been performed atleast once
      isSearchPerformed: false
    }
  },
  computed: {
    hasActiveSubscription() {
      return this.activeSubscription !== null
    },
    selectedPricing() {
      if (this.pricing) {
        return this.pricing.find((p) => p.tier === this.selectedTier)
      }
    },
    notEnoughBalanceToSearchTier() {
      return this.pricing && this.activeSubscription.balance < this.selectedPricing.price
    },
    noResponsePricing() {
      if (this.pricing) {
        return this.pricing.find((p) => p.tier === 4)
      }
    },
    disableSelect() {
      return !this.selectedVehicle || this.vehicles.length < 1 || this.selectedVehicle.blueflag_make === null || this.selectedVehicle.blueflag_model === null
    }
  },
  validations: {
    rego: {
      required
    },
    selectedState: {
      required
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    notEnoughBalanceToSearchTier(value) {
      if (value) {
        this.$notification.error('Error', 'No enough balance to search tier')
      }
    }
  },
  created() {
    this.rego = this.value
    if (this.stateRego !== null)
    {
      this.selectedState = this.stateRego
    }
    this.getActiveSubscription()
    this.getCompanyDefaultAddress()
    this.getStates()
    this.getPricing()
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    async select() {
      this.isActive = false
      const model = await VehicleLookupService.saveVehicle(this.selectedVehicle)
      // console.log(model)
      this.$emit('select', model, this.selectedVehicle)
      this.$emit('input', this.rego)
      this.$emit('update:active', false)
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    async getStates() {
      const results = await VehicleLookupService.getStateListComboProto()
      this.states = _orderBy(results, 'areaCode', 'desc')
    },
    async search() {
      this.$showSpinner('Searching...')
      await this.getActiveSubscription()
      const tier = this.pricing.find((p) => p.tier === this.selectedTier)
      if (tier.price > this.activeSubscription.balance) {
        this.$notification.openNotificationWithType('danger','','There is not enough balance to perform the request. Please contact FlexiQuote support.',5000)
        // this.$toast.open({
        //   message: 'There is not enough balance to perform the request. Please contact FlexiQuote support.',
        //   type: 'is-danger',
        //   duration: 5000
        // })
      }
      this.$v.rego.$touch()
      this.$v.selectedState.$touch()
      if (!this.$v.rego.$error && !this.$v.selectedState.$error) {
        // Search rego
        // console.log('search')
        // this.vehicles = null
        const calllog = new CallLogModel(this.$userInfo.companyId, this.$userInfo.userId, '', this.selectedTier, this.rego, this.selectedState)
        try {
          const result = await VehicleLookupService.searchVehicle(calllog)
          // console.log(result.data)
          this.vehicles = result.data

          // if (!!result.data.length) {
          //   this.isActive = false
          //   const model = await VehicleLookupService.saveVehicle(result.data[0])
          //   this.$emit('select', model, this.selectedVehicle)
          //   this.$emit('input', this.rego)
          //   this.$emit('update:active', false)
          //   this.$toast.open({
          //     message: 'Vehicle details successfully added to the Quote.',
          //     type: 'is-success',
          //     duration: 5000
          //   })
          // }
        } catch (e) {
          this.close()
          throw e
        }
        this.getActiveSubscription()
      }
      this.$hideSpinner()
      // this.isSearchPerformed = true
    },
    async getCompanyDefaultAddress() {
      this.companyDefaultAddress = await VehicleLookupService.getCompanyDefaultAddress()
      if (this.stateRego === null)
      {
        this.selectedState = this.companyDefaultAddress.stateCode
      }
    },
    async getActiveSubscription() {
      this.isLoadingActiveSubscription = true
      this.activeSubscription = await VehicleLookupService.getActiveSubscription()
      this.isLoadingActiveSubscription = false
    },
    async getPricing(reset) {
      this.pricing = await VehicleLookupService.getPricing(0)
    },
    selected(vehicle) {
      this.selectedVehicle = vehicle
      // console.log('selectedVehicle', this.selectedVehicle)
      this.selectedVehicle.stateRego = this.selectedState
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>

<!-- <header class="modal-card-head">
  <p class="modal-card-title"
    :class="{ 'is-flex-items-center' : showIcon}">
    <span v-if="showIcon"
      class="icon is-medium"
      :class="`has-text-${iconType}`"
      style="margin-right: 0.5rem">
      <i class="mdi mdi-36px"
        :class="`${iconName}`" />
    </span>
    <slot name="text-title" />
  </p>
  <span v-if="hasActiveSubscription">
    Remaining Balance: ${{ activeSubscription.balance }}
  </span>
</header>
<section class="modal-card-body">
   <slot name="text-content" />
  <div v-if="!hasActiveSubscription && !isLoadingActiveSubscription"
    class="message is-danger">
    <div class="message-body">
      There is no active subscription or balance is too low. Please contact FlexiQuote support.
    </div>
  </div>

  <article class="tile is-child box detail-page-tile">
    <div class="field-body">
      <div class="field">
        <div class="label">Rego</div>
        <div class="control">
          <input ref="rego"
            class="input is-uppercase"
            :class="{ 'is-danger' : $v.rego.$error }"
            v-model="rego"
            @input="$v.rego.$touch()"
            v-focus-inserted>
          <span class="help is-danger"
            v-if="$v.rego.$error">Rego is required.
          </span>
        </div>
      </div>
      <div class="field">
        <div class="label">State</div>
        <div class="control">
          <div class="select is-fullwidth"
            :class="{ 'is-danger' : $v.selectedState.$error, 'is-loading': !companyDefaultAddress }">
            <select v-model="selectedState">
              <option v-for="s in states"
                :key="s.code"
                :value="s.code">{{ s.code }}</option>
            </select>
          </div>
          <span class="help is-danger"
            v-if="$v.selectedState.$error">State is required.
          </span>
        </div>
      </div>

      <div class="field">
        <button class="button is-primary mt-5"
          @click="search()"
          :disabled="!hasActiveSubscription">
          <span class="icon">
            <i class="mdi mdi-magnify mdi-24px" />
          </span>
          <span>Search</span>
        </button>
      </div>

    </div>
    <div class="field" />
  </article>

  <div>
    <div v-if="!isSearchPerformed"
      class="message is-info">
      <div class="message-body">
        Perform a search to find a vehicle and automatically add it to the Quote.
      </div>
    </div>
  </div>

  <div v-if="isSearchPerformed && !vehicles?.length"
    class="message is-danger">
    <div class="message-body">
      Nothing found. Change the search criteria and try again.
    </div>
  </div>

   <div v-show="vehicles"
    class="is-divider"
    data-content="RESULTS" />
  <vehicle-lookup-result :value="vehicles"
    @selected="selected" />

</section>

<footer class="modal-card-foot"
  :style="`justify-content: flex-${buttonLocation}`">
   <button class="button is-success tooltip"
    @click="select()"
    data-tooltip="Save &amp; continue"
    :disabled="disableSelect">Select</button>
  <button class="button tooltip is-tooltip-topright"
    @click="close()"
    data-tooltip="Don't continue">Close</button>
</footer> -->