<template>
  <div class="full-calendar">
    <!-- <div class="full-calendar-sidebar is-hidden">
      <div class="full-calendar-sidebar-section">
        <label>
          <input type="checkbox"
            :checked="innerCalendarOptions.weekends"
            @change="handleWeekendsToggle">
          toggle weekends
        </label>
      </div>
      <div class="full-calendar-sidebar-section is-size-7">
        <h2>All Events ({{ currentEvents.length }})</h2>
        <ul>
          <li v-for="event in currentEvents"
            :key="event.id">
            <div class="box p-2 m-2 has-background-success-light">
              <i class="mdi mdi-alarm" />{{ `${removeSeconds(removeTimezone(event.startStr)).replace('T', ' ')}` }}
              <br>
              <i class="mdi mdi-car" />{{ event.title }}
            </div>
          </li>
        </ul>
      </div>
      <div id="myDraggable"
        class="full-calendar-sidebar-section">
        <h2>Draggable outside element</h2>
        <ul>
          <li v-for="event in draggableEvents"
            :key="event.id">
            <i>{{ event.title }}</i>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="full-calendar-main">
      <FullCalendar ref="fullCalendar"
        class="full-calendar-calendar"
        :options="innerCalendarOptions">
        <!-- <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template> -->
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import { DateTime } from 'luxon'
import BookingService from '../../views/booking/BookingService'
// import { truncate } from '../../utils/StringFunctions'
import store from '@/store'
import Vue from 'vue'
import CalendarTooltip from './CalendarTooltip.vue'
import tippy from 'tippy.js'

const company = store.getters['company/company']

// https://github.com/fullcalendar/fullcalendar-vue2

export default {
  name: 'Calendar',
  components: {
    FullCalendar
  },
  props: {
    calendarOptions: {
      type: Object,
      default: () => ({})
    },
    businessHours: {
      type: Array,
      default: function () {
        return [
          // {
          //   daysOfWeek: [1, 2, 3, 4, 5],
          //   startTime: '08:00', // 08:00AM Aus time
          //   endTime: '17:00' // 05:00PM Aus time
          // }
        ]
      }
    },
    events: {
      type: Array,
      default: () => []
    },
    isShowWeekend: {
      type: Boolean,
      required: true,
      default: true
    },
    overrideBusinessHours: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      draggable: null,
      innerCalendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          start: 'timeGridDay,timeGridWeek,dayGridMonth', // will normally be on the left. if RTL, will be on the right
          center: 'prev,title,next',
          end: 'customSwitch' // will normally be on the right. if RTL, will be on the left
        },
        customButtons: {
          customSwitch: {
            text: '',
            click: null
          }
        },
        initialView: 'dayGridMonth',
        // initialEvents: INITIAL_EVENTS,
        timeZone: 'UTC',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: this.isShowWeekend,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        drop: this.handleDrop, //event is triggered when an external draggable element
        droppable: true,
        events: this.events,
        businessHours: this.businessHours,
        // selectConstraint: 'businessHours',
        //eventConstraint: 'businessHours',
        // eventAllow: this.eventAllow,
        datesSet: this.handleDatesSet,
        eventContent: this.renderEventContent,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          meridiem: true
        },
        dayHeaderContent: (args) => {
          const { view } = args
          if (view && view.type == 'timeGridWeek') {
            return args.date.toLocaleDateString('en-GB', {
              weekday: 'short', // "WED"
              day: 'numeric', // "2"
              month: 'short' // "Oct"
            })
          }
          return args.text
        },
        eventReceive: this.handleEventReceive,
        defaultTimedEventDuration: '00:30:00',
        eventResize: this.handleEventResize,
        eventDrop: this.handleEventDrop, //event is triggered when an existing calendar event is dragged and dropped
        views: {
          dayGrid: {
            dayHeaderFormat: { weekday: 'long' } // Display weekday, month, and day
          }
        },
        // dayCellDidMount: this.handleDayCellDidMount,
        // slotLabelDidMount: this.handleSlotLabelDidMount
        allDaySlot: false,
        // slotLaneContent: this.customSlotLabelContent,
        //slotLaneClassNames: this.handleSlotLaneClassNames
        // viewDidMount: this.onViewDidMount,
        eventDidMount: this.handleEventDidMount,
        eventDragStart: this.handleEventDragStart,
        eventDragStop: this.handleEventDragStop
      },
      currentEvents: [],
      draggableEvents: [],
      // isSwitchOn: false
      currentDate: null,
      // tippyInstances: new Map(),
      // previousViewType: null,
      isDragging: false
    }
  },
  computed: {
    timezone() {
      return company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    eventDuration() {
      return this.innerCalendarOptions.defaultTimedEventDuration
    },
    offset() {
      return DateTime.utc().setZone(this.timezone).toFormat('ZZ')
    }
    // computedCurrentDate() {
    //   if (this.currentDate) {
    //     let luxonDate = DateTime.fromJSDate(this.currentDate).setZone(this.timezone)
    //     return luxonDate
    //   }
    //   return null
    // }
  },
  watch: {
    events: function (val, oldVal) {
      if (typeof val !== 'undefined' && val) {
        this.innerCalendarOptions.events = val
      }
    },
    isShowWeekend: function (val, oldVal) {
      this.innerCalendarOptions.weekends = val
    },
    businessHours: function (val, oldVal) {
      this.innerCalendarOptions.businessHours = val
    }
  },
  created() {
    console.log('calendar created')
    this.setCalendarOptions()
  },
  mounted() {
    console.log('calendar mounted')
    this.setCalendarOptions()
    this.initDraggable()

    this.$nextTick(() => {
      this.injectSwitchToggleWeekend()
    })
  },
  beforeDestroy() {
    this.destroyDraggable()
  },
  methods: {
    getFullCalendar() {
      return this.$refs.fullCalendar
    },
    handleEventDragStart(info) {
      this.isDragging = true
    },
    handleEventDragStop(info) {
      this.isDragging = false
    },
    injectSwitchToggleWeekend() {
      // Replace button text with a checkbox switch
      const button = document.querySelector('.fc-customSwitch-button')
      if (button) {
        button.innerHTML = `
        <div class="pretty p-switch p-fill">
          <input type="checkbox" id="customSwitchCheckbox"
            checked="${this.isShowWeekend}">
          <div class="state p-primary">
            <label class="pr-3">Show Weekends</label>
          </div>
        </div>`
        const checkbox = document.getElementById('customSwitchCheckbox')
        checkbox.addEventListener('change', this.handleToggleWeekend)
      }
    },
    setCalendarOptions() {
      this.innerCalendarOptions = { ...this.innerCalendarOptions, ...this.calendarOptions }
      // this.innerCalendarOptions.timeZone = this.timezone
      this.innerCalendarOptions.timeZone = 'UTC'
    },
    initDraggable() {
      let myDraggable = document.getElementById('myDraggable')
      this.draggable = new Draggable(myDraggable, {
        itemSelector: '.draggable-item'
        // eventData(eventEl) {
        //   var key = eventEl.getAttribute('data-custom')
        //   return JSON.parse(key)
        // }
      })
    },
    destroyDraggable() {
      if (this.draggable) {
        this.draggable.destroy()
        this.draggable = null
      }
    },
    handleEventDidMount(info) {
      console.log('handleEventDidMount')
      if (!info.event.id) return
      // const viewType = info.view.type
      // if (viewType != this.previousViewType) {
      //   debugger
      //   this.previousViewType = viewType
      //   this.clearTippyInstances()
      // }
      this.injectEventTooltip(info)
    },
    injectEventTooltip(eventInfo) {
      // const { id } = eventInfo.event
      const { vehicleRegistrationNo, claimNo, vehicleMake, vehicleModel } = eventInfo.event.extendedProps || {}

      if (this.isDragging)
        return

      // if (this.tippyInstances.has(id)) {
      //   debugger
      //   console.log('tippy instance exist')
      //   return
      // }

      const tooltipContent = new Vue({
        render: (h) =>
          h(CalendarTooltip, {
            props: {
              title: eventInfo.event.title,
              description: `
              ${vehicleRegistrationNo || ''}<br/>
              ${claimNo || ''}<br/>
              ${vehicleMake || ''}${vehicleModel ? ' ' + vehicleModel : ''}
            `
            }
          })
      }).$mount()

      tippy(eventInfo.el, {
        content: tooltipContent.$el,
        allowHTML: true,
        placement: 'top',
        theme: 'light-border',
        delay: [700, 0],
        interactive: false,
        //arrow: true,
        // onHidden(instance) {
        //   tooltipContent.$destroy()
        // }
        onCreate(instance) {
          instance.popper.classList.add('calendar-custom-tippy')
        }
      })

      // this.tippyInstances.set(id, instance)
    },
    // customSlotLabelContent(arg) {
    //   const { date, view } = arg
    //   if (view.type == 'timeGridWeek')
    //     return
    //   if (!this.computedCurrentDate) return
    //   let slotDateTime = DateTime.fromJSDate(date).toUTC()
    //   const isoDate = this.computedCurrentDate.toISO()
    //   const clonedDate = DateTime.fromISO(isoDate)
    //   // const timezoneDate = clonedDate.setZone(this.timezone)
    //   const newDateTime = clonedDate.set({
    //     hour: slotDateTime.hour,
    //     minute: slotDateTime.minute,
    //     second: slotDateTime.second
    //   })

    //   if (this.isNonBusinessDate(newDateTime)) {
    //     const customLabel = document.createElement('div')
    //     customLabel.className = 'has-text-centered'
    //     customLabel.innerHTML = '<span class="has-text-grey-light is-size-7">N/A</span>'
    //     return { domNodes: [customLabel] }
    //   }
    //   return null
    // },
    isNonBusinessDate(luxonDate) {
      for (const obj of this.overrideBusinessHours) {
        const { date, isBusinessDay } = obj
        const _luxonDate = DateTime.fromISO(date).toUTC()
        const isSameDay = luxonDate.hasSame(_luxonDate, 'day')
        if (isSameDay && !isBusinessDay) {
          return true
        }
      }
      return false
    },
    onViewDidMount(info) {
      const currentDate = info.view.currentStart // Get the start date of the current view (timeGridDay)
      this.currentDate = currentDate
    },
    // handleSlotLaneClassNames(slotInfo) {
    //   const slotHours = slotInfo.date.getHours()
    //   const slotMinutes = slotInfo.date.getMinutes()
    //   const slotTime = this.convertToTime(`${slotHours}:${slotMinutes}`)
    //   if (this.overrideBusinessHours && this.overrideBusinessHours.length) {
    //     this.overrideBusinessHours.map(({ startTime, endTime, isBusinessDay }) => {
    //       if (isBusinessDay && startTime && endTime) {
    //         if (slotTime >= this.convertToTime(startTime) && slotTime < this.convertToTime(endTime)) {
    //           return 'helllo world'
    //         }
    //       }
    //     })
    //   }
    //   return 'custom-non-business'
    // },
    // convertToTime(timeString) {
    //   const [hours, minutes] = timeString.split(':').map(Number)
    //   const date = new Date()
    //   date.setHours(hours, minutes, 0, 0) // Set hours, minutes, seconds, and milliseconds
    //   return date
    // },
    handleToggleWeekend(event) {
      this.innerCalendarOptions.weekends = event.target.checked
      this.$emit('toggleWeekend', this.innerCalendarOptions.weekends)
    },
    // toggleSwitch() {
    //   this.isSwitchOn = !this.isSwitchOn
    //   console.log('Switch state:', this.isSwitchOn)
    // },

    // handleDayCellDidMount: function (info) {
    //   const date = info.date
    //   if (this.overrideBusinessHours) {
    //     const hours = this.overrideBusinessHours.filter((i) => this.isSameDate(i.date, date))
    //     if (hours) {
    //       hours.forEach((hour) => {
    //         //const { isBusinessDay } = hour
    //         // if (info.el.classList.contains('fc-daygrid-day')) {
    //         //   info.el.style.backgroundColor = isBusinessDay ? '#A5D6A7' : '#EF9A9A'
    //         //   info.el.style.zIndex = '1'
    //         // }
    //       })
    //     }
    //   }
    // },

    handleSlotLabelDidMount(info) {
      const infoDate = info.date
      //const hours = date.getHours()

      if (this.overrideBusinessHours && this.overrideBusinessHours.length) {
        const overrideHours = this.overrideBusinessHours.filter((i) => {
          const luxonDate = DateTime.fromISO(i.date).setZone(this.timezone)
          const isSameDate = luxonDate.hasSame(infoDate, 'day')
          return isSameDate
        })
        if (overrideHours && overrideHours.length) {
          overrideHours.forEach((overrideHour) => {
            const { date, isBusinessDay, startTime, endTime } = overrideHour
            if (isBusinessDay) {
              const [startHours, startMinutes] = startTime.split(':').map(Number)
              const luxonStartDate = DateTime.fromISO(date).setZone(this.timezone)
              const dtStartDate = luxonStartDate.set({ hour: startHours, minute: startMinutes, second: 0, millisecond: 0 })

              const [endHours, endMinutes] = endTime.split(':').map(Number)
              const luxonEndDate = DateTime.fromISO(date).setZone(this.timezone)
              const dtEndDate = luxonEndDate.set({ hour: endHours, minute: endMinutes, second: 0, millisecond: 0 })

              // const calendarApi = this.$refs.fullCalendar.getApi()
              // const defaultDuration = calendarApi.getOption('defaultTimedEventDuration')
              // const [defaultHours, defaultMinutes] = defaultDuration.split(':').map(Number)

              const infoTimeFormated = infoDate.toFormat('HH:mm')
              const startTimeFormated = dtStartDate.toFormat('HH:mm')
              const endTimeFormated = dtEndDate.toFormat('HH:mm')

              // const isWithin = this.compareTimeToTarget(date, startTime) >= 0 && this.compareTimeToTarget(date, endTime) <= 0
              const isWithin = infoTimeFormated >= startTimeFormated && infoTimeFormated < endTimeFormated
              //info.el.style.backgroundColor = isWithin ? '#fff' : 'hsla(0,0%,84%,.3)'
              if (!isWithin) {
                info.el.style.background = 'hsla(0,0%,84%,.3)'
                // if (!info.el.classList.contains('fc-non-business')) {
                //   info.el.classList.add('fc-non-business')
                // }
              }
            }
          })
        }
      }
      info.el.style.zIndex = '1'
    },

    handleEventDrop: async function (info) {
      console.log('calendar handleEventDrop')
      console.log(info)
      const isWithinBusinessHours = await this.isWithinBusinessHours(info.event)
      if (isWithinBusinessHours) {
        this.$emit('event-drop', info)
        return
      } else {
        info.revert()
      }
    },

    handleEventResize: function (info) {
      console.log('calendar handleEventResize')
      this.$emit('event-resize', info)
    },

    removeTimezone(dateTimeString) {
      const match = dateTimeString.match(/^(.*?)([+-]\d{2}:\d{2})?$/)
      return match ? match[1] : dateTimeString
    },

    removeSeconds(dateTimeString) {
      const dateTimeParts = dateTimeString.split(':')
      if (dateTimeParts.length === 3) {
        return dateTimeParts.slice(0, 2).join(':')
      }
      return dateTimeString
    },

    // handleWeekendsToggle() {
    //   this.innerCalendarOptions.weekends = !this.innerCalendarOptions.weekends // update a property
    // },

    async handleDateSelect(selectInfo) {
      console.log('calendar handleDateSelect')
      const calendarApi = selectInfo.view.calendar
      const isWithin = await this.isWithinBusinessHours(selectInfo)
      if (isWithin) {
        this.$emit('date-select', selectInfo)
      }
      calendarApi.unselect() // clear date selection

      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },

    handleEventClick(clickInfo) {
      this.$emit('event-click', clickInfo)
      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove()
      // }
    },

    handleEvents(events) {
      this.currentEvents = events
    },

    async handleDrop(dropInfo) {
      console.log('calendar handleDrop')
      const { date, allDay } = dropInfo
      const dataEvent = dropInfo.draggedEl.attributes['data-event']
      const jsonString = dataEvent ? dataEvent.value : null
      const jsonObj = JSON.parse(jsonString)
      const isWithinBusinessHours = await this.isWithinBusinessHours(dropInfo)

      //let utcDate = new Date(date.toISOString())
      let startStr = date.toISOString()

      if (isWithinBusinessHours) {
        if (jsonObj.uniqueId && jsonObj.title) {
          const calendarApi = this.$refs.fullCalendar.getApi()
          if (allDay) {
            const hour = this.businessHours.find((item) => item.daysOfWeek.includes(date.getDay()))
            if (hour) {
              let [hours, minutes] = hour.startTime.split(':').map(Number)
              // start = new Date(date.setHours(hours, minutes, 0, 0))
              // start.setUTCHours(start.getUTCHours() + hours)
              // start.setUTCMinutes(start.getUTCMinutes() + minutes)

              startStr = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)).toISOString()
            }
          }

          const start = DateTime.fromISO(startStr).toJSDate()
          const newEvent = {
            id: jsonObj.uniqueId, //createEventId(),
            title: jsonObj.title, //dropInfo.draggedEl.innerText,
            start: start,
            end: this.addTimeToDate(start, this.eventDuration),
            // start: dropInfo.dateStr + 'T12:00:00',
            // duration: '00:30'
            classNames: 'custom-event-is-booking',
            event: {
              title: jsonObj.title
            }
          }
          if (this.isDuplicateEvent(newEvent)) {
            dropInfo.revert()
            return
          }
          calendarApi.addEvent(newEvent)
          // calendarApi.addEvent({
          //   id: jsonObj.uniqueId, //createEventId(),
          //   title: jsonObj.title, //dropInfo.draggedEl.innerText,
          //   start: date,
          //   end: this.addTimeToDate(date, this.eventDuration)
          //   // start: dropInfo.dateStr + 'T12:00:00',
          //   // duration: '00:30'
          // })
          this.$emit('drop', newEvent)
        }
      }
    },
    isDuplicateEvent: function (newEvent) {
      const calendarApi = this.$refs.fullCalendar.getApi()
      const events = calendarApi.getEvents()
      for (let event of events) {
        if (event.id === newEvent.id) {
          return true
        }
      }
      return false
    },
    eventAllow: async function (dropInfo, draggedEvent) {
      console.log('calendar eventAllow')
      const isEventAllow = await this.isWithinBusinessHours(dropInfo)
      //console.log(`isEventAllow: ${isEventAllow}`)
      return isEventAllow
    },

    // alertOverrideRules: function () {
    //   this.$toast.open({
    //     message: 'Action has been canceled due to override rules in place.',
    //     type: 'is-danger',
    //     position: 'is-top',
    //     queue: false
    //   })
    //   // this.$notification.error('Override Rules', 'Action has been canceled due to override rules in place.')
    // },
    // alertNotBusinessHours: function () {
    //   this.$toast.open({
    //     message: 'Action unavailable outside business hours.',
    //     type: 'is-danger',
    //     position: 'is-top',
    //     queue: false
    //   })
    //   // this.$notification.error('Business Hours', 'Action unavailable outside business hours.')
    // },
    // alertMaxCapacity: function () {
    //   this.$toast.open({
    //     message: 'Action not allow due to overloaded.',
    //     type: 'is-danger',
    //     position: 'is-top',
    //     queue: false
    //   })
    //   // this.$notification.error('Max Capacity', 'Action not allow due to overloaded.')
    // },

    isWithinBusinessHours: async function (event) {
      //const { allDay, date, start, end } = event
      const { allDay, date, start, end, id } = event

      // let luxonStart = DateTime.fromJSDate(start).setZone('Australia/Brisbane')
      // let luxonEnd = DateTime.fromJSDate(end).setZone('Australia/Brisbane')
      // let adjustedStart = luxonStart.minus({ minutes: luxonStart.offset })
      // let adjustedEnd = luxonEnd.minus({ minutes: luxonEnd.offset })
      // let utcStart = adjustedStart.toUTC().toISO()
      // let utcEnd = adjustedEnd.toUTC().toISO()

      // let isoStart = allDay ? this.$filters.fromJsDateToISO(start) : this.$filters.fromJsDateAdjustedToISO(start)
      // let isoEnd = allDay ? this.$filters.fromJsDateToISO(end) : this.$filters.fromJsDateAdjustedToISO(end)
      let isoStart = this.$filters.fromJsDateToISO(start)
      let isoEnd = this.$filters.fromJsDateToISO(end)

      // const offset = DateTime.fromJSDate(date, { zone: 'utc' }).setLocale(this.$company.info.locale).toFormat('ZZ') //this.getLocalUTCOffset()
      //const offset = DateTime.utc().setZone(this.timezone).toFormat('ZZ')
      // const businessStartTimeUTC = this.subtractTimeWithOffset(this.businessHours.startTime, offset)
      // const businessEndTimeUTC = this.subtractTimeWithOffset(this.businessHours.endTime, offset)

      // const businessStartTimeUTC = this.businessHours.startTime
      // const businessEndTimeUTC = this.businessHours.endTime

      const message = await BookingService.checkAvailableTimeSlot(allDay, date, isoStart, isoEnd, id)
      if (message) {
        this.$toast.open({
          message: message,
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        // this.$notification.error('Time Slot', message)
        return false
      }
      return true
    },

    // getDayEvents(date) {
    //   const calendarApi = this.$refs.fullCalendar.getApi()
    //   const events = calendarApi.getEvents()
    //   if (events) {
    //     const filtered = events.filter((i) => this.isSameDate(i.start, date))
    //     return filtered
    //   }
    //   return []
    // },

    // isSameDate(date1, date2) {
    //   const d1 = new Date(date1)
    //   const d2 = new Date(date2)
    //   return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
    // },

    compareTimeToTarget(date, targetTime) {
      const [targetHours, targetMinutes] = targetTime.split(':').map(Number)

      const currentHours = date.getUTCHours()
      const currentMinutes = date.getUTCMinutes()

      if (currentHours === targetHours && currentMinutes === targetMinutes) {
        return 0
      } else if (currentHours > targetHours || (currentHours === targetHours && currentMinutes > targetMinutes)) {
        return 1
      } else {
        return -1
      }
    },

    // toISODateString(date) {
    //   return date.toISOString().replace(/T.*$/, '') + 'T12:00:00'
    // },

    handleDatesSet(info) {
      console.log('calendar handleDatesSet')
      //const viewType = info.view.type
      // const isSameViewType = this.previousViewType == viewType
      // if (isSameViewType) {
         //this.clearTippyInstances()
      // } else {
        //this.previousViewType = viewType
      //}
      this.fetchData(info)
    },
    // clearTippyInstances() {
    //   if (this.tippyInstances && this.tippyInstances.size) {
    //     for (const value of this.tippyInstances.values()) {
    //       value.destroy()
    //     }
    //     this.tippyInstances.clear()
    //   }
    // },
    refresh() {
      console.log('calendar refresh')
    },

    updateOverrideBusinessHours(hours) {
      this.overrideBusinessHours = hours
    },

    // updateBusinessHours(hours) {
    //   this.businessHours = hours
    //   this.innerCalendarOptions.businessHours = hours
    // },

    fetchData(info) {
      const { startStr, endStr } = info
      const data = {
        startDate: startStr,
        endDate: endStr,
        info
      }
      this.$emit('fetch-data', data)
    },

    renderEventContent(eventInfo) {
      const vnode = this.$scopedSlots.default({ eventInfo })
      const container = document.createElement('div')
      vnode.forEach((v) => {
        const vm = new this.$root.constructor({
          parent: this,
          render: (h) => v
        }).$mount()
        container.appendChild(vm.$el)
      })
      return { domNodes: [container] }

      // return {
      //   html: `
      //     <div class="custom-event">
      //       <i class="mdi mdi-alarm"></i>${eventInfo.timeText}
      //       <i class="mdi mdi-car"></i>${eventInfo.event.title}
      //     </div>
      //   `
      // }
    },

    getLocalUTCOffset() {
      const date = new Date()
      const offsetMinutes = date.getTimezoneOffset()
      const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60)
      const offsetMins = Math.abs(offsetMinutes) % 60
      const sign = offsetMinutes <= 0 ? 1 : -1
      return { hours: offsetHours * sign, minutes: offsetMins * sign }
    },

    subtractTimeWithOffset(time) {
      const offset = this.offset
      const [hours, minutes] = time.split(':').map(Number)
      const [offsetHours, offsetMinutes] = offset.split(':').map(Number)
      const newMinutes = minutes - offsetMinutes
      const additionalHours = Math.floor(newMinutes / 60)
      const newHours = hours - offsetHours + additionalHours

      const finalMinutes = ((newMinutes % 60) + 60) % 60
      const finalHours = ((newHours % 24) + 24) % 24

      return `${String(finalHours).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`
    },

    addTimeToDate(date, time) {
      const [hours, minutes, seconds] = time.split(':').map(Number)
      const millisecondsToAdd = (hours * 60 * 60 + minutes * 60 + seconds) * 1000
      return new Date(date.getTime() + millisecondsToAdd)
    },

    resize() {
      this.$nextTick(() => {
        const calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.updateSize()
      })
    },

    handleEventReceive(info) {
      console.log('calendar handleEventReceive')
      info.revert()
      this.$emit('event-receive', info)
      //console.log('New event received:', info.event)
    },

    subtractOffset(utcDate) {
      const offset = this.offset
      const [offsetHours, offsetMinutes] = offset.split(':').map(Number)
      const date = new Date(utcDate)
      date.setUTCHours(date.getUTCHours() - offsetHours)
      date.setUTCMinutes(date.getUTCMinutes() - offsetMinutes)
      const dateStr = date.toISOString()
      return dateStr
    },

    addOffset(utcDate) {
      const offset = this.offset
      const [offsetHours, offsetMinutes] = offset.split(':').map(Number)
      const date = new Date(utcDate)
      date.setUTCHours(date.getUTCHours() + offsetHours)
      date.setUTCMinutes(date.getUTCMinutes() + offsetMinutes)
      const dateStr = date.toISOString()
      return dateStr
    }
  }
}
</script>

<style lang="css">
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.full-calendar {
  display: flex;
  min-height: 100%;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.full-calendar-sidebar {
  width: 180px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.full-calendar-sidebar-section {
  padding: 1em;
}

.full-calendar-main {
  flex-grow: 1;
  /* padding: 2em; */
  background-color: #fff;
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  max-height: 93vh;
  margin: 0 auto;
  box-sizing: inherit;
}

.fc .fc-col-header-cell-cushion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fc .fc-col-header-cell {
  /* background-color: #f0f0f0; */
  padding: 3px;
}

.fc .fc-daygrid-day-number {
  font-weight: bold;
}

.fc-view-harness {
  margin: 20px 20px 0px;
}

table.fc-scrollgrid > thead {
  box-shadow: 0px 2px 5px 0px #4f4e4e;
  color: #333;
}
a.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: #333;
}
table.fc-col-header > thead {
  height: 40px;
}
.fc .fc-col-header-cell {
  padding: 0px;
}
.fc-scrollgrid-sync-inner {
  padding: 8px;
}
.fc .fc-col-header-cell-cushion {
  padding: 0px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}
.fc-header-toolbar.fc-toolbar {
  background-color: #3291ef;
  color: #fff;
  /* justify-content:flex-start; */
}
.fc-header-toolbar > .fc-toolbar-chunk > div {
  display: flex;
}
.fc-header-toolbar > .fc-toolbar-chunk:nth-child(2) {
  margin-left: 3rem;
}
.fc-header-toolbar > .fc-toolbar-chunk > div > button.fc-prev-button,
.fc-header-toolbar > .fc-toolbar-chunk > div > button.fc-next-button {
  background-color: transparent;
  border-color: transparent;
}
.fc-toolbar-title {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  height: 50px !important;
}

.fc-button-group {
  margin-left: 0.5rem !important;
}
.fc-button-group > button.fc-button {
  border: 0;
}
.fc-button-group > .fc-button-primary {
  background-color: #2874bf;
  color: #fff;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-left: 1px;
}
.fc-button-group > .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #fff;
  color: #222;
}
a.custom-event-is-quote {
  background-color: #3291ef !important;
  color: #fff;
}
a.custom-event-is-booking {
  background-color: #2874bf !important;
  color: #fff;
}
a.custom-event-is-quote-completed {
  background-color: #54b700 !important;
  color: #fff;
}
.fc-button:hover {
  background-color: #fff !important; /* Change to your desired hover color */
  color: black !important; /* Change text color if needed */
}
/* .fc .fc-button-primary {
  background-color: #3291ef;
  border: 0;
}*/
.fc-customSwitch-button {
  background-color: #3291ef !important;
  border: 0 !important;
}
.fc-customSwitch-button:hover {
  background-color: #3291ef !important;
  border: 0 !important;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #fff !important;
}
.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #fff !important;
}
.fc .fc-toolbar-title {
  font-size: 1.3em !important;
  padding-top: 10px;
}
.fc-day-today {
  background-color: transparent !important; /* Removes the background color */
}
.fc-daygrid .fc-col-header-cell-cushion {
  text-transform: uppercase;
}
.fc-timegrid .fc-col-header-cell-cushion {
  text-transform: uppercase;
}
.custom-non-business {
  /* background: hsla(0, 0%, 84%, 0.3); */
  background-color: hsla(0, 0%, 90%, 1);
}

.calendar-custom-tippy .tippy-content {
  padding: 0 !important;
}
.calendar-custom-tippy .tippy-content .tooltip-header {
  text-align: left;
}

.custom-available-slot {
  padding: 5px;
  font-size: 0.7em;
  margin-right: auto;
  color: rgb(143, 143, 143);
}

.fc-timegrid .fc-scrollgrid-sync-inner {
  text-align: center !important;
}
.fc-timegrid .fc-col-header-cell-cushion {
  display: inline-block !important;
}
.custom-day-not-available {
  text-align: center;
  display: block;
}
.fc-event-main {
  overflow: hidden; /*hide text longer than div*/
}
.custom-day-time-slot-not-available {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 0.65em;
  font-weight: bold;
}
</style>