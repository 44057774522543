import { CompanySettingService } from '@/services'
import DeepDiff from 'deep-diff'

function invokeMethod(obj, functionName, ...args) {
  return obj[functionName].apply(obj, args)
}

const state = {
  baseIndex: 0,
  currentIndex: 0,
  hasError: false,
  snapshots: [],
  saving: false
}

const getters = {
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  hasError: state => state.hasError,
  saving: state => state.saving,
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  snapshots: state => state.snapshots
}

const actions = {
  clearSnapshots({ commit }) {
    commit('clearSnapshots')
  },
  async editStoreItem({ dispatch, commit }, { serviceFunction, entity, params = null }) {
    commit('switchSavingStatus', true)
    commit('switchErrorStatus', false)
    try {
      const updatedEntity = await invokeMethod(CompanySettingService, serviceFunction, entity, params)
      commit('saveInitialSnapshots', updatedEntity)
      commit('switchSavingStatus', false)

      // get the latest company setting for company vuex store
      if (serviceFunction == 'updateCompanySetting') {
        await dispatch('company/getCompanySetting', null, { root: true })
      }

      return updatedEntity
    } catch (e) {
      commit('switchErrorStatus', true)
    }
  },
  async getStoreItem({ commit }, { serviceFunction, params = null }) {
    const entity = await invokeMethod(CompanySettingService, serviceFunction, params)
    commit('saveInitialSnapshots', entity)
    return entity
  },
  saveSnapshot({ commit }, { entity }) {
    commit('saveSnapshot', entity)
  }
}

const mutations = {
  clearSnapshots(state) {
    state.baseIndex = 0
    state.currentIndex = 0
    state.snapshots = []
  },
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  switchErrorStatus(state, status) {
    state.hasError = status
  },
  switchSavingStatus(state, status) {
    state.saving = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
