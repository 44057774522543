import Vue from 'vue'
import VueRouter from 'vue-router'
import { EventHubTypes } from '@/enums'
import NavService from '@/components/NavService.js'
import DashboardRoutes from '@/views/dashboard/routes.js'
import VendorRoutes from '@/views/vendor/routes.js'
import InsurerRoutes from '@/views/insurer/routes.js'
import PermissionRecordRoutes from '@/views/permissionrecord/routes.js'
import CompanyRoutes from '@/views/company/routes.js'
import CustomerRoutes from '@/views/customer/routes.js'
import ContactRoutes from '@/views/contact/routes.js'
import VehicleRoutes from '@/views/vehicle/routes.js'
import QuickInvoiceRoutes from '@/views/quickinvoice/routes.js'
import SundryCreditorRoutes from '@/views/sundrycreditor/routes.js'
import PaymentEntryRoutes from '@/views/paymententry/routes.js'
import OrmMessageRoutes from '@/views/ormmessage/routes.js'
import PnetMessageRoutes from '@/views/pnetmessage/routes.js'
import EstImageMessageRoutes from '@/views/estimagemessage/routes.js'
import AudanetTaskRoutes from '@/views/audanettask/routes.js'
import QuoteRoutes from '@/views/quote/routes.js'
import ImageUploaderRoutes from '@/views/imageuploader/routes.js'
import PrintPreviewRoutes from '@/components/printpreview/routes.js'
import QuickItemRoutes from '@/views/quickitem/routes.js'
import ItemRoutes from '@/views/item/routes.js'
import OtherLabourRoutes from '@/views/otherlabour/routes.js'
import UnscheduledModelRoutes from '@/views/unscheduledmodel/routes.js'
import RecurringRemarkRoutes from '@/views/recurringremarks/routes.js'
import EmailSmsLogRoutes from '@/views/emailsmslog/routes.js'
import UsedPartsInfoRoutes from '@/views/usedpartsinfo/routes.js'
import PartsCatalogueRoutes from '@/views/partscatalogue/routes.js'
import DebtorAgingReportRoutes from '@/views/debtoragingreport/routes.js'
import CreditorAgingReportRoutes from '@/views/creditoragingreport/routes.js'
import SalesAnalysisRoutes from '@/views/salesanalysis/routes.js'
import OutstandingCreditRoutes from '@/views/outstandingcredit/routes.js'
import OutstandingPartRoutes from '@/views/outstandingpart/routes.js'
import DebtorListRoutes from '@/views/debtorlist/routes.js'
import DebtorReceiptRoutes from '@/views/debtorreceipt/routes.js'
import ReceiptEntryRoutes from '@/views/receiptentry/routes.js'
import DebtorAdjustmentRoutes from '@/views/debtoradjustment/routes.js'
import AuditTrailRoutes from '@/views/audittrail/routes.js'
import ClientToolRoutes from '@/views/clienttool/routes.js'
import AdvanceReceiptRoutes from '@/views/advancereceipt/routes.js'
import SuperadminVehicleLookupRoutes from '@/views/superadmin/vehiclelookup/routes.js'
import VehicleLookupRoutes from '@/views/vehiclelookup/routes.js'
import PaymentListRoutes from '@/views/paymentlist/routes.js'
import CreditorListRoutes from '@/views/creditorlist/routes.js'
import OrmBatchingRoutes from '@/views/ormbatching/routes.js'
import MiscQuoteRoutes from '@/views/miscquote/routes.js'
import GstReportRoutes from '@/views/gstreport/routes'
import PurchaseOrderRoutes from '@/views/purchaseorder/routes'
import DealerCreditEntryRoutes from '@/views/dealercreditentry/routes.js'
import ReturnPartsRoutes from '@/views/returnparts/routes.js'
import UtilitiesRoutes from '@/views/utilities/routes.js'
// import BookingCalendarRoutes from '../views/bookings/routes.js'
import JCNIRoutes from '@/views/jobsCompletedNotInvoiced/routes.js'
import CapacityCalendarRoutes from '@/views/capacity/routes.js'
import ProductionInOutRoutes from '@/views/productioninout/routes.js'
import JobInvoicedRoutes from '@/views/jobinvoiced/routes.js'
import CompanySettingRoutes from '@/views/companysetting/routes.js'
import QuoteTemplateRoutes from '@/views/quotetemplate/routes.js'
import AuthRoutes from '@/views/auth/routes.js'
import BookingRoutes from '@/views/booking/routes.js'
import RepxRoutes from '@/views/repx/routes.js'
import SubJobStageRoutes from '@/views/subjobstage/routes.js'

Vue.use(VueRouter)

const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...VendorRoutes,
  ...InsurerRoutes,
  ...PermissionRecordRoutes,
  ...CompanyRoutes,
  ...CustomerRoutes,
  ...ContactRoutes,
  ...VehicleRoutes,
  ...QuickInvoiceRoutes,
  ...SundryCreditorRoutes,
  ...PaymentEntryRoutes,
  ...OrmMessageRoutes,
  ...PnetMessageRoutes,
  ...EstImageMessageRoutes,
  ...AudanetTaskRoutes,
  ...QuoteRoutes,
  ...ImageUploaderRoutes,
  ...PrintPreviewRoutes,
  ...QuickItemRoutes,
  ...ItemRoutes,
  ...OtherLabourRoutes,
  ...UnscheduledModelRoutes,
  ...RecurringRemarkRoutes,
  ...EmailSmsLogRoutes,
  ...UsedPartsInfoRoutes,
  ...PartsCatalogueRoutes,
  ...DebtorAgingReportRoutes,
  ...CreditorAgingReportRoutes,
  ...SalesAnalysisRoutes,
  ...OutstandingCreditRoutes,
  ...OutstandingPartRoutes,
  ...DebtorListRoutes,
  ...DebtorReceiptRoutes,
  ...DebtorAdjustmentRoutes,
  ...AuditTrailRoutes,
  ...ReceiptEntryRoutes,
  ...ClientToolRoutes,
  ...AdvanceReceiptRoutes,
  ...SuperadminVehicleLookupRoutes,
  ...VehicleLookupRoutes,
  ...PaymentListRoutes,
  ...CreditorListRoutes,
  ...OrmBatchingRoutes,
  ...GstReportRoutes,
  ...PurchaseOrderRoutes,
  ...MiscQuoteRoutes,
  ...DealerCreditEntryRoutes,
  ...ReturnPartsRoutes,
  ...UtilitiesRoutes,
  // ...BookingCalendarRoutes,
  ...JCNIRoutes,
  ...CapacityCalendarRoutes,
  ...ProductionInOutRoutes,
  ...JobInvoicedRoutes,
  ...CompanySettingRoutes,
  ...QuoteTemplateRoutes,
  ...BookingRoutes,
  ...RepxRoutes,
  ...SubJobStageRoutes
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_URI,
  base: process.env.BASE_URL,
  routes
})

Vue.prototype.$eventHub = new Vue()
router.beforeEach((to, from, next) => {
  // Get access permissions for module by ID
  const parentRoute = to.matched[0]
  if (parentRoute && parentRoute.meta && parentRoute.meta.id) {
    NavService.getAccess(parentRoute.meta.id, parentRoute.meta.route).then(res => {
      if (res && res.version && res.version.toLowerCase() === 'default') {
        // TODO: Go to entry Url or go to specific Url?
        // Depends on whether wer're going to deploy listing and detail separately
        const url = res.data.entryUrl.replace('~', process.env.VUE_APP_ROOT_URI)
        window.location.href = url
      } else {
        to.meta.access = res
        Vue.prototype.$eventHub.$emit(EventHubTypes.SetActiveMenuCode, res.code)
        next()
      }
    })
  } else {
    next()
    // router.replace('/')
  }
})

// function guardRoute(to, from, next) {
//   // work-around to get to the Vuex store (as of Vue 2.0)
//   // const auth = router.app.$options.store.state.auth
//   router.app.$auth.isLoggedIn()
//     .then(rs => {
//       if (!rs) {
//         alert('you are not logged in')
//         const loginUrl = `http://localhost/uniq/signincsr.aspx?returnUrl=${window.location.href}`

//         window.location.href = loginUrl
//         // next({
//         //   path: '/login',
//         //   query: { redirect: to.fullPath }
//         // })
//       } else {
//         next()
//       }
//     })
// }

export default router
