<template>
  <div>
    <div class="field">
      <div class="columns is-gapless">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      :class="[ filter.butterflyCode ? 'is-warning' : 'is-primary' ]"
                      :data-tooltip="filter.butterflyCode ? 'Active Butterfly search' : 'Butterfly search'"
                      @click="butterflySearch()">
                      <span class="icon">
                        <i class="fqi fqi-butterfly fqi-20px" />
                      </span>
                    </a>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-narrow"
          v-if="showScrollToAdd"
          style="display: flex; align-items: center">
          <div class="field-body is-pulled-right">
            <div class="field is-narrow">
              <input class="is-checkradio"
                id="labourScroll"
                type="checkbox"
                :checked="$userInfo.scrollItemPart"
                @change="$setScrollItemPart"
                name="labourScroll">
              <label for="labourScroll">Scroll on add</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container-avaialble-butterfly-items">
      <bulma-table class="table is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        @sort="sort">
        <tr v-for="(item, index) in availableItems"
          :key="index"
          @dblclick="addNewItem(item, index)">
          <td>{{ item.itemNo }}</td>
          <td>{{ item.description }}</td>
          <td class="has-text-centered">{{ item.side }}</td>
          <td class="has-text-centered">{{ item.quantity }}</td>
          <td class="has-text-right mr-2"
            @click="addNewItem(item, index)">
            <div class="butterfly-item-values is-flex is-justify-content-center px-2 mr-2"
              :class="{ ' has-text-weight-bold is-selected': getExistingItem(item, itemCategoryTypes.PART) }">
              <!-- {{ item.unitPrice | formatNumber($userInfo.locale) }} -->
              +
            </div>
          </td>

          <!-- <td>{{ item.partNo }}</td> -->
          <!-- <td class="has-vertical-middle has-text-centered is-content-width">
            <a class="button is-small is-inverted"
              :class="!getExistingItem(item, itemCategoryTypes.PART) ? 'is-primary' : 'is-danger'"
              @click="addNewItem(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-24px"
                  :class=" !getExistingItem(item, itemCategoryTypes.PART) ? 'mdi-plus' : 'mdi-minus'" />
              </span>
            </a>
          </td> -->
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </div>
    <div>
      <butterfly-modal v-if="isButterflyModalActive"
        :active.sync="isButterflyModalActive"
        v-model="selectedButterflyCodes"
        @filterButterfly="butterflySearch"
        @reset="reset"
        @done="filterButterly"
        @cancel="cancel">
        <div slot="text-content">
          <butterfly v-if="isButterflyModalActive"
            v-model="selectedButterflyCodes" />
        </div>
      </butterfly-modal>
    </div>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailablePartsColumns } from '../columns'
import { ItemCategoryTypes, PartTypes, QuoteItemRevTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
import { Butterfly, ButterflyModal } from '@/components/Butterfly'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
// import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableParts',
  components: {
    BulmaTable,
    Butterfly,
    ButterflyModal
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    vehicleId: {
      type: String,
      default: ''
    },
    quoteNo: {
      type: Number,
      default: 0
    },
    subQuoteNo: {
      type: Number,
      default: 0
    },
    subQuoteNos: {
      type: Array,
      default: function () {
        return []
      }
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    showScrollToAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        vehicleId: this.vehicleId,
        query: '',
        butterflyCode: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedButterflyCodes: [],
      isButterflyModalActive: false,
      partsMain: null,
      partsSupp: null
    }
  },
  computed: {
    columns() {
      return AvailablePartsColumns
    },
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    }
  },
  created() {
    // this.innerValue = this.value
    this.getAvailableParts()
    // this.getQuoteItems()
  },
  mounted() {},
  methods: {
    async getAvailableParts() {
      this.isTableLoading = true
      this.availableItems = await QuoteService.getAvailableParts(this.filter)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableParts()
    },
    sort(name, order) {},
    reset() {
      this.selectedButterflyCodes = []
      this.filter.query = ''
      this.filter.butterflyCode = ''
      this.filter.pageIndex = 1
      this.getAvailableParts()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableParts()
    }, 500),
    butterflySearch() {
      this.isButterflyModalActive = true
    },
    filterButterly() {
      this.isButterflyModalActive = false
      this.filter.butterflyCode = this.selectedButterflyCodes.join()
      this.getAvailableParts()
    },
    cancel(value) {
      this.selectedButterflyCodes = value
      this.isButterflyModalActive = false
    },
    getExistingItem(item) {
      return this.value.find((i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
    },
    addNewItem(item, index) {
      const existingItem = this.getExistingItem(item)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.PART)
        newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
        newItem.itemQuantity = item.quantity
        newItem.value = roundAwayFromZero(item.quantity * item.unitPrice)
        newItem.mark = PartTypes.NEW.value
        newItem.markupPercent = 0
        newItem.markupValue = roundAwayFromZero(item.quantity * item.unitPrice)
        newItem.buyPrice = item.tradePrice
        newItem.partNo = item.partNo
        newItem.side = item.side
        newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        if (this.isAudanet) {
          newItem.rev = QuoteItemRevTypes.NonAudaNetPart
        }
        this.$emit('item-added', newItem)
      } else {
        this.$eventHub.$emit('deletePart', existingItem)
        // this.$notification.openNotificationWithType('warning', 'Parts', 'Part is already in selected list. Item not added.')
      }
    },
    async getQuoteItems() {
      if (this.subQuoteNo !== 0) {
        this.partsMain = await QuoteService.getQuoteItems(this.quoteNo, 0, [ItemCategoryTypes.PART])
      } else {
        const vm = this
        this.partsSupp = {}
        // this.subQuoteNos.forEach(async function(q) {
        //   if (q.key !== 0) {
        //     const items = await QuoteService.getQuoteItems(vm.quoteNo, q.key, ItemCategoryTypes.PART)
        //     const prop = `parts${q.key}`
        //     vm.partsSupp[prop] = items
        //   }
        // })
        const promises = this.subQuoteNos.map(async function (q, index) {
          if (q.key !== 0) {
            const items = await QuoteService.getQuoteItems(vm.quoteNo, q.key, [ItemCategoryTypes.PART])
            const prop = `parts${q.key}`
            vm.partsSupp[prop] = items
          }
        })
        await Promise.all(promises)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/themes/default/variables';

#svgButterfly {
  fill: $primary;
}
// .button:hover svg path {
//   fill: white;
// }
.button:hover #svgButterfly {
  fill: white;
}
</style>
