<template>
  <base-modal-ex
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p
        class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span
          v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i
            class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <slot name="text-content" />
    </section>
    <footer
      class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button
        class="button is-success tooltip is-hidden"
        @click="previous()"
        data-tooltip="Previous step"
        :disabled="previousDisabled">Previous</button>
      <button
        v-if="!nextDisabled"
        class="button is-warning tooltip is-hidden"
        @click="next()"
        data-tooltip="Next step"
        :disabled="nextDisabled">Next</button>
      <a v-if="!continueDisabled"
        id="convert"
        class="button is-primary tooltip"
        @click="load()"
        data-tooltip="Convert to quote">
        <span class="icon">
          <i class="mdi mdi-swap-horizontal mdi-18px" />
        </span>
        <span>Convert</span>
      </a>
      <button
        v-if="!continueDisabled"
        class="button is-primary tooltip is-hidden"
        @click="load()"
        data-tooltip="Continue">Continue</button>
      <button
        class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'

export default {
  name: 'LoadQuoteModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    previousDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    },
    continueDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  computed: {
    buttonCSS: function () {
      return 'is-inverted'
    },
    ...mapGetters(['licenceExpired'])
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    next() {
      this.$emit('next')
    },
    previous() {
      this.$emit('previous')
    },
    close() {
      this.$emit('close')
    },
    load() {
      this.$emit('load')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}

  //#convert.button {
  //  background-color: #3291EF;
  //  color: #FFF;
  //  opacity: 50%;
  //}
</style>
