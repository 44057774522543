<template>

  <!--------------------------------------Full width Mode ------------------>

  <div v-if="isFullWidth"
    class="fixed-grid has-12-cols is-align-self-center">
    <div class="grid is-align-items-center">
      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6">

        <!-- Description, belnd and report -->
        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">
            <div class="cell is-col-span-11 is-flex is-flex-direction-column">
              <slot name="description" />
            </div>

            <!-- Report -->
            <div class="cell is-col-span-1 is-clickable">
              <slot name="report_blend" />
            </div>

          </div>
        </div>
      </div>

      <!-- empty slots -->
      <div class="cell is-col-span-3" />

      <!---------- Total value ------------->
      <div class="cell is-col-span-1 end">
        <slot name="total_value" />
      </div>

      <!---------- Present As ------------->
      <div class="cell">
        <slot name="present_as" />
      </div>

      <!---------- Actions ------------->
      <div class="cell">
        <div class="cell end is-align-items-center">
          <slot name="comment" />
          <slot name="assessment_status" />
          <slot name="delete_button" />
          <slot name="drag_handle" />
        </div>
      </div>

    </div>
  </div>

  <!------------------------------- Half screen width/ multi col view ------------>

  <div v-else
    class="fixed-grid has-12-cols is-align-self-center">
    <div class="grid "
      style="row-gap: 5px;">
      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6 is-align-self-center">
        <slot name="description" />
      </div>

      <!----------- Report  -------------->
      <div class="cell is-flex is-justify-content-end is-align-items-center">
        <slot name="report_blend" />
      </div>

      <div class="cell is-col-span-1" />

      <!---------- Total value ------------->
      <div class="cell is-col-span-2 is-align-self-center end">
        <slot name="total_value" />
      </div>

      <!---------- Actions ------------->
      <div class="cell is-col-span-2 end is-align-items-center">
        <slot name="comment" />
        <slot name="assessment_status" />
        <slot name="delete_button" />
        <slot name="drag_handle" />
      </div>

      <!------ Line No ---->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="lineNo" />
      </div>

      <!----- Item No  ----->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemNo" />
      </div>

      <!----- Item Status  ---->
      <div class="cell is-col-span-2"
        v-if="isFullyExpanded && isSupportUser">
        <slot name="itemStatus" />
      </div>

      <!----- Present As  ----->
      <div class="cell is-col-span-2 mt-2"
        v-if="isFullyExpanded">
        <slot name="present_as" />
      </div>

      <!-- empty -->
      <div class="cell is-col-span-3"
        :class="isSupportUser ? 'is-col-span-3' : 'is-col-span-9'"
        v-if="isFullyExpanded" />

    </div>

  </div>

</template>

<script>
export default {
  name: 'QuoteBuilderMiscItemTemplate',
  props: {
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isFullyExpanded: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isHeading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSupportUser() {
      return !!this.$userInfo.isSupportUser
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 2px solid $primary;
}
</style>