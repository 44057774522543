import { ComponentRoute } from '@/classes'

const CompanyRoutes = {
  CompanyOnboardingView: new ComponentRoute('/company/onboarding', 'CompanyOnboardingView'),
  CompanyMenuVersionAccess: new ComponentRoute('/company/onboarding/', 'CompanyMenuVersionAccess'),
  CompanyEnableNewMenu: new ComponentRoute('/company/onboarding/enablenewmenu', 'CompanyEnableNewMenu'),
  CompanyUpdateUserAccess: new ComponentRoute('/company/onboarding/updateuseraccess', 'CompanyUpdateUserAccess'),
  NewFeaturesControl: new ComponentRoute('/company/onboarding/newfeatures', 'NewFeaturesControl'),
  CompanyEmailRouteManage: new ComponentRoute('/company/onboarding/emailroutemanage', 'CompanyEmailRouteManage'),
  CompanySetting: new ComponentRoute('/company/onboarding/companysetting', 'CompanySetting'),
  CompanyBusinessHours: new ComponentRoute('/company/onboarding/companybusinesshours', 'CompanyBusinessHours'),
  CompanyOverrideBusinessHours: new ComponentRoute('/company/onboarding/companyoverridebusinesshours', 'CompanyOverrideBusinessHours'),
  CompanyRepxStagesSettings: new ComponentRoute('/company/onboarding/companyrepxstagessettings', 'CompanyRepxStagesSettings'),
  CompanyRepxStagesMappingSetup: new ComponentRoute('/company/onboarding/companyrepxstagesmappingsetup', 'CompanyRepxStagesMappingSetup')
}

export default CompanyRoutes