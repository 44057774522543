<template>
  <div v-if="value"
    class="tile is-parent"
    :class="{ 'box  ml-2': showBox }">
    <article class="tile is-child is-parent">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center ">
          <i class="icon mdi mdi-account-outline mdi-24px" />
          <p class="modal-card-title pl-1">Customer</p>
        </div>
        <div class="pt-3 is-flex is-justify-content-flex-end">
          <div :class="{'disabled' : (value.quoteId) }">
            <div class="pretty p-default p-round p-smooth is-size-7">
              <input id="chk-pretty-customer-type"
                v-model="innerIsCompany"
                name="chk-pretty-customer-type"
                :value="true"
                type="radio">
              <div class="state p-primary">
                <label class="is-size-7">Company</label>
              </div>
            </div>
            <div class="pretty p-default p-round p-smooth is-size-7">
              <input id="chk-pretty-customer-type"
                v-model="innerIsCompany"
                name="chk-pretty-customer-type"
                :value="false"
                type="radio">
              <div class="state p-primary">
                <label class="is-size-7">Individual</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="is-divider" />
      <div class="tile is-ancestor">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child detail-page-tile"
              :class="{'disabled' : (value.quoteId) }">
              <div class="field"
                v-if="innerIsCompany">
                <label class="label is-required-label">Company Name</label>
                <div class="control">
                  <input v-model="value.customerName"
                    class="input"
                    :class="{ 'is-danger': $v.entity.customerName.$error }"
                    type="text">
                  <span class="help is-danger"
                    v-if="$v.entity && !$v.entity.customerName.required">Company Name is required</span>
                </div>
              </div>
              <div v-else
                class="field-body">
                <div class="field">
                  <label class="label is-required-label">First Name</label>
                  <div class="control">
                    <input v-model="value.firstname"
                      class="input"
                      :class="{ 'is-danger': $v.entity.firstname.$error }"
                      type="text">
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.firstname.required">First Name is required</span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-required-label">Last Name</label>
                  <div class="control">
                    <input v-model="value.lastname"
                      class="input"
                      :class="{ 'is-danger': $v.entity.lastname.$error }"
                      type="text">
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.lastname.required">Last Name is required</span>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Phone No </label>
                <div class="control">
                  <input v-model="value.customerPhoneNo"
                    class="input"
                    type="text">
                </div>
              </div>
              <div class="field">
                <label class="label">Email </label>
                <div class="control">
                  <input v-model="value.customerEmail"
                    class="input"
                    :class="{ 'is-danger': $v.entity.customerEmail.$error }"
                    type="text">
                </div>
              </div>
              <!-- <div class="columns field">
                <div class="column">
                  <div class="field">
                    <label class="label">Is First Contact With Customer?</label>
                  </div>
                  <div class="field">
                    <div class="pretty p-default p-round p-bigger p-smooth">
                      <input type="radio"
                        id="text"
                        value="true"
                        v-model="value.isFirstContactWithCustomer">
                      <div class="state p-primary-o">
                        <label>Yes</label>
                      </div>
                    </div>
                    <div class="pretty p-default p-round p-bigger p-smooth">
                      <input type="radio"
                        id="text"
                        value="false"
                        v-model="value.isFirstContactWithCustomer">
                      <div class="state p-primary-o">
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="field">
                <label class="label">First Contact With Customer Date</label>
                <v-date-picker v-model="value.firstContactWithCustomerDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Arrive For Inspection Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div> -->
            </article>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// import QuickItemService from './QuickItemService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
import storeMixin from '../../storeMixin'

export default {
  name: 'BookingCustomer',
  inject: ['$vv'],
  components: {
    // QuickItemService,
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, storeMixin],
  props: {
    value: null,
    isNew: Boolean,
    showBox: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '08:00:00'
        }
      },
      innerIsCompany: ''
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    innerIsCompany: function (newVal, oldVal) {
      this.value.isCompany = newVal
      if (newVal === true) {
        this.value.firstname = null
        this.value.lastname = null
      }
      else{
        this.value.customerName = null
      }
    }
  },
  created() {
    this.innerIsCompany = this.value.isCompany
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(deleted) {
      this.value.deleted = deleted
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 0 0 1.2rem 0;
}
.desc-width {
  width: 50%;
}
article.tile.is-child > .columns .column i.icon{
  color: #1A5276;
}
.modal-card-title{
  color: #1A5276;
    font-weight: 600;
    line-height: 1.125;
}
div.tile.is-parent {
  min-height: calc(100vh - 18rem);
}
</style>
