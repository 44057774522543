<template>

  <div>

    <div class="tabs is-toggle ">
      <ul>
        <li class="is-active">
          <a>
            <span>Repair</span>
          </a>
        </li>
        <li>
          <a>
            <span>Replace</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="butterfly-component">
      <butterfly v-model="selectedButterflyCodes"
        :show-selected-parts="false" />
    </div>
  </div>
</template>

<script>
import { Butterfly } from '@/components/Butterfly'

export default {
  name: 'ButterflyComponent',
  components: {
    Butterfly
  },
  data() {
    return {
      selectedButterflyCodes: []
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.butterfly-component {
  /* Add your component styles here */
}
</style>