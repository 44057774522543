<template>
  <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
    :columns="columns"
    :page-index="filter.pageIndex"
    :page-size="filter.pageSize"
    :total-rows="totalRows"
    :is-loading="isTableLoading"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :draggable="false"
    :show-pagination="false"
    :is-title-checkable="editable"
    title-checkbox-location="front"
    :is-title-checked="selectAllChecked"
    @title-checkbox-changed="selectAllItems"
    @pageChanged="onPageChange"
    @sort="sort">
    <tr v-for="(item, index) in activeItems"
      :class="isItemDeleted(item) ? 'strike-through-line' : ''"
      :key="item.quoteItemId">
      <td v-if="editable"
        class="has-vertical-middle is-content-width">
        <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
          <input v-model="item.isSelected"
            type="checkbox">
          <div class="state p-info">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
      </td>
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-right linenumber-input">
        <vue-numeric v-if="item.isAdded"
          class="input has-text-right"
          :class="defaultRowColourClass(item)"
          v-model.number="item.lineNumber"
          :precision="0"
          separator="" />
        <span v-else>{{ item.lineNumber }}</span>
      </td>
      <td class="has-vertical-middle itemno-input">
        <input v-if="item.isAdded"
          class="input"
          :class="[defaultRowColourClass(item), $v.activeItems.$each[index].itemNo.required ? '' : 'is-danger']"
          placeholder="Item No."
          v-model="item.itemNo">
        <span v-else>{{ item.itemNo }}</span>
      </td>
      <td :class="['has-vertical-middle itemdesc-input']">
        <input v-if="item.isAdded"
          class="input"
          :class="[defaultRowColourClass(item), $v.activeItems.$each[index].itemDesc.required ? '' : 'is-danger']"
          placeholder="Item Description"
          v-model="item.itemDesc">
        <span v-else>{{ item.itemDesc }}</span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <div v-if="editable && !isItemDeleted(item)"
          class="pretty p-icon p-curve p-smooth m-0 p-bigger">
          <input v-model="item.reportOnly"
            type="checkbox">
          <div class="state p-info">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
        <span v-else
          class="icon has-text-info">
          <i class="mdi mdi-24px"
            :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
        </span>
      </td>
      <td class="has-vertical-middle has-text-centered is-content-width">0</td>
      <td class="has-vertical-middle has-text-centered is-content-width">0</td>
      <td class="has-vertical-middle has-text-right money-input">
        <vue-numeric v-if="editable && !isItemDeleted(item)"
          class="input has-text-right"
          :class="defaultRowColourClass(item)"
          v-model.number="item.value"
          :precision="2"
          @value-changed="updateValue(index, item)" />
        <span v-else>{{ item.value| formatNumber($userInfo.locale) }}</span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <div v-if="item.presentAs">
          <div v-for="split in item.presentAs"
            :key="split.itemType">
            <div class="tags has-addons mb-0 present-as-tags">
              <span :class="`tag mb-1 is-${split.itemType.toLowerCase()}`">{{ split.itemType }}</span>
              <span class="tag is-dark mb-1">{{ split.percent }}%</span>
            </div>
          </div>
        </div>
      </td>
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-centered is-content-width">{{ item.itemStatus }}</td>
      <td v-if="editable"
        class="has-vertical-middle has-text-centered is-content-width">
        <div class="icon-container">
          <button v-if="!isItemDeleted(item) && item.isDeleted !== true && item.deleted !== true"
            class="button is-danger is-small is-inverted tooltip"
            @click="deleteItem(item, index)"
            :disabled="innerValue.readOnly || isItemDeleted(item)"
            data-tooltip="Delete">
            <span class="icon is-small">
              <i class="mdi mdi-delete mdi-24px" />
            </span>
          </button>
        </div>
      </td>
    </tr>
    <template v-if="!editable"
      slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>
    <template slot="footer">
      <tr>
        <th class="left-right-items"
          :colspan="totalColSpan">
          <div v-if="editable"
            class="left-item">
            <button class="button is-info is-rounded is-outlined"
              data-tooltip="add item"
              @click="addBlankItem()">
              <span class="icon">
                <i class="mdi mdi-plus" />
              </span>
              <span>Add Item</span>
            </button>
          </div>
          <div v-if="activeItems.length > 0"
            class="has-text-right has-text-success right-item">
            Totals
          </div>
        </th>
        <th v-if="activeItems.length > 0"
          class="has-text-right has-text-success"
          :class="[ total >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ total | formatCurrency($userInfo.locale) }}</th>
        <th colspan="100%" />
      </tr>
    </template>
  </bulma-table>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { QuoteItemModel } from '@/classes/viewmodels'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { MiscColumns } from '../columns'
import BulmaTable from '@/components/BulmaTable'
import _cloneDeep from 'lodash/cloneDeep'
import { PartTypes, ItemCategoryTypes } from '@/enums'
import VueNumeric from '@/components/VueNumeric'
import { QuoteAssessmentMixin, QuoteMiscMixin } from '../mixins'

export default {
  name: 'AuthoriseMiscOld',
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  components: {
    BulmaTable,
    VueNumeric
  },
  mixins: [NumberFiltersMixin, QuoteMiscMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    originalValue: null,
    itemType: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    itemsList: {
      type: Array,
      default: () => []
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: this.value,
      isTableLoading: false,
      filter: {
        sortColumn: MiscColumns[1].name,
        sortOrder: MiscColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  validations: {
    activeItems: {
      $each: {
        itemNo: {
          required
        },
        itemDesc: {
          required
        }
      }
    }
  },
  computed: {
    columns() {
      const c = _cloneDeep(MiscColumns)
      c[0].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      if (!this.editable) {
        c.splice(c.length - 1, 1)
      }
      return c
    },
    selectAllChecked() {
      if (this.activeItems.every((item) => item.isSelected)) {
        return true
      } else if (this.activeItems.some((item) => item.isSelected)) {
        return null
      } else {
        return false
      }
    },
    totalRows() {
      if (this.editable) {
        return this.activeItems.length + 1
      }
      return this.activeItems.length
    },
    totalColSpan() {
      let length = this.columns.length - (this.$userInfo.isSupportUser ? 3 : 4)
      return length
    },
    total() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, this.activeItems, ItemCategoryTypes.MISC)
    },
    activeItems() {
      return this.itemsList.filter((i) => i.itemType === this.itemType)
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal
      this.defaultSelectItems()
    }
  },
  created() {},
  mounted() {},
  methods: {
    onPageChange() {},
    sort() {},
    updateTotal(index) {},
    deleteItem(item, index) {
      item.deleted = true
    },
    isItemDeleted(item) {
      return item.deleted || item.isDeleted
    },
    selectAllItems(value) {
      if (value !== null) {
        this.activeItems.forEach((item) => {
          item.isSelected = value
        })
      }
    },
    defaultSelectItems() {
      this.activeItems.forEach((item) => {
        item.isSelected = false
      })
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.MISC)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.side = ''
      newItem.isSelected = true
      newItem.isAdded = true
      newItem.isNew = true
      newItem.sortNo = this.innerValue.miscs.length ? Math.max(...this.innerValue.miscs.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      // this.$toast.open({
      //   message: 'Item added',
      //   type: 'is-success',
      //   position: 'is-top',
      //   queue: false
      // })
      this.$notification.success('','Item added')

    }
  }
}
</script>

<style lang="scss" scoped>
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}
</style>