<template>
  <div class="tooltip-panel">
    <div class="tooltip-header">{{ title }}</div>
    <div class="tooltip-body"
      v-html="description" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String
  }
}
</script>

<style scoped>

.tippy-box[data-theme~='custom'] {
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: #333;
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  border: 1px solid #ddd;
}

.tooltip-panel {
  width: 200px; /* Customize width as needed */
}

.tooltip-header {
  background-color: #F8F8F8;
  padding: 8px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

.tooltip-body {
  padding: 10px;
  font-size: 0.9em;
  color: #333;
}
</style>