<template>
  <div class="page-body">

    <div class="is-flex"
      style="height: 94%;">
      <div class="calendar-container thin-scollbar  animate__animated animate__fadeIn"
        :class="[{ 'is-full-width' : !isSidePanelOpen, 'loading': !!isLoading }]">
        <calendar ref="calendar"
          :calendar-options="innerCalendarOptions"
          @fetch-data="handleFetchData"
          :business-hours="[]"
          :events="innerEvents"
          @event-click="handleEventClick"
          @date-select="handleDateSelect"
          @drop="handleDrop"
          :is-show-weekend="isShowWeekend"
          @event-resize="handleEventResize"
          @event-drop="handleEventDrop"
          :override-business-hours="overrideBusinessHours"
          @event-receive="handleEventReceive">
          <template v-slot:default="{ eventInfo }">
            <!-- <div class="custom-event tooltip is-tooltip"
              :data-tooltip="renderTooltip(eventInfo)"> -->
            <div class="custom-event">
              <i class="mdi pl-1"
                :class="renderIcon(eventInfo)" /> {{ renderTimeText(eventInfo) }}; {{ eventInfo.event.title }}
            </div>
          </template>
        </calendar>
      </div>
    </div>

    <aside class="booking-panel"
      :class="{'closed' : !isSidePanelOpen, 'open' : isSidePanelOpen}">
      <booking-calendar-side-panel ref="bookingCalendarSidePanel"
        :is-side-panel-open="isSidePanelOpen"
        @handleSidePanelToggle="handleSidePanelToggle"
        @toggleWeekend="handleToggleWeekend" />
    </aside>

    <booking-simple-view-modal :active.sync="isSimpleBookingModelActive"
      :booking-id="selectedEditBookingId"
      :visible="isSimpleBookingModelActive"
      :is-new="isNewSimpleBooking"
      :booking-start-date="selectedBookingStartDate"
      @close="handleSimpleBookingModalClosed"
      @save="handleSimpleBookingSave" />

  </div>
</template>

<script>
import Calendar from '@/components/FullCalendar/Calendar.vue'
import BookingCalendarSidePanel from './BookingCalendarSidePanel.vue'
import BookingService from './BookingService'
import CompanyService from '@/views/company/CompanyService'
import { DateTime, Duration } from 'luxon'
import BookingSimpleViewModal from './BookingSimpleViewModal.vue'
import { formatBulletString } from '@/utils/StringFunctions'
import BookingRoutes from './route-types'
import store from '@/store'
import StoreMixin from './storeMixin'

const company = store.getters['company/company']

export default {
  name: 'BookingCalendar',
  components: { Calendar, BookingCalendarSidePanel, BookingSimpleViewModal },
  mixins: [StoreMixin],
  props: {},
  data() {
    return {
      innerEvents: [],
      isLoading: false,
      isSidePanelOpen: true,
      isShowWeekend: true,
      businessHours: [
        // {
        //   daysOfWeek: [1, 2, 3, 4, 5],
        //   startTime: '08:00', // 08:00AM Aus time
        //   endTime: '17:00' // 05:00PM Aus time
        // }
      ],
      overrideBusinessHours: [],

      selectedBookingId: null,
      selectedEditBookingId: null,
      isSimpleBookingModelActive: false,
      isNewSimpleBooking: false,
      startDate: null,
      endDate: null,
      selectedBookingStartDate: null,

      innerCalendarOptions: {
        initialView: 'timeGridDay',
        defaultTimedEventDuration: '00:30:00',
        slotLabelFormat: { hour: 'numeric', minute: '2-digit', hour12: true }
        // slotMinTime: '09:00:00',
        // slotMaxTime: '17:00:00'
        // slotLaneClassNames: this.handleCustomSlotClass
        //slotLaneContent: this.customSlotLabelContent
        // slotMinTime: '07:00:00',
        // slotMaxTime: '16:00:00'
      }
    }
  },
  computed: {
    timezone() {
      return company && company.info ? company.info.timezoneIana : Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  watch: {},
  created() {},
  methods: {
    getSlotMinMaxTime() {
      console.log('getSlotMinMaxTime')
      const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
      const currentView = calendarApi.view
      const currentDate = currentView.calendar.currentData.currentDate
      if (!currentDate) return
      let luxonDate = DateTime.fromJSDate(currentDate).toUTC()
      let minTime = DateTime.fromObject({ hour: 8, minute: 0 }, { zone: 'utc' })
      let maxTime = DateTime.fromObject({ hour: 16, minute: 0 }, { zone: 'utc' })
      if (this.businessHours) {
        let businessHour = this.businessHours.find((i) => i.daysOfWeek[0] == luxonDate.weekday)
        if (!businessHour) businessHour = this.businessHours[0]
        const [startHour, startMinute] = businessHour.startTime.split(':')
        const [endHour, endMinute] = businessHour.endTime.split(':')
        minTime = minTime.set({ hour: parseInt(startHour, 10), minute: parseInt(startMinute, 10) })
        maxTime = maxTime.set({ hour: parseInt(endHour, 10), minute: parseInt(endMinute, 10) })
      }
      if (currentView.type === 'timeGridDay') {
        const allDays = document.querySelectorAll('.fc-col-header-cell') //daily
        allDays.forEach((dayCell) => {
          const date = new Date(dayCell.dataset.date)
          const utcDate = DateTime.fromJSDate(date).toUTC()
          if (this.hasOverrideBusinessHour(utcDate)) {
            if (this.isNonBusinessDate(utcDate)) {
              minTime = minTime.set({ hour: 3, minute: 0 })
              maxTime = maxTime.set({ hour: 4, minute: 30 })
            } else {
              const filtered = this.overrideBusinessHours.filter((item) => {
                const itemDate = DateTime.fromISO(item.date).toUTC()
                return itemDate.hasSame(utcDate, 'day')
              })
              const grouped = filtered.reduce((acc, curr) => {
                const { date, startTime, endTime } = curr
                if (!acc[date]) {
                  acc[date] = { minStartTime: startTime, maxEndTime: endTime }
                } else {
                  if (startTime && (!acc[date].minStartTime || startTime < acc[date].minStartTime)) {
                    acc[date].minStartTime = startTime
                  }
                  if (endTime && (!acc[date].maxEndTime || endTime > acc[date].maxEndTime)) {
                    acc[date].maxEndTime = endTime
                  }
                }
                return acc
              }, {})
              const result = Object.entries(grouped).map(([date, times]) => ({
                date,
                minStartTime: times.minStartTime,
                maxEndTime: times.maxEndTime
              }))
              const [_startHour, _startMinute] = result.minStartTime.split(':')
              const [_endHour, _endMinute] = result.maxEndTime.split(':')
              minTime = minTime.set({ hour: parseInt(_startHour, 10), minute: parseInt(_startMinute, 10) })
              maxTime = maxTime.set({ hour: parseInt(_endHour, 10), minute: parseInt(_endMinute, 10) })
            }
          }
        })
      }
      this.innerCalendarOptions.slotMinTime = minTime.toFormat('HH:mm:ss')
      this.innerCalendarOptions.slotMaxTime = maxTime.toFormat('HH:mm:ss')
      this.$refs.calendar.getApi().render()
    },
    customSlotLabelContent(arg) {
      const { date, view } = arg
      if (view.type == 'timeGridWeek') return
      const currentDate = view.calendar.currentData.currentDate
      if (!currentDate) return
      const isoDate = DateTime.fromJSDate(currentDate).toISO()
      const clonedDate = DateTime.fromISO(isoDate).toUTC()
      // const timezoneDate = clonedDate.setZone(this.timezone)
      let slotDateTime = DateTime.fromJSDate(date).toUTC()
      const newDateTime = clonedDate.set({
        hour: slotDateTime.hour,
        minute: slotDateTime.minute,
        second: slotDateTime.second
      })
      if (this.hasOverrideBusinessHour(newDateTime)) {
        if (this.isNonBusinessDate(newDateTime)) {
          const customDiv = document.createElement('div')
          customDiv.className = 'has-text-centered day-na'
          customDiv.innerHTML = '<span class="has-text-grey-light is-size-7">N/A</span>'
          return { domNodes: [customDiv] }
        }
      }
      return null
    },
    isNonBusinessDate(luxonDateUTC) {
      let matches = []
      for (const obj of this.overrideBusinessHours) {
        const { date, isBusinessDay } = obj
        const _luxonDate = DateTime.fromISO(date).toUTC()
        const isSameDay = luxonDateUTC.hasSame(_luxonDate, 'day')
        if (isSameDay) {
          if (!isBusinessDay) return true
          matches.push(obj)
        }
      }
      if (matches.length) {
        for (const match of matches) {
          const { date, startTime, endTime } = match
          let lxDate = DateTime.fromISO(date).toUTC()
          const startDatetime = lxDate.set({
            hour: parseInt(startTime.split(':')[0], 10),
            minute: parseInt(startTime.split(':')[1], 10),
            second: 0,
            millisecond: 0
          })
          const endDatetime = lxDate.set({
            hour: parseInt(endTime.split(':')[0], 10),
            minute: parseInt(endTime.split(':')[1], 10),
            second: 0,
            millisecond: 0
          })
          if (luxonDateUTC >= startDatetime && luxonDateUTC < endDatetime) return false
        }
      }
      return true
    },
    hasOverrideBusinessHour(luxonDateUTC) {
      for (const obj of this.overrideBusinessHours) {
        const { date } = obj
        const _luxonDate = DateTime.fromISO(date).toUTC()
        const isSameDay = luxonDateUTC.hasSame(_luxonDate, 'day')
        if (isSameDay) {
          return true
        }
      }
      return false
    },
    handleCustomSlotClass(slotInfo) {
      const { view, time } = slotInfo
      const { currentStart } = view

      const luxonDate = DateTime.fromISO(currentStart.toISOString()).toUTC()
      const infoDate = luxonDate.plus({ milliseconds: time.milliseconds })

      if (this.overrideBusinessHours && this.overrideBusinessHours.length) {
        const overrideHours = this.overrideBusinessHours.filter((i) => {
          const luxonDate = DateTime.fromISO(i.date).setZone(this.timezone)
          const isSameDate = luxonDate.hasSame(infoDate, 'day')
          return isSameDate
        })
        if (overrideHours && overrideHours.length) {
          for (let overrideHour of overrideHours) {
            const { date, isBusinessDay, startTime, endTime } = overrideHour
            if (isBusinessDay) {
              const [startHours, startMinutes] = startTime.split(':').map(Number)
              const luxonStartDate = DateTime.fromISO(date).setZone(this.timezone)
              const dtStartDate = luxonStartDate.set({ hour: startHours, minute: startMinutes, second: 0, millisecond: 0 })

              const [endHours, endMinutes] = endTime.split(':').map(Number)
              const luxonEndDate = DateTime.fromISO(date).setZone(this.timezone)
              const dtEndDate = luxonEndDate.set({ hour: endHours, minute: endMinutes, second: 0, millisecond: 0 })

              // const calendarApi = this.$refs.fullCalendar.getApi()
              // const defaultDuration = calendarApi.getOption('defaultTimedEventDuration')
              // const [defaultHours, defaultMinutes] = defaultDuration.split(':').map(Number)

              const infoTimeFormated = infoDate.toFormat('HH:mm')
              const startTimeFormated = dtStartDate.toFormat('HH:mm')
              const endTimeFormated = dtEndDate.toFormat('HH:mm')

              const isWithin = infoTimeFormated >= startTimeFormated && infoTimeFormated < endTimeFormated
              if (!isWithin) {
                return 'custom-non-business'
              }
            }
          }
        }
      }
      // const slotTime = slotInfo.date.getHours()
      // if (this.overrideBusinessHours) {
      //   const { startTime, endTime } = this.overrideBusinessHours
      //   if (slotTime >= this.convertToTime(startTime) && slotTime < this.convertToTime(endTime)) {
      //     return 'custom-non-business'
      //   }
      // }
      return ''
    },
    convertToTime(timeString) {
      const [hours, minutes] = timeString.split(':').map(Number)
      const date = new Date()
      date.setHours(hours, minutes, 0, 0) // Set hours, minutes, seconds, and milliseconds
      return date
    },
    renderIcon: function (eventInfo) {
      const { event } = eventInfo || {}
      const { id } = event || {}
      const found = this.innerEvents.find((i) => i.id === id)
      if (found) {
        if (found.quoteId) {
          return 'mdi-car'
        }
      }
      return 'mdi-clock'
    },

    renderTooltip: function (eventInfo) {
      const { event } = eventInfo || {}
      const { extendedProps } = event || {}
      const { quoteId, claimNo, vehicleMake, vehicleModel } = extendedProps || {}
      const type = quoteId ? 'Quote' : 'Booking'
      const arr = [type, claimNo, vehicleMake, vehicleModel]
      return arr.filter((str) => !!str).join(' | ')
    },

    async getBusinessHours() {
      // console.log('BookingCalendar getBusinessHours')
      const hours = await CompanyService.getBusinessHours()

      const transformedHours = this.transformBusinessHours(hours)
      // this.$refs.calendar.updateBusinessHours(transformedHours)
      this.businessHours = transformedHours
      // console.log(this.$refs.calendar.innerCalendarOptions.businessHours)
    },

    transformBusinessHours(hours) {
      const items = []

      hours.forEach((item) => {
        const { weekDay, openTime, closeTime, productionSlot } = item
        const found = items.find((i) => i.daysOfWeek.includes(weekDay))

        const _openTime = this.$filters.formatISOToString(openTime, null, 'HH:mm')
        const _closeTime = this.$filters.formatISOToString(closeTime, null, 'HH:mm')

        if (found) {
          found.startTime = _openTime
          found.endTime = _closeTime
        } else {
          items.push({
            daysOfWeek: [weekDay],
            startTime: _openTime, // HH:mm with timezone
            endTime: _closeTime, // HH:mm with timezone
            productionSlot: productionSlot
          })
        }
      })
      return items
    },

    async getOverrideBusinessHours(startDate, endDate) {
      // console.log('BookingCalendar getOverrideBusinessHours')
      const hours = await CompanyService.getOverrideBusinessHoursInRange(startDate, endDate) //UTC (openTime and closeTime)
      const transformedHours = this.transformOverrideBusinessHours(hours)
      //this.$refs.calendar.updateOverrideBusinessHours(this.overrideBusinessHours)
      this.overrideBusinessHours = transformedHours
    },

    transformOverrideBusinessHours(hours) {
      const items = []
      hours.forEach((item) => {
        const { date, openTime, closeTime, productionSlot, isBusinessDay } = item
        const _openTime = openTime ? this.$filters.formatISOToString(openTime, null, 'HH:mm') : openTime
        const _closeTime = openTime ? this.$filters.formatISOToString(closeTime, null, 'HH:mm') : closeTime
        items.push({
          date: date,
          startTime: _openTime, // HH:mm with timezone
          endTime: _closeTime, // HH:mm with timezone
          productionSlot: productionSlot,
          isBusinessDay: isBusinessDay
        })
      })
      return items
    },

    handleToggleWeekend(isShow) {
      this.isShowWeekend = isShow
    },

    handleSidePanelToggle() {
      this.isSidePanelOpen = !this.isSidePanelOpen
      setTimeout(() => this.$refs.calendar.resize(), 500)
    },

    async handleFetchData(data) {
      console.log('BookingCalendar handleFetchData')
      const { startDate, endDate } = data
      this.startDate = startDate
      this.endDate = endDate

      await this.getOverrideBusinessHours(startDate, endDate)
      await this.getBusinessHours()
      await this.refresh()

      this.$refs.calendar.refresh()
      //this.injectAvailableSlots()
    },
    async refresh() {
      const utcStartDate = this.$refs.calendar.subtractOffset(this.startDate)
      const utcEndDate = this.$refs.calendar.subtractOffset(this.endDate)
      const events = await BookingService.getEvents(utcStartDate, utcEndDate)
      this.innerEvents = this.convertToFullCalendarEvent(events)
      this.$refs.bookingCalendarSidePanel.refresh()
      // this.getSlotMinMaxTime()
      // this.injectShrinkTimeSlot()
      this.injectAvailableSlots()
      this.injectDayNotAvailable()
    },
    injectShrinkTimeSlot() {
      this.$nextTick(() => {
        const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
        const currentView = calendarApi.view
        if (currentView.type === 'timeGridDay') {
          const dayCols = document.querySelectorAll('.fc-day')
          dayCols.forEach((dayCol) => {
            const date = new Date(dayCol.dataset.date)
            const avaiableSlots = this.getAvaiableSlots(date)
            if (avaiableSlots.length) {
              const minDate = DateTime.min(...avaiableSlots)
              const maxDate = DateTime.max(...avaiableSlots)

              const slotLanes = document.querySelectorAll('.fc-timegrid-slot-lane') //daily
              slotLanes.forEach((slotLane) => {
                const [hour, minute, second] = slotLane.dataset.time.split(':').map(Number)
                const luxonDate = DateTime.fromObject(
                  { year: date.year, month: date.month, day: date.day, hour: hour, minute: minute, second: second },
                  { zone: 'utc' }
                )
                if (luxonDate < minDate || luxonDate > maxDate) {
                  const parentTR = slotLane.closest('tr')
                  if (parentTR) {
                    parentTR.classList.add('is-hidden')
                  }
                }
              })
            }
          })
        }
      })
    },
    injectAvailableSlots() {
      this.$nextTick(() => {
        const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
        const currentView = calendarApi.view
        if (currentView.type === 'dayGridMonth') {
          const allDayCells = document.querySelectorAll('.fc-daygrid-day') //monthly
          allDayCells.forEach((dayCell) => {
            const date = new Date(dayCell.dataset.date)
            this.handleMonthDayCellDidMount({ date, el: dayCell })
          })
        } else if (currentView.type === 'timeGridWeek') {
          const allDays = document.querySelectorAll('.fc-col-header-cell') //weekly
          allDays.forEach((dayCell) => {
            const date = new Date(dayCell.dataset.date)
            this.handleDayCellDidMount({ date, el: dayCell })
          })
        } else if (currentView.type === 'timeGridDay') {
          const allDays = document.querySelectorAll('.fc-col-header-cell') //daily
          allDays.forEach((dayCell) => {
            const date = new Date(dayCell.dataset.date)
            this.handleDayCellDidMount({ date, el: dayCell })
          })
        }
      })
    },
    injectDayNotAvailable() {
      const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
      const currentView = calendarApi.view
      if (currentView.type === 'dayGridMonth') {
        this.injectDayNotAvailableMonthly()
      } else if (currentView.type === 'timeGridWeek') {
        this.injectDayNotAvailableTimeGrid()
      } else if (currentView.type === 'timeGridDay') {
        this.injectDayNotAvailableTimeGrid()
      }
    },
    injectDayNotAvailableMonthly() {
      this.$nextTick(() => {
        const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
        const currentView = calendarApi.view
        if (currentView.type === 'dayGridMonth') {
          const allDayCells = document.querySelectorAll('.fc-daygrid-day') //monthly
          allDayCells.forEach((dayCell) => {
            const date = new Date(dayCell.dataset.date)
            const luxonDate = DateTime.fromJSDate(date).toUTC()
            this.removeCustomNotAvailableDiv(dayCell)
            const dayFrame = dayCell.querySelector('.fc-daygrid-day-frame')
            if (!dayFrame) return
            let isBusinessDay = true
            if (this.hasOverrideBusinessHour(luxonDate)) {
              for (let overrideHour of this.overrideBusinessHours) {
                let lxDate = DateTime.fromISO(overrideHour.date).toUTC()
                if (luxonDate.equals(lxDate) && !overrideHour.isBusinessDay) {
                  isBusinessDay = false
                  break
                }
              }
              // this.overrideBusinessHours.forEach(({ date, isBusinessDay }) => {
              //   let lxDate = DateTime.fromISO(date).toUTC()
              //   if (luxonDate.equals(lxDate) && !isBusinessDay) {
              //     isBusinessDay = false
              //   }
              // })
            } else {
              if (this.businessHours) {
                isBusinessDay = this.businessHours.some((i) => i.daysOfWeek == luxonDate.weekday)
              } else {
                isBusinessDay = false
              }
            }
            if (!isBusinessDay) {
              const customNonBusinessDiv = document.createElement('div')
              customNonBusinessDiv.className = 'fc-non-business custom-day-not-available'
              dayFrame.appendChild(customNonBusinessDiv)
            }
          })
        }
      })
    },
    injectDayNotAvailableWeekly() {
      this.$nextTick(() => {
        const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
        const currentView = calendarApi.view
        if (currentView.type === 'timeGridWeek') {
          const slots = []
          const slotLanes = document.querySelectorAll('.fc-timegrid-slot-lane') //weekly
          const height = 25 //px
          let startPoint = 0 - height
          slotLanes.forEach((slotLane) => {
            const [hour, minute, second] = slotLane.dataset.time.split(':').map(Number)
            const luxonDate = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: hour, minute: minute, second: second }, { zone: 'utc' })
            // const luxonDate = DateTime.fromJSDate(date).toUTC()
            startPoint += height
            slots.push({ time: luxonDate, startPoint: startPoint, height })
          })

          const dayCols = document.querySelectorAll('.fc-day') //weekly
          dayCols.forEach((dayCol) => {
            const date = new Date(dayCol.dataset.date)
            const luxonDate = DateTime.fromJSDate(date).toUTC()
            if (this.hasOverrideBusinessHour(luxonDate)) {
              const colBg = dayCol.querySelector('.fc-timegrid-col-bg')
              if (!colBg) return
              this.removeCustomNotAvailableDayTimeSlotDiv(dayCol)
              this.overrideBusinessHours.forEach(({ date, isBusinessDay, startTime, endTime }) => {
                slots.forEach((slot) => {
                  let lxDate = DateTime.fromISO(date).toUTC()
                  if (luxonDate.equals(lxDate) && isBusinessDay && startTime && endTime) {
                    const [startHour, startMinute] = startTime.split(':').map(Number)
                    const [endHour, endMinute] = endTime.split(':').map(Number)
                    const luxonStartTime = DateTime.fromObject(
                      { year: 1970, month: 1, day: 1, hour: startHour, minute: startMinute, second: 0 },
                      { zone: 'utc' }
                    )
                    const luxonEndTime = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: endHour, minute: endMinute, second: 0 }, { zone: 'utc' })
                    if (slot.time >= luxonStartTime && slot.time < luxonEndTime) {
                      const div = document.createElement('div')
                      div.className = 'fc-timegrid-bg-harness'
                      div.setAttribute('style', `top: ${slot.startPoint}px; bottom: ${-(slot.startPoint + height)}px;`)
                      const nonBusinessDiv = document.createElement('div')
                      nonBusinessDiv.className = 'fc-non-business has-text-grey-light is-size-7 custom-day-time-slot-not-available'
                      nonBusinessDiv.textContent = 'N/A'
                      div.appendChild(nonBusinessDiv)
                      colBg.appendChild(div)
                    }
                  }
                })
              })
            }
          })
        }
      })
    },
    injectDayNotAvailableTimeGrid() {
      //daily, weekly
      this.$nextTick(() => {
        const calendarApi = this.$refs.calendar.getFullCalendar().getApi()
        const currentView = calendarApi.view
        if (currentView.type === 'timeGridDay' || currentView.type === 'timeGridWeek') {
          const slots = []
          const slotLanes = document.querySelectorAll('.fc-timegrid-slot-lane') //daily
          const height = 25 //px
          let startPoint = 0 - height
          slotLanes.forEach((slotLane) => {
            const [hour, minute, second] = slotLane.dataset.time.split(':').map(Number)
            const luxonDate = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: hour, minute: minute, second: second }, { zone: 'utc' })
            // const luxonDate = DateTime.fromJSDate(date).toUTC()
            startPoint += height
            slots.push({ slotLane, time: luxonDate, startPoint, height })
          })

          const dayCols = document.querySelectorAll('.fc-day')
          dayCols.forEach((dayCol) => {
            const date = new Date(dayCol.dataset.date)
            //const luxonDate = DateTime.fromJSDate(date).toUTC()
            this.removeCustomNotAvailableDayTimeSlotDiv(dayCol)
            const colBg = dayCol.querySelector('.fc-timegrid-col-bg')
            if (!colBg) return
            const avaiableSlots = this.getAvaiableSlots(date)

            // const [hours, minutes, seconds] = this.innerCalendarOptions.defaultTimedEventDuration.split(':').map(Number)
            // const stepDuration = Duration.fromObject({ hours: hours, minutes: minutes, seconds: seconds })

            let cnt = 0
            let newStartPoint = null
            let accumulatedHeight = 0
            const isOffDay = avaiableSlots.length === 0
            slots.forEach((slot, index) => {
              const last = index === slots.length - 1
              const isAvailable = avaiableSlots.some((dt) => dt.hour === slot.time.hour && dt.minute === slot.time.minute)
              if (!isAvailable || isOffDay) {
                // const div = document.createElement('div')
                // div.className = 'has-text-centered day-na'
                // div.innerHTML = '<span class="has-text-grey-light is-size-7">N/A</span>'
                // slotLane.appendChild(div)
                if (cnt === 0) newStartPoint = slot.startPoint

                const isBreakOff = cnt > 0 && slot.startPoint !== newStartPoint + accumulatedHeight
                if (isBreakOff) {
                  const divBgGrey = document.createElement('div')
                  divBgGrey.className = 'fc-timegrid-bg-harness'
                  divBgGrey.setAttribute('style', `top: ${newStartPoint}px; bottom: ${-(newStartPoint + accumulatedHeight)}px;`)
                  const nonBusinessDiv = document.createElement('div')
                  nonBusinessDiv.className = 'fc-non-business has-text-grey-light is-size-7 custom-day-time-slot-not-available'
                  nonBusinessDiv.textContent = 'N/A'
                  divBgGrey.appendChild(nonBusinessDiv)
                  colBg.appendChild(divBgGrey)
                  newStartPoint = slot.startPoint
                  accumulatedHeight = slot.height
                } else {
                  accumulatedHeight += slot.height
                }
                cnt++
              }
              if (last) {
                if (newStartPoint != null && accumulatedHeight > 0) {
                  const divBgGrey = document.createElement('div')
                  divBgGrey.className = 'fc-timegrid-bg-harness'
                  divBgGrey.setAttribute('style', `top: ${newStartPoint}px; bottom: ${-(newStartPoint + accumulatedHeight)}px;`)
                  const nonBusinessDiv = document.createElement('div')
                  nonBusinessDiv.className = 'fc-non-business has-text-grey-light is-size-7 custom-day-time-slot-not-available'
                  nonBusinessDiv.textContent = 'N/A'
                  divBgGrey.appendChild(nonBusinessDiv)
                  colBg.appendChild(divBgGrey)
                }
              }
            })
          })
        }
      })
    },
    getAvaiableSlots(date) {
      let luxonDate = DateTime.fromJSDate(date).toUTC()
      const hasOverride = this.hasOverrideBusinessHour(luxonDate)
      const [hours, minutes, seconds] = this.innerCalendarOptions.defaultTimedEventDuration.split(':').map(Number)
      const stepDuration = Duration.fromObject({ hours: hours, minutes: minutes, seconds: seconds })
      const slots = []
      if (hasOverride) {
        if (this.overrideBusinessHours) {
          this.overrideBusinessHours.forEach(({ date, isBusinessDay, startTime, endTime }) => {
            if (date) {
              let lxDate = DateTime.fromISO(date).toUTC()
              if (luxonDate.equals(lxDate)) {
                if (!isBusinessDay) {
                  return []
                } else {
                  const [startHour, startMinute] = startTime.split(':')
                  const [endHour, endMinute] = endTime.split(':')
                  const minTime = luxonDate.set({ hour: parseInt(startHour, 10), minute: parseInt(startMinute, 10) })
                  const maxTime = luxonDate.set({ hour: parseInt(endHour, 10), minute: parseInt(endMinute, 10) })

                  let current = minTime
                  while (current < maxTime) {
                    const isExist = slots.some((dt) => dt.toMillis() === current.toMillis())
                    if (!isExist) {
                      slots.push(current)
                    }
                    current = current.plus(stepDuration)
                  }
                }
              }
            }
          })
        }
      } else {
        if (this.businessHours) {
          let businessHour = this.businessHours.find((i) => i.daysOfWeek == luxonDate.weekday)
          if (businessHour) {
            const { startTime, endTime } = businessHour
            const [startHour, startMinute] = startTime.split(':')
            const [endHour, endMinute] = endTime.split(':')
            const minTime = luxonDate.set({ hour: parseInt(startHour, 10), minute: parseInt(startMinute, 10) })
            const maxTime = luxonDate.set({ hour: parseInt(endHour, 10), minute: parseInt(endMinute, 10) })

            let current = minTime
            while (current < maxTime) {
              const isExist = slots.some((dt) => dt.toMillis() === current.toMillis())
              if (!isExist) {
                slots.push(current)
              }
              current = current.plus(stepDuration)
            }
          }
        }
      }
      const sortedSlots = slots.sort((a, b) => a.ts - b.ts) //asc
      return sortedSlots
    },
    handleDayCellDidMount({ date, el }) {
      const elm = el.querySelector('.fc-scrollgrid-sync-inner')
      this.removeCustomAvailableSlotDiv(el)
      if (elm) {
        const newDiv = document.createElement('span')
        newDiv.className = 'custom-available-slot'
        newDiv.textContent = this.getAvailableSlotsText(el, date)
        elm.appendChild(newDiv)
      }
    },
    handleMonthDayCellDidMount({ date, el }) {
      const elm = el.querySelector('.fc-daygrid-day-top')
      this.removeCustomAvailableSlotDiv(el)
      if (elm) {
        const newDiv = document.createElement('div')
        newDiv.className = 'custom-available-slot'
        newDiv.textContent = this.getAvailableSlotsText(el, date)
        elm.appendChild(newDiv)
      }
    },
    getAvailableSlotsText(el, date) {
      let luxonDate = DateTime.fromJSDate(date).toUTC()
      let availableSlots = 0
      if (this.businessHours) {
        let businessHour = this.businessHours.find((i) => i.daysOfWeek == luxonDate.weekday)
        if (businessHour) availableSlots = businessHour.productionSlot
      }
      let overrideSlots = 0
      if (this.overrideBusinessHours) {
        let isNotBusinessDay = false
        this.overrideBusinessHours.forEach(({ date, isBusinessDay, productionSlot }) => {
          if (date) {
            let lxDate = DateTime.fromISO(date).toUTC()
            if (luxonDate.equals(lxDate)) {
              if (isBusinessDay) {
                overrideSlots += productionSlot
                this.removeCustomNonBusinessDiv(el)
              } else {
                isNotBusinessDay = true
              }
            }
          }
        })
        if (isNotBusinessDay) {
          return 'N/A'
        }
      }
      if (overrideSlots) {
        availableSlots = overrideSlots
      }
      let matchEvents = []
      if (this.innerEvents) {
        matchEvents = this.innerEvents.filter(({ start }) => {
          if (start) {
            let lxDate = DateTime.fromISO(start).toUTC()
            return luxonDate.hasSame(lxDate, 'day')
          }
          return false
        })
      }
      if (availableSlots == 0 && matchEvents.length == 0) return 'N/A'
      return `${availableSlots - matchEvents.length}/${availableSlots}`
    },
    removeCustomNonBusinessDiv(el) {
      const customs = el.querySelectorAll('.fc-non-business')
      customs.forEach((element) => {
        element.remove()
      })
    },
    removeCustomAvailableSlotDiv(el) {
      const customs = el.querySelectorAll('.custom-available-slot')
      customs.forEach((element) => {
        element.remove()
      })
    },
    removeCustomNotAvailableDiv(el) {
      const customs = el.querySelectorAll('.custom-day-not-available')
      customs.forEach((element) => {
        element.remove()
      })
    },
    removeCustomNotAvailableDayTimeSlotDiv(el) {
      const customs = el.querySelectorAll('.custom-day-time-slot-not-available')
      customs.forEach((element) => {
        element.remove()
      })
    },
    convertToFullCalendarEvent(events) {
      //let aa = DateTime.fromISO(events[0].bookingStartDateTime, { zone: 'local' }).toString()
      const customEvents = events.map((event) => {
        const isQuote = !!event.quoteId
        const isJobCompleted = !!event.isJobCompleted
        return {
          id: event.quoteBookingId,
          title: `${event.vehicleRegistrationNo}`,
          start: this.addOffset(event.bookingStartDateTime),
          end: this.addOffset(event.bookingEndDateTime),
          classNames: [isQuote ? (isJobCompleted ? 'custom-event-is-quote-completed' : 'custom-event-is-quote') : 'custom-event-is-booking'],
          ...event
        }
      })
      return customEvents
    },

    async handleEventClick(info) {
      this.handleSimpleEdit(info.event.id)
    },

    handleDateSelect(info) {
      this.handleNew(info)
    },

    async addNew() {
      this.addStoreItem()
    },
    handleSimpleEdit(bookingId) {
      this.handleQuoteClick(bookingId)
      return
      this.selectedEditBookingId = bookingId
      this.isNewSimpleBooking = false
      this.isSimpleBookingModelActive = true
    },
    handleSimpleBookingModalClosed() {
      this.selectedEditBookingId = null
      this.isSimpleBookingModelActive = false
      this.isNewSimpleBooking = false
    },
    async handleNew(info) {
      this.handleQuoteClick(null)
      return
      if (info && info.start) {
        const isoDate = DateTime.fromJSDate(info.start).toISODate()
        const timeSlot = await BookingService.getStartTimeSlot(isoDate)
        this.selectedBookingStartDate = timeSlot
      }
      this.selectedEditBookingId = null
      this.isNewSimpleBooking = true
      this.isSimpleBookingModelActive = true
    },
    handleSimpleBookingSave() {
      this.refresh()
    },
    async handleDrop(event) {
      console.log('BookingCalendar handleDrop')
      // start: date,
      // end: this.addTimeToDate(date, this.eventDuration)
      // id: jsonObj.uniqueId, //createEventId(),
      const { start, end, id } = event
      // this.$showSpinner('Saving...')
      // const bookingItem = { bookingStartDateTime: start, bookingEndDateTime: end, quoteBookingId: id }
      // console.log(bookingItem)
      // await BookingService.putBookingDate(bookingItem)
      // this.$hideSpinner()
      console.log(event)
      // this.$refs.bookingCalendarSidePanel.refresh()
      const isSuccess = await this.updateBookingStartEndDate(id, start, end)
      if (isSuccess) {
        await this.refresh()
      } else {
        dropInfo.revert()
      }
    },
    async handleEventResize(info) {
      info.revert()
      // const { start, end, id, extendedProps } = info.event
      // const { quoteId } = extendedProps || {}
      // if (quoteId) {
      //   info.revert()
      // } else {
      //   const isSuccess = await this.updateBookingStartEndDate(id, start, end)
      //   if (!isSuccess) info.revert()
      // }
    },
    async handleEventDrop(info) {
      console.log('BookingCalendar handleEventDrop')
      const { start, end, id, extendedProps } = info.event
      const { quoteId } = extendedProps
      if (quoteId) {
        info.revert()
        this.$toast.open({
          message: 'Booking time slots with quote can\'t be changed.',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        // this.$notification.error('Quote', 'Booking time slots with quote can\'t be changed.')
      } else {
        const isSuccess = await this.updateBookingStartEndDate(id, start, end)
        if (isSuccess) {
        }
        //this.refresh()
        // if (!isSuccess) {
        //   info.revert()
        // }
      }
    },
    async updateBookingStartEndDate(id, start, end) {
      this.$showSpinner('Saving...')
      const utcStart = this.$refs.calendar.subtractOffset(start.toISOString())
      const utcEnd = this.$refs.calendar.subtractOffset(end.toISOString())
      const bookingItem = { bookingStartDateTime: utcStart, bookingEndDateTime: utcEnd, quoteBookingId: id }
      const response = await BookingService.updateBookingDate(bookingItem)
      const { isSuccess, errors } = response
      if (!isSuccess) {
        if (isSuccess == false && errors == null) {
          this.$toast.open({
            message: 'No changes. Not saved',
            type: 'is-warning',
            position: 'is-top',
            queue: false
          })
          // this.$notification.openNotificationWithType('warning', this.title, 'No changes. Not saved')
        } else if (errors) {
          this.$toast.open({
            message: `Save failed.\n ${formatBulletString(errors)}`,
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
          // this.$notification.openNotificationWithType('danger', this.title, `Save failed.\n ${formatBulletString(errors)}`)
        }
      }
      if (isSuccess) {
        this.$refs.bookingCalendarSidePanel.remove(id)
      }
      this.$hideSpinner()
      return isSuccess
    },
    handleEventReceive(info) {
      console.log('BookingCalendar handleEventReceive')
    },

    addOffset(dateStr) {
      const date = new Date(dateStr)
      const adjustedDate = this.$refs.calendar.addOffset(date.toISOString())
      return adjustedDate
    },
    handleQuoteClick(bookingId) {
      if (!bookingId) {
        this.addStoreItem()
      } else {
        this.$router.push({
          path: BookingRoutes.BookingView.path.replace(':bookingId', bookingId)
        })
      }
    },
    renderTimeText(eventInfo) {
      const timeText = eventInfo.timeText
      var startTime = timeText
      if (timeText.includes('-')) startTime = timeText.split('-')[0].trim()
      startTime = startTime.replace(/ /g, '')
      return startTime
    },
    dateDiffInDay(date1, date2) {
      const dt1 = new Date(date1)
      const dt2 = new Date(date2)
      const diffInMs = dt2 - dt1
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
      return diffInDays
    },
    isSameDate(date1, date2) {
      const d1 = new Date(date1)
      const d2 = new Date(date2)
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-container {
  // margin: 20px 20px 0px 20px;
  background: #f8f9fa;
  width: 80%;
  // height: 97%;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;

  // when side panel is closed
  &.is-full-width {
    width: 100% !important;
  }
}

.page-body {
  height: 100%;
  background: #f8f9fa;
  position: relative;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 0.6em;
}

.thin-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  position: relative;
}

.thin-scrollbar::-webkit-scrollbar-track::before,
.thin-scrollbar::-webkit-scrollbar-track::after {
  content: '▲';
  position: absolute;
  width: 100%;
  text-align: center;
}

.thin-scrollbar::-webkit-scrollbar-track::before {
  top: 0;
}

.thin-scrollbar::-webkit-scrollbar-track::after {
  content: '▼';
  bottom: 0;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #7e7e7e;
  color: #7e7e7e;
}

.booking-panel {
  width: 20%;
  height: 100%;
  padding: 1em;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  // transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  // &.closed {
  //   transform: translateX(100%) !important;
  // }
  // &.open {
  //   transform: translateX(0%) !important;
  // }
}

// .slide-fade-leave-active {
//   transition: all 0.5s ease-in-out;
// }
</style>

