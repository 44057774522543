import FqApi from '@/services/fq-api'

export default {
  async getCapacityDueInOut(isDueOut, dateFrom, dateTo, sortColumn, sortOrder) {
    const result = await FqApi.get('/capacity/getDueInOutCapacity' + '?isDueOut=' + isDueOut + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&sortColumn=' + sortColumn + '&sortOrder=' + sortOrder)
    return result.data
  },
  async getBookings() {
    const result = await FqApi.get('/booking/getBookings')
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/booking/new')
    return result.data
  },
  async getEntity(id) {
    const result = await FqApi.get(`/booking/${id}`)
    return result.data
  },
  async getBookingList(filter) {
    let url = '/booking/list/'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  async getInspectionBooking(filter) {
    let url = '/repx/inspection/'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  putBookingItem(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/booking/${entity.quoteBookingId}`, entity)
  },
  async updateBookingDate(entity) {
    const result = await FqApi.put(`/booking/updateBookingDate/${entity.quoteBookingId}`, entity)
    return result.data
  },
  async postBookingItem(entity) {
    return FqApi.post('/booking', entity)
  },
  putConvertBookingToQuote(entity) {
    return FqApi.put(`/booking/convert/${entity.quoteBookingId}`, entity)
  },
  async getEvents(startDate, endDate) {
    let url = '/booking/getEvents/'
    const result = await FqApi.post(url, { startDate, endDate })
    return result.data
  },
  async deleteBooking(entity) {
    return FqApi.delete(`/booking/deleteBooking/${entity.quoteBookingId}`)
  },
  async checkAvailableTimeSlot(allDay, date, start, end, id) {
    const result = await FqApi.post('/booking/checkAvailableTimeSlot', { allDay, date, start, end, quoteBookingId: id })
    return result.data
  },
  async getStartTimeSlot(date) {
    const result = await FqApi.get(`/booking/getStartTimeSlot/${date}`)
    return result.data
  },
  async getAllocationBooking(filter) {
    let url = '/repx/allocation/'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  async hasBooking() {
    const result = await FqApi.get('/booking/hasBooking')
    return result.data
  }
}