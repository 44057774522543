<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <div class="column"
          align="center">
          <div class="map-container"
            :style="'width: 300px; height: 500px; background-image: url(\'' + require('../../assets/images/butterfly.jpg') + '\')'">
            <canvas id="canvas"
              width="500"
              height="500"
              style="left: 0px; top: 0px; padding: 0px; border: none; opacity: 1;" />
            <img id="base"
              width="300"
              height="500"
              style="border:1px solid #FF00FF; border-style: none; border-color: inherit; border-width: 0px; opacity: 0;
                  position: absolute; left: 0px; top: -1px; padding: 0px;"
              class="image"
              :src="require('../../assets/images/butterfly.jpg')"
              usemap="#map">
          </div>
          <map id="map"
            name="map">
            <area id="0"
              alt="G"
              shape="poly"
              coords="131,456,167,456,167,440,127,440"
              @click="showddlG = !showddlG; areaClick(showddlG, 0)"
              @mouseover="mouseOver('ddlG', $event)">
            <area id="1"
              alt="FP"
              shape="poly"
              coords="195,476,103,476,95,468,199,468"
              @click="showddlFP = !showddlFP, areaClick(showddlFP,1)"
              @mouseover="mouseOver('ddlFP', $event)">
            <area id="2"
              alt="LFL"
              shape="poly"
              coords="171,452,175,440,195,440,195,452"
              @click="showddlLFL = !showddlLFL; areaClick(showddlLFL, 2)"
              @mouseover="mouseOver('ddlLFL', $event)">
            <area id="3"
              alt="RFL"
              shape="poly"
              coords="124,452,100,452,104,440,120,440"
              @click="showddlRFL = !showddlRFL; areaClick(showddlRFL, 3)"
              @mouseover="mouseOver('ddlRFL', $event)">
            <area id="4"
              alt="FB"
              shape="poly"
              coords="93,459,95,468,98,469,135,470,134,462,162,462,163,469,201,469,201,458"
              @click="showddlFB = !showddlFB; areaClick(showddlFB, 4)"
              @mouseover="mouseOver('ddlFB', $event)">
            <area id="5"
              alt="B"
              shape="poly"
              coords="107,308,151,312,191,308,191,364,191,368,163,372,131,372,111,368"
              @click="showddlB = !showddlB; areaClick(showddlB, 5)"
              @mouseover="mouseOver('ddlB', $event)">
            <area id="6"
              alt="BL"
              shape="poly"
              coords="112,144,188,144,188,112,112,112"
              @click="showddlBL = !showddlBL; areaClick(showddlBL, 6)"
              @mouseover="mouseOver('ddlBL', $event)">
            <area id="7"
              alt="FW"
              shape="poly"
              coords="152,403,184,403,192,431,148,431,104,431,112,403"
              @click="showddlFW = !showddlFW; areaClick(showddlFW, 7)"
              @mouseover="mouseOver('ddlFW', $event)">
            <area id="8"
              alt="LCP"
              shape="poly"
              coords="211,227,267,227, 267, 237, 211,237"
              @click="showddlLCP = !showddlLCP; areaClick(showddlLCP, 8)"
              @mouseover="mouseOver('ddlLCP', $event)">
            <area id="9"
              alt="LFD"
              shape="poly"
              coords="267,239,267,299,259,299,235,291,211,263,211,239"
              @click="showddlLFD = !showddlLFD; areaClick(showddlLFD, 9)"
              @mouseover="mouseOver('ddlLFD', $event)">
            <area id="10"
              alt="LFG"
              shape="poly"
              coords="234,290,235,326,240,376,245,381,244,371,259,370,258,354,253,347,250,335,251,325,253,317,258,311,264,306,276,302,262,298,262,300,257,300"
              @click="showddlLFG = !showddlLFG; areaClick(showddlLFG, 10)"
              @mouseover="mouseOver('ddlLFG', $event)">
            <area id="11"
              alt="LFH"
              shape="poly"
              coords="211,263,207,271,223,295,235,311,235,291"
              @click="showddlLFH = !showddlLFH; areaClick(showddlLFH, 11)"
              @mouseover="mouseOver('ddlLFH', $event)">
            <area id="12"
              alt="LQP"
              shape="poly"
              coords="235,171,251,171,251,155,259,143,259,107,255,111,255,119,243,123,239,123,239,115,235,115,235,163"
              @click="showddlLQP = !showddlLQP; areaClick(showddlLQP, 12)"
              @mouseover="mouseOver('ddlLQP', $event)">
            <area id="13"
              alt="LRD"
              shape="poly"
              coords="211,227,211,191,219,179,235,171,251,171,255,183,267,191,267,227"
              @click="showddlLRD = !showddlLRD; areaClick(showddlLRD, 13)"
              @mouseover="mouseOver('ddlLRD', $event)">
            <area id="14"
              alt="LSP"
              shape="poly"
              coords="275,195,275,299,267,299,267,191"
              @click="showddlLSP = !showddlLSP; areaClick(showddlLSP, 14)"
              @mouseover="mouseOver('ddlLSP', $event)">
            <area id="15"
              alt="RB"
              shape="poly"
              coords="95,40,203,40, 203, 30, 95, 30"
              @click="showddlRB = !showddlRB; areaClick(showddlRB, 15)"
              @mouseover="mouseOver('ddlRB', $event)">
            <area id="16"
              alt="RCP"
              shape="poly"
              coords="31,228,87,228, 87, 238, 31, 238"
              @click="showddlRCP = !showddlRCP; areaClick(showddlRCP, 16)"
              @mouseover="mouseOver('ddlRCP', $event)">
            <area id="17"
              alt="RFD"
              shape="poly"
              coords="87,264,67,288,47,296,31,300,31,236,87,236"
              @click="showddlRFD = !showddlRFD; areaClick(showddlRFD, 17)"
              @mouseover="mouseOver('ddlRFD', $event)">
            <area id="18"
              alt="RFG"
              shape="poly"
              coords="40,371,56,367,56,379,60,379,64,351,64,319,64,291,44,299,24,299,24,303,36,307,44,315,52,331,48,343,40,351"
              @click="showddlRFG = !showddlRFG; areaClick(showddlRFG, 18)"
              @mouseover="mouseOver('ddlRFG', $event)">
            <area id="19"
              alt="RFH"
              shape="poly"
              coords="66,289,66,309,94,270,88,264"
              @click="showddlRFH = !showddlRFH; areaClick(showddlRFH, 19)"
              @mouseover="mouseOver('ddlRFH', $event)">
            <area id="20"
              alt="LRL"
              shape="poly"
              coords="179,48,179,58,202,58,201,48"
              @click="showddlLRL = !showddlLRL; areaClick(showddlLRL, 20)"
              @mouseover="mouseOver('ddlLRL', $event)">
            <area id="21"
              alt="RRL"
              shape="poly"
              coords="94,48,95,57,117,57,117,48"
              @click="showddlRRL = !showddlRRL; areaClick(showddlRRL, 21)"
              @mouseover="mouseOver('ddlRRL', $event)">
            <area id="22"
              alt="RNP"
              shape="poly"
              coords="137,49,138,57,162,57,162,49"
              @click="showddlRNP = !showddlRNP; areaClick(showddlRNP, 22)"
              @mouseover="mouseOver('ddlRL', $event)">
            <area id="23"
              alt="BP"
              shape="poly"
              coords="95,41,94,47,116,47,117,58,97,58,99,65,196,66,199,58,178,57,178,47,201,45,201,43"
              @click="showddlBP = !showddlBP; areaClick(showddlBP, 23)"
              @mouseover="mouseOver('ddlBP', $event)">
            <area id="24"
              alt="RQP"
              shape="poly"
              coords="64,115,64,139,64,167,64,171,48,171,52,163,48,155,40,143,40,107,48,111,44,119,56,123,56,111"
              @click="showddlRQP = !showddlRQP; areaClick(showddlRQP, 24)"
              @mouseover="mouseOver('ddlRQP', $event)">
            <area id="25"
              alt="RRD"
              shape="poly"
              coords="32,227,88,227,88,191,80,179,64,171,48,171,48,179,44,183,32,191"
              @click="showddlRRD = !showddlRRD; areaClick(showddlRRD, 25)"
              @mouseover="mouseOver('ddlRRD', $event)">
            <area id="26"
              alt="RSP"
              shape="poly"
              coords="31,299,23,299,23,195,31,191"
              @click="showddlRSP = !showddlRSP; areaClick(showddlRSP, 26)"
              @mouseover="mouseOver('ddlRSP', $event)">
            <area id="27"
              alt="SP"
              shape="poly"
              coords="106,298,109,311,192,310,195,296,179,300,158,302,144,302"
              @click="showddlSP = !showddlSP; areaClick(showddlSP, 27)"
              @mouseover="mouseOver('ddlSP', $event)">
            <area id="28"
              alt="T"
              shape="poly"
              coords="119,263,179,263,183,259,183,187,151,183,115,187"
              @click="showddlT = !showddlT; areaClick(showddlT, 28)"
              @mouseover="mouseOver('ddlT', $event)">
            <area id="29"
              alt="TG"
              shape="poly"
              coords="115,179,107,159,111,155,147,151,191,155,191,167,183,179,191,179,199,171,199,119,187,115,187,143,111,143,111,115,103,119,103,171,107,179"
              @click="showddlTG = !showddlTG; areaClick(showddlTG, 29)"
              @mouseover="mouseOver('ddlTG', $event)">
            <area id="30"
              alt="RW"
              shape="poly"
              coords="107,71,115,91,119,95,151,95,179,95,183,91,191,71,187,71,147,71"
              @click="showddlRW = !showddlRW; areaClick(showddlRW, 30)"
              @mouseover="mouseOver('ddlRW', $event)">
          </map>
        </div>
      </div>
      <div v-if="showSelectedParts"
        class="tile is-parent is-half"
        style="padding: 0px">
        <article class="tile is-child box detail-page-tile"
          style="adding: 0px;">
          <div class="title is-5">Selected Parts</div>
          <div class="subtitle is-6">
            <input type="checkbox"
              class="is-checkradio"
              id="selectAll"
              v-model="selectAll">
            <label for="selectAll">Check/Uncheck All</label>
          </div>

          <div class="column is-narrow body-style-container"
            style="overflow-y: auto; height: 460px;">
            <ul v-for="(a, index) in selectedPartLists"
              :key="index">
              <li>
                <label class="title is-6">{{ a[0].partname }}</label>
              </li>
              <ul>
                <li v-for="partList in a"
                  :key="partList.partid">
                  <input type="checkbox"
                    class="is-checkradio"
                    :id="partList.partid"
                    :value="partList.partid"
                    v-model="selectedParts">
                  <label :for="partList.partid">{{ partList.partname }}</label>
                </li>
              </ul>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMapHighlighter from '@/components/image-map-highlighter/image-map-highlighter.js'
import EventBus from '@/components/EventBus'

export default {
  name: 'Butterfly',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    defaultParts: {
      type: Array,
      default: function () {
        return []
      }
    },
    showSelectedParts: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showGroup: [],
      selectedParts: [],
      selectedPartLists: [],
      showddlG: false,
      showddlFP: false,
      showddlLFL: false,
      showddlRFL: false,
      showddlFB: false,
      showddlB: false,
      showddlBL: false,
      showddlFW: false,
      showddlLCP: false,
      showddlLFD: false,
      showddlLFG: false,
      showddlLFH: false,
      showddlLQP: false,
      showddlLRD: false,
      showddlLSP: false,
      showddlRB: false,
      showddlRCP: false,
      showddlRFD: false,
      showddlRFG: false,
      showddlRFH: false,
      showddlLRL: false,
      showddlRRL: false,
      showddlRNP: false,
      showddlBP: false,
      showddlRQP: false,
      showddlRRD: false,
      showddlRSP: false,
      showddlSP: false,
      showddlT: false,
      showddlTG: false,
      showddlRW: false,
      partLists: [
        [
          { group: 'ddlG', partname: 'Grille', partid: 'G' },
          { group: 'ddlG', partname: 'Radiator', partid: 'RA' },
          { group: 'ddlG', partname: 'Radiator Support', partid: 'RS' }
        ],
        [{ group: 'ddlFP', partname: 'Front Apron', partid: 'FP' }],
        [{ group: 'ddlLFL', partname: 'Front Lamps (left)', partid: 'LFL' }],
        [{ group: 'ddlRFL', partname: 'Front Lamps (right)', partid: 'RFL' }],
        [{ group: 'ddlFB', partname: 'Front Bumper Bar', partid: 'FB' }],
        [
          { group: 'ddlB', partname: 'Bonnet', partid: 'B' },
          { group: 'ddlB', partname: 'Air Conditioning', partid: 'AC' },
          { group: 'ddlB', partname: 'Engine Bay Parts', partid: 'EBH' },
          { group: 'ddlB', partname: 'Front Chassis', partid: 'CF' },
          { group: 'ddlB', partname: 'Front Suspension, Steering & Wheels', partid: 'FM' },
          { group: 'ddlB', partname: 'Engine', partid: 'E' },
          { group: 'ddlB', partname: 'Decal', partid: 'OL' }
        ],
        [
          { group: 'ddlBL', partname: 'Boot Lid', partid: 'BL' },
          { group: 'ddlBL', partname: 'Exhaust', partid: 'EXH' },
          { group: 'ddlBL', partname: 'Boot Floor', partid: 'BF' },
          { group: 'ddlBL', partname: 'Rear Suspension, Tank & Wheels', partid: 'RM' }
        ],
        [
          { group: 'ddlFW', partname: 'Front Windscreen', partid: 'FW' },
          { group: 'ddlFW', partname: 'Wiper', partid: 'W' }
        ],
        [
          { group: 'ddlLCP', partname: 'Centre Pillar (left)', partid: 'LCP' },
          { group: 'ddlLCP', partname: 'Side Panel (left)', partid: 'LBP' },
          { group: 'ddlLCP', partname: 'Sliding Door (left)', partid: 'LSD' }
        ],
        [{ group: 'ddlLFD', partname: 'Front Door (left)', partid: 'LFD' }],
        [{ group: 'ddlLFG', partname: 'Front Guard (left)', partid: 'LFG' }],
        [{ group: 'ddlLFH', partname: 'Front Pillar (left)', partid: 'LFH' }],
        [{ group: 'ddlLQP', partname: 'Rear Qtr Panel (left)', partid: 'LQP' }],
        [{ group: 'ddlLRD', partname: 'Rear Door (left)', partid: 'LRD' }],
        [{ group: 'ddlLSP', partname: 'Sill Panel (left)', partid: 'LSP' }],
        [{ group: 'ddlRB', partname: 'Rear Bumper Bar', partid: 'RB' }],
        [
          { group: 'ddlRCP', partname: 'Centre Pillar (right)', partid: 'RCP' },
          { group: 'ddlRCP', partname: 'Side Panel (right)', partid: 'RBP' },
          { group: 'ddlRCP', partname: 'Sliding Door (right)', partid: 'RSD' }
        ],
        [{ group: 'ddlRFD', partname: 'Front Door (right)', partid: 'RFD' }],
        [{ group: 'ddlRFG', partname: 'Front Guard (right)', partid: 'RFG' }],
        [{ group: 'ddlRFH', partname: 'Front Pillar (right)', partid: 'RFH' }],
        [{ group: 'ddlLRL', partname: 'Rear Lamps (left)', partid: 'RL' }],
        [{ group: 'ddlRRL', partname: 'Rear Lamps (right)', partid: 'RL' }],
        [{ group: 'ddlRNP', partname: 'Rear Licence Plate', partid: 'RNP' }],
        [{ group: 'ddlBP', partname: 'Beaver Panel', partid: 'BP' }],
        [{ group: 'ddlRQP', partname: 'Rear Qtr Panel (right)', partid: 'RQP' }],
        [{ group: 'ddlRRD', partname: 'Rear Door (right)', partid: 'RRD' }],
        [{ group: 'ddlRSP', partname: 'Sill Panel (right)', partid: 'RSP' }],
        [
          { group: 'ddlSP', partname: 'Plenum & Firewall', partid: 'SP' },
          { group: 'ddlSP', partname: 'Dash & Glove Box', partid: 'D' }
        ],
        [
          { group: 'ddlT', partname: 'Turret', partid: 'T' },
          { group: 'ddlT', partname: 'Interior Trims', partid: 'I' },
          { group: 'ddlT', partname: 'Floor Panel', partid: 'FPAN' },
          { group: 'ddlT', partname: 'Chassis Assembly', partid: 'FC' },
          { group: 'ddlT', partname: 'Antenna', partid: 'AE' }
        ],
        [{ group: 'ddlTG', partname: 'Tailgate', partid: 'TG' }],
        [{ group: 'ddlRW', partname: 'Rear Windscreen', partid: 'RW' }]
      ]
    }
  },
  computed: {
    selectAll: {
      get: function (value) {
        var count = 0
        this.selectedPartLists.forEach(function (list) {
          count = count + list.length
        })
        return this.selectedParts ? count === this.selectedParts.length && this.selectedParts.length !== 0 : false
      },
      set: function (check) {
        var selected = []
        if (check) {
          this.selectedPartLists.forEach(function (list) {
            list.forEach(function (part) {
              selected.push(part.partid)
            })
          })
        }
        this.selectedParts = selected
      }
    }
  },
  watch: {
    selectedParts: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    EventBus.$on('resetEmpty', (data) => {
      this.handleBusEvent(data)
    })

    this.selectedParts = this.value
    var listFound = ''
    for (let i = 0; i < this.selectedParts.length; i++) {
      var added = false
      for (let j = 0; j < this.partLists.length; j++) {
        for (let k = 0; k < this.partLists[j].length; k++) {
          if (added) {
            break
          }
          if (this.selectedParts[i] === this.partLists[j][k].partid) {
            listFound = this.showGroup.find((i) => i === j)

            if (listFound === undefined) {
              this.selectedPartLists.push(this.partLists[j])
              this.showGroup.push(j)
            }
            added = true
            break
          }
        }
        if (added) {
          break
        }
      }
    }

    for (let l = 0; l < this.showGroup.length; l++) {
      switch (this.showGroup[l]) {
        case 0:
          this.showddlG = true
          break
        case 1:
          this.showddlFP = true
          break
        case 2:
          this.showddlLFL = true
          break
        case 3:
          this.showddlRFL = true
          break
        case 4:
          this.showddlFB = true
          break
        case 5:
          this.showddlB = true
          break
        case 6:
          this.showddlBL = true
          break
        case 7:
          this.showddlFW = true
          break
        case 8:
          this.showddlLCP = true
          break
        case 9:
          this.showddlLFD = true
          break
        case 10:
          this.showddlLFG = true
          break
        case 11:
          this.showddlLFH = true
          break
        case 12:
          this.showddlLQP = true
          break
        case 13:
          this.showddlLRD = true
          break
        case 14:
          this.showddlLSP = true
          break
        case 15:
          this.showddlRB = true
          break
        case 16:
          this.showddlRCP = true
          break
        case 17:
          this.showddlRFD = true
          break
        case 18:
          this.showddlRFG = true
          break
        case 19:
          this.showddlRFH = true
          break
        case 20:
          this.showddlLRL = true
          break
        case 21:
          this.showddlRRL = true
          break
        case 22:
          this.showddlRNP = true
          break
        case 23:
          this.showddlBP = true
          break
        case 24:
          this.showddlRQP = true
          break
        case 25:
          this.showddlRRD = true
          break
        case 26:
          this.showddlRSP = true
          break
        case 27:
          this.showddlSP = true
          break
        case 28:
          this.showddlT = true
          break
        case 29:
          this.showddlTG = true
          break
        case 30:
          this.showddlRW = true
          break
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.drawCanvas(this.showGroup)
    })
  },
  methods: {
    drawCanvas: function (showGroup) {
      var image = document.querySelector('.image')
      var highlighter = new ImageMapHighlighter(image, {
        fill: true,
        fillColor: '54C571',
        fillOpacity: 0.3,
        stroke: true,
        strokeColor: '54C571',
        strokeOpacity: 0,
        strokeWidth: 1,
        areas: showGroup
      })
      highlighter.init()
    },
    handleBusEvent: function (data) {
      this.selectedParts = []
      this.selectedPartLists = []
      this.showGroup = []
      this.showddlG = false
      this.showddlFP = false
      this.showddlLFL = false
      this.showddlRFL = false
      this.showddlFB = false
      this.showddlB = false
      this.showddlBL = false
      this.showddlFW = false
      this.showddlLCP = false
      this.showddlLFD = false
      this.showddlLFG = false
      this.showddlLFH = false
      this.showddlLQP = false
      this.showddlLRD = false
      this.showddlLSP = false
      this.showddlRB = false
      this.showddlRCP = false
      this.showddlRFD = false
      this.showddlRFG = false
      this.showddlRFH = false
      this.showddlLRL = false
      this.showddlRRL = false
      this.showddlRNP = false
      this.showddlBP = false
      this.showddlRQP = false
      this.showddlRRD = false
      this.showddlRSP = false
      this.showddlSP = false
      this.showddlT = false
      this.showddlTG = false
      this.showddlRW = false
      this.drawCanvas(this.showGroup)
    },
    mouseOver: function (group, e) {
      let target = e.target

      switch (group) {
        case 'ddlG':
          target.title = 'Grille\nRadiator\nRadiator Support'
          break
        case 'ddlFP':
          target.title = 'Front Apron'
          break
        case 'ddlLFL':
          target.title = 'Front Lamps (left)'
          break
        case 'ddlRFL':
          target.title = 'Front Lamps (right)'
          break
        case 'ddlFB':
          target.title = 'Front Bumper Bar'
          break
        case 'ddlB':
          target.title = 'Bonnet\nAir Conditioning\nEngine Bay Parts\nFront Chassis\nFront Suspension, Steering & Wheels\nEngine\nDecal'
          break
        case 'ddlBL':
          target.title = 'Boot Lid\nExhaust\nBoot Floor\nRear Suspension, Tank & Wheels'
          break
        case 'ddlFW':
          target.title = 'Front Windscreen\nWiper'
          break
        case 'ddlLCP':
          target.title = 'Centre Pillar (left)\nSide Panel (left)\nSliding Door (left)'
          break
        case 'ddlLFD':
          target.title = 'Front Door (left)'
          break
        case 'ddlLFG':
          target.title = 'Front Guard (left)'
          break
        case 'ddlLFH':
          target.title = 'Front Pillar (left)'
          break
        case 'ddlLQP':
          target.title = 'Rear Qtr Panel (left)'
          break
        case 'ddlLRD':
          target.title = 'Rear Door (left)'
          break
        case 'ddlLSP':
          target.title = 'Sill Panel (left)'
          break
        case 'ddlRB':
          target.title = 'Rear Bumper Bar'
          break
        case 'ddlRCP':
          target.title = 'Centre Pillar (right)\nSide Panel (right)\nSliding Door (right)'
          break
        case 'ddlRFD':
          target.title = 'Front Door (right)'
          break
        case 'ddlRFG':
          target.title = 'Front Guard (right)'
          break
        case 'ddlRFH':
          target.title = 'Front Pillar (right)'
          break
        case 'ddlLRL':
          target.title = 'Rear Lamps (left)'
          break
        case 'ddlRRL':
          target.title = 'Rear Lamps (right)'
          break
        case 'ddlRNP':
          target.title = 'Rear Licence Plate'
          break
        case 'ddlBP':
          target.title = 'Beaver Panel'
          break
        case 'ddlRQP':
          target.title = 'Rear Qtr Panel (right)'
          break
        case 'ddlRRD':
          target.title = 'Rear Door (right)'
          break
        case 'ddlRSP':
          target.title = 'Sill Panel (right)'
          break
        case 'ddlSP':
          target.title = 'Plenum & Firewall\nDash & Glove Box'
          break
        case 'ddlT':
          target.title = 'Turret\nInterior Trims\nFloor Panal\nChassis Assembly\nAntenna'
          break
        case 'ddlTG':
          target.title = 'Tailgate'
          break
        case 'ddlRW':
          target.title = 'Rear Windscreen'
          break
      }

      this.drawCanvas(this.showGroup)
    },
    areaClick: function (show, index) {
      if (show) {
        this.selectedPartLists.push(this.partLists[index])
        for (let i = 0; i < this.partLists[index].length; i++) {
          this.selectedParts.push(this.partLists[index][i].partid)
        }
        this.showGroup.push(index)
      } else {
        this.selectedPartLists.splice(this.selectedPartLists.indexOf(this.selectedPartLists.find((i) => i[0].group === this.partLists[index][0].group)), 1)

        for (let j = 0; j < this.partLists[index].length; j++) {
          var delIndex = this.selectedParts.indexOf(this.selectedParts.find((i) => i === this.partLists[index][j].partid))

          if (delIndex > -1) {
            this.selectedParts.splice(delIndex, 1)
          }
        }
        this.showGroup.splice(this.showGroup.indexOf(this.showGroup.find((i) => i === index)), 1)
      }

      this.drawCanvas(this.showGroup)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
