<template>
  <div v-if="value"
    class="tile is-parent "
    :class="{ 'box ml-2 mb-5': showBox }">
    <article class="tile is-child is-parent">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-shield-outline mdi-24px" />
          <p class="modal-card-title pl-1">Insurer</p>
        </div>
      </div>
      <div class="is-divider" />
      <div class="tile is-ancestor">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child detail-page-tile"
              :class="{'disabled' : (value.quoteId) }">
              <!-- <div class="field">
                <label class="label is-required-label">Booking Type</label>
                <div class="control is-extended">
                  <div class="select is-fullwidth">
                    <select v-model="value.bookingType">
                      <option value="null"
                        disabled>Select</option>
                      <option v-for="(s, index) in storeBookingTypes"
                        :key="index"
                        :value="s.id">
                        {{ s.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="field">
                <label class="label">E-Com Type</label>
                <div class="control select">
                  <select v-model="innerEcomType"
                    class="select-width">
                    <option value="all"
                      :selected="innerEcomType == 'all'">ALL</option>
                    <option :value="ecomTypeEnum.ORM">ORM</option>
                    <option :value="ecomTypeEnum.PNET">PNET</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label is-required-label">Insurer</label>
                <div class="control">
                  <multiselect
                    v-if="insurers"
                    :class="[ $v.entity.insurerId.$error ? 'is-danger' : '' ]"
                    v-model="selectedInsurer"
                    label="name"
                    placeholder="Search By Name..."
                    :options="insurers"
                    :loading="isInsurerLoading"
                    @select="selectInsurer"
                    @remove="removeInsurer"
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                  >
                    <template slot="option"
                      slot-scope="props">
                      <span>{{ props.option.name }}</span>
                    </template>
                    <span class="has-text-danger"
                      slot="noResult">Insurer not found.</span>
                  </multiselect>
                  <span class="help is-danger"
                    v-if="$v.entity && !$v.entity.insurerId.required">Insurer is required</span>
                </div>
              </div>
              <div class="field">
                <label class="label">Booking Start Date</label>
                <v-date-picker
                  v-model="innerBookingStartDateTime"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="30"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :class="{ 'disabled' : (value.quoteId) }"
                  :model-config="innerBookingStartDateTime ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()"
                  :min-date="minDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input
                          type="text"
                          class="input"
                          placeholder="Booking Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="isArriveForInspection"
                          readonly
                          ref="innerBookingStartDateTime"
                        >
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <div class="control"
                        v-if="!isArriveForInspection">
                        <a class="button tooltip"
                          data-tooltip="Reset dates"
                          @click="clearStartDate('innerBookingStartDateTime')">
                          <span class="icon">
                            <i class="mdi mdi-close mdi-24px" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="field">
                <label class="label">Claim No.</label>
                <div class="control">
                  <input v-model="value.claimNo"
                    class="input"
                    :class="{ 'is-danger': $v.entity.claimNo.$error }"
                    type="text">
                </div>
              </div>
              <div class="field">
                <label class="label">Excess</label>
                <div class="control">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="value.excess"
                      :min="0"
                      formats
                      :precision="2"
                      :symbol="$company.currency.symbol" />
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Is Third Party?</label>
                <div class="control">
                  <div class="control">
                    <bulma-checkbox v-model="value.isThirdParty" />
                  </div>
                </div>
              </div>
              <div class="columns field">
                <div class="column">
                  <div class="field">
                    <bulma-checkbox-date v-model="value.isTowed"
                      :label="'Is Towed?'"
                      :date="value.towedDate" />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// import QuickItemService from './QuickItemService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
import { mapGetters } from 'vuex'
import VueNumeric from '@/components/VueNumeric'
import BulmaCheckbox from '../../../../components/BulmaCheckbox/BulmaCheckbox.vue'
import BulmaCheckboxDate from '../../../../components/BulmaCheckbox/BulmaCheckboxDate.vue'
//  import { QuoteService } from '@/services'
// import _debounce from 'lodash.debounce'
// import _cloneDeep from 'lodash.clonedeep'
// import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import { EcomTypes } from '@/enums'
import LoadQuoteService from '../../../../components/LoadQuoteSteps/LoadQuoteService'

export default {
  name: 'BookingClaim',
  inject: ['$vv'],
  components: {
    VueNumeric,
    BulmaCheckbox,
    BulmaCheckboxDate,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    isNew: Boolean,
    showBox: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        // start: {
        //   timeAdjust: '08:00:00'
        // },
        // end: {
        //   timeAdjust: '08:00:00'
        // }
      },
      innerBookingStartDateTime: null,
      innerBookingEndDateTime: null,
      insurers: [],
      selectedInsurer: null,
      isInsurerLoading: false,
      innerEcomType: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    isArriveForInspection() {
      const isArriveForInspection = (this.value && this.value.isArriveForInspection === 'true') || this.value.isArriveForInspection === true
      return isArriveForInspection
    },
    minDate() {
      const today = new Date()
      return today
    },
    ...mapGetters('booking', {
      storeRepairDelayReasons: 'repairDelayReasons',
      storeBookingTypes: 'bookingTypes'
    }),
    ecomType() {
      if(!this.value.ecomType){
        if (this.value.isRepx) return 'ORM'
        return 'all'
      }
      else return this.value.ecomType
    },
    ecomTypeEnum: function () {
      return EcomTypes
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },

    innerBookingStartDateTime: {
      handler: function (newVal, oldVal) {
        this.value.bookingStartDateTime = newVal
        if (this.value.bookingStartDateTime != null && this.value.bookingStartDateTime !== '') {
          // let date = this.value.bookingStartDateTime
          // date.setMinutes(date.getMinutes() + 30)
          // this.innerBookingEndDateTime = date
          this.value.isInspectionBooked = true
        } else this.value.isInspectionBooked = false
      }
    },
    innerEcomType: function (newVal, oldVal) {
      this.value.ecomType = newVal
      if (oldVal != null && newVal!= null && newVal != oldVal) {
        if (this.value.insurerId) {
          this.removeInsurer()
        }
      }
      this.getEntitySummaries()
    }
    // innerBookingEndDateTime: {
    //   handler: function (newVal, oldVal) {
    //     this.value.bookingEndDateTime = newVal
    //   }
    // }
  },
  created() {
    if (this.value) {
      this.innerBookingStartDateTime = this.value.bookingStartDateTime
      // this.innerBookingEndDateTime = this.value.bookingEndDateTime
    }
    this.$store.dispatch('booking/load')
    this.innerEcomType = this.ecomType
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(deleted) {
      this.value.deleted = deleted
    },
    checkIfDateTime(dateString) {
      const date = new Date(dateString)
      this.isValidDate = !isNaN(date.getTime())
    },
    clear(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.value[input] = ''
      }
    },
    clearStartDate(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.innerBookingStartDateTime = null
        // this.innerBookingEndDateTime = null
      }
    },
    async selectInsurer(selected) {
      if (!selected) {
        return
      }
      this.$emit('select-insurer', selected.insurerId)
      this.value.insurerId = selected.insurerId
    },
    removeInsurer() {
      this.value.insurerId = null
      this.selectedInsurer = null
    },
    async getEntitySummaries() {
      this.isInsurerLoading = true
      this.insurers = await LoadQuoteService.getInsurerSummaries(this.innerEcomType, '')
      this.isInsurerLoading = false
      this.selectedInsurer = this.insurers.find((c) => c.insurerId === this.value.insurerId)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 0 0 1.2rem 0;
}
.desc-width {
  width: 50%;
}
article.tile.is-child > .columns .column i.icon{
  color: #1A5276;
}
.modal-card-title{
  color: #1A5276;
    font-weight: 600;
    line-height: 1.125;
}
div.tile.is-parent {
  min-height: calc(100vh - 18rem);
}
</style>
