<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: companyRoutes.CompanyMenuVersionAccess.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              V2 Menu Access
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: companyRoutes.CompanyEnableNewMenu.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              New Menu Access
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: companyRoutes.CompanyUpdateUserAccess.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              Xero Export Access
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: companyRoutes.CompanyEmailRouteManage.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              Email Route Manage
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: companyRoutes.CompanySetting.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              Company Setting
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: companyRoutes.NewFeaturesControl.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span>
              New Features
            </span>
          </router-link>
        </li>
        <li v-if="isRepxEnabled">
          <router-link :to="{ name: companyRoutes.CompanyBusinessHours.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span class="has-badge-child-offset3">
              Business Hours
            </span>
            <span class="has-badge-rounded has-badge-medium has-badge-danger"
              :data-badge="'NEW'" />
          </router-link>
        </li>
        <!-- <li>
          <router-link :to="{ name: companyRoutes.CompanyRepxStagesSettings.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span class="has-badge-child-offset3">
              RepX Stages Setting
            </span>
            <span class="has-badge-rounded has-badge-medium has-badge-danger"
              :data-badge="'NEW'" />
          </router-link>
        </li> -->
        <li v-if="isRepxEnabled">
          <router-link :to="{ name: companyRoutes.CompanyRepxStagesMappingSetup.name, params: {}, query: '' }"
            active-class="is-active"
            exact>
            <span class="has-badge-child-offset3">
              RepX Stages Setup
            </span>
            <span class="has-badge-rounded has-badge-medium has-badge-danger"
              :data-badge="'NEW'" />
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import CompanyRoutes from '../route-types'
import RepxService from '@/views/repx/RepxService'
import BookingService from '@/views/booking/BookingService'

export default {
  name: 'CompanyOnboardingSideMenu',
  components: {
    AppSideMenu
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      isRepxEnabled: false
    }
  },
  computed: {
    companyRoutes() {
      return CompanyRoutes
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.checkIsRepxEnabled()
  },
  beforeDestroy() {},
  methods: {
    async checkIsRepxEnabled() {
      const isRepxEnabled = await RepxService.getIsRepxEnable()
      const hasBooking = await BookingService.hasBooking()
      if (isRepxEnabled || hasBooking) {
        this.isRepxEnabled = true
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.menu {
  min-height: calc(100vh - 6.5rem - 2rem - 2rem); // 6.5rem=toolbars, 2rem=child tile padding, 2rem=parent tile padding
}
</style>
