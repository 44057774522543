<template>
  <div class="column">
    <div class="quote-header tile is-ancestor is-parent pl-0">
      <booking-vehicle v-if="value"
        :show-box="true"
        v-model="value" />
      <booking-customer v-if="value"
        :show-box="true"
        v-model="value" />
      <booking-claim v-if="value"
        :show-box="true"
        v-model="value" />
    </div>
    <div class="quote-header tile is-ancestor is-parent pl-0">
      <booking-repair v-if="value"
        :show-box="true"
        v-model="value" />
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import { BookingCustomer, BookingVehicle, BookingClaim } from '@/views/booking/components/BookingHeader'
import storeMixin from './storeMixin'
import { EventHubTypes } from '@/enums'
// import DeepDiif from 'deep-diff'
import BookingValidation from './BookingValidation'
import BookingRepair from './components/BookingHeader/BookingRepair.vue'

export default {
  name: 'BookingHeader',
  inject: ['$vv'],
  components: { BookingCustomer, BookingClaim, BookingVehicle, BookingRepair },
  mixins: [BookingValidation, storeMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null,
    insurer: null,
    nextLineNumber: null
  },
  data: () => {
    return {
      innerBooking: null,
      isPauseWatch: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    // innerBooking: {
    //   handler: function (newVal, oldVal) {
    //     const diff = DeepDiif.diff(oldVal, newVal)
    //     if (!this.isPauseWatch && diff) {
    //       this.$emit('input', newVal)
    //       this.$nextTick(() => {
    //         this.$eventHub.$emit(`${EventHubTypes.EntitySaved}-${this.$options.name}`)
    //       })
    //     }
    //   },
    //   deep: true
    // },
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
    // innerCustomer: {
    //   handler: function (newVal, oldVal) {
    //     if (!this.isPauseWatch) {
    //       this.$emit('update:customer', newVal)
    //     }
    //   },
    //   deep: true
    // },
    // value: {
    //   handler: function (newVal, oldVal) {
    //     if (!this.isPauseWatch) {
    //       this.innerBooking = _cloneDeep(newVal)
    //     }
    //   },
    //   deep: true
    // }
  },
  created() {
    this.isPauseWatch = true
    this.innerBooking = _cloneDeep(this.value)
    // this.innerCustomer = _cloneDeep(this.customer)
    this.isPauseWatch = false
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, this.updateInnerObjects)
    // this.$vv.quoteHeaderGroup.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    updateInnerObjects() {
      this.isPauseWatch = true
      console.log(`${this.$options.name} - ${this.value.modifiedDate}`)
      // console.log(`${this.$options.name} - ${this.customer.modifiedDate}`)
      this.innerBooking = _cloneDeep(this.value)
      // this.innerCustomer = _cloneDeep(this.customer)
      this.isPauseWatch = false
      this.$nextTick(() => {
        this.$eventHub.$emit(`${EventHubTypes.EntitySaved}-${this.$options.name}`)
      })
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|bookingItem|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|bookingItem|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      if (this.entity && this.entity.quoteBookingId) this.clearSnapshots(this.entity.quoteBookingId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      next()
    }
    // selectCustomer(id) {
    //   this.$emit('select-customer', id)
    // }
  }
  // beforeRouteEnter(to, from, next) {
  //   next()
  // },
  // beforeRouteLeave(to, from, next) {
  //   next()
  // }
}
</script>

<style lang="scss" scoped>
</style>