import { mapGetters, mapActions } from 'vuex'

const moduleName = 'companyonboarding'

export default {
  data() {
    return {
      moduleName: null
    }
  },
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    })
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem','addStoreItemRoute', 'saveSnapshot', 'clearSnapshots', 'setReturnRoute']),
    setModuleName(value) {

      // this.moduleName = value
      // this.$store.dispatch(`${value}/updateExternal`, { tag: value })
    }
  }
}
