<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">
              Parts Enquiry / Manual Quote
            </span>
          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">
            <div>
              <div class="pretty p-icon p-curve p-smooth m-0 is-size-6">
                <input type="checkbox"
                  @click="checkAll"
                  :checked="areAllChecked">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>
            <div class="left">Description</div>
            <div class="flex">Type</div>

            <div style="direction: rtl;">
              Unit Cost
            </div>

            <div class="flex">
              <span>
                Qty
              </span>
            </div>
          </div>

          <label class="purchase-order-card-small mb-1"
            v-for="(part, index) in poItems"
            :class="{'is-selected': part.checked}"
            :key="index + 'part'">

            <div class="is-flex ml-2">
              <div class="pretty p-icon p-curve p-smooth m-0 is-size-6">
                <input type="checkbox"
                  v-model="part.checked">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>

            <div class="is-flex is-flex-direction-column">
              <span :title="part.itemDesc">{{ part.itemDesc }}</span>
              <span class="is-size-7"
                title="part no">
                {{ part.partNo }}
              </span>
            </div>

            <div class="flex">{{ part.itemType }}</div>
            <div style="direction: rtl;">
              {{ $filters.formatCurrency(part.buyPrice, $userInfo.locale) }}
            </div>

            <!-- Only show quantities for item types that are parts -->
            <div v-if="part.itemType === 'PART'"
              class="flex">
              <div class="item-qty">
                <vue-numeric class="input has-text-right"
                  :min="1"
                  :disabled="false"
                  :precision="0"
                  @input="checkOrderQty(part)"
                  :default-value="2"
                  v-model.number="part.poOrderQty" />
              </div>
              <span class="ml-1">/</span>
              <span>{{ part.itemQuantity - part.quantityOrdered + part.quantityReturned }}</span>
            </div>
            <!-- miscs and sublets -->
            <div v-else
              class="flex">
              N/A
            </div>

          </label>

        </div>

        <span v-if="!isAnyChecked"
          class="has-text-danger ml-4">
          Select at least one item to continue
        </span>

      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />

        <div class="column is-full">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Total Items
          </span>
          <div class="is-flex mt-2 ml-2">
            <span> {{ filteredItems.length }}</span>
          </div>
        </div>
        <div class="column is-full ">
          <div class="field">
            <label class="label">Vendor</label>
            <div class="field-body">
              <div class="field ">
                <div class="control is-expanded">
                  <multiselect v-if="vendors"
                    v-model="selectedVendor"
                    deselect-label="Can't remove this value"
                    track-by="name"
                    label="name"
                    :show-labels="false"
                    :options="vendors"
                    :options-limit="assetFilter.pageSize"
                    :loading="isVendorLoading"
                    :class="!selectedVendor ? 'is-danger' : ''"
                    :internal-search="false"
                    :searchable="true"
                    :allow-empty="true"
                    @search-change="getVendorDropdownFilter"
                    placeholder="Select a vendor">
                    <span class="has-text-danger"
                      slot="noResult">Vendor not found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Email Address
          </span>

          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input class="input"
                type="email"
                :class="{ 'is-danger' : !isEmailValid }"
                v-model="vendorEmail"
                placeholder="Email">
              <span class="icon is-small is-left">
                <i class="mdi mdi-email" />
              </span>
              <span class="icon is-small is-right">
                <i class="mdi"
                  :class="!isEmailValid ? 'has-text-danger mdi-close' : 'mdi-check has-text-success'" />
              </span>
            </p>
          </div>

        </div>

        <!--Add new Email :  Add this back in later maybe?? -->
        <!-- <span class="has-text-weight-bold ml-4">Or</span>=
        <div class="column is-full">
          <div class="button is-primary">
            Add New
          </div>
        </div> -->

      </div>
    </div>
    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">
            <div class="button mr-2 mt-3 is-danger is-outlined"
              @click="$emit('close')">Cancel</div>
            <div @click="Preview()"
              class="button  mr-2 mt-3 is-success is-outlined">
              Preview
            </div>
            <div @click="createMQ()"
              class="button  mr-2 mt-3 is-success is-outlined"
              :class="{ 'is-loading' : isPoSaving }">
              Send Enquiry
            </div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Subject
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <input class="input"
                  type="text"
                  :class="!emailSubject ? 'is-danger' : ''"
                  v-model="emailSubject"
                  placeholder="Enter Subject">
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Requested By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="Select"
                  v-model="requestedBy"
                  :class-prop="!requestedBy ? 'is-invalid' : ''"
                  :data="filteredRecieverList"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Message
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <textarea class="textarea"
                  v-model="emailMessage"
                  rows="6"
                  maxlength="2000"
                  placeholder="Type Message here" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Show Part No.
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <div>
                  <div class="pretty p-icon p-curve p-smooth m-0 is-size-4">
                    <input type="checkbox"
                      v-model="showPartNumber">
                    <div class="state">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Photos
          </span>
          <div class="is-flex mt-2">

            <div class="control w-100">
              <div class="is-flex is-align-items-center">
                <div class="pretty p-icon p-curve p-smooth m-0 is-size-4 mr-2"
                  :class="{'disabled' : !selectedImages.length}">
                  <input type="checkbox"
                    v-model="sendPhotos">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
                <span v-if="!!selectedImages.length">Send {{ selectedImages.length }} Photos</span>
                <span v-else>No Photos Selected</span>

                <button class="button tooltip is-tooltip-topright ml-4"
                  data-tooltip="View Photos"
                  @click="getSelectedImages">
                  <span>View Photos</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <price-parts-image-selection-modal v-if="isPartsCheckExportModalActive"
      v-model="selectedImages"
      :active.sync="isPartsCheckExportModalActive"
      :quote-id="quote.quoteId"
      :title="'Select Images for Manual Quote'"
      @selected-image-ids="selectedImageIds" />

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Guid from '../../../../components/Guid.js'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import PartsControlService from '../PartsControlService.js'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import VueNumeric from '@/components/VueNumeric'
import PricePartsImageSelectionModal from '@/views/quote/components/PricePartsImageSelectionModal'
import _orderBy from 'lodash/orderBy'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import { RemarkTypes, EmailAssetTypes, AssetTypes } from '@/enums'
import { EmailService } from '@/services'
import PartStatusTypes from '@/enums/PartStatusTypes.js'
import StoreUtil from '@/store/utils'
import PrintPreviewRoutes from '@/components/printpreview/route-types'

const emailValidator = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export default {
  name: 'PartsControlManualQuote',
  components: {
    Multiselect,
    BulmaAutocomplete,
    VueNumeric,
    PricePartsImageSelectionModal
  },
  props: {
    unorderedParts: {
      type: Array,
      default: () => []
    },
    quote: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isEmailLoading: false,
      isVendorLoading: false,
      validateError: false,
      recieverList: [],
      requestedBy: '',
      selectedVendor: null,
      emailSubject: 'Parts Enquiry for Quote ' + this.quote.quoteNo.toString(),
      emailMessage: '',
      vendorEmail: '',
      sendPhotos: false,
      selectedImages: [],
      showPartNumber: false,
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      vendors: [],
      itemToAdd: [],
      isPoSaving: false,
      isPartsCheckExportModalActive: false,
      poItems: []
    }
  },
  computed: {
    filteredRecieverList() {
      return this.recieverList.filter((item) => item.toLowerCase().includes(this.requestedBy?.toLowerCase()))
    },
    filteredItems() {
      // Filter out the items that are marked for removal (delete button)
      return this.poItems.filter((item) => item.checked)
    },
    areAllChecked() {
      return this.poItems.every((item) => item.checked)
    },

    isAnyChecked() {
      return this.poItems.some((item) => item.checked)
    },
    hasVendorSelected() {
      return this.selectedVendor && !_isEmpty(this.selectedVendor)
    },
    isEmailValid() {
      return emailValidator.test(this.vendorEmail)
    },
    isPartsEnquiryValid() {
      return this.isEmailValid && this.isAnyChecked && this.requestedBy && this.emailSubject && this.hasVendorSelected
    }
  },
  watch: {
    // vendor ID
    selectedVendor: {
      handler: function (val) {
        if (!!val) {
          this.getVendorEmail(val)
        }
      },
      deep: true
    }
  },
  created() {
    // list of orderers for auto complete
    this.getRecieverList()
    // list of vendors for auto complete
    this.getVendorsDropdown()

    let poItems = _cloneDeep(this.unorderedParts)
    poItems.forEach((item) => {
      item.checked = true
      item.poOrderQty = item.itemQuantity - item.quantityOrdered + item.quantityReturned
    })
    this.poItems = poItems

    if (!!this.quote.imageIds.length) {
      this.selectedImages = this.quote.imageIds
      this.sendPhotos = true
    }
  },
  methods: {
    // get the vendor email automatically when a vendor is selected
    async getVendorEmail(vendor) {
      this.isEmailLoading = true
      const vendorDetails = await VendorService.getEntity(vendor.id)
      const email = vendorDetails.emails.length > 0 ? vendorDetails.emails.find((e) => e.isDefault) || vendorDetails.emails[0] : null
      this.vendorEmail = email?.address
      this.isEmailLoading = false
    },
    // view images button
    async getSelectedImages() {
      this.isPartsCheckExportModalActive = !this.isPartsCheckExportModalActive
    },
    checkAll() {
      let check = this.areAllChecked
      this.poItems.forEach((item) => {
        item.checked = !check
      })
    },

    selectedImageIds(value) {
      this.selectedImages = value
      if (!!this.selectedImages.length) this.sendPhotos = true
      else this.sendPhotos = false
    },

    checkOrderQty(part) {
      if (part.poOrderQty > part.itemQuantity - part.quantityOrdered + part.quantityReturned) {
        // this.$toast.open({
        //   message: 'Purchase order quantity is greater than the quantity of the item',
        //   type: 'is-warning',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.openNotificationWithType('warning','','Purchase order quantity is greater than the quantity of the item')

      }
    },

    // create the Manual Quote order
    async createMQ() {
      const itemIds = this.filteredItems.map((i) => i.quoteItemId)
      if (!this.isPartsEnquiryValid) {
        // this.$toast.open({
        //   message: 'Please resolve the errors before sending Parts Enquiry',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.openNotificationWithType('danger','','Please resolve the errors before sending Parts Enquiry')

        return
      }

      this.isPoSaving = true
      const partsXml = this.generatePartsXml()

      const params = {
        CompanyID: this.quote.companyId,
        QuoteID: this.quote.quoteId,
        VendorID: this.selectedVendor ? this.selectedVendor.id : null,
        reportDocId: this.$lzstring.compressToBase64(partsXml)
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)

      const emailer = new Emailer()
      emailer.assetId = this.quote.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.toEmails = this.vendorEmail ? [this.vendorEmail] : []
      emailer.remark = `${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.remarkTypeId = RemarkTypes.PURCHASEORDER
      emailer.subject = `Parts Enquiry for Quote ${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.reportName = `PartsEnquiry_${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.message = this.emailMessage + '\n\n' + 'Requested By: ' + this.requestedBy

      const formData = new FormData()

      formData.append('emailer', JSON.stringify(emailer))
      const objGenerateReport = {
        ReportName: 'rptPartsEnquiry',
        Format: 'pdf',
        KeyValuePairs: keyValuePairs
      }
      formData.append('generateReport', JSON.stringify(objGenerateReport))
      if (this.sendPhotos) formData.append('imageIds', JSON.stringify(this.selectedImages))
      const result = await EmailService.send(formData)
      this.isPoSaving = false
      if (!!result && result.status === 200) {
        this.$emit('updateUnorderedPartStatus', itemIds, PartStatusTypes.sentManualEnquiry, this.selectedVendor)
      } else {
        // this.$toast.open({
        //   message: 'Error sending Parts Enquiry',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.openNotificationWithType('danger','','Error sending Parts Enquiry')

      }
    },
    generatePartsXml() {
      let xmlDoc = document.implementation.createDocument('', '', null)
      let partEnquiryElement = xmlDoc.createElement('PARTSENQUIRY')
      const selected = _orderBy(this.filteredItems, 'sortNo')
      for (let i = 0; i < selected.length; i++) {
        let quoteItemElement = xmlDoc.createElement('QUOTEITEM')
        let itemDescElement = xmlDoc.createElement('ITEMDESC')
        let partNoElement = xmlDoc.createElement('PARTNO')
        let markElement = xmlDoc.createElement('MARK')
        itemDescElement.innerHTML = selected[i].itemDesc.escapeXml()
        partNoElement.innerHTML = selected[i].partNo.escapeXml()
        markElement.innerHTML = selected[i].mark.escapeXml()
        quoteItemElement.appendChild(itemDescElement)
        quoteItemElement.appendChild(partNoElement)
        quoteItemElement.appendChild(markElement)
        partEnquiryElement.appendChild(quoteItemElement)
      }
      xmlDoc.appendChild(partEnquiryElement)
      return new XMLSerializer().serializeToString(xmlDoc)
    },

    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function (query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500),
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      // set the reciever as the user thats currently logged in
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.requestedBy = currentReciever
    },
    handleRecieverChange(value = null) {
      this.requestedBy = value ? value : null
    },
    // print previw
    async Preview() {

      if (!this.isPartsEnquiryValid) {
        // this.$toast.open({
        //   message: 'Please resolve the errors before sending Parts Enquiry',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.openNotificationWithType('danger','','Please resolve the errors before sending Parts Enquiry')

        return
      }

      const partsXml = this.generatePartsXml()

      const params = {
        CompanyID: this.quote.companyId,
        QuoteID: this.quote.quoteId,
        VendorID: this.selectedVendor ? this.selectedVendor.id : null,
        reportDocId: this.$lzstring.compressToBase64(partsXml)
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
       StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const reportName = 'rptPartsEnquiry'

      const emailer = new Emailer()
      emailer.assetId = this.quote.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      emailer.toEmails = this.vendorEmail ? [this.vendorEmail] : []
      emailer.remark = `${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.remarkTypeId = RemarkTypes.PURCHASEORDER
      emailer.subject = `Parts Enquiry for Quote ${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.reportName = `PartsEnquiry_${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.message = this.emailMessage + '\n\n' + 'Requested By: ' + this.requestedBy
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName, parameterId: parameterId, showEmail: false},
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true,showEmail: false  }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  height: 42px !important;
  opacity: 0.8;
  &.is-selected {
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    border-color: $primary !important;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

.purchase-order-card-header {
  border: 1px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  #, checkbox Description, type, side,  Price  Qty
  grid-template-columns: 10% 45% 10% 15% 20%;
  border-radius: 5px;
  align-items: center;

  div {
    &.flex {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
