import FqApi from '@/services/fq-api'

export default {
  searchCompanyByName(key) {
    return FqApi.get('/companies/searchname?key=' + key)
  },
  async getBusinessHours() {
    const result = await FqApi.get('/companies/getBusinessHours')
    return result.data
  },
  async updateBusinessHours(businessHours) {
    const result = await FqApi.post('/companies/updateBusinessHours', businessHours)
    return result
  },
  async getOverrideBusinessHours(filter) {
    let url = '/companies/getOverrideBusinessHours/'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  async getNewEntityOverrideBusinessHours() {
    const result = await FqApi.get('/companies/getNewEntityOverrideBusinessHours')
    return result.data
  },
  async getEntityOverrideBusinessHours(id) {
    const result = await FqApi.get(`/companies/getEntityOverrideBusinessHours/${id}`)
    return result.data
  },
  async postOverrideBusinessHours(entity) {
    const result = await FqApi.post('/companies/overrideBusinessHours', entity)
    return result.data
  },
  async deleteEntityOverrideBusinessHours(id) {
    const result = await FqApi.delete(`/companies/deleteEntityOverrideBusinessHours/${id}`)
    return result.data
  },
  async getOverrideBusinessHoursInRange(dateFrom, dateTo) {
    const result = await FqApi.post('companies/getOverrideBusinessHoursInRange', { fromDate: dateFrom, toDate: dateTo })
    return result.data
  },
  async updateRepxStagesSetting(stagesSettings) {
    const result = await FqApi.post('/companies/updateRepxStagesSetting', stagesSettings)
    return result
  },
  async getRepxStagesSetting() {
    const result = await FqApi.get('companies/getRepxStagesSetting')
    return result.data
  },

  async updateBusinessHourTimeSlot(time) {
    const result = await FqApi.put('companies/updateBusinessHourTimeSlot/', { time })
    return result.data
  },
  async updateRepxStagesSetup(stagesSettings) {
    const result = await FqApi.post('/companies/updateRepxStagesSetup', stagesSettings)
    return result.data
  }
}
