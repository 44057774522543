
<template>
  <div class="m-3 fixed-grid has-12-cols">
    <div class="grid">

      <!------------------------- Available Item  -------------------------->
      <article v-if="!!isAvailableItemsOpen || !!isAvailableMainActive"
        class="tile p-2 is-child box cell is-col-span-5 mb-4 "
        :class="!!isAvailableItemsOpen ? 'is-col-span-5' : 'is-col-span-0'">

        <div class="is-flex is-align-items-center is-justify-content-space-between">
          <div class="tabs is-medium add-items-tabs">
            <ul>
              <li :class="{'is-active' : selectedAddItemsTab ==addItemsTabs.quoteBuilder.key}"
                @click="selectedAddItemsTab = addItemsTabs.quoteBuilder.key">
                <a>Quote Builder</a>
              </li>
              <li :class="{'is-active' : selectedAddItemsTab ==addItemsTabs.butterfly.key}"
                @click="selectedAddItemsTab = addItemsTabs.butterfly.key">
                <a>Butterfly</a>
              </li>
              <li :class="{'is-active' : selectedAddItemsTab ==addItemsTabs.templates.key}"
                @click="selectedAddItemsTab = addItemsTabs.templates.key">
                <a>Templates</a>
              </li>
            </ul>
          </div>

          <div>
            <button class="button is-grey has-text-grey is-small is-rounded px-0 is-outlined mr-1 mb-6"
              @click="isAvailableItemsOpen = false">
              <span class="material-symbols-rounded">
                close
              </span>
            </button>
          </div>

        </div>

        <!-- when quote builder is selected -->
        <div class="tabs"
          v-if="selectedAddItemsTab == addItemsTabs.quoteBuilder.key">
          <ul>
            <li :class="{'is-active': avaliableItemsType == 'LABOUR'}"
              @click="setAvaliableItemsType('LABOUR')">
              <a>LABOUR</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'OTHER'}"
              @click="setAvaliableItemsType('OTHER')">
              <a>MECH</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'PARTS'}"
              @click="setAvaliableItemsType('PARTS')">
              <a>PARTS</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'MISC'}"
              @click="setAvaliableItemsType('MISC')">
              <a>MISC.</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'SUBLETS'}"
              @click="setAvaliableItemsType('SUBLETS')">
              <a>SUBLETS</a>
            </li>
          </ul>
        </div>

        <div class="scrollable-quote-builder">
          <quote-builder-component v-if="selectedAddItemsTab == addItemsTabs.quoteBuilder.key"
            :value="value"
            :vehicle="vehicle"
            :next-line-number="nextLineNumber"
            :is-available-items-active="isAvailableItemsOpen"
            :avaliable-items-type="avaliableItemsType"
            :is-main-active="isAvailableMainActive"
            @handleUpdate="handleUpdate" />

          <!-- When quote builder is selected -->
          <add-items-component v-if="false"
            :value="value"
            :vehicle="vehicle"
            :next-line-number="nextLineNumber"
            :is-available-items-active="isAvailableItemsOpen"
            :avaliable-items-type="avaliableItemsType"
            :is-main-active="isAvailableMainActive"
            @handleUpdate="handleUpdate" />

          <!-- when butterfly is selected -->
          <butterfly-component v-if="selectedAddItemsTab == addItemsTabs.butterfly.key" />

          <!-- when templates is selected -->
          <template-component v-if="selectedAddItemsTab == addItemsTabs.templates.key"
            :quote-times-type="value.labourType"
            :vehicle-body-id="vehicle.bodyId"
            :rates="value.rates"
            @addRepairQuoteTemplateItems="id => $emit('addRepairQuoteTemplateItems', id)"
            @addTemplateItemsCustom="obj => $emit('handleAddTemplateItemsCustom', obj)" />
        </div>

      </article>

      <article class="cell tile is-child box p-2"
        :class="!!isAvailableItemsOpen ? 'is-col-span-7' : 'is-col-span-12'">
        <div class="is-flex is-align-items-center mb-3 is-justify-content-space-between">

          <div class="is-flex is-align-items-center">

            <span class="px-3 is-size-4 has-text-weight-bold">Quote Items</span>
            <!-- vertical break -->
            <!-- <div class="is-divider-vertical" /> -->

            <div class="is-flex  ml-2">
              <span class="mr-2">Items:</span>
              <span class="has-text-success has-text-weight-bold">
                {{ $filters.formatCurrency( totalQuoteItems, $userInfo.locale, false, 2) }}
              </span>
            </div>

            <div class="is-flex ml-3"
              v-if="totalQuoteItems != value.totalExGst">
              <span class="mr-2">Grand Total:</span>
              <div class="has-text-success has-text-weight-bold is-underlined is-clickable"
                @click="handleSummaryClick">
                <span class="">
                  {{ $filters.formatCurrency( value.totalExGst, $userInfo.locale, false, 2) }}
                </span>
              </div>
            </div>
          </div>

          <div class="is-flex">

            <!-- view options -->
            <div class="dropdown"
              v-if="!isAvailableItemsOpen"
              :class="{'is-active': viewOptionDropdownActive && !isAvailableItemsOpen, 'disabled' : !!isAvailableItemsOpen}"
              v-click-outside="handleOutsideClick">
              <div class="dropdown-trigger">
                <button class="button has-text-grey mx-2"
                  @click="viewOptionDropdownActive = !viewOptionDropdownActive"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu">
                  <div class="is-flex is-align-items-center"
                    v-if="selectedViewType == viewTypes.tabbedView">
                    <span class="mdi mdi-tab mdi-24px mr-1" />
                    <span>Tabs</span>
                  </div>
                  <div class="is-flex is-align-items-center"
                    v-else-if="selectedViewType == viewTypes.multiColView">
                    <span class="mdi mdi-view-split-vertical mdi-24px" />
                    <span>Dual</span>
                  </div>
                  <div class="is-flex is-align-items-center"
                    v-else>
                    <span class="mdi mdi-view-split-horizontal mdi-24px mr-1" />
                    <span>Full</span>
                  </div>
                  <span class="material-symbols-outlined ">
                    keyboard_arrow_down
                  </span>
                </button>
              </div>
              <div class="dropdown-menu"
                id="dropdown-menu"
                role="menu">
                <div class="dropdown-content">
                  <a href="#"
                    @click="selectedViewType = viewTypes.singleColView"
                    class="dropdown-item is-flex is-align-items-center has-text-grey">
                    <span class="mdi mdi-view-split-horizontal mdi-20px mr-1" />
                    <span>Full Screen</span>
                  </a>
                  <a href="#"
                    @click="selectedViewType = viewTypes.multiColView"
                    class="dropdown-item is-flex is-align-items-center has-text-grey">
                    <span class="mdi mdi-view-split-vertical mdi-20px mr-1" />
                    <span>Split Screen</span>
                  </a>
                  <a href="#"
                    @click="selectedViewType = viewTypes.tabbedView"
                    class="dropdown-item is-flex is-align-items-center has-text-grey">
                    <span class="mdi mdi-tab mdi-20px mr-1" />
                    <span>Tabbed View</span>
                  </a>
                </div>
              </div>
            </div>

            <!-- Quote Builder Button -->
            <div class="button mx-2"
              :class="isAvailableItemsOpen ? 'is-primary' : 'has-text-grey'"
              @click="handleToggleAvaliableItems">
              <span class="material-symbols-outlined mr-2">
                no_crash
              </span>
              <span> Quote Builder</span>
              <!-- {{ isAvailableItemsOpen ? '- Close' : '+ Add' }} -->
            </div>

            <button v-if="value.subQuoteNo > 0"
              class="button is-success tooltip is-tooltip-topright"
              data-tooltip="Adjust Main Quote Items"
              @click="handleToggleAvaliableMainItems">
              <span class="icon">
                <i class="mdi"
                  :class="{ 'mdi-adjust' : !isAvailableMainActive, 'mdi-close' : isAvailableMainActive }" />
              </span>
              <span>{{ isAvailableMainActive ? 'Done' : 'Adjust' }}</span>
            </button>

            <!-- Toggle for different views -->
            <!-- <div class="is-flex is-align-items-center is-size-5 mr-4"
      :class="{'disabled' : isAvailableItemsOpen}">
      <div class="buttons has-addons">
        <button class="button"
        @click="selectedViewType = viewTypes.singleColView"
        :class="selectedViewComputed == viewTypes.singleColView ? 'is-selected has-text-primary': 'has-text-grey'">
        <span class="mdi mdi-view-split-horizontal mdi-24px mr-1" />
        <span>Full</span>
      </button>
              <button class="button"
              @click="selectedViewType = viewTypes.multiColView"
              :class="selectedViewComputed == viewTypes.multiColView ? 'is-selected has-text-primary': 'has-text-grey'">
              <span class="mdi mdi-view-split-vertical mdi-24px mr-1" />
              <span>Dual</span>
            </button>
            <button class="button"
            @click="selectedViewType = viewTypes.tabbedView"
            :class="selectedViewComputed == viewTypes.tabbedView ? 'is-selected has-text-primary': 'has-text-grey'">
            <span class="mdi mdi-tab mdi-24px mr-1" />
            <span>Tabs</span>
          </button>
        </div>
      </div> -->

            <!-- Price parts -->
            <div>
              <button class="button"
                :class="value.parts.filter((p) => p.deleted === false && !p.isNew).length > 0 ? 'is-success' : 'is-disabled has-text-grey'"
                @click="goToPriceParts">
                <span>Price Parts</span>
                <span class="material-symbols-outlined mr-2">
                  arrow_forward
                </span>
              </button>
            </div>

          </div>
        </div>

        <div class=""
          :class="{'scrollable-part-items': isAvailableItemsOpen}">
          <div class="tabs"
            v-if="selectedViewType == viewTypes.tabbedView && !isAvailableItemsOpen">
            <ul>
              <li :class="{'is-active': selectedTab.id == tab.id}"
                v-for="(tab) in dataTypes"
                :key="tab.title"
                @click="selectedTab = tab">
                <a>
                  <span>{{ tab.title }}</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- large/multi row/tab view -->
          <quote-builder-items-view :selected-view-type="selectedViewComputed"
            :selected-tab="selectedTab"
            :view-types="viewTypes"
            :value="value"
            :is-available-items-open="isAvailableItemsOpen"
            :avaliable-items-type="avaliableItemsType"
            :is-present-as-modal-active="isPresentAsModalActive"
            :next-line-number="nextLineNumber"
            @onOpgCodeChanged="$emit('onOpgCodeChanged')"
            @handleUpdate="handleUpdate"
            @handlePresentAs="handlePresentAs"
            :vehicle="vehicle" />
        </div>

      </article>

      <quote-item-present-as-modal v-if="isPresentAsModalActive"
        :active.sync="isPresentAsModalActive"
        v-model="item.presentAs"
        :maximum-options="2"
        @ok="closePresentAsModal()"
        @cancel="closePresentAsModal()"
        @close="closePresentAsModal()">
        <p slot="text-title">Present As</p>
      </quote-item-present-as-modal>

    </div>
  </div>
</template>

<script>
import { ItemCategoryTypes } from '@/enums'
import QuoteBuilderItemsView from './QuoteBuilderItemsView.vue'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'
import AddItemsComponent from './components/AddItemsComponent.vue'
import cloneDeep from 'lodash.clonedeep'
import { QuoteTotalsMethodMixin } from './mixins'
import QuoteRoutes from '../route-types'
import deepDiff from 'deep-diff'
import ButterflyComponent from './components/ButterflyComponent.vue'
import TemplateComponent from './components/TemplateComponent.vue'
import QuoteBuilderComponent from './components/QuoteBuilderComponent.vue'
export default {
  name: 'QuoteBuilderView',
  components: {
    QuoteBuilderItemsView,
    QuoteItemPresentAsModal,
    AddItemsComponent,
    QuoteItemPresentAsModal,
    QuoteItemPresentAsModal,
    ButterflyComponent,
    TemplateComponent,
    QuoteBuilderComponent
  },
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  mixins: [QuoteTotalsMethodMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    vehicle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewOptionDropdownActive: false,

      isAvailableItemsOpen: false,
      isAvailableMainActive: false,
      avaliableItemsType: 'LABOUR',
      dataTypes: [
        { id: 0, key: 'RR', title: 'Remove & Refit', source: 'labours', type: ItemCategoryTypes.RR, source: 'labours' },
        { id: 2, key: 'Repair', title: 'Repair', source: 'labours', type: ItemCategoryTypes.REP, source: 'labours' },
        { id: 3, key: 'Paint', title: 'Paint', source: 'labours', type: ItemCategoryTypes.PAINT, source: 'labours' },
        { id: 4, key: 'MECH', title: 'Mechanical', source: 'others', type: ItemCategoryTypes.MECH, source: 'labours' },
        { id: 5, key: 'Parts', title: 'Parts', source: 'parts', type: ItemCategoryTypes.PART, source: 'parts' },
        { id: 6, key: 'Misc', title: 'Miscellaneous', source: 'miscs', type: ItemCategoryTypes.MISC, source: 'misc' },
        { id: 7, key: 'Sublets', title: 'Sublets', source: 'sublets', type: ItemCategoryTypes.SUBL, source: 'sublets' }
      ],
      totals: {
        parts: 0,
        labours: 0,
        others: 0,
        miscs: 0,
        sublets: 0
      },
      // view types for the quote items
      viewTypes: {
        singleColView: 'singleCol',
        multiColView: 'multiCol',
        tabbedView: 'tabView'
      },
      selectedViewType: 'multiCol',
      //just used for tabbed view
      selectedTab: { id: 0, title: 'Remove && Refit', source: 'labours', type: ItemCategoryTypes.RR },
      isPresentAsModalActive: false,
      presentAsItem: null,

      addItemsTabs: {
        quoteBuilder: { key: 0, title: 'Quote Builder' },
        butterfly: { key: 1, title: 'Butterfly' },
        templates: { key: 2, title: 'Templates' }
      },
      selectedAddItemsTab: 0
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    },
    selectedViewComputed() {
      // return single column view
      if (!this.isAvailableItemsOpen) return this.selectedViewType
      return this.viewTypes.singleColView
    },
    totalQuoteItems() {
      const clonedValue = cloneDeep(this.value)
      return (
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.RR) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.REP) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.PAINT) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.others), ItemCategoryTypes.MECH) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.parts), ItemCategoryTypes.PART) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.miscs), ItemCategoryTypes.MISC) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.sublets), ItemCategoryTypes.SUBL)
      )
    }
  },
  watch: {
    selectedViewType(val) {
      // check if value matches any of the view types
      if (Object.values(this.viewTypes).includes(val)) {
        StoreUtil.setLocalStorage('quoteBuilder', 'selectedViewType', val)
      }
    },
    value: {
      handler(newVal, oldVal) {
        // Is this really the best way to do this?
        // I was thinking of maybe running these based on the category that was updated
        // but i think the problem with that is we risk maintaining the sync between the categories
        // if you update one category, you have to update the others as well
        // otherwise when you update the other categories, the values will be out of sync
        if (deepDiff(newVal, oldVal)) {
          this.$nextTick(() => {
            this.$eventHub.$emit('updateLabour')
            this.$eventHub.$emit('updateOthers')
            this.$eventHub.$emit('updateParts')
            this.$eventHub.$emit('updateMiscs')
            this.$eventHub.$emit('updateSublets')
          })
        }
      },
      deep: true
    }
  },
  created() {
    // get selected view type from local storage
    const selectedViewType = StoreUtil.getLocalStorage('quoteBuilder', 'selectedViewType')
    if (selectedViewType) {
      this.selectedViewType = selectedViewType
    }
  },
  methods: {
    setAvaliableItemsType(type) {
      // get div with class quote-items-section and scroll to selected type
      const mechSection = document.querySelector(`.quote-items-section.${type?.toLowerCase()}`)
      if (mechSection) {
        mechSection.scrollIntoView({ behavior: 'smooth' })
      }
      this.avaliableItemsType = type
    },
    handleAddTemplateItemsCustom(obj) {
      console.log('handleAddTemplateItemsCustom', obj)
    },
    handleOutsideClick() {
      this.viewOptionDropdownActive = false
    },
    handleSummaryClick() {
      this.$router.push({ name: this.routeTypes.QuoteSummary.name, params: { quoteId: this.$route.params.quoteId }, query: this.$route.query })
    },
    handleToggleAvaliableMainItems() {
      this.isAvailableItemsOpen = false
      this.isAvailableMainActive = !this.isAvailableMainActive
    },
    handleToggleAvaliableItems() {
      this.isAvailableMainActive = false
      this.isAvailableItemsOpen = !this.isAvailableItemsOpen
    },
    handleUpdate(value) {
      this.$emit('input', value)
    },
    closePresentAsModal() {
      this.$nextTick(() => {
        this.isPresentAsModalActive = false
      })
    },
    handlePresentAs(item) {
      if (item) {
        this.item = item
        this.isPresentAsModalActive = true
      }
    },
    goToPriceParts() {
      // TODO: ideally  unordered parts should be (p.quantityOrdered < p.itemQuantity), but i
      // TODO: just leaving it like this as price parts uses this

      const innerValue = cloneDeep(this.value)

      const unorderedParts = innerValue.parts.filter(
        (p) =>
          p.deleted == false &&
          p.partStatus != 'Pord' &&
          (p.quantityOrdered === 0 || p.quantityReturned > p.quantityOrdered || p.quantityOrdered - p.quantityReturned < p.itemQuantity)
      )

      const unSavedParts = innerValue.parts.filter((p) => p.isNew).length
      if (!unorderedParts.length && unSavedParts == 0) {
        this.$toast.open({
          message: 'There are no more parts to order, please add parts before proceeding',
          type: 'is-warning',
          queue: false,
          duration: 5000,
          position: 'is-bottom'
        })
        return
      }

      if (innerValue.parts.filter((p) => p.deleted === false).length > 0) {
        if (innerValue.parts.filter((p) => p.isNew).length > 0) {
          this.$emit('save', QuoteRoutes.QuotePriceParts.name)
          this.shouldRoutePriceParts = true
        } else {
          this.$router.push({
            name: QuoteRoutes.QuotePriceParts.name
          })
        }
      }
      this.isPricePartsModalActive = true
    }
  }
}
</script>

<style lang="scss" >
.full-quote-page-height {
  height: fit-content;
  // 52px for the navbar and 52px for the quote header, 20px for the padding
  min-height: calc(100vh - 52px - 52px - 20px) !important;
}

.cell {
  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(6, 108, 224);
  }

  .row {
    background-color: #eeeeee;
    border: 1px solid #000000;
    padding: 10px;
    margin: 4px;
    border-radius: 5px;
  }
}

.scrollable-quote-builder {
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 52px - 52px - 200px);
}

.scrollable-part-items {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 52px - 52px - 110px);

  .quote-item-heading {
    position: sticky;
    top: 0px;
    z-index: 9;
  }
}
</style>