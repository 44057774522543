<template>
  <div v-if="$userInfo">
    <div class="columns is-gapless">
      <div v-show="showfilter"
        class="column is-narrow">
        <Repx-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article :class="{'tile is-child detail-page-tile': true, 'box': tableBoxCssOn }">

            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(RepxService, index) in entities"
                :key="RepxService.quoteRepxServiceID"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ $filters.formatDateTimeLocale(RepxService.createdDate, $userInfo.locale) }}</td>
                <!-- <td class="has-text-right">{{ RepxService.excess | formatCurrency($userInfo.locale) }}</td> -->
                <td>{{ RepxService.repairQuoteId }}</td>
                <td>{{ RepxService.quoteNo }}</td>
                <td>{{ RepxService.messageTypeDescription }}</td>
                <td>{{ RepxService.remark }}</td>
                <!-- <td>
                  <span class="button"
                    :class="{ 'is-success' : RepxService.statusId == 20, 'is-danger' : RepxService.statusId === 50 }">
                    {{ getStatusText(RepxService.statusId) }}
                  </span>
                </td> -->
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ RepxService.statusId == 20 ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ RepxService.statusId == 20 ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    data-tooltip="Click to view"
                    @click="handleMessageClicked(RepxService)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-magnify mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
            <!-- <router-link class="button is-success"
              to="/RepxServiceCalendar"
              append>
              <span class="icon">
                <i class="mdi mdi-open-in-app mdi-18px" />
              </span>
            </router-link> -->
            <repx-message-detail :visible="isDetailActive"
              :message-id="messageId"
              @close="close" />
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import RepxService from './RepxService'
import StoreMixin from './storeMixin'
import RepxListSideMenu from './RepxListSideMenu.vue'
import RepxMessageDetail from './RepxMessageDetail.vue'
import { MessageStatusTypes } from '@/enums'

export default {
  name: 'RepxList',
  components: {
    BulmaTable,
    RepxListSideMenu,
    RepxMessageDetail
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    quoteId: {
      type: String,
      default: null
    },
    quoteBookingId: {
      type: String,
      default: null
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    tableBoxCssOn: {
      type: Boolean,
      default: false
    }
  },

  data: () => {
    return {
      isTableLoading: false,
      totalRows: 0,
      selectedRow: null,
      filter: {
        quoteNo: null,
        quoteId: null,
        fromDate: null,
        toDate: null,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      messageId: '',
      _quoteId: '',
      showfilter: true,
      isDetailActive: false,
      entities: null
    }
  },
  computed: {
    columns() {
      return Columns
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}|Repx`
      } else {
        return ''
      }
    }
  },
  watch: {
    quoteId: {
      handler: function (newVal, oldVal) {
        // this._quoteId = newVal
        this.filter.quoteId = newVal
      },
      deep: true
    },
    quoteBookingId: {
      handler: function (newVal, oldVal) {
        // this._quoteId = newVal
        this.filter.quoteBookingId = newVal
      },
      deep: true
    },
    hideFilter: {
      handler: function (newVal, oldVal) {
        this.showfilter = !newVal
      },
      deep: true
    }
  },
  created() {
    this.filter.quoteId = this.quoteId
    this.filter.quoteBookingId = this.quoteBookingId
    console.log(this.filter)
    if (!this.filter.quoteId && !this.filter.quoteBookingId) {
      if (this.$route.params.bookingId) {
        this.filter.quoteBookingId = this.$route.params.bookingId
      }
    }
    this.persistFilter()
    this.showfilter = !this.hideFilter
    this.getRepxServiceList()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getRepxServiceList()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getRepxServiceList()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getRepxServiceList()
    },
    resetList() {
      this.filter.quoteNo = null
      this.filter.repairQuoteId = null
      this.filter.quoteBookingId = null
      this.filter.fromDate = null
      this.filter.toDate = null
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getRepxServiceList()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getRepxServiceList() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entities = await RepxService.getWebServiceTransactions(this.filter)
      this.totalRows = this.entities.length > 0 ? this.entities[0].totalRows : 0
      this.isTableLoading = false
    },
    async addNew() {
      this.addStoreItem()
    },
    handleMessageClicked(item) {
      this.messageId = item.messageId
      this.isDetailActive = true
    },
    close() {
      this.isDetailActive = false
    },
    getStatusText(status) {
      switch (status) {
        case MessageStatusTypes.Sent:
          return 'Sent'
        case MessageStatusTypes.Failed:
          return 'Failed'
        default:
          return 'Unknown'
      }
    }
  }
}
</script>