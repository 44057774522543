import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Type', 'messageType', true, 'desc', 'left'),
  new TableColumn('QDE Status', 'qdeStatus', true, 'desc', 'left'),
  new TableColumn('Message No.', 'ormMessageNo', true, 'desc', 'left'),
  new TableColumn('Rego', 'regoNo', true, 'desc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Claim Type', 'claimType', true, 'desc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'desc', 'left'),
  new TableColumn('Mobile No.', 'mobilePhone', true, 'desc', 'left'),

  new TableColumn('UID', 'uid', true, 'desc', 'left'),
  new TableColumn('Quote No.', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Vehicle', 'vehicleDescription', true, 'desc', 'left'),
  new TableColumn('Created Date', 'createdDate', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
