<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Override Business hours</span>
      </p>
    </header>
    <section class="modal-card-body">
      <override-business-hours ref="overrideBusinessHours"
        :override-business-hours-id="overrideBusinessHoursId"
        :enable-save="enableSave"
        :is-new="isNew"
        @save="handleSave" />
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <div class="buttons">

        <button v-if="enableSave"
          class="button is-success tooltip"
          :data-tooltip="isNew ? 'Add' : 'Save'"
          @click="save">
          <span class="icon">
            <i class="mdi mdi-content-save mdi-20px" />
          </span>
          <span>{{ isNew ? 'Add' : 'Save' }}</span>
        </button>
        <button class="button tooltip"
          @click="close"
          data-tooltip="Close">
          <span class="icon">
            <i class="mdi mdi-close mdi-20px" />
          </span>
          <span>Close</span>
        </button>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import OverrideBusinessHours from './OverrideBusinessHours.vue'

export default {
  name: 'OverrideBusinessHoursModel',
  components: { BaseModalEx, OverrideBusinessHours },
  props: {
    // value: null,
    overrideBusinessHoursId: {
      type: String,
      require: true
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-message-text'
    },
    isNew: Boolean
  },
  data() {
    return {
      isActive: false,
      enableSave: true
    }
  },
  computed: {},
  watch: {

  },
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.$refs.overrideBusinessHours.close()
      this.$emit('update:active', false)
      this.$emit('close')
      this.isActive = false
    },
    handleSave() {
      this.close()
    },
    save() {
      this.$refs.overrideBusinessHours.save()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
