import { guidValidator } from '@/components/validators'

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    loadOptions: {
      quoteId: {
        validGuid: guidValidator
      }
    }
  }
}
