import RepxRoutes from './route-types'

import RepxListView from './RepxListView.vue'

const moduleId = 'A210'

export default [
  {
    path: RepxRoutes.DashboardView.path,
    name: RepxRoutes.DashboardView.name,
    component: RepxListView,
    meta: {
      id: moduleId,
      title: 'RepX Log'
    }
  }
]
