import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async CheckPasswordResetEligibility(token) {
    const url = `/auth/checkPasswordResetEligibility?token=${token}`
    const result = await FqApi.get(url)
    return result.data
  },
  async forgotPassword(companyLoginId, username, isSupportUser) {
    const url = '/auth/forgotPassword'
    const result = await FqApi.post(url, { companyLoginId, username, isSupportUser })
    return result.data
  },
  async forgotPasswordReset(token, refToken, password) {
    const url = '/auth/forgotPasswordReset'
    const result = await FqApi.post(url, { token, refToken, password })
    return result.data
  },
  async getForgotPasswordLogRecords(filter) {
    const serialised = this.serialiseForgotPasswordLogFilter(filter)
    const url = `/auth/getForgotPasswordLogRecords?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseForgotPasswordLogFilter(filter) {
    const entityProto = require('../assets/proto/auth/ForgotPasswordLogFilter.proto')
    const filterMessage = entityProto.ForgotPasswordFilter.ForgotPasswordFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
