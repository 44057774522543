<template>
  <div>
    <app-detail-header :show-toggle="true"
      v-if="entity"
      :entity="entity"
      :show-new="false"
      :show-print="false"
      :show-delete="!!entity && !entity.deleted && canConvert"
      :show-save="!!entity && !entity.deleted && !entity.quoteId"
      :show-lock="false"
      :show-convert="canConvert"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <booking-side-menu v-if="entity"
          :entity-name="`${entity.claimNo}`"
          v-model="entity"
          ref="sideMenu"
          @select-booking="changeBooking" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="handleDelete()"
      @cancel="isConfirmModalActive = !isConfirmModalActive"
      :ok-text="'Yes'"
      :cancel-text="'No'"
      v-model="entity">
      <p slot="text-title">Delete Booking</p>
      <p slot="text-content">
        Booking
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.vehicleRegistrationNo}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <booking-convert-to-quote-simple v-if="isBookingConvertQuoteActive"
      :active.sync="isBookingConvertQuoteActive"
      :quote-booking-id="innerBookingId"
      :entity="entity"
      v-model="loadOptions"
      @close="closeBookingConvertQuote" />
  </div>
</template>

<script>
import BookingSideMenu from './BookingSideMenu'
import AppDetailHeader from '@/components/AppDetailHeader'
import BookingRoutes from './route-types'
import { EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash.clonedeep'
import _isEmpty from 'lodash/isEmpty'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import BookingService from './BookingService'
import BookingValidation from './BookingValidation'
import { AppHeaderButtonTypes } from '@/enums'
// import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import { MessageTypes } from '@/enums'
import { CustomerOptionTypes } from '@/components/LoadQuoteSteps'
import QuoteRoutes from '@/views/quote/route-types'
import BookingConvertToQuoteSimple from './BookingConvertToQuoteSimple.vue'

export default {
  name: 'BookingView',
  components: {
    BookingSideMenu,
    AppDetailHeader,
    ConfirmModal,
    UnsavedModal,
    BookingConvertToQuoteSimple
  },
  mixins: [BookingValidation, StoreMixin],
  props: {
    returnUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      entity: null,
      entityMainQuote: null,
      isSkipSave: false,
      isSaveContinue: false,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      toRoute: null,
      isConfirmModalActive: false,
      isBookingConvertQuoteActive: false,
      innerBookingId: '',

      loadOptions: {
        messageId: Guid.empty(),
        messageTypeId: MessageTypes.QML_RequestForQuote,
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        customerId: Guid.empty(),
        originalCustomerId: Guid.empty(),
        customerOption: CustomerOptionTypes.Create,
        title: '',
        customerName: '',
        firstname: '',
        lastname: '',
        isPerson: false,
        regoNo: '',
        claimNo: '',
        vehicleId: Guid.empty(),
        isOverwriteVehicle: true,
        isOverwriteOrmMessageNo: false,
        isRepx: false
      }
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    },
    canConvert() {
      return !this.entity.quoteId
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
    // entity: {
    //   handler: _debounce(function(newVal) {
    //     if (newVal) {
    //       this.$forceUpdate()
    //       this.saveSnapshot(_cloneDeep(this.entity))
    //     }
    //   }, 500),
    //   deep: true
    // }
  },
  created() {
    this.getEntity()
    this.innerBookingId = this.$route.params.bookingId
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.bookingId)
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntityReload, async () => {
      await this.getEntity(true)
      this.innerBookingId = this.entity.quoteBookingId
      this.$eventHub.$emit(EventHubTypes.EntityReloaded)
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
        case AppHeaderButtonTypes.Convert:
          this.handleConvert()
          break
      }
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|quote|returnUrl`)
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.bookingId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.bookingId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$toast.open({
          message: e,
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        // this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      if (this.validateError) {
        this.$toast.open({
          message: 'Validation errors. Please fix before saving',
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        //const title = 'Booking Item'
        // this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await BookingService.postBookingItem(this.entity)
        } else if (this.snapshotDiff) {
          await BookingService.putBookingItem(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$toast.open({
            message: 'No changes detected. Not saved',
            type: 'is-success',
            position: 'is-top',
            queue: false
          })
          // this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return null
        }
        //await this.removeStoreItem(this.entity.QuoteBookingID)
        this.$toast.open({
          message: isDelete ? 'Booking Item set to In-Active' : 'Save successful',
          type: 'is-success',
          position: 'is-top',
          queue: false
        })
        // this.$notification.success('Booking Item', isDelete ? 'Booking Item set to In-Active' : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        // if (e.response.request.status === HttpStatus.CONFLICT) {
        //   this.isSaveConflictModalActive = true
        // } else {
        this.$toast.open({
          message: e,
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        // this.$notification.openMessageXhrError('Booking Item', e)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        // const returnUrl = sessionStorage.getItem(this.returnUrlKey)
        // if it comes from url => redirect after save
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            // cleanup vuex store
            this.clearSnapshots(this.entity.QuoteBookingID)
          }
          this.$router.push({
            name: BookingRoutes.DashboardView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    changeBooking(booking) {
      // console.log(subQuote.key)
      this.$router.push({
        name: this.$route.name,
        params: { bookingId: booking.value }
      })
    },
    async handleDelete() {
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        await BookingService.deleteBooking(this.entity)
        this.isConfirmModalActive = false
        this.$toast.open({
          message: 'Booking Item set to In-Active',
          type: 'is-success',
          position: 'is-top',
          queue: false
        })
        // this.$notification.success('Booking Item', 'Booking Item set to In-Active')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        // this.$notification.openMessageXhrError('Booking Item', e)
        this.$toast.open({
          message: e,
          type: 'is-danger',
          position: 'is-top',
          queue: false
        })
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    async handleConvert() {
      const isSaved = await this.save(false)
      if (isSaved == false)
        return
      this.isBookingConvertQuoteActive = true
      this.loadOptions.regoNo = this.entity.vehicleRegistrationNo
      this.loadOptions.claimNo = this.entity.claimNo
      this.loadOptions.customerName = this.entity.customerName
      this.loadOptions.isRepx = this.isRepx
      this.loadOptions.isPerson = this.entity.isCompany
      this.loadOptions.insurerId = this.entity.insurerId
      this.loadOptions.firstname = this.entity.firstname
      this.loadOptions.lastname = this.entity.lastname
      this.loadOptions.isRepx = this.entity.isRepx
    },
    async handleConvertQuote() {
      try {
        this.$showSpinner('Converting...')
        console.log(this.innerEntity)

        var _entity = _cloneDeep(this.entity)
        _entity.loadOptions = _cloneDeep(this.loadOptions)

        var quote = await BookingService.putConvertBookingToQuote(_entity)
        var route = QuoteRoutes.QuoteView.path.replace(':quoteId', quote.data.quoteId)
        this.$router.push(`${route}`)

        this.$hideSpinner()
      } catch (e) {
        throw e
      } finally {
        this.$$hideSpinner()
      }
    },
    closeBookingConvertQuote() {
      this.isBookingConvertQuoteActive = false
      this.triggerConvert = false
    }
  }
}
</script>
