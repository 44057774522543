<template>
  <card-modal :visible="isModelActive"
    :title="'Detail Info'"
    @ok="hanldeClose"
    @close="hanldeClose"
    @cancel="hanldeClose">
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Direction</label>
        <span>{{ entities.messageDirection == 1? 'Sent': 'Recevied' }}</span>
      </div>
      <div class="field">
        <label class="label">Request Json</label>
        <div>{{ entities.jsonDataRequest }}</div>
      </div>
      <div class="field">
        <label class="label">Response Json</label>
        <div>{{ entities.jsonDataResponse }}</div>
      </div>
      <div class="field">
        <label class="label">Sent On</label>
        <span>{{ $filters.formatDateTimeLocale(entities.createdDate, $userInfo.locale) }}</span>
      </div>
    </section>
  </card-modal>
</template>

<script>
import CardModal from '../../components/BulmaModal/CardModal.vue'
import RepxService from './RepxService'

export default {
  name: 'RexpMessageDetail',
  components: { CardModal },
  props: {
    value: null,
    messageId: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      id: '',
      entities: {},
      isModelActive: false
    }
  },
  watch: {
    visible: {
      handler: function (newVal, oldVal) {
        this.isModelActive = newVal
      },
      deep: true
    },
    messageId: {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        this.id = newVal
        this.getRepxMessageDetail()
      },
      deep: true
    }
  },
  created() {
    if (this.messageId == undefined) this.getRepxMessageDetail()
  },
  mounted() {},
  methods: {
    async getRepxMessageDetail() {
      this.entities = await RepxService.getWebServiceDetail(this.id)
    },
    hanldeClose() {
      this.isModelActive = false
      this.$emit('close')
    }
  }
}
</script>