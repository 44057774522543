import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Vehicle No.', 'vehicleRegistrationNo', true, 'desc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Quote No.', 'quoteNo', true, 'desc', 'left'),
  // new TableColumn('Make', 'vehicleMake', true, 'desc', 'left'),
  // new TableColumn('Model', 'vehicleModel', true, 'desc', 'left'),
  new TableColumn('Customer', 'customerName', false, 'desc', 'left'),
  new TableColumn('Excess', 'excess', true, 'desc', 'right'),
  new TableColumn('Booking Start DateTime', 'bookingStartDateTime', true, 'desc', 'left'),
  new TableColumn('Booking End DateTime', 'BookingEndDateTime', true, 'desc', 'left'),
  new TableColumn('Created Date', 'createdDate', true, 'desc', 'left'),
  new TableColumn('Deleted', 'deleted', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
