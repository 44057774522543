<template>
  <div>
    <label class="label">{{ label }}</label>
    <div class="control">
      <div class="pretty p-switch p-fill">
        <div class="tags has-addons"
          style="margin-left: auto;">
          <input type="checkbox"
            v-model="newValue"
            :checked="!!newValue"
            :disabled="disabledCheckbox"
            :true-value="trueValue"
            :false-value="falseValue">
          <div class="state p-primary  mr-2">
            <label>{{ newValue ? 'Yes' : 'No' }} </label>
          </div>
          <span v-if="date"
            class="tag is-dark">Last saved</span>
          <span v-if="date"
            class="tag is-success">{{ $filters.formatDateTimeLocale(date, $userInfo.locale) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTimeFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'BulmaCheckboxDate',
  components: {},
  mixins: [DateTimeFiltersMixin],
  props: {
    value: null,
    date: null,
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: false
    }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    innerProps() {
      return {
        name: this.name
      }
    },
    disabledCheckbox() {
      return this.disabled || this.date != null
    }
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    value(value) {
      this.newValue = value
    },
    /**
     * Emit input event to update the user v-model.
     */
    newValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  methods: {
    // handleChange(event) {
    //   this.$emit('change', event)
    // }
  }
}
</script>

<style scoped>
.pretty .tag {
  opacity: 0.8 !important;
}
</style>