<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">Return Parts</span>

          </div>
        </div>

        <div class="column is-full">
          <span class="w-100 bb-primary-2 has-text-primary mb-2 has-text-weight-semibold">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">

            <div>Description</div>
            <div>Rec.</div>
            <div>Ret.</div>
            <div style="direction: rtl;">
              Unit Cost
            </div>
            <div style="direction: rtl;"
              class="pr-4">Qty
            </div>
          </div>

          <div class="purchase-order-card-small mb-1 px-1"
            v-for="(part, index) in entity?.returnPartsItem"
            :key="index + 'part'">

            <div :content="part.script"
              v-tippy="{ enabled: true }"
              class="is-flex is-flex-direction-column">
              <span>
                {{ part.script }}
              </span>
            </div>

            <div>{{ part.rced }}</div>
            <div>{{ part.returned }}</div>

            <div style="direction: rtl;">{{ $filters.formatCurrency(part.invcAmount, $userInfo.locale) }}</div>

            <div style="align-items: center;"
              class="is-flex pl-5">
              <div class="">
                <vue-numeric class="input"
                  :min="0"
                  :disabled="false"
                  :precision="0"
                  v-model.number="part.returning"
                  @input="checkRecQty(part)" />
              </div>
              <!-- <span>/</span>
              <span>{{ part.rced }} {{ part.returned }}</span> -->
            </div>
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary has-text-weight-semibold">
            Invoice & Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Invoice Number
          </span>
          <div class="field">
            <input class="input"
              :class="{ 'is-danger' : !selectedInvoice.invoiceNo}"
              v-model="selectedInvoice.invoiceNo"
              type="text"
              placeholder="">
          </div>
        </div>

        <div class="column is-full">
          <span class="has-text-weight-semibold">
            {{ invoiceList?.length > 1 ? 'Invoices: ' : 'Invoice' }} {{ invoiceList?.length > 1 ? invoiceList.length : '' }}
          </span>
          <div class="field"
            v-if="invoiceList?.length > 1">
            <multiselect track-by="invoiceNo"
              :value="selectedInvoice"
              label="invoiceNo"
              placeholder="Select an Invoice"
              :options="invoiceList"
              @select="handleInvoiceChange"
              :loading="false"
              :searchable="true"
              :allow-empty="true" />
          </div>
          <div class="is-flex mt-2 ml-2"
            v-else>
            <span>{{ selectedInvoice.invoiceNo }}</span>
          </div>
        </div>

        <div class="column is-full has-text-danger">
          <span class=" has-text-weight-semibold">
            ABN
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.abn }}</span>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">Total Amt. (Incl GST)</label>

              <div class="is-flex mt-2 ml-2">
                {{ $filters.formatCurrency(totalAmount, $userInfo.locale ) }}
              </div>

            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field is-narrow">
              <label class="label">GST</label>
              <!-- <div class="control">
                <vue-numeric v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
                  class="input has-text-right"
                  v-model="receiptEntity.overrideGst"
                  type="text"
                  placeholder=""
                  :precision="2"
                  @blur="checkOverrideGst(receiptEntity)" />
                <span v-else>{{ receiptEntity.overrideGst | formatCurrency($userInfo.locale) }}</span>
              </div> -->
              <div class="is-flex mt-2 ml-2">
                {{ $filters.formatCurrency(totalGStAmount, $userInfo.locale) }}
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold mb-1">
            Supplier
          </span>
          <div class="field"
            v-if="!!entity">
            <input class="input"
              :class="{ 'is-danger' : !entity?.vendorName}"
              v-model="entity.vendorName"
              type="text"
              placeholder="">
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="column p-0 m-0 is-full "
        style="height: 30px;">
        <div class="is-flex is-justify-content-end">
          <div class="button mr-2 mt-3 is-danger is-outlined"
            @click="$emit('close')">Cancel</div>
          <div @click="saveReturnOrder(isNewReturnOrder)"
            class="button is-success is-outlined mr-2 mt-3">Return Parts</div>
        </div>
      </div>
      <div class="column is-full ">
        <span class="w-100 bb-primary-2 has-text-primary has-text-weight-semibold">
          Details
        </span>
      </div>

      <div class="columns is-multiline mt-3"
        v-if="!!entity">
        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Return Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>RFC {{ entity.returnNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <!-- <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Returned By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="Search for Returner"
                  v-model="selectedReturner"
                  :data="filteredReturnerList"
                  :keep-first="true"
                  :loading="false" />
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Reason
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <multiselect track-by="message"
                  label="message"
                  placeholder="Select a Reason for Return"
                  :options="[
                    { message: 'Hello' },
                    { message: 'World' }
                  ]"
                  :loading="false"
                  :searchable="true"
                  :allow-empty="true" />
              </div>
            </div>
          </div>
        </div> -->

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Comments
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <textarea class="textarea"
                  rows="6"
                  v-model="comments"
                  maxlength="2000"
                  placeholder="Reason for return, etc." />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field is-narrow">
            <label class="label">Date</label>
            <v-date-picker v-model="entity.returnDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Return Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>

      </div>
    </div>

    <confirm-modal :active="confirmModalOpen"
      :show-icon="false"
      :ok-text="'Print'"
      :cancel-text="'No'"
      @ok="createReturnOrder(isNewReturnOrder, true)"
      @cancel="createReturnOrder(isNewReturnOrder, false)">
      <template v-slot:text-title>
        <span>Return For Credit</span>
      </template>
      <template v-slot:text-content>
        <span>Do you Wish to print Credit Return for <strong> RFC {{ entity?.returnNo }}</strong> now?</span>
      </template>
    </confirm-modal>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { PartsControlActionTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import VueNumeric from '@/components/VueNumeric'
// import { roundAwayFromZero } from '../../../../../utils/AccountingFunctions'
import DeepDiff from 'deep-diff'
// import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import PartsControlReturnPartsService from './PartsControlReturnPartsService.js'
import { ReturnItemPartsModel } from '@/classes/viewmodels'
import PartsControlService from '../../PartsControlService'
import { RemarkModel } from '@/classes/viewmodels'
import { RemarkTypes } from '@/enums'
import ConfirmModal from '@/components/BulmaModal/ConfirmModal.vue'

export default {
  name: 'PartsControlReturnParts',
  components: {
    VueNumeric,
    // BulmaAutocomplete,
    Multiselect,
    ConfirmModal
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    isNewReturnOrder: {
      type: Boolean,
      default: true
    },
    orderReceipt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      returnerList: [],
      selectedReturner: null,
      // returnorder
      entity: null,
      snapShotEntity: null,
      invoiceList: [],
      selectedInvoice: null,
      comments: '',

      // confirmModal
      confirmModalOpen: false
    }
  },
  computed: {
    filteredReturnerList() {
      return this.returnerList.filter((i) => i.toLowerCase().includes(this.selectedReturner.toLowerCase()))
    },
    partsControlEnums() {
      return PartsControlActionTypes
    },
    totalAmountBeforeGst() {
      return this.entity?.returnPartsItem.reduce((acc, item) => acc + item.invcAmount * item.returning, 0)
    },
    totalGStAmount() {
      return this.totalAmountBeforeGst * this.entity?.gstFactor
    },
    totalAmount() {
      return this.totalAmountBeforeGst + this.totalGStAmount
    },
    validItemQtyForReturn() {
      return this.entity.returnPartsItem.some((item) => item.returning)
    }
  },
  watch: {
    // if there is items in multiple invoices, then we need to select the invoice
    // before an return order can be created
    selectedInvoice: {
      handler: function (newVal, oldVal) {
        if (!!newVal) this.createNewReturnOrder()
      },
      deep: true
    },
    comments: {
      handler: function (newVal, oldVal) {
        this.handleCommentsChange(newVal)
      }
    }
  },
  created() {
    this.getReturnerList()

    if (this.orderReceipt !== null) {
      this.selectedInvoice = this.orderReceipt
      this.creditorInvoiceId = this.orderReceipt.creditorInvoiceId
    } else this.selectInvoice()
  },
  methods: {
    handleCommentsChange(val) {
      ///regex remove spaces if no letters or numbers
      if (val.match(/^\s*$/)) {
        this.entity.remarks = []
        this.entity.printedRemarks = []
      }
      if (!this.entity.remarks.length || !this.entity.printedRemarks.length) {
        if (!this.entity.remarks.length) {
          const newRemark = new RemarkModel(this.entity.creditReturnMainId, RemarkTypes.Remark, val)
          this.entity.remarks.push(newRemark)
        }
        if (!this.entity.printedRemarks.length) {
          const newRemark = new RemarkModel(this.entity.creditReturnMainId, RemarkTypes.PrintedRemark, val)
          this.entity.printedRemarks.push(newRemark)
        }
      } else {
        console.log('here')

        this.entity.remarks[0].remarks = val
        this.entity.printedRemarks[0].remarks = val
      }
    },
    // main function to select the invoice
    selectInvoice() {
      let invoices = []
      // ids of all items that were selected in parts control screen
      const purchaseOrderItemIds = this.selectedItems.map((item) => item.purchaseOrderItemId)
      // find all invoices that contain the selected items, and add them to the invoices array
      this.purchaseOrder.orderReceipts.forEach((receipt) => {
        const receiptItems = receipt.receiptItems.filter((item) => purchaseOrderItemIds.includes(item.purchaseOrderItemId))
        if (!!receiptItems.length) {
          invoices.push(receipt)
        }
      })
      this.creditorInvoiceId = invoices[0]?.creditorInvoiceId
      this.selectedInvoice = invoices[0]
      if (invoices.length > 1) {
        // if there are multiple invoices
        this.$notification.openNotificationWithType('warning', 'Return Parts', 'Multiple invoices found. Use invoice dropdown to select the invoice.')
        this.invoiceList = invoices
      }
    },
    async createNewReturnOrder() {
      const returnOrderModel = await PartsControlReturnPartsService.getNewEntity()
      const selectedItemIds = this.selectedItems.map((item) => item.purchaseOrderItemId)
      let selectedItems
      if (!!this.selectedItems.length) {
        selectedItems = this.selectedInvoice.receiptItems.filter((inv) => inv.received > 0 && selectedItemIds.includes(inv.purchaseOrderItemId))
      } else {
        selectedItems = this.selectedInvoice.receiptItems.filter((inv) => inv.received > 0 && inv.returnedQty < inv.received && inv.returnedQty < inv.received)
      }
      const newReturnOrder = this.addNewItems(returnOrderModel, selectedItems, this.selectedInvoice)
      // entity that will be used to modify the return order
      this.entity = newReturnOrder
      // snapshot of the entity, to compare changes
      this.snapShotEntity = _cloneDeep(newReturnOrder)
    },
    addNewItems(rOrder, selectedItems, invoice) {
      let returnOrder = _cloneDeep(rOrder)
      // these names are all over the place, different keys for different models :(
      returnOrder.vendorId = this.selectedInvoice.vendorId
      returnOrder.vendorAbn = this.selectedInvoice.abn
      returnOrder.vendorName = this.selectedInvoice.remarks
      selectedItems.forEach((item) => {
        // Find item by item no
        let itemExist = returnOrder?.returnPartsItem.filter((i) => i.poItemId === item.poItemID)
        // only inlcude item if in selected list
        if (itemExist.length !== 0) {
          this.$notification.openNotificationWithType('warning', 'Return Parts', 'Item is already in selected list. Item not added.')
        } else {
          const newItem = new ReturnItemPartsModel(
            item.vendorId,
            item.crReturnNo,
            item.creditReturnMainId,
            item.creditorInvoiceId,
            item.unitCost,
            invoice.invoiceNo,
            item.invoiceRef,
            item.model,
            item.partNo,
            item.purchaseOrderItemId,
            this.purchaseOrder.purchaseOrderNo,
            item.poRetQty,
            item.qtyCredited,
            this.purchaseOrder.quoteNo,
            item.receivedDate,
            item.received,
            item.return,
            item.returnPartsID,
            item.returnedQty, //19
            // returning qty
            item.received - item.returnedQty,
            item.description,
            item.totalAmount,
            item.returnQty
          )
          returnOrder?.returnPartsItem.push(newItem)
        }
      })
      return returnOrder
    },
    checkRecQty(part) {
      if (part.returning + part.returned > part.rced) {
        // let user know that the returning qty is more than received qty
        // part.returning = part.rced
        // this.$toast.open({
        //   message: 'Returned Quantity is now more than Received Quantity for the order receipt.',
        //   type: 'is-warning ',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.warning('','Returned Quantity is now more than Received Quantity for the order receipt.')

      }
    },
    async saveReturnOrder(isNew) {
      if (!this.validItemQtyForReturn) {
        this.$notification.openNotificationWithType('danger', 'Return Parts', 'Please select at least one part to return.')
        return false
      }

      this.confirmModalOpen = true
    },

    async createReturnOrder(isNew, printReturnOrder = false) {
      this.confirmModalOpen = false
      this.$showSpinner('Saving...')
      if (isNew) {
        const response = await PartsControlReturnPartsService.postEntity(this.entity)
        this.$hideSpinner()
        if (response) {
          if (printReturnOrder) {
            const creditNo = response.data.prefix + response.data.creditNo
            this.$emit('printReturnOrder', creditNo, response.data.vendorId)
          } else {
            this.$emit('close', 'Successfully returned Parts', true)
          }
        } else {
          this.$notification.openNotificationWithType('error', 'Return Parts', 'Could not Return Parts.')
        }
      }
    },
    handleInvoiceChange(invoice) {
      const difference = DeepDiff.diff(this.entity, this.snapShotEntity)
      console.log('difference', difference)
      ///TODO: modal to prompt user to save changes ???
      this.selectedInvoice = invoice
    },
    async getReturnerList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.returnerList = recList
      const currentReturner = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReturner = currentReturner
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  align-items: center;
  height: 42px !important;
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  # Description received returned  Price  Qty
  grid-template-columns: 50% 10% 10% 15% 15%;

  div {
    &.flex {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
