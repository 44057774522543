import Vue from 'vue'
import Vuex from 'vuex'
import plugins from './plugins'
import contacts from '@/views/contact/store'
import companysetting from '@/views/companysetting/store'
import vendors from '@/views/vendor/store'
import customers from '@/views/customer/store'
import vehicles from '@/views/vehicle/store'
import vehicleOptions from './vehicleOptions/store'
import sundrycreditors from '@/views/sundrycreditor/store'
import paymententries from '@/views/paymententry/store'
import quickinvoices from '@/views/quickinvoice/store'
import partscatalogues from '@/views/partscatalogue/store'
import salesanalysis from '@/views/salesanalysis/store'
import outstandingparts from '@/views/outstandingpart/store'
import debtorlists from '@/views/debtorlist/store'
import debtorsreceipts from '@/views/debtorreceipt/store'
import recurringremarks from '@/views/recurringremarks/store'
import quickitems from '@/views/quickitem/store'
import receiptentry from '@/views/receiptentry/store'
import items from '@/views/item/store'
import otherlabours from '@/views/otherlabour/store'
import insurers from '@/views/insurer/store'
import unscheduledmodels from '@/views/unscheduledmodel/store'
import ormmessages from '@/views/ormmessage/store'
import pnetmessages from '@/views/pnetmessage/store'
import estimagemessages from '@/views/estimagemessage/store'
import quotes from '@/views/quote/store'
import factorinvoice from '@/views/factorinvoice/store'
import advancereceipt from '@/views/advancereceipt/store'
import receiptpayment from '@/views/receiptpayment/store'
import emailsmslogs from '@/views/emailsmslog/store'
import debtoradjustments from '@/views/debtoradjustment/store'
import audittrail from  '@/views/audittrail/store'
import paymentlist from '@/views/paymentlist/store'
import gstreport from '@/views/gstreport/store'
import printpreview from '@/components/printpreview/store'
import userInfo from './userInfo/store'
import errors from './errors/store'
import labourTypes from './labourTypes/store'
import userSetting from './userSetting/store'
import vehicleLookup from '@/views/superadmin/vehiclelookup/store'
import creditorlist from  '@/views/creditorlist/store'
import ormbatching from  '@/views/ormbatching/store'
import purchaseorders from '@/views/purchaseorder/store'
import miscquotes from  '@/views/miscquote/store'
import returnparts from  '@/views/returnparts/store'
import dealercreditentry from '@/views/dealercreditentry/store'
import jobinvoiced from  '@/views/jobinvoiced/store'
import agent from  './agent/store'
import company from  './company/store'
import notification from  './notification/store'
import dashboard from '@/views/dashboard/store'
import booking from  '@/views/booking/store'
import repx from  '@/views/repx/store'
import companyonboarding from '@/views/company/store'
import subJobStage from '@/views/subjobstage/store'

import state from './state'
import mutations from './mutations'
import * as getters from './getters'
import * as actions from './actions'
import quotecommunications from '../views/quote/QuoteCommunicationsStore'
import quotetemplate from '@/views/quotetemplate/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    contacts,
    companysetting,
    customers,
    errors,
    insurers,
    ormmessages,
    pnetmessages,
    estimagemessages,
    quickinvoices,
    recurringremarks,
    quickitems,
    receiptentry,
    items,
    otherlabours,
    quotes,
    sundrycreditors,
    paymententries,
    printpreview,
    userInfo,
    labourTypes,
    vendors,
    vehicleOptions,
    vehicles,
    unscheduledmodels,
    emailsmslogs,
    partscatalogues,
    salesanalysis,
    outstandingparts,
    debtorlists,
    debtorsreceipts,
    debtoradjustments,
    audittrail,
    factorinvoice,
    receiptpayment,
    advancereceipt,
    userSetting,
    vehicleLookup,
    creditorlist,
    paymentlist,
    gstreport,
    ormbatching,
    purchaseorders,
    miscquotes,
    returnparts,
    dealercreditentry,
    jobinvoiced,
    company,
    agent,
    notification,
    dashboard,
    quotecommunications,
    quotetemplate,
    booking,
    repx,
    companyonboarding,
    subJobStage
  },
  plugins,
  strict: process.env.NODE_ENV !== 'production'
})

export default store
