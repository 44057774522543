<template>
  <div>
    <div v-for="i in structuredData"
      :key="i.name">
      <div class="fixed-grid has-12-cols quote-builder-section-item p-2 template-list-item is-clickable mb-1 "
        @click="handleOpen(i.name)">
        <div class="grid">
          <!-- Description -->
          <div class="cell is-col-span-8">{{ i.name }}</div>
          <!-- R&R, Repair and Paint values -->
          <div class="cell is-col-span-3">
            <div v-if="isOpen(i.name)"
              class="is-flex is-justify-content-end">
              Add
            </div>
          </div>
          <!-- Dropdown Arrow -->
          <div class="cell is-col-span-1 is-flex is-justify-content-end">
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-if="!isOpen(i.name)">
              chevron_right
            </span>
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-else>
              keyboard_arrow_down
            </span>
          </div>
        </div>
      </div>

      <div v-if="isOpen(i.name)">
        <div v-for="(item, index) in i.items"
          :key="index"
          class="fixed-grid has-12-cols p-1 vehicle-section-item mb-1 b "
          :class="!checkFavorite(item) && !i.open ? 'hidden' : ''">
          <div class="grid ">
            <!-- item name -->
            <div class="cell is-col-span-1">
              <span>{{ item.id }}</span>
            </div>

            <div class="cell is-col-span-8">{{ item.description }}</div>

            <!-- R&R, Repair and Paint values -->
            <div class="cell is-col-span-2">

              <div class="butterfly-item-values is-flex is-justify-content-center"
                @click="addNewItem(item, index)"
                :class="getExistingItem(item) ? 'has-background-primary is-selected' : ''">
                +
              </div>
            </div>

            <!-- star favourite -->
            <div class="cell is-col-span-1 is-flex is-justify-content-end is-clickable"
              :class="checkFavorite(item) ? 'has-text-gold' : ''"
              @click="handleFavouriteClick(item)">
              <span class="material-symbols-rounded">
                star
              </span>
            </div>
          </div>
        </div>

        <!-- show more /less -->
        <div class="mb-2">
          <div class="button has-text-primary px-2"
            v-if="!i.open"
            @click="i.open = true">
            <span class="material-symbols-rounded">
              arrow_downward
            </span>
            <span>Load more</span>
          </div>
          <div class="button has-text-primary px-2"
            v-else
            @click="i.open = false">
            <span class="material-symbols-rounded">
              arrow_upward
            </span>
            <span>Show Less</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { QuoteItemModel } from '@/classes/viewmodels'
import { ItemCategoryTypes, PartTypes } from '@/enums'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'QuoteBuilderPartComponent',
  props: {
    structuredData: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    quoteId: {
      type: String,
      required: true
    },
    nextLineNumber: {
      type: Number,
      required: true
    },
    isAudanet: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      expandedItems: []
      // Add your component data here
    }
  },
  computed: {
    itemCategoryTypes() {
      return ItemCategoryTypes
    }
  },
  methods: {
    checkFavorite(item) {
      return item?.isFavouritePart
    },
    isOpen(name) {
      return this.expandedItems.includes(name)
    },
    handleOpen(name) {
      if (this.expandedItems.includes(name)) {
        this.expandedItems = this.expandedItems.filter((item) => item !== name)
      } else {
        this.expandedItems.push(name)
      }
    },
    handleFavouriteClick(item) {
      item.isFavouritePart = !item.isFavouritePart
    },
    getExistingItem(item) {
      return this.value.find((i) => i.itemDesc === item.description && i.itemNo === item.id && !i.deleted && !i.deleteOtherItem)
    },
    addNewItem(item, index) {
      const existingItem = this.getExistingItem(item)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.id, item.description, ItemCategoryTypes.PART)
        newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
        newItem.itemQuantity = 1
        newItem.value = 0
        newItem.mark = PartTypes.NEW.value
        newItem.markupPercent = 0
        newItem.markupValue = 0
        newItem.buyPrice = 0
        newItem.partNo = ''
        newItem.side = 'R'
        newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        if (this.isAudanet) {
          newItem.rev = QuoteItemRevTypes.NonAudaNetPart
        }
        this.$emit('item-added', newItem)
      } else {
        this.$eventHub.$emit('deletePart', existingItem)
      }
    }
  }
}
</script>

<style scoped>
.quote-builder-labour-component {
  /* Add your component styles here */
}
</style>