const errorMessages = {
  'default': {
    title: 'Something wrong',
    message: 'Something wrong. Please get in touch with support.'
  },
  'password_not_meet_criteria': {
    title: 'Password not meet criteria',
    message: 'The password you provided does not meet criteria.'
  },
  'forget_password_request_not_found': {
    title: 'Forget Password Request not found',
    message: 'Forget password request not found. Please send a new request.'
  },
  'no_valid_user_found': {
    title: 'No valid user found',
    message: 'We could not locate a user matching information you provided. Please get in touch with support.'
  },
  'failed_send_email_with_mailgun': {
    title: 'Failed to send an email',
    message: 'Failed to send an email. Please get in touch with support.'
  },
  'failed_create_password_entry': {
    title: 'Failed to create password entry',
    message: 'Failed to create password entry. Please get in touch with support.'
  },
  'failed_insert_emailoutgoing': {
    title: 'Failed to insert email outgoing record',
    message: 'Failed to insert email outgoing record. Please get in touch with support.'
  },
  'password_reset_link_expired': {
    title: 'Expired password reset link',
    message: 'The password reset link has expired. Please send a new request.'
  },
  'password_reset_link_used': {
    title: 'Used password reset link',
    message: 'The password reset link has already been used. Please send a new request.'
  },
  'not_valid_user_token': {
    title: 'Invalid user token',
    message: 'Not valid user. Please contact support.'
  }
}

export const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || errorMessages['default']
}