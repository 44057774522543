<template>
  <quote-builder-labour-item-template :is-full-width="isFullWidth"
    :is-header="true"
    :is-quote-hour="isQuoteHour"
    :is-paint-item="isPaintItem"
    :show-paint-loading="showLoading"
    :is-ntar="isNtar"
    class=" has-text-weight-bold bb-primary-1 pb-2 mb-2 has-background-white quote-item-heading">
    <template v-slot:description>
      <div class="is-flex is-align-items-center has-text-weight-bold">
        <span>{{ name }}</span>
        <span class="ml-2">({{ count }})</span>
        <!-- <span class=" ml-5 is-size-6 has-background-primary has-text-white px-2 is-rounded">
          {{ count }}
        </span> -->
      </div>
    </template>

    <template v-slot:loading>
      <div class="is-flex is-justify-content-center is-align-items-center mx-2">
        <span>Load</span>
      </div>
    </template>

    <template v-slot:paint_group>
      <div class="is-flex is-justify-content-center is-align-items-center mx-2 has-text-weight-bold">
        <span>Pt Grp</span>
      </div>
    </template>

    <template v-slot:report>
      <div class="is-flex is-align-items-center has-text-weight-bold">
        <span>Rpt</span>
      </div>
    </template>

    <template v-slot:rates>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>Rate</span>
      </div>
    </template>

    <template v-slot:blend>
      <div class="is-flex is-align-items-center has-text-weight-bold">
        <span>Blend</span>
      </div>
    </template>

    <template v-slot:hour_value>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>{{ isQuoteHour ? 'H(s)' : 'Value' }}</span>
      </div>
    </template>

    <template v-slot:total_value>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>{{ $filters.formatCurrency(totalValue, $userInfo.locale) }}</span>
      </div>
    </template>

    <template v-slot:present_as>
      <div class="is-flex is-justify-content-center is-align-items-center mx-2 has-text-weight-bold">
        <span>Present As</span>
      </div>
    </template>
  </quote-builder-labour-item-template>
</template>

<script>
import QuoteBuilderLabourItemTemplate from '@/views/quote/quoteBuilder/templates/QuoteBuilderLabourItemTemplate.vue'

export default {
  name: 'QuoteBuilderLabourHeadingsComponent',
  components: {
    QuoteBuilderLabourItemTemplate
  },
  props: {
    name: {
      type: String,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: 0
    },
    totalValue: {
      type: Number,
      default: 0
    },
    isQuoteHour: {
      type: Boolean,
      default: false
    },
    isPaintItem: {
      type: Boolean,
      default: false
    },
    isNtar: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  }
  // Your component's logic goes here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>