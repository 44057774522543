<template>
  <div v-if="$userInfo.isSupportUser"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div>
        <h3 class="subtitle mb-5">RepX Stages Setup</h3>
      </div>
      <div class="columns">
        <div class="column is-four-fifths">
          <div class="columns">
            <div class="column is-half">
              <span class="subtitle">Repair Stages</span>
            </div>
            <div class="column is-half">
              <span class="subtitle">FlexiQuote Stages</span>
            </div>
          </div>
          <div v-for="(stage, key) in repxStages"
            :key="key">
            <div class="pb-3"
              v-if="Array.isArray(repxStageSettings[key])">
              <ExpendableDiv :ref="setExpendableDivRefs"
                :title="castToTitleCase(stage)"
                :sub-title="Array.isArray(repxStageSettings[key]) && Array.isArray(repxStageSettings[key][0]) && repxStageSettings[key][0][0] ? repxStageSettings[key][0][0].jobStageName : ''"
                expended-title="RepX Stage"
                expended-sub-title="Map With">
                <div class="columns">
                  <div class="column is-half">
                    <!-- <div class="columns">
                      <div class="label pl-5 pt-1">{{ castToTitleCase(stage) }}</div><br>
                      <div class="pl-5 py-1">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="repxStageSettings[key][0][0].isSubJobStageCreated">
                          <div class="state p-primary">
                            <i class="icon mdi mdi-check" />
                            <label>Create Sub Stage (set multiple steps within 1 System Stage)</label>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <input v-show="repxStageSettings[key][0][0].isSubJobStageCreated"
                          v-model="repxStageSettings[key][0][0].subJobStageName"
                          class="input"
                          type="text"
                          placeholder="Sub Stage">
                      </div>
                    </div> -->
                    <div class="tile is-ancestor no-margin">
                      <div class="tile is-parent is-vertical">
                        <div class="tile is-child">
                          <div class="label">{{ castToTitleCase(stage) }}</div>
                        </div>
                        <!-- <div v-if="repxStageSettings[key][0][0]"
                          class="tile is-child">
                          <div class="pretty p-icon p-curve p-smooth p-bigger">
                            <input type="checkbox"
                              v-model="repxStageSettings[key][0][0].isSubJobStageCreated">
                            <div class="state p-primary">
                              <i class="icon mdi mdi-check" />
                              <label>Create Sub Stage (set multiple steps within 1 System Stage)</label>
                            </div>
                          </div>
                        </div>
                        <div v-if="repxStageSettings[key][0][0]"
                          class="tile is-child">
                          <input v-show="repxStageSettings[key][0][0].isSubJobStageCreated"
                            v-model="repxStageSettings[key][0][0].subJobStageName"
                            class="input"
                            type="text"
                            placeholder="Sub Stage">
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="column is-half">
                    <div v-for="(input, index) in repxStageSettings[key]"
                      :key="index">
                      <div>
                        <Multiselect v-model="repxStageSettings[key][index]"
                          :options="jobStagesAvailable"
                          :hide-selected="true"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :show-labels="false"
                          :preserve-search="true"
                          placeholder="Please select a Job Stage"
                          track-by="compositeKey"
                          label="compositeName"
                          @input="limitSelection($event, key)" />
                      </div>
                    </div>
                    <!-- <div class="field">
                      <div class="select is-fullwidth">
                        <select v-model="repxStageSettings[key][0]">
                          <option v-for="jobNSubJobStage in jobNSubJobStages"
                            :key="jobNSubJobStage.compositeKey"
                            :value="jobNSubJobStage">{{ jobNSubJobStage.compositeName }}</option>
                        </select>
                      </div>
                    </div> -->
                  </div>
                </div>
              </ExpendableDiv>
            </div>
          </div>
          <!-- <div v-for="(stage, key) in repxStages"
            :key="key">
            <div v-for="(input, index) in repxStageSettings[key]"
              :key="index">
              <div class="pt-2">
                <div class="label">{{ stage }}</div>
                <Multiselect v-model="repxStageSettings[key][index]"
                  :options="jobStagesAvailable"
                  :hide-selected="true"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :show-labels="false"
                  :preserve-search="true"
                  placeholder="Please Select At one Job stage"
                  track-by="compositeKey"
                  label="compositeName" />
              </div>
            </div>
          </div> -->
          <!-- <pre>{{ repxStageSettings }}</pre> -->
          <!-- <div class="buttons mt-5">
            <button class="button is-primary tooltip"
              data-tooltip="Save"
              @click="updateRepxStagesSetting">
              <span class="icon">
                <i class="mdi mdi-content-save mdi-20px" />
              </span>
              <span>Save</span>
            </button>
          </div> -->
        </div>
        <div class="column is-one-fifths">
          <h3 class="subtitle">Available Stages</h3>
          <!-- <pre>{{ jobStages }}</pre> -->
          <!-- <pre>{{ jobNSubJobStages }}</pre> -->
          <ul>
            <li v-for="item in jobStages"
              :key="item.stage"
              class="has-background-light has-text-weight-bold is-flex">
              <span class="mt-2 job-stage">
                {{ item.description }}
              </span>
              <i class="icon mdi mdi-24 mdi-layers is-align-self-center"
                v-show="isMultipleSelectedStage(item.stage)" />
              <i class="icon mdi mdi-24 mdi-check is-align-self-center"
                v-show="isSelectedStage(item.stage)" />
            </li>
          </ul>

        </div>
      </div>

    </section>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import QuoteService from '../../quote/QuoteService'
import CompanyService from '../../company/CompanyService'
import { RepxStages } from '@/enums'
import ExpendableDiv from '../components/ExpendableDiv'
import { toTitleCase } from '@/utils/StringFunctions'
// import EventBus from '@/components/EventBus'
import store from '@/store'

export default {
  name: 'CompanyRepxStagesMappingSetup',

  components: {
    Multiselect,
    ExpendableDiv
  },

  data: () => {
    return {
      // users: [],
      // usersWithAccess: [],
      showSave: true,
      repxStageSettings: {},
      repxRepairStages: [],
      jobStages: [],
      usedStages: [],
      jobNSubJobStages: []
    }
  },
  computed: {
    repxStages() {
      return RepxStages
    },
    jobStagesAvailable() {
      var finds = []
      for (let key in this.repxStages) {
        if (this.repxStageSettings[key] != undefined && this.repxStageSettings[key][0] != '')
          this.repxStageSettings[key][0].forEach((item) => {
            finds.push(item)
          })
      }
      var filterItem = this.jobNSubJobStages.filter((stage) => !finds.some((a) => a.compositeKey == stage.compositeKey))
      return filterItem
    },
    serilaizeData() {
      const serializedData = Object.entries(this.repxStageSettings).map(([key, value]) => ({
        StageName: key,
        Stages: value
      }))
      return serializedData
    }
  },
  watch: {
    repxStageSettings: {
      handler(newVal, oldVal) {

      },
      deep: true
    }
  },
  async created() {
    // await this.fetchUsers()
    // await this.fetchUsersWithXeroAccess()
    this.refresh()
  },
  mounted() {
    // EventBus.$emit('showSave', this.showSave)
  },
  methods: {
    setExpendableDivRefs(el) {
      if (!this.$refs.expendableDivRefs) {
        this.$refs.expendableDivRefs = []
      }
      if (el) {
        this.$refs.expendableDivRefs.push(el)
      }
    },
    resetExpendableDivs() {
      if (!this.$refs.expendableDivRefs) {
        return
      }
      (this.$refs.expendableDivRefs).forEach(i => {
        if (i.isExpanded)
          i.toggle()
      })
    },
    async refresh() {
      await this.fetchJobStages()
      await this.fetchJobNSubJobStages()
      await this.fetchRepxRepairStages()
      // Initialize repxStageSettings for each stage
      //this.populateRepxStages()
      this.populateRepxStages()
      this.resetExpendableDivs()
    },
    limitSelection(event, key) {
      if (event.length > 1) {
        this.repxStageSettings[key][0] = [event[event.length - 1]]
      }
    },
    isSelectedStage(jobStageId) {
      let isSelected = false
      if (this.repxStageSettings) {
        for (let key in this.repxStageSettings) {
          const setting = this.repxStageSettings[key]
          if (setting) {
            setting.map((stages) => {
              if (Array.isArray(stages)) {
                stages.map((stage) => {
                  if (!isSelected && stage && stage.jobStageId == jobStageId) {
                    isSelected = true
                  }
                })
              }
            })
          }
        }
      }
      return isSelected
    },
    isMultipleSelectedStage(jobStageId) {
      let count = 0
      let isMulti = false
      if (this.repxStageSettings) {
        for (let key in this.repxStageSettings) {
          const setting = this.repxStageSettings[key]
          if (setting) {
            setting.map((stages) => {
              if (Array.isArray(stages)) {
                stages.map((stage) => {
                  if (!isMulti && stage && stage.jobStageId == jobStageId) {
                    count++
                  }
                  if (count >= 2) isMulti = true
                })
              }
            })
          }
        }
      }
      return isMulti
    },
    castToTitleCase(str) {
      return toTitleCase(str)
    },
    async fetchJobStages() {
      this.jobStages = await QuoteService.getJobStages()
    },
    async fetchJobNSubJobStages() {
      // const _jobNSubJobStages = await QuoteService.getJobNSubJobStages()
      const _jobNSubJobStages = await QuoteService.getJobNSubJobStagesWithparent()

      this.jobNSubJobStages = _jobNSubJobStages
    },
    async fetchRepxRepairStages() {
      this.repxRepairStages = await CompanyService.getRepxStagesSetting()
    },
    async populateRepxStages() {
      for (let key in this.repxStages) {
        var repxStageItems = this.repxRepairStages.filter((o) => o.repairStage == key)
        if (repxStageItems.length > 0) {
          const items = this.jobNSubJobStages.filter((stage) => repxStageItems.some((a) => a.compositeKey == stage.compositeKey))
          var container = []
          container.push(items)
          this.$set(this.repxStageSettings, key, container)
        } else this.$set(this.repxStageSettings, key, [''])
      }
    },
    async updateRepxStagesSetting() {
      // if (!user) return this.$notification.error('Error', 'Please select at least one user')
      // const userIds = user.map((u) => ({ userId: u.userId }))

      try {
        this.$showSpinner()
        // var data = this.serilaizeData
        // console.log(data)
        const result = await CompanyService.updateRepxStagesSetup(this.serilaizeData)

        const { isSuccess, message } = result
        if (!isSuccess && message) {
          this.$toast.open({
            message: message,
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
          // this.$notification.error('Error', message)
          return
        }

        await store.dispatch('quotes/resetJobNSubJobStages')
        this.$toast.open({
            message: 'Settings Updated',
            type: 'is-success',
            position: 'is-top',
            queue: false
          })
        // this.$notification.success('Success', 'Settings Updated')
        this.refresh()

      } catch (error) {
        this.$toast.open({
            message: 'Failed Settings Updated',
            type: 'is-danger',
            position: 'is-top',
            queue: false
          })
        // this.$notification.error('Error', 'Failed Settings Updated')
      } finally {
        this.$hideSpinner()
      }
    },
    async save() {
      this.updateRepxStagesSetting()
    }
  }
}
</script>

<style lang="scss" scoped>
.job-stage {
  display: block;
  width: 100%;
  padding: 0.5em;
  font-size: 0.9em;
}
.tile.is-vertical {
  margin-left: 1rem;
}
.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 0.5rem !important;
}
.tile.is-ancestor.no-margin {
  padding-left: 0.5rem;
}
</style>