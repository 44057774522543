<template>

  <div class="has-text-white"
    style="height: 100%; position: relative;">

    <!-- <div class="is-flex is-flex-direction-row show-weekend is-hidden">
      <div class="control"
        style="width: 5em;">
        <div class="pretty p-switch p-fill">
          <input type="checkbox"
            @change="handleToggleWeekend(!isShowWeekend)"
            :checked="isShowWeekend">
          <div class="state p-primary">
            <label>Show Weekends</label>
          </div>
        </div>
      </div>
    </div> -->

    <div class="is-flex is-justify-content-space-between booking-header">

      <span>Bookings</span>
      <span>{{ totalBookings || 0 }}</span>

      <div style="position:absolute; top: -4px; left: -46px;"
        @click="$emit('handleSidePanelToggle')">

        <div class="side-panel-slide-button">
          <span class="mdi mdi-30px"
            :class="{ 'mdi-chevron-left': !isSidePanelOpen, 'mdi-chevron-right': isSidePanelOpen }" />
        </div>
      </div>

    </div>

    <div class="field search-field">
      <div class="control has-icons-right">
        <input class="input is-capitalized"
          type="text"
          placeholder="Search"
          v-model="searchTextField"
          @keyup.enter="onSearch()">
        <span v-if="!!searchTextField"
          class="icon is-right is-clickable"
          @click="clearSearch">
          <i class="mdi mdi-close has-text-white" />
        </span>
      </div>
    </div>

    <div id="myDraggable"
      class="quote-card-section thin-scrollbar">
      <div v-for="booking in filteredBookings"
        class="quote-card mb-3 draggable-item"
        :key="booking.quoteBookingId"
        :data-event="JSON.stringify({ uniqueId: booking.quoteBookingId, title: booking.vehicleRegistrationNo })">
        <div class="is-flex is-justify-content-space-between quote-card-header">
          <span>
            <div>
              <i class="mdi mdi-clock" />
              <span class="is-size-6">{{ booking.vehicleRegistrationNo }}</span>
            </div>
          </span>
          <div>
            <span class="is-size-7">
              {{ $filters.formatDateTimeLocale(`${booking.createdDate}`, $userInfo.locale) }}</span>
            <!-- <a class="tooltip is-tooltip-left"
              data-tooltip="Edit"
              @click="editBooking()">
              <span class="icon has-text-primary">
                <i class="mdi mdi-pencil mdi-18px has-text-white" />
              </span>
            </a> -->
          </div>
        </div>
        <div class="quote-body">
          <div v-if="bookings.bookingType || bookings.brand"
            class="is-flex is-justify-content-space-between my-1">
            <span>{{ getNameById(storeBookingTypes, booking.bookingType) }}</span>
            <span>{{ getNameById(storeIntegrationTypes, booking.integrationType) }}</span>
          </div>
          <div class="is-flex is-justify-content-space-between vehicle-info">
            <div class="is-flex is-flex-direction-row">
              <div>{{ booking.vehicleMake }}</div>
              <div class="ml-1">{{ booking.vehicleModel }}</div>
            </div>

            <span>{{ $filters.formatCurrency(booking.excess, $userInfo.locale, false, 2) }}</span>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import BookingService from './BookingService'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingCalendarSidePanel',
  components: {},
  props: {
    isSidePanelOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      searchTextField: null,
      selectedSort: '0',
      bookings: [],
      filteredBookings: [],
      isShowWeekend: true
    }
  },
  computed: {
    totalBookings() {
      return this.bookings.length
    },
    ...mapGetters('booking', {
      storeIntegrationTypes: 'integrationTypes',
      storeBookingTypes: 'bookingTypes'
    })
  },
  watch: {},
  async created() {
    this.$store.dispatch('booking/load')
    this.refresh()
  },
  mounted() {},
  methods: {
    async refresh() {
      this.bookings = await this.getBookings()
      this.filteredBookings = this.bookings
    },
    async getBookings() {
      const data = await BookingService.getBookings()
      return data
    },
    getNameById(array, id) {
      const item = array.find((element) => element.id === id)
      return item ? item.name : null // Return the name if found, otherwise null
    },
    handleToggleWeekend(isShow) {
      this.isShowWeekend = isShow
      this.$emit('toggleWeekend', this.isShowWeekend)
    },
    remove(id) {
      if (this.filteredBookings) {
        let indexToRemove = this.filteredBookings.findIndex((item) => item.quoteBookingId === id)
        if (indexToRemove !== -1) {
          this.filteredBookings.splice(indexToRemove, 1)
        }
      }
    },
    async filterBookings() {
      if (!this.searchTextField) {
        this.filteredBookings = this.bookings
      } else {
        const text = this.searchTextField.toLowerCase()
        let filtered = this.bookings.filter((i) => {
          const regex = new RegExp(text, 'i')
          return Object.values(i).some((value) => regex.test(String(value)))
        })
        this.filteredBookings = filtered
      }
    },
    onSearch() {
      this.filterBookings()
    },
    clearSearch() {
      this.searchTextField = null
      this.filterBookings()
    }
    // handleQuoteRedirect(quote) {
    //   this.$emit('handleQuoteRedirect', quote)
    // },
    // editBooking() {
    //   this.$emit('editBooking')
    // }
  }
}
</script>

<style lang="scss">
.show-weekend {
  margin-bottom: 1em;
}

.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #58a1e8;
  padding-bottom: 1em;
  border-bottom: 2px solid #58a1e8;
  margin-bottom: 1em;
}

.search-field-dark {
  .select select,
  .textarea,
  .input {
    background-color: #495057;
    border: #495057;
    color: #fff;
    &::placeholder {
      color: rgb(255, 255, 255, 0.5);
    }
  }
}

.quote-card-section {
  height: 79%;
  overflow-y: auto;
  // padding-right: 0.25em;
}

.quote-card {
  width: 100%;
  background: #004a95;
  border: solid 1px #dee7f1;
  border-radius: 0.3em;
  color: #fff;
  cursor: pointer;
  // opacity: 0.8;

  &:hover {
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }

  .quote-card-header {
    width: 100%;
    padding: 0.3em 0.5em;
    background-color: #fff;
    color: #2874BF;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
  }

  .quote-body {
    padding: 0.5em 0.6em;
    font-size: 14px;
    background-color: #2874BF;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;

  }
}

.side-panel-slide-button {
  background-color: #2874bf;
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transition: all 0.25s ease-in-out;
    background-color: #058cba;
  }
}

.booking-panel i.mdi-clock {
  font-size: 1.1em;
}
</style>
