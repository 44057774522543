<template>
  <div>
    <div v-for="i in structuredData"
      :key="i.name">
      <div class="fixed-grid has-12-cols quote-builder-section-item p-2 template-list-item is-clickable mb-1 "
        @click="handleOpen(i.name)">
        <div class="grid">
          <!-- Description -->
          <div class="cell is-col-span-8">{{ i.name }}</div>
          <!-- R&R, Repair and Paint values -->
          <div class="cell is-col-span-3">
            <div class="fixed-grid has-3-cols"
              v-if="isOpen(i.name)">
              <div class="grid">
                <div class="cell is-col-span-1 ">
                  R&R
                </div>
                <div class="cell is-col-span-1">
                  Repair
                </div>
                <div class="cell is-col-span-1">
                  Paint
                </div>
              </div>
            </div>
          </div>
          <!-- Dropdown Arrow -->
          <div class="cell is-col-span-1 is-flex is-justify-content-end">
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-if="!isOpen(i.name)">
              chevron_right
            </span>
            <span class="material-symbols-outlined is-clickable has-text-primary mx-2"
              v-else>
              keyboard_arrow_down
            </span>
          </div>
        </div>
      </div>

      <div v-if="isOpen(i.name)">
        <div v-for="(item, index) in i.items"
          :key="index"
          class="fixed-grid has-12-cols p-1 vehicle-section-item mb-1 b "
          :class="!checkFavorite(item) && !i.open ? 'hidden' : ''">
          <div class="grid ">
            <!-- item name -->
            <div class="cell is-col-span-1">
              <span>{{ item.id }}</span>
            </div>

            <div class="cell is-col-span-7">{{ item.description }}</div>

            <!-- R&R, Repair and Paint values -->
            <div class="cell is-col-span-3">
              <div class="fixed-grid has-3-cols">
                <div class="grid">
                  <div class="cell is-col-span-1 butterfly-item-values is-flex is-justify-content-center"
                    @click="handleItemClick(item, 'rr')"
                    :class="item.selectedLabours.rr ? selectedVehicleSection == vehicleSectionOptions.replace.key ? 'is-selected has-background-success' : 'is-selected has-background-primary' : ''">
                    {{ item.RRHour }}
                  </div>
                  <div class="cell is-col-span-1 butterfly-item-values is-flex is-justify-content-center "
                    @click="handleItemClick(item, 'repair')"
                    :class="item.selectedLabours.repair ? selectedVehicleSection == vehicleSectionOptions.replace.key ? 'is-selected has-background-success' : 'is-selected has-background-primary' : ''">
                    0.00
                  </div>
                  <div class="cell is-col-span-1 butterfly-item-values is-flex is-justify-content-center"
                    @click="handleItemClick(item, 'paint')"
                    :class="item.selectedLabours.paint ? selectedVehicleSection == vehicleSectionOptions.replace.key ? 'is-selected has-background-success' : 'is-selected has-background-primary' : ''">
                    {{ item.PaintHour }}
                  </div>
                </div>
              </div>
            </div>

            <!-- star favourite -->
            <div class="cell is-col-span-1 is-flex is-justify-content-end is-clickable"
              :class="checkFavorite(item) ? 'has-text-gold' : ''"
              @click="handleFavouriteClick(item)">
              <span class="material-symbols-rounded">
                star
              </span>
            </div>
          </div>
        </div>

        <div class="is-flex is-justify-content-space-between my-2">
          <div class="button has-text-primary px-2"
            v-if="!i.open"
            @click="i.open = true">
            <span class="material-symbols-rounded">
              arrow_downward
            </span>
            <span>Load more</span>
          </div>
          <div class="button has-text-primary px-2"
            v-else
            @click="i.open = false">
            <span class="material-symbols-rounded">
              arrow_upward
            </span>
            <span>Show Less</span>
          </div>

          <div class="button has-text-primary px-2"
            @click="addNewItems(i.items, !i.open)">
            <span class="material-symbols-rounded">add</span>
            <span>Add to Quote</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import { QuoteItemModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'QuoteBuilderLabourComponent',
  props: {
    quoteId: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },

    nextLineNumber: {
      type: Number,
      required: true
    },
    avaliableItemsType: {
      type: String,
      required: true
    },
    structuredData: {
      type: Array,
      required: true
    },
    selectedVehicleSection: {
      type: String,
      required: true
    },
    vehicleSectionOptions: {
      type: Object,
      required: true
    },
    rrRate: {
      type: Number,
      required: true
    },
    repairRate: {
      type: Number,
      required: true
    },
    paintRate: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expandedItems: [],
      addedItems: []
    }
  },
  computed: {
    maxAddedItemsLineNumber() {
      return Math.max(...this.addedItems.map((a) => a.lineNumber), 0)
    }
  },
  methods: {
    isOpen(name) {
      return this.expandedItems.includes(name)
    },
    handleOpen(name) {
      if (this.expandedItems.includes(name)) {
        this.expandedItems = this.expandedItems.filter((item) => item !== name)
      } else {
        this.expandedItems.push(name)
      }
    },
    handleItemClick(item, type) {
      if (type === 'rr') {
        item.selectedLabours.rr = !item.selectedLabours.rr
      } else if (type === 'repair') {
        item.selectedLabours.repair = !item.selectedLabours.repair
      } else {
        item.selectedLabours.paint = !item.selectedLabours.paint
      }
    },
    checkFavorite(item) {
      return this.selectedVehicleSection == this.vehicleSectionOptions.repair.key ? item.isFavoriteRepair : item.isFavoriteReplace
    },
    handleFavouriteClick(item) {
      if (this.selectedVehicleSection == this.vehicleSectionOptions.repair.key) {
        item.isFavoriteRepair = !item.isFavoriteRepair
      } else {
        item.isFavoriteReplace = !item.isFavoriteReplace
      }
    },
    addNewItems(items, favoritesOnly) {
      let itemsToAdd = items.filter((item) => {
        if (favoritesOnly) {
          return this.checkFavorite(item)
        } else {
          return true
        }
      })

      itemsToAdd.forEach((item) => {
        if (!!item.selectedLabours.rr) {
          this.addSingleNewItem(item, ItemCategoryTypes.RR, false, true)
        }
        if (!!item.selectedLabours.repair) {
          this.addSingleNewItem(item, ItemCategoryTypes.REP, false, true)
        }
        if (!!item.selectedLabours.paint) {
          this.addSingleNewItem(item, ItemCategoryTypes.PAINT, false, true)
        }
      })

      if (!_isEmpty(this.addedItems)) {
        // const vm = this
        // this.addedItems.forEach(function(newItem) {
        //   vm.innerValue.splice(vm.innerValue.length, 1, newItem)
        // })
        this.$emit('item-added', this.addedItems)
        this.addedItems = []
      }
    },

    addSingleNewItem(item, type, emit = false, bulkAdd = false) {
      console.log('addSingleNewItem', item, type, emit, bulkAdd)

      let hour = 0
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.RR:
          hour = item.RRHour
          rate = this.isNtar ? this.shopRate : this.rrRate
          break
        case ItemCategoryTypes.REP:
          hour = item.RepHour
          rate = this.isNtar ? this.shopRate : this.repairRate
          break
        case ItemCategoryTypes.PAINT:
          hour = item.PaintHour
          rate = this.isNtar ? this.shopRate : this.paintRate
          break
      }

      const existingItem = this.findItem(item, type)

      if (!existingItem) {
        const newItem = this.generateItem(item.id, item.description, hour, rate, type)
        this.addedItems.splice(this.addedItems.length, 1, newItem)
        if (emit) {
          // this.innerValue.splice(this.innerValue.length, 1, newItem)
          this.$emit('item-added', this.addedItems)
          this.addedItems = []
        }
      } else {
        if (!bulkAdd) {
          this.$eventHub.$emit('deleteLabour', existingItem)
        } else this.$notification.openNotificationWithType('warning', 'Labour', `${type} item is already in selected list. Item not added.`)
      }
    },
    findItem(item, type) {
      const existingItem = this.value.find(
        (i) => i.itemDesc === item.description && i.itemNo === item.id && !i.deleted && i.itemType === type && !i.deleteOtherItem
      )
      return existingItem
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.quoteId, itemNo, description, type)
      newItem.lineNumber = Math.max(this.maxAddedItemsLineNumber + 1, this.nextLineNumber)
      newItem.hourValue = this.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehiclePaintGroup
      newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
      return newItem
    }
  }
}
</script>

<style scoped>
.quote-builder-labour-component {
  /* Add your component styles here */
}
</style>