<template>
  <div class="forgot-password-container
    is-flex is-align-items-center is-justify-content-center">
    <component :is="randomiseSpinner"
      v-show="$showSpinnerRequestCount > 0"
      message="Loading..." />
    <div class="box">
      <div class="control mb-5 is-flex is-justify-content-center">
        <img src="@/assets/images/flexiquote-logo.svg"
          style="max-height: 2.25rem"
          alt="Flexiquote Logo"
          title="Flexiquote Logo">
      </div>

      <div class="control mb-5">
        <h4 class="has-text-weight-medium is-size-5">Reset your password</h4>
        <p class="has-text-grey is-size-7">Please enter your username and company ID,
          and we will email you instructions on how to reset your password.
        </p>
      </div>

      <div v-if="!isRequestComplete">
        <div class="control mb-6">
          <div class="field">
            <label class="label is-required-label">Username</label>
            <input class="input"
              type="text"
              v-model="username"
              placeholder="Username">
            <span v-if="$v.username.$dirty && !$v.username.required"
              class="help is-danger ml-1">Required</span>
          </div>

          <div class="field">
            <label class="label is-required-label">Company ID</label>
            <input class="input"
              type="text"
              v-model="companyLoginId"
              placeholder="Company ID">
            <span v-if="$v.companyLoginId.$dirty && !$v.companyLoginId.required"
              class="help is-danger ml-1">Required</span>
          </div>

          <div class="field">
            <label class="is-required-label" />
            <label class="is-size-7">Required Fields</label>
          </div>
        </div>

        <div class="control mb-2 is-flex is-justify-content-space-between">
          <button class="button"
            @click="cancel">
            <span class="icon mr-1">
              <i class="mdi mdi-18px mdi-close" />
            </span>
            Cancel</button>
          <button class="button is-primary"
            @click="sendEmail">
            <span class="icon mr-1">
              <i class="mdi mdi-18px mdi-send" />
            </span>
            Send Email</button>
        </div>
      </div>

      <div v-if="isRequestComplete">
        <notification-box class="mb-6"
          :type="notificationType">
          <p class="is-size-7"
            slot="text-content">{{ message }}</p>
        </notification-box>

        <div class="control is-flex is-justify-content-end">
          <button class="button is-primary"
            @click="cancel">
            <span class="icon mr-1">
              <i class="mdi mdi-18px mdi-arrow-left" />
            </span>
            Back to login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/services'
import { Circle9, DoubleBounce, LetterCube, Circle8, Socket, Spinner, RandomSpinner } from '@/components/Spinners'
import { getErrorMessage } from './ErrorMessage'
import GlobalSpinnerMixin from '@/components/mixins/global/GlobalSpinnerMixin'
import NotificationBox from '@/components/Notification/NotificationBox.vue'
import { NotificationBoxType } from '@/enums'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ForgotPasword',
  components: {
    Circle9,
    DoubleBounce,
    LetterCube,
    Circle8,
    Socket,
    Spinner,
    NotificationBox
  },
  mixins: [GlobalSpinnerMixin],
  data() {
    return {
      companyLoginId: '',
      isRequestComplete: false,
      hasError: false,
      message: '',
      notificationType: '',
      username: ''
    }
  },
  computed: {
    randomiseSpinner() {
      return RandomSpinner
    }
  },
  methods: {
    cancel() {
      window.location.href = process.env.VUE_APP_ROOT_URI + '/SignIn.aspx'
    },

    async sendEmail() {
      this.$v.$touch()
      if (this.$v.$error) return

      try {
        this.$showSpinner()
        await AuthService.forgotPassword(this.companyLoginId, this.username, false)
        this.message = 'An email has been sent to your nominated account. Please follow the instructions to reset your password.'
        this.notificationType = NotificationBoxType.Warning
      } catch (e) {
        this.hasError = true
        this.notificationType = NotificationBoxType.Error
        this.message = getErrorMessage(e.response.data).message
      } finally {
        this.isRequestComplete = true
        this.$hideSpinner()
      }
    }
  },
  validations: {
    username: { required },
    companyLoginId: { required }
  }
}
</script>

<style lang="scss" scoped>
.forgot-password-container {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/flexiquoteloginbackground.jpg) top center no-repeat !important;
  background-size: cover !important;
}
.box {
  width: 350px;
}
.label {
  font-weight: 500;
}
</style>